<template>
    <div class="row">
        <div v-if="storeInfo.cruzlife" class="col-12 mb-4 app-header" align="left">
            <div class="row">
              <div class="col-1 " align="right">
                    <h5 class="pointer" @click="$router.push(`/cruzlife`)"><i class="fas fa-arrow-left bold"></i></h5>
              </div>

              <div class="col-10 ">
                <h5 class="pointer bold" @click="$router.push(`/cruzlife`)" >{{$store.state.appTitle}}</h5>
              </div>
            </div>
        </div>     
        &nbsp;  


        <!-- Modal CruzLife menu-->
        <div class="modal fade" id="cruzlifemenu" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
                <div class="modal-content">
                  <div class="modal-header" style="border-bottom:0;">
                      <h5 class="modal-title" style="margin-left:1rem;">{{$store.state.appTitle}}</h5>
                      <button id="btnCloseMenu" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body" style="padding:20px 25px; margin-bottom:2rem;">
                      <CruzBarMenu />
                  </div>
                  </div>
            </div>
        </div>


    </div>
</template>
<script>

    import CruzBarMenu from '@/components/CruzBarMenu.vue'

    export default {
        name: 'CruzBar',
        props: {

        },
        filters: {
            currency: function (value) {
                if (!value) return ''
                return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
            }
        },
        components:{
            CruzBarMenu,
        },
        computed:{
            storeInfo(){
                return this.$store.state.storeinfo
            },
        },
        data(){
            return{
                
            }
        },
        methods:{
          
            dismissModal(cat){
                document.getElementById('btnCloseMenu').click()
                this.$router.push(`/cruzlife/${cat}`)
            },
            async goHome(){
                document.getElementById('btnCloseMenu').click()
                location.href="/cruzlife"
            },
        },
        mounted(){

        }
    }
</script>

