<template>
    <div>
        <div class="row app-header-alt" >
            <div class="col-7" style="padding-left:2rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                <h6 class="my-2">
                    <small class="bold">{{storeInfo.name}}</small><br/>
                    <strong>{{sectionname}}</strong>
                </h6>
            </div>
            <div class="col-5 my-3" align="right">
                <button type="button" class="btn btn-outline-dark btn-sm bold" @click="backMenuClick"><i class="fas fa-book-open"></i> Menu</button>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'


Vue.use(VueToast, {
  position: 'top-right',
  type: 'success'
})

export default {
  name: 'Header',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      sectionname(){
          return this.$store.state.sectionname
      },
  },
  data(){
      return{
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
      }
  },
  methods:{
      backMenuClick(){
          let url = `/${this.storeId}/order/menu`
          this.$router.push(url)
      },
  },
  mounted(){
      let storeid = this.$route.params.store
      if(!window.localStorage.ordertype) {
          setTimeout(()=>{this.$router.push(`/${storeid}`)}, 1500)
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>
