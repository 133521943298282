<template>
    <div  style="padding:0 .2rem;">

        <div class="row" style="padding-bottom:5rem;">
            <div class="col-12">

                <div class="row" v-if="activecat===''">
                <div class="col-6 my-2" v-for="(cat, index) in menusections" :key="index">
                    <button  @click="showItems(cat.id)" class="btn btn-lg btn-block btn-dark" style="padding:40px 15px; max-height:120px; height:120px;">
						{{cat.data.name}}
					</button>
                </div>
                </div>

                <div class="col-12">
                <ul class="list-group list-group-flush" v-for="(cat, index) in menusections" :key="index">
                    <div  v-show="activecat==cat.id">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-9">
                                <p><strong>{{cat.data.name.toUpperCase()}}</strong></p>
                            </div>
                            <div class="col-3" align="right">
                                <a href="#!" class="text-dark" @click="btnBackClick"><strong><i class="fas fa-arrow-left"></i></strong> <small>BACK</small></a>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item" style="display:;">

                        <div class="row">

                        <div class="col-6 my-2" v-for="(item, index) in catitems(cat.id)" :key="index">
                            <button class="btn btn-lg btn-block btn-outline-dark" style="padding:20px 15px; max-height:120px; height:120px;" @click="menuitemClick(item)" data-toggle="modal" data-target="#posmodifiermodal">{{item.data.name}} <!--br> <small>${{item.data.price | currency}}</small--></button>
                        </div>

                        </div>

                    </li>
                    </div>
                </ul>
                </div>

            </div>

        </div>



        <!-- Modal modifiers -->
        <div v-if="activeitem.data" class="modal fade" id="posmodifiermodal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>{{activeitem.data.name}} </strong></h4>
                <button type="button" id="btnPosModalClose" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 5px;">
                  <!--div class="col-12">
                      <center><img style="width:80%; height:auto; max-width:300px; border-radius:1rem;" :src="activeitem.data.photo" /></center>
                      <p class="my-3"><em>{{activeitem.data.desc}}</em></p>
                  </div-->
                  <div class="col-12 my-3">
                      <div style="font-weight:500;" class="my-4" v-for="(modifier, index) in modifiers" :key="index">
                          <span v-if="!modifier.data.multiselect">
                              {{modifier.data.name}} <span class="badge1 badge-secondary1" style="font-size:12px; font-weight:400;"><em>{{(modifier.data.required) ? '(Required)' : '(Optional)'}}</em></span>
                          </span>

                          <!--- single-option modifiers -->
                          <select class="form-control" name="selmodifier" v-if="!modifier.data.multiselect" v-model="itemoptions[index]" @change="updateAddonPricing" :data-required="modifier.data.required">

                              <option :value="{name:'None', price:0}" v-if="modifier.data.required" disabled></option>

                              <option :value="{name:'None', price:0}" v-if="!modifier.data.required">None</option>

                              <option v-for="(option, index) in moditems(modifier.id)" :key="index" :value="{mid:modifier.id, id: option.id, name: option.data.name, price:option.data.price}">
                                  {{option.data.name}}  {{(option.data.price>0) ? '(add $' + option.data.price  + ')' : ''}}
                              </option>
                          </select>

                          <!--- multioption modifiers -->
                          <div v-if="modifier.data.multiselect">
                              <span v-if="modifier.data.multiselect">{{modifier.data.name}}</span>
                              <div class="form-check my-3" v-for="(option, i) in moditems(modifier.id)" :key="i">
                                  <input class="form-check-input my-2" type="checkbox" name="selmodifier" :id="'ch'+option.id" :value="{mid:modifier.id, id: option.id, name: option.data.name, price:option.data.price}" v-model="itemoptions" @change="updateAddonPricing"/>
                                  <label class="form-check-label" style="font-weight:400; font-size:16px;" :for="'ch'+option.id" >
                                      {{option.data.name}} {{(option.data.price>0) ? '(add $' + option.data.price  + ')' : ''}}
                                  </label>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!--div class="col-12">
                      <strong>Order Notes</strong>
                      <textarea rows="2" class="form-control" placeholder="Order notes..." v-model="itemnotes"/>
                  </div-->
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-3" style="padding-left:7px !important; padding-right:7px !important;">
                      <select class="form-control" v-model="itemqty">
                          <option v-for="i in 10" :key="i">{{i}}</option>
                      </select>
                  </div>
                  <div align="right" class="col-9" style="padding-left:7px !important; padding-right:7px !important;">
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal1" aria-label="Close1" @click="btnAddClick" >ADD {{lineitemtotal | currency}} TO ORDER</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <Footer :poscart="cart" :activeloc="activeloc" @reset-cart="cart=[]" @reset-menu="activecat=''" @remove-cart-item="cart.splice($event,1)" />

    </div>
</template>

<script>
import Vue from 'vue'
//import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import Footer from '@/components/PosFooter.vue'

/*Vue.use(VueToast, {
  // One of the options
  position: 'top',
  type: 'danger'
})*/

const fb = require('../firebaseConfig.js')

export default {
  name: 'POS',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{
    Footer,
  },
  computed:{
      storeInfo(){
          //console.log(this.$store.state.storeinfo.pagesettings)
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      menusections(){
          return this.$store.state.posmenusections
      },
      menuitems(){
          let search = ''
          if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
          const items = this.$store.state.menuitems
          const filter = (item)=>{
                              //return (item.data.name.toLowerCase().includes(search) && !this.unavailablelist.includes(item.id))

                              return (item.data.name.toLowerCase().includes(search))
                          }

          return items.filter(filter)
      },
      modifiers(){
          const modifiers = this.$store.state.posmodifiers
          const modifierlist = this.activeitem.data.modifiers
          const filter = (modifier)=>{
                              return (modifierlist.includes(modifier.id) && !this.unavailablelist.includes(modifier.id))
                          }

          return modifiers.filter(filter)
      },
      modifieritems(){
          return this.$store.state.modifieritems
      },
      /*orderid(){
          return this.$store.state.orderid
      },
      orderloc(){
          return this.$store.state.orderlocation
      },*/
      lineitemtotal(){
          return (this.itemqty * (this.itemprice + this.itemaddonprice))
      },
      /*cart(){
          return this.$store.state.cart
      },*/
      itemValid(){
          for(var i=0; i<this.itemoptions.length; i++){
              let mname = this.itemoptions[i].name
              if(!document.getElementsByName("selmodifier")[i]) return false
              let required = document.getElementsByName("selmodifier")[i].getAttribute("data-required")
              if(required && mname==='None'){
                  return false
              }
          }

          return true
      },
  },
  data(){
      return{
          activeloc:{},
          txtsearch:'',
          activeitem:{},
          activecat:'',
          cart:[],
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
          itemnotes:'',
          modifiererror:'*',
          unavailablelist:[],
      }
  },
  methods:{
      btnBackClick(){
          this.activecat = ''
      },
      showItems(item){
			this.activecat = item
      },
      catitems(catid){
          const items = this.$store.state.menuitems
          const filter = function(item){
                              return (item.data.section===catid)
                          }

          return items.filter(filter)
      },
      modifierinfo(modifierid){
          //console.log(this.modifiers)
          const modifiers = this.modifiers
          const filter = (modifier)=>{
                              return (modifier.id===modifierid)
                          }

          return modifiers.filter(filter)
      },
      moditems(modid){
			console.log(modid);
          const modlist = this.modifieritems
          const filter = (mod)=>{
                              return (mod.data.section===modid && !this.unavailablelist.includes(mod.id))
                          }

          return modlist.filter(filter)
			//return modlist
      },
      menuitemClick(index){
          this.activeitem = index
          this.itemqty = 1
          this.itemprice = this.activeitem.data.price
          this.itemaddonprice = 0
          this.itemname = this.activeitem.data.name
          this.itemoptions = []
          this.itemaddons = []
          this.modifiererror = '*'

          //set default value of modifier options for the dropdowns
          let j=0
          let modifierlist = this.activeitem.data.modifiers

          for(var i=0; i<modifierlist.length; i++){
              let modifier = this.modifierinfo(modifierlist[i])
              if(modifier[0].data.multiselect === false){ 
                  this.itemoptions[j] = {name:'None', price:0}
                  j++
                  //console.log(modifierlist[i])
              }
          }

      },
      updateAddonPricing(){
          this.itemaddonprice = 0
          //console.log(this.itemoptions)
          //console.log(this.itemaddons)

          for(var i=0; i<this.itemoptions.length; i++){
              this.itemaddonprice += this.itemoptions[i].price
          }

          for(var j=0; j<this.itemaddons.length; j++){
              this.itemaddonprice += this.itemaddons[j].price
          }
      },
      btnAddClick(){
          /*let itemqty = {this.itemqty
          let itemoptions = this.itemoptions
          let itemprice = this.itemprice
          let itemaddonprice = this.itemaddonprice
          let itemname = this.itemname
          let itemid = this.itemid*/

          //check for blank modifiers
          for(var i=0; i<this.itemoptions.length; i++){
              //let mid = this.itemoptions[i].mid
              let mname = this.itemoptions[i].name
              let required = document.getElementsByName("selmodifier")[i].getAttribute("data-required")
              if(required && mname==='None'){
                  //alert('Missing required options.')
                  Vue.$toast.error('Missing required variation!', {position:'top'})
                  this.modifiererror = '(Required)'
                  return false
              }
              //console.log(i, document.getElementsByName("selmodifier")[i].getAttribute("data-required"))
          }

          let cartobj = {qty: this.itemqty, itemid: this.activeitem.id, item: this.itemname, modifiers: this.itemoptions, price:this.lineitemtotal, subtotal:this.lineitemtotal, notes:this.itemnotes}

          this.cart = [...this.cart, cartobj]

          //console.log(this.cart)

          document.getElementById("btnPosModalClose").click()
      },
  },
  mounted(){
      /*setTimeout(()=>{
          fb.unavailableCollection.where("storeid", "==", this.storeId).onSnapshot(querySnapshot=>{
              this.unavailablelist.length = 0
              querySnapshot.forEach(doc=>{
                  this.unavailablelist.push(doc.data().id)
              })
              if(this.unavailablelist.length<1) this.unavailablelist = []
          })
      }, 1500)*/

      //console.log("poslocation", this.$route.params.location)
      let location = this.$route.params.location

      fb.locationsCollection.doc(location).onSnapshot((doc) =>{
          if (doc.exists) {
              this.activeloc = {id:doc.id, data:doc.data()}
              /*this.validkey = true
              this.leadtime = doc.data().leadtime
              this.locationname = doc.data().name*/

          } else {
              console.log("No such document!")
          }
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    body{
        font-size:18px;
    }

</style>
