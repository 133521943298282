<template>
    <div class="row">
        <div class="my-2 mb-4 col-lg-6 col-12"><input type="text" class="form-control" placeholder="Search locations ..." v-model="txtsearch"/></div>
        <div align="right"  class="my-2 mb-4 col-12 col-lg-6">
            <button type="button" class="btn btn-primary mr-2"  @click="copyLiveOrdersLink"><i class="fas fa-copy"></i> Copy Live Orders Link</button>
            <input type="text" style="display:none;" :value="'https://orderuptoday.com/'+storeid+'/live-orders'" id="liveOrdersLink">

            <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#locationform" @click="editLocation()"><i class="fas fa-plus"></i> Add New Location</button>
        </div>
        <div class="table-responsive-lg col-lg-12">
            <p align="center" class="my-5" v-show="locationList.length<1"><em>Your locations will appear here when added... </em></p>
            <table class="table table-bordered" v-show="locationList.length>0">
              <thead class="thead-primary">
              <tr>
                  <th>Address</th>
                  <th>Location Key</th>
                  <th>Lead Time</th>
                  <th></th>
              </tr>
              </thead>
              <tbody>
              <tr class="small" v-for="(location, index) in locationList" :key="index">
                  <td>
                      <h5>{{location.data.name}}</h5>
                      {{location.data.address.street}}, {{location.data.address.city}} 
                      <em><span class="small badge badge-danger" v-show="location.data.public===false">Offline</span> 
                      <span class="small badge badge-danger" v-show="storelocations.length>0 && location.data.active===false">Inactive</span></em>
                      <br><small>{{location.data.email}} / {{location.data.phone}}</small>
                  </td>
                  <td>{{String(location.id).toUpperCase()}} <br><a :href="'/'+location.data.storeid+'/live-orders/'+location.id" target="_liveorders">(view live orders)</a> 
                  <br><a :href="'/'+location.data.storeid+'/kitchen-orders/'+location.id" target="_kitchen">(view kitchen orders)</a></td>
                  <td>{{location.data.leadtime}} mins
                      <span v-show="leadtime[location.id]!=true"><a href="#!" @click="editLeadtime(location.id)">(edit)</a></span>
                      <div v-show="leadtime[location.id]===true"><input class="form-control mb-1" type="number" step="1" v-model="txtleadtime[location.id]"/><button type="button" class="btn btn-secondary mr-1"  @click="cancelLeadtime(location.id)">Cancel</button>
                      <button type="button" class="btn btn-primary" @click="saveLeadtime(location.id)">Save</button></div>
                  </td>
                  <td>
                      <div class="input-group-append">
                          <button class="btn btn-link dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Options</button>
                          <div class="dropdown-menu">
                              <a :href="'/'+location.data.storeid+'/live-orders/'+location.id" target="_liveorders" style="" class="btn btn-primary dropdown-item">View Live Orders</a>

                              <a :href="'/'+location.data.storeid+'/kitchen-orders/'+location.id" target="_kitchen" style="" class="btn btn-primary dropdown-item">Kitchen View</a>
                            
                              <div class="dropdown-divider"></div>

                              <a class="dropdown-item" href="#!" data-toggle="modal" data-target="#locationform" @click="editLocation(location.id, location.index)">Edit Location</a>
                              <a class="dropdown-item" href="#!" data-toggle="modal" data-target="#openinghours" @click="editOpenHours(location.id, location.index)">Edit Opening Hours</a>
                              <a class="dropdown-item" href="#!" @click="takeOffline(location.id, false)" v-show="location.data.public===true">Disable Online Ordering</a>
                              <a class="dropdown-item" href="#!" @click="takeOffline(location.id, true)" v-show="location.data.public===false">Enable Online Ordering</a>

                              <a class="dropdown-item" href="#!" @click="markInactive(location.id, false)"  v-show="storelocations.length>0 && location.data.active===true">Disable Location</a>
                              <a class="dropdown-item" href="#!" @click="markInactive(location.id, true)"  v-show="storelocations.length>0 && location.data.active===false">Enable Location</a>
                              <!--a class="dropdown-item" href="#" v-show="storelocations.length>1">Delete Location</a-->
                          </div>
                      </div>
                  </td>
              </tr>
              </tbody>
            </table>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="locationform" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Location Information</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-lg-12 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.name" placeholder="Name of this location"/>
                      </div>
                      <div class="col-lg-12 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.address.street" placeholder="Street"/>
                      </div>
                      <div class="col-lg-3 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.address.city" placeholder="City"/>
                      </div>
                      <div class="col-lg-3 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.address.state" placeholder="State"/>
                      </div>
                      <div class="col-lg-2 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.address.zip" placeholder="Zip"/>
                      </div>
                      <div class="col-lg-4 mb-3">
                          <select class="form-control" v-model="locationinfo.address.country">
                              <option>Antigua</option>
                              <option>Barbados</option>
                              <option>Grenada</option>
                              <option>Jamaica</option>
                              <option>St. Lucia</option>
                              <option>St. Vincent</option>
                              <option>Trinidad and Tobago</option>
                          </select>
                      </div>
                      <div class="col-lg-6 mb-3">
                          <input type="text" class="form-control" v-model="locationinfo.email" placeholder="Email address"/>
                      </div>
                      <div class="col-lg-6 mb-3">
                          <input type="text" class="form-control"  v-model="locationinfo.phone" placeholder="Phone number" />
                      </div>
                      <p class="my-4 col-12"><strong>Lead Time (mins.)</strong></p>

                      <div class="col-lg-4 mb-4">
                          <input type="number" class="form-control"  v-model="locationinfo.leadtime" placeholder="mins." />
                      </div>
                      <div class="col-lg-8 mb-4">
                          
                      </div>

                      <p class="my-4 col-12"><strong>Currency</strong></p>

                      <div class="col-lg-6 mb-4">
                          <select class="form-control" v-model="locationinfo.currency">
                              <option value="bbd">Barbadian Dollar</option>
                              <option value="xcd">Eastern Caribbean Dollar</option>
                              <option value="jmd">Jamaican Dollar</option>
                              <option value="ttd">Trinidad & Tobago Dollar</option>
                              <option value="usd">United States Dollar</option>
                          </select>
                      </div>
                      <div class="col-lg-6 mb-4">
                          
                      </div>


                      <p class="my-4 col-12"><strong>Taxes and Fees</strong></p>

                      <div class="col-lg-4 mb-4">
                          <label>Taxes(%)</label>
                          <input type="text" class="form-control"  v-model="locationinfo.fees.tax" placeholder="Tax (%)" />
                      </div>
                      <div class="col-lg-4 mb-4">
                          <label>Service Charge(%)</label>
                          <input type="text" class="form-control"  v-model="locationinfo.fees.service" placeholder="Service charge (%)" />
                      </div>
                      <div class="col-lg-4 mb-4">
                          <label>Delivery Fee (flat rate)</label>
                          <input type="number" class="form-control"  v-model="locationinfo.fees.delivery" placeholder="Delivery fee" />
                      </div>

                      <p class="my-4 col-12"><strong>Order Management</strong></p>

                      <div class="row col-12 mb-3">
                          <div class="col-lg-4 col-12 mb-3">
                              <strong>Receiving Orders</strong>
                              <br><small>How would like to be notified of orders?</small>
                              <ul class="list-group my-3">
                                  <li class="list-group-item pointer">
                                      Receive via SMS <small>(rates apply)</small>
                                       <span style="float:right;"><input type="checkbox" class="form-check-input"  value="true"   v-model="locationinfo.receiveorders.sms" /></span>
                                  </li>
                              </ul>
                              <ul class="list-group my-3">
                                  <li class="list-group-item pointer">
                                      Receive via Email
                                       <span style="float:right;"><input type="checkbox" class="form-check-input"  value="true"   v-model="locationinfo.receiveorders.email"/></span>
                                  </li>
                              </ul>
                          </div>
                          <div class="col-lg-4 col-12 mb-3">
                              <strong>Order Types</strong>
                              <br><small>How can customers receive their orders?</small>
                              <ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Pickup In-store
                                       <span style="float:right;"><input type="checkbox" class="form-check-input"   value="true"   v-model="locationinfo.deliveryoptions.pickup"/></span>
                                  </li>
                              </ul>
                              <ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Curbside Pickup
                                       <span style="float:right;"><input type="checkbox" class="form-check-input"  value="true"   v-model="locationinfo.deliveryoptions.curbside"/></span>
                                  </li>
                              </ul>
                              <ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Delivery
                                       <span style="float:right;"><input type="checkbox" class="form-check-input"  value="true"   v-model="locationinfo.deliveryoptions.delivery"/></span>
                                  </li>
                              </ul>
                          </div>
                          <div class="col-lg-4 col-12 mb-3">
                              <strong>Payment Methods</strong>
                              <br><small>Which payment methods do you want to accept?</small>
                              <ul class="list-group my-3">
                                  <li class="list-group-item">
                                      Pay in-person with Cash
                                      <span style="float:right;"><input type="checkbox" class="form-check-input" value="true"   v-model="locationinfo.paymentoptions.cashonpickup"/></span>
                                  </li>
                              </ul>
                              <ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Pay in-person Debit or Credit Card
                                       <span style="float:right;"><input type="checkbox" class="form-check-input" value="true"   v-model="locationinfo.paymentoptions.cardonpickup"/></span>
                                  </li>
                              </ul>
                              <ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Pay online with Credit Card
                                       <span style="float:right;"><input type="checkbox" class="form-check-input" value="true"   v-model="locationinfo.paymentoptions.creditcard"/></span>
                                  </li>
                              </ul>
                              <!--ul class="list-group my-3">
                                  <li class="list-group-item ">
                                      Credit Card (other)
                                       <span style="float:right;"><input type="checkbox" class="form-check-input" value="true" disabled/></span>
                                       <br><small>Please contact us</small>
                                  </li>
                              </ul-->
                          </div>
                      </div>


                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="saveLocation(activelocation)" :disabled="!locationFormValid">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="openinghours" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"><strong>{{activelocationname}}</strong> <small> - Opening Hours</small></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">

                      <ul class="list-group">
                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Monday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.monday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.monday.from" v-show="openhours.monday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.monday.to" v-show="openhours.monday.open===true && openhours.monday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Tuesday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.tuesday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.tuesday.from" v-show="openhours.tuesday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.tuesday.to" v-show="openhours.tuesday.open===true && openhours.tuesday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Wednesday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.wednesday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.wednesday.from" v-show="openhours.wednesday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.wednesday.to" v-show="openhours.wednesday.open===true && openhours.wednesday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Thursday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.thursday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.thursday.from" v-show="openhours.thursday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.thursday.to" v-show="openhours.thursday.open===true && openhours.thursday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Friday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.friday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.friday.from" v-show="openhours.friday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.friday.to" v-show="openhours.friday.open===true && openhours.friday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Saturday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.saturday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.saturday.from" v-show="openhours.saturday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.saturday.to" v-show="openhours.saturday.open===true && openhours.saturday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>

                          <li class="list-group-item">
                              <div class="row col-12">
                                  <div class="col-lg-3">
                                      Sunday
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" @change="showHours"  v-model="openhours.sunday.open">
                                          <option :value="true">Open</option>
                                          <option :value="false">Closed</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.sunday.from" v-show="openhours.sunday.open===true">
                                          <option value="0" disabled>From</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="24:00">24 hours</option>
                                      </select>
                                  </div>
                                  <div class="col-lg-3">
                                      <select class="form-control" v-model="openhours.sunday.to" v-show="openhours.sunday.open===true && openhours.sunday.from!='24:00'">
                                          <option value="0" disabled>To</option>
                                          <option value="0:00">0:00</option>
                                          <option v-for="
                                          (hour, index) in 23" :key="index" :value="hour+':00'">{{hour}}:00</option>
                                          <option value="23:59">23:59</option>
                                      </select>
                                  </div>
                              </div>
                          </li>
                      </ul>

              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" :disabled="!openHoursFormValid || isbusy" @click="saveOpenHours">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'

const fb = require('../firebaseConfig.js')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'ScheduleOrders',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{

  },
  computed:{
      locationList(){
          let locations = this.storelocations
          if(!this.txtsearch) return this.storelocations
          const search = this.txtsearch.toLowerCase()
          const filter = function(location){
                              return (location.data.address.street.toLowerCase().includes(search) || location.data.address.city.toLowerCase().includes(search) || location.data.address.state.toLowerCase().includes(search) || location.data.address.country.toLowerCase().includes(search) || location.data.address.zip.toLowerCase().includes(search) || location.data.email.toLowerCase().includes(search) || location.data.phone.toLowerCase().includes(search) )
                          }
          return locations.filter(filter)
      },
      locationFormValid(){
          //let locationinfo = this.locationinfo

          if(!this.locationinfo.name) return false
          if(!this.locationinfo.address.street) return false
          if(!this.locationinfo.address.city) return false
          if(!this.locationinfo.address.state) return false
          if(!this.locationinfo.address.zip) return false

          if(!this.locationinfo.leadtime) return false

          if(!String(this.locationinfo.fees.tax)) return false
          if(!String(this.locationinfo.fees.service)) return false
          if(!String(this.locationinfo.fees.delivery)) return false

          if(!this.locationinfo.email) return false
          if(!this.locationinfo.phone) return false
          return true
      },
      openHoursFormValid(){
          let openhours = this.openhours
          if(openhours.monday.open && (!openhours.monday.from  || !openhours.monday.to)) return false
          if(openhours.tuesday.open && (!openhours.tuesday.from  || !openhours.tuesday.to)) return false
          if(openhours.wednesday.open && (!openhours.wednesday.from  || !openhours.wednesday.to)) return false
          if(openhours.thursday.open && (!openhours.thursday.from  || !openhours.thursday.to)) return false
          if(openhours.friday.open && (!openhours.friday.from  || !openhours.friday.to)) return false
          if(openhours.saturday.open && (!openhours.saturday.from  || !openhours.saturday.to)) return false
          if(openhours.sunday.open && (!openhours.sunday.from  || !openhours.sunday.to)) return false
          return true
      },
  },
  data(){
      return{
          isbusy:false,
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          leadtime:[],
          txtleadtime:[],
          modaltitle:'',
          activelocation:0,
          activelocationname:'',
          openhours:{monday:{open:false, from:0, to:0}, tuesday:{open:false, from:0, to:0}, wednesday:{open:false, from:0, to:0}, thursday:{open:false, from:0, to:0}, friday:{open:false, from:0, to:0}, saturday:{open:false, from:0, to:0}, sunday:{open:false, from:0, to:0}},
          locationinfo:{address:{}, fees:{}, paymentoptions:{}, deliveryoptions:{}, receiveorders:{}, currency:'ttd'},
      }
  },
  methods:{
      showHours(){
          //alert(this.openhours.monday.open)
      },
      editLeadtime(id){
          this.$set(this.leadtime, id, true)
      },
      cancelLeadtime(id){
          this.$set(this.leadtime, id, false)
      },
      saveLeadtime(id){
          let leadtime = this.txtleadtime[id]
          if(leadtime>4){
              fb.locationsCollection.doc(id).update({
                  leadtime: Number(leadtime),
              }).then(()=>{
                  Vue.$toast.open('Lead time updated!')
              })
          }
          this.$set(this.leadtime, id, false)
      },
      editOpenHours(id, index){
          this.activelocation = id
          this.activelocationname = this.storelocations[index].data.name
          if(this.storelocations[index].data.openhours) this.openhours = this.storelocations[index].data.openhours
          console.log(this.storelocations[index].data.openhours)
      },
      saveOpenHours(){
          fb.locationsCollection.doc(this.activelocation).update({
              openhours: this.openhours,
          }).then(()=>{
              Vue.$toast.open('Opening hours saved!')
          })
      },
      takeOffline(id, status){
          fb.locationsCollection.doc(id).update({
              public: status,
          }).then(()=>{
              Vue.$toast.open('Online status changed!')
          })
      },
      markInactive(id, status){
          fb.locationsCollection.doc(id).update({
              active: status,
          }).then(()=>{
              Vue.$toast.open('Online status changed!')
          })
      },
      editLocation(id, index){
          if(id){
              this.activelocation = id
              this.locationinfo =  this.storelocations[index].data       
          }else{
              this.activelocation = ''
              this.locationinfo = {address:{country:'Trinidad and Tobago'}, currency:'ttd', fees:{}, paymentoptions:{}, deliveryoptions:{}, receiveorders:{}}
              //alert()
          }
      },
      saveLocation(id){
          let locationinfo = this.locationinfo
          locationinfo.fees.delivery = Number(locationinfo.fees.delivery)
          locationinfo.fees.tax = Number(locationinfo.fees.tax)
          locationinfo.fees.service = Number(locationinfo.fees.service)
          if(id){
              fb.locationsCollection.doc(this.activelocation).update({
                  name: locationinfo.name,
                  address: locationinfo.address,
                  email: locationinfo.email,
                  phone: locationinfo.phone,
                  currency: locationinfo.currency,
                  leadtime: locationinfo.leadtime,
                  paymentoptions: locationinfo.paymentoptions,
                  deliveryoptions: locationinfo.deliveryoptions,
                  receiveorders: locationinfo.receiveorders,
                  fees: locationinfo.fees,
              }).then(()=>{
                  Vue.$toast.open('Location info saved!')
              })
              
          }else{
              fb.locationsCollection.add({
                  name: locationinfo.name,
                  address: locationinfo.address,
                  email: locationinfo.email,
                  phone: locationinfo.phone,
                  storeid: this.storeid,
                  leadtime:30,
                  currency: locationinfo.currency,
                  openhours:{monday:{open:true, from:'10:00', to:'22:00'}, tuesday:{open:true, from:'10:00', to:'22:00'}, wednesday:{open:true, from:'10:00', to:'22:00'}, thursday:{open:true, from:'10:00', to:'22:00'}, friday:{open:true, from:'10:00', to:'22:00'}, saturday:{open:true, from:'10:00', to:'22:00'}, sunday:{open:true, from:'10:00', to:'22:00'}},
                  paymentoptions: locationinfo.paymentoptions,
                  deliveryoptions: locationinfo.deliveryoptions,
                  receiveorders: locationinfo.receiveorders,
                  fees: locationinfo.fees,
                  public:true,
                  active:true,
              }).then(()=>{
                  Vue.$toast.open('Location info saved!')
              })
          }
      },
      viewHistory(){

      },
      viewOrder(order){
          this.singleorder = order
          this.cart = order.details
      },
      copyLiveOrdersLink(){
          let copytext = document.getElementById('liveOrdersLink')
          //alert(copytext.value)
          copytext.select()
          copytext.setSelectionRange(0, 99999)
          document.execCommand("copy")
          Vue.$toast.open('Link copied to clipboard')
      },
      
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      fb.locationsCollection.where("storeid", "==", storeid).orderBy("name", "asc").onSnapshot((querySnapshot)=>{
          let i=0
          this.storelocations.length=0
          querySnapshot.forEach((doc)=>{
              this.storelocations.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
