<template>
  <div>
    <OrderHome />
  </div>
</template>

<script>
// @ is an alias to /src
import OrderHome from '@/components/OrderHome.vue'

export default {
  name: 'Order',
  components: {
    OrderHome
  }
}
</script>
