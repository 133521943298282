import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

import Auth from '../views/Auth.vue'
import Home from '../views/Home.vue'
import Order from '../views/Order.vue'
import OrderStart from '../views/OrderStart.vue'
import LiveOrders from '../views/LiveOrders.vue'
import AdminLiveOrders from '../views/AdminLiveOrders.vue'
import KitchenOrders from '../views/KitchenOrders.vue'
import Dashboard from '../views/Dashboard.vue'
import Expenses from '../views/Expenses.vue'
import OrderMenu from '../views/OrderMenu.vue'
import MenuItems from '../components/MenuItems.vue'
import OrderItem from '../views/OrderItem.vue'
import CheckOut from '../views/CheckOut.vue'
import ViewOrder from '../views/ViewOrder.vue'
import SignUp from '../views/SignUp.vue'
import BusinessSignUp from '../views/BusinessSignUp.vue'
import Login from '../views/Login.vue'
import ViewMenu from '../views/ViewMenu.vue'
import BillPay from '../views/BillPay.vue'
import Settings from '../views/Settings.vue'
import Terms from '../views/Terms.vue'
import Privacy from '../views/Privacy.vue'
import Support from '../views/Support.vue'
import Promotions from '../views/Promotions.vue'
import MenuEditor from '../views/MenuEditor.vue'
import Locations from '../views/Locations.vue'
import CruzLife from '../views/CruzLife.vue'
import CruzLifeCategory from '../views/CruzLifeCategory.vue'
import CruzLifeFeed from '../views/CruzLifeFeed.vue'
import CruzLifeFeedAdd from '../views/CruzLifeFeedAdd.vue'
import CruzLifeEvents from '../views/CruzLifeEvents.vue'
import CruzLifeProfile from '../views/CruzLifeProfile.vue'
import CruzLifeInfo from '../views/CruzLifeInfo.vue'
import CruzLifeDirectory from '../views/CruzLifeDirectory.vue'
import CruzLifeShop from '../views/CruzLife.vue'
import ReOrder from '../views/ReOrder.vue'
import OrderHistory from '../views/OrderHistory.vue'
import PastOrders from '../views/PastOrders.vue'
import StoreInfo from '../views/StoreInfo.vue'
import DemoImporter from '../views/MenuEditorTest.vue'
import AdminLDeliveryLocations from '../views/AdminLDeliveryLocations.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/app',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth:false,
      }
    },
    {
    path: '/',
    name: 'CruzLife',
    component: CruzLife,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife',
    name: 'CruzLife',
    component: CruzLife,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/shop',
    name: 'CruzLifeShop',
    component: CruzLifeShop,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/profile',
    name: 'CruzLifeProfile',
    component: CruzLifeProfile,
    meta: {
      requiresAuth:true,
      authType:`personal`,
    }
  },
  {
    path: '/cruzlife/info',
    name: 'CruzLifeInfo',
    component: CruzLifeInfo,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/directory',
    name: 'CruzLifeDirectory',
    component: CruzLifeDirectory,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/feed',
    name: 'CruzLifeFeed',
    component: CruzLifeFeed,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/feed/add',
    name: 'CruzLifeFeedAdd',
    component: CruzLifeFeedAdd,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/events',
    name: 'CruzLifeEvents',
    component: CruzLifeEvents,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/cruzlife/past-orders',
    name: 'PastOrders',
    component: PastOrders,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/cruzlife/:category',
    name: 'CruzLifeCategory',
    component: CruzLifeCategory,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/business-signup',
    name: 'BusinessSignUp',
    component: BusinessSignUp,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/menu-editor',
    name: 'MenuEditor',
    component: MenuEditor,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/promotions',
    name: 'Promotions',
    component: Promotions,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/view-menu',
    name: 'ViewMenu',
    component: ViewMenu,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/view-order/:orderid',
    name: 'ViewOrder',
    component: ViewOrder,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/view-order/:orderid',
    name: 'ViewOrder',
    component: ViewOrder,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/billpay/:storeid',
    name: 'BillPay',
    component: BillPay,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/admin/live-orders',
    name: 'AdminLiveOrders',
    component: AdminLiveOrders,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/admin/delivery-locations',
    name: 'AdminLDeliveryLocations',
    component: AdminLDeliveryLocations,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/locations',
    name: 'Locations',
    component: Locations,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/expenses',
    name: 'Expenses',
    component: Expenses,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/kitchen-orders/:location',
    name: 'KitchenOrders',
    component: KitchenOrders,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/live-orders',
    name: 'LiveOrders',
    component: LiveOrders,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/live-orders/:location',
    name: 'LiveOrders',
    component: LiveOrders,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/:store/demo-importer',
    name: 'DemoImporter',
    component: DemoImporter,
    meta: {
      requiresAuth:true,
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/reorder',
    name: 'ReOrder',
    component: ReOrder,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/info',
    name: 'StoreInfo',
    component: StoreInfo,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/history',
    name: 'OrderHistory',
    component: OrderHistory,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/order/checkout',
    name: 'CheckOut',
    component: CheckOut,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/order/:orderid',
    name: 'OrderMenu',
    component: OrderMenu,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/order/menu/:catid',
    name: 'OrderItem',
    component: OrderItem,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/:category/:item',
    name: 'MenuItems',
    component: MenuItems,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store/start-order',
    name: 'OrderStart',
    component: OrderStart,
    meta: {
      requiresAuth:false,
    }
  },
  {
    path: '/:store',
    name: 'Order',
    component: Order,
    meta: {
      requiresAuth:false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  //alert(to.path);

  if (requiresAuth && !currentUser) {
      next('/login?from='+to.path)
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router
