<template>
	<div class="" style="margin-bottom:100px;" v-show="pageload==true">
		<button @click="topFunction" id="myBtn" class="btn btn-primary btn-lg floating-button" title="Go to top"><i class="fas fa-chevron-up"></i></button>
		
		<AdminBar />
		
		<div class="col-12 my-2" style="padding-top:5rem;">

			<div class="row">
				<h2 class="col-lg-6 mb-4"><strong class="">{{storeinfo.name}}</strong></h2>

				<div class="col-lg-6">
					<!--a :href="'/'+storeid" target="storepage" style="float:right;" class="btn btn-primary mr-3">View Store <i class="fas fa-desktop"></i></a>

					<a :href="'/'+storeid+'/menu-editor'" target="_menueditor" style="float:right;" class="btn btn-primary mr-3">Menu Editor <i class="fas fa-hamburger"></i></a-->
				</div>
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="!currentUser.emailVerified">
				Please verify your email. <a href="#!" @click="verifyEmail">Click here to resend verification link?</a> 
			</div>

			<!--div class="alert alert-primary my-3" role="alert" v-if="storeinfo.pagesettings && !storeinfo.pagesettings.logo">
				Please upload your company logo and cover photo. <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="locations.length<1">
				Please create at least one location for your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="menuItems.length<1">
				Please add your menu to your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div-->
			
		</div>
		<div class="tab-content dashboard-tabs">
			<MenuEditor />
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import MenuEditor from '@/components/MenuEditor.vue'
import AdminBar from '@/components/AdminBar.vue'

const fb = require('../firebaseConfig.js')
var moment = require('moment')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'Dashboard',
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("YYYY-MM-DD hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components: {
    AdminBar, MenuEditor
  },
  computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		todaysales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(0, 'days').startOf('Day').unix()
			let cursorend = moment().subtract(0, 'days').endOf('Day').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return [sales, count]
		},
		yesterdaysales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(1, 'days').startOf('Day').unix()
			let cursorend = moment().subtract(1, 'days').endOf('Day').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		lastweeksales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(1, 'weeks').startOf('Week').unix()
			let cursorend = moment().subtract(1, 'weeks').endOf('Week').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return [sales, count]
		},
		weeksales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(0, 'weeks').startOf('Week').unix()
			let cursorend = moment().subtract(0, 'weeks').endOf('Week').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		lastmonthsales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(1, 'month').startOf('Month').unix()
			let cursorend = moment().subtract(1, 'month').endOf('Month').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return [sales, count]
		},
		monthsales(){
			let sales = 0
			let count = 0
			let cursorstart = moment().subtract(0, 'month').startOf('Month').unix()
			let cursorend = moment().subtract(0, 'month').endOf('Month').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorstart && order.id< cursorend) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorstart && order.id< cursorend)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		locations(){
			return this.$store.state.locations
		},
		menuItems(){
			return this.$store.state.menuitems
		},


  },
  data(){
		return{
			storeinfo:[],
			storeid:'',
			pendingorders:0,
			readyorders:0,
			datacollection: {},
			orders:[],
			payments:[],
			validkey:null,
			pageload:null,
		}
  },
  methods:{
		topFunction(){
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		},
		verifyEmail(){
			fb.auth.currentUser.sendEmailVerification().then(()=>{
				Vue.$toast.open('Verification email sent!')
			})
		},
		toggleOnlineStatus(status){
			let msg = (status) ? "Turn ON online ordering?" : "Turn OFF online ordering?"
			var r = confirm(msg)
			if (r == true) {
				fb.storesCollection.doc(this.storeid).update({
					isopen: status,
				})
				.then(()=> {
					Vue.$toast.open('Online ordering status successfully updated!')
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
		},
		togglePublishStatus(status){
			let msg = (status) ? "Publish your store?" : "Unpublish your store?"
			var r = confirm(msg)
			if (r == true) {
				fb.storesCollection.doc(this.storeid).update({
					public: status,
				})
				.then(()=> {
					Vue.$toast.open('Store published status successfully updated!')
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
		},
		pendingOrderCount(params){
			this.pendingorders = params
		},
		readyOrderCount(params){
			this.readyorders = params
		},
		signOut(){
			this.$store.dispatch('logout')
		},
  },
  mounted(){

		//console.log(this.currentUser.uid)

		const storeid = this.$route.params.store
		this.storeid = storeid

		fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
			if (doc.exists) {
				if(this.currentUser.uid != doc.data().user) {
					this.$store.dispatch('logout')
					return false
				}
				this.storeinfo = doc.data()
				this.pageload = true
			} else {
				console.log("No such document!")
				this.$router.push('/login')
			}
		})

		let cursorstart = moment().subtract(1, 'month').startOf('Month').unix()

		fb.ordersCollection.where("storeid", "==", storeid).where("status", "in", ["pending", "ready", "complete"]).where("id", ">=", cursorstart).onSnapshot(querySnapshot=>{
			this.orders = []
			querySnapshot.forEach((doc)=>{
				this.orders.push(doc.data())
			})
			if(this.orders.length==0) this.orders = []
		

			//render graph

			let duration = 7

			let labels = []
			let dataset = []
			for(var d=duration; d>-1; d--){
				labels.push(moment().subtract(d, 'd').startOf('day').format('MMM DD'))
				
				let sales = 0
				let cursorstart = moment().subtract(d, 'd').startOf('day').unix()
				let cursorend = moment().subtract(d, 'd').endOf('day').unix()

				const filter = (order)=>{
									if (order.id>=cursorstart && order.id< cursorend) {
										sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									}
									return(order.id>=cursorstart && order.id< cursorend)
								}

				this.orders.filter(filter)
				dataset.push(sales)
			}

			this.datacollection = {
				labels: labels,
				datasets: [
					{
						//label: '`Last ${duration} days`',
						label:'',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						borderColor: '#007bff',
						pointBackgroundColor: '#007bff',
						data: dataset,
						lineTension:0,
					}
				],
			}

		})
	}
}
</script>

<style>
	.dashboard-count {font-size:80px; color:#fff;}
	.dashboard-title {color:#fff;}

	.dashboard-tabs1 .tab-pane{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}

	.floating-button{
		display: ;
		position: fixed;
		bottom: 20px;
		right: 30px;
		z-index: 80;
		font-size: 18px;
		padding: 15px;
		border-radius: 100%;
		width:60px;
		height:60px;
	}
</style>