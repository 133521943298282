<template>
    <div class="row">
        <div class="row col-12">
            <div class="col-lg-7 my-3">
                <input type="text" class="form-control" v-model="txtsearch" placeholder="Search results..." />
            </div>
            <div class="col-lg-2 my-3" align="right">
                <div class="btn-group btn-block">
                    <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#billpayfilterform">Apply Filter</button>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"><i class="fas fa-file-csv"></i> Export .csv</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 my-3" align="right">
                <a :href="'/billpay/'+storeid" class="btn btn-outline-primary" target="_billpay">Public Online Payment Link</a>
            </div>
        </div>
        <div class="col-12 my-2">
            <div align="center" class="my-5" v-if="orders.length<1">
                <em>Search query returned zero results...</em>
            </div>
            <div class="row col-12 text-white" v-if="orders.length>0">
                <div align="center" class="col-12 col-lg-3 my-2">
                    <div align="center" class="card card-body bg-primary">
                        Sales: ${{totalsales[0] | currency}}
                    </div>
                </div>
                <div align="center" class="col-12 col-lg-3 my-2">
                    <div align="center" class="card card-body bg-primary">
                        Count: {{totalsales[1]}}
                    </div>
                </div>
            </div>
            <div class="table-responsive my-3"  v-show="orders.length>0">
                <table class="table table-bordered billpay-table">
                  <thead class="thead-primary">
                  <tr>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Amount</th>
                      <!--th>Net</th-->                     
                      <th>Invoice</th>
                      <th>Settled</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(order, index) in salesList" :key="index">
                      <td>{{order.data.id | convertdate}}</td>
                      <td>{{order.data.customer.fullname}} <br> <small>{{order.data.customer.emailaddress}} / {{order.data.customer.mobile}}</small></td>
                      <td align="right">
                          {{order.data.invoice.currency.toUpperCase()}} ${{order.data.invoice.amount| currency}}
                          <br><small>Platform fee: {{order.data.invoice.currency.toUpperCase()}} ${{(order.data.invoice.amount  * 0.02)| currency}}</small>
                      </td>
                      <!--td align="right">
                          {{order.data.invoice.currency.toUpperCase()}} ${{(order.data.invoice.amount - (order.data.invoice.amount  * 0.02))| currency}}
                          <br><small>USD ${{ exchangeRate((order.data.invoice.amount - (order.data.invoice.amount  * 0.02)), order.data.invoice.currency) }}</small>
                      </td-->
                      <td>
                          {{order.data.invoice.id}}
                      </td>

                      <td align="center">
                         <span class="badge " :class="(order.data.settled) ? 'badge-success' : 'badge-warning'">{{(order.data.settled) ? 'YES: ' : 'NO'}}</span>
                      </td>

                      <td align="center">
                         <a class="btn btn-link" href="#!">Issue Refund</a>
                      </td>
                  </tr>
                  </tbody>
                </table>
            </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="billpayfilterform" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Filter Sales</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.date">
                              <option value="0">Today</option>
                              <option value="7">Last 7 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="365">Last 365 days</option>
                              <option value="-1">Custom</option>
                              <!--option value="this-month">This month</option>
                              <option value="last-month">Last month</option>
                              <option value="this-year">This year</option>
                              <option value="last-year">Last year</option-->
                          </select>
                      </div>
                      <div class="row col-12 my-3" v-show="filter.date=='-1'">
                          <date-range-picker ref="picker" v-model="daterange" :singleDatePicker="true" :autoApply="true" :ranges="false" :maxDate="maxDate" class="col-12">
                              <!--template v-slot:input="picker" style="min-width: 350px;">
                                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                              </template-->
                          </date-range-picker>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.currency">
                              <option value="0">ALL</option>
                              <option value="bbd">BBD</option>
                              <option value="jmd">JMD</option>
                              <option value="ttd">TTD</option>
                              <option value="usd">USD</option>
                              <option value="xcd">XCD</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <input type="text" class="form-control" v-model="filter.invoice" placeholder="Invoice No." />
                      </div>
                      <div class="col-12 my-3">
                          <input type="email" class="form-control" v-model="filter.emailaddress" placeholder="Email address"/>
                      </div>
                      
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="applyFilters">Apply</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="viewpaymentorder" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Order - {{activeorder}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <ViewCart :singleorder="singleorder" :orderLoc="orderloc" :orderid="activeorder" :cart="cart"/>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



    </div>
</template>

<script>
import ViewCart from '@/components/ViewCart.vue'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'PaymentDashboard',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, HH:mm")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart, DateRangePicker,
  },
  computed:{
      salesList(){
          let sales = this.orders
          if(!this.txtsearch) return this.orders
          const search = this.txtsearch.toLowerCase()
          const filter = function(sale){
                              return (sale.id.toLowerCase().includes(search) || sale.data.customer.fullname.toLowerCase().includes(search) || sale.data.customer.emailaddress.toLowerCase().includes(search) || sale.data.customer.mobile.toLowerCase().includes(search))
                          }
          return sales.filter(filter)
      },
      totalsales(){
          let sales = 0
          let count = 0
          const orders = this.orders
          const filter = (order)=>{
                      sales += Number(order.data.subtotal)
                      count++
                      return (order.id)
                  }
          orders.filter(filter)
          return [sales, count]
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          activeorder:'',
          filter:{date:'0', locations:'0', ordertype:'0', paymenttype:'0', currency:'0'},
          orderloc:{},
          daterange:{startDate: moment(), endDate: moment()},
          maxDate: moment().add(1,'days').format('YYYY-MM-DD'),
      }
  },
  methods:{
      applyFilters(){

          let filter = this.filter

          let ordersref = fb.billpayCollection.where("storeid", "==", this.storeid)

          if(filter.date != '-1') {
              let filterdate = moment().subtract(Number(filter.date),'d').startOf('day').unix()
              console.log('date', filterdate)
              ordersref = ordersref.where("id", ">=", filterdate)
          }else{
              let startfilterdate = moment(this.daterange.startDate).startOf('day').unix()
              let endfilterdate = moment(this.daterange.startDate).endOf('day').unix()
              //console.log('date',startfilterdate,startfilterdate)
              ordersref = ordersref.where("id", ">=", startfilterdate).where("id", "<=", endfilterdate)
          }

          if(filter.currency!='0') ordersref = ordersref.where("invoice.currency", "==", filter.currency)

          if(filter.emailaddress) ordersref = ordersref.where("customer.emailaddress", "==", filter.emailaddress)

          if(filter.invoice) ordersref = ordersref.where("invoice.id", "==", filter.invoice)

          ordersref.orderBy("id", "desc").onSnapshot((querySnapshot)=>{
              let j=0
              this.orders.length=0
              querySnapshot.forEach((doc)=>{
                  //console.log(doc.id)
                  this.orders.push({index:j, id:doc.id, data:doc.data()})
                  j++
              })
              if(this.orders.length<1) this.orders = []
          })


      },
      exchangeRate(cash, currency){
          let amount = Number(cash)

          if(currency=='usd') return (amount * 1).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          if(currency=='ttd') return (amount * 0.1492).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          if(currency=='xcd') return (amount * 0.3692).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          if(currency=='jmd') return (amount * 0.0092).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          if(currency=='bbd') return (amount * 1).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })


      let filterdate = moment().subtract(0,'d').startOf('day').unix()

      fb.billpayCollection.where("storeid", "==", storeid).where("id", ">=", filterdate).orderBy("id", "desc").onSnapshot((querySnapshot)=>{
          let j=0
          this.orders.length=0
          querySnapshot.forEach((doc)=>{
              this.orders.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
          if(this.orders.length<1) this.orders = []
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

   .billpay-table th, .billpay-table td{
      font-size:16px !important;
   } 

</style>
