import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
const fb = require('../firebaseConfig.js')
Vue.use(Vuex)
export const store =  new Vuex.Store({
	state: {
		firstload: true,
		isLoggedIn:false,
		currentUser:null,
		cruzstores:[],
		locations:[],
		alllocations:[],
		menusections:[],
		menuitems:[],
		modifiers:[],
		posmenusections:[],
		posmodifiers:[],
		modifieritems:[],
		storeinfo:{},
		storeid: null,
		ordertype:'',
		carnumber:'',
		appnotice:{},
		orderlocation:{data:{paymentoptions:{}, address:{}, fees:{}}},
		orderid:'',
		cart:[],
		pastOrders:[],
		cruzLifeFeed:null,
		cruzLifeVendors:null,
		cruzLifeVendorCategory:'all',
		cruzLifeDirectory:null,
		stagingFeed:[],
		sectionname:'',
		custaddress:'',
		custgeo:'',
		custlandmark:'',
		isIOS: null,
		isMobile: null,
		isBusy:null,
		appMsg: false,
		unavailablelist:[],
		appTitle: 'Santa Cruz Hub.',
		inactiveLocationMsg:'Sorry, this location is not accepting online orders at this time.',
		poweredbytext:'Powered by Order Up - www.orderuptoday.com',
		api_url:'https://order-up-app-tt.web.app/api',
	},
	actions: {
		logout(){
			fb.auth.signOut().then(()=> {
				location.href = "/login"
			}).catch(function(error) {
				console.log(error)
			});
		},
		cancelOrder({state}){
			state.cart = []
            state.custaddress = ''
            state.custlandmark = ''
            state.carnumber = ''
            window.localStorage.clear()
            window.localStorage.setItem('isIOS', state.isIOS)
			window.localStorage.setItem(`${state.storeid}History`, JSON.stringify(state.pastOrders))
            router.push(`/${state.storeid}/start-order`)

		},
		exchangeRate(){
			return '0.00'
		},
		fetchCruzLife({commit, state}){
			fb.storesCollection.where("cruzlifeStatus", "==", true).onSnapshot((querySnapshot) =>{
				let vendors = []
				querySnapshot.forEach((doc)=>{
					vendors.push({id:doc.id, data:doc.data()})
				})
				if(vendors.length<1) {
					commit("setCruzLifeVendors", [])
				} else{
					commit("setCruzLifeVendors", vendors)
				}
			})

			fb.locationsCollection.onSnapshot(querySnapshot => {
				commit('setAllLocations', [])
				querySnapshot.forEach(doc=>{
					commit('setAllLocations', {id:doc.id, data:doc.data()})
				})
			})

			fb.directoryCollection.where("status", "==", true).orderBy("bname", "asc").onSnapshot((querySnapshop)=>{
                let directory = []
                querySnapshop.forEach((doc)=>{
                    directory.push({id:doc.id, data:doc.data()})
                })

                if(directory.length<1) {
					commit("setCruzLifeDirectory", [])
				} else{
					commit("setCruzLifeDirectory", directory)
				}
            })

			fb.feedCollection.where("status", "==", true).orderBy("postDate", "desc").onSnapshot((querySnapshot) =>{ 
                let feed = []
				if(state.cruzLifeFeed==null || state.cruzLifeFeed.length<1){
					querySnapshot.forEach((doc)=>{
						feed.push({id:doc.id, data:doc.data()})
					})
					if(feed.length<1) {
						commit("setCruzLifeFeed", [])
					}else{
						commit("setCruzLifeFeed", feed)
					}
				}else{
					querySnapshot.docChanges().forEach((change) => {
						if (change.type === "added") {
							commit("setStagingFeed", {id: change.doc.id, data: change.doc.data()})
						}else if (change.type === "modified") {
							commit("setStagingFeed", {id: change.doc.id, data: change.doc.data()})
						}else if (change.type === "removed") {
							let index = state.cruzLifeFeed.findIndex(item => item.id === change.doc.id)
							commit("removeFeed", index)
						}
					})
				}	
                
            })
		},
		loadFeedPosts({ commit, state }){
			let tempFeed = [...state.stagingFeed, ...state.cruzLifeFeed]
			commit("setCruzLifeFeed", tempFeed)
			commit("clearStagingFeed")
			window.scrollTo(0,0)
		},
		fetchStoreProfile({ commit, state }, storeId) {
			
			commit('setStoreid', storeId)
			fb.storesCollection.doc(state.storeid).onSnapshot(doc => {
				if(doc.exists){
					commit('setStoreinfo', doc.data())

					fb.locationsCollection.where("storeid", "==", state.storeid).orderBy("name", "asc").onSnapshot(querySnapshot => {
						commit('setLocations', [])
						querySnapshot.forEach(doc=>{
							commit('setLocations', {id:doc.id, data:doc.data()})
						})
					})

					fb.appnoticeCollection.onSnapshot(querySnapshot => {
						querySnapshot.forEach(doc=>{
							commit('setAppNotice', doc.data())
						})
					})

					fb.categoryCollection.where("storeid", "==", state.storeid).where("public", "==", true).orderBy("name", "asc").onSnapshot(querySnapshot => {
						commit('setCategories', [])
						commit('setModifiers', [])
						querySnapshot.forEach(doc=>{
							if(doc.data().type==='category') commit('setCategories', {id:doc.id, data:doc.data()})
							if(doc.data().type==='modifier') commit('setModifiers',{id:doc.id, data:doc.data()})
						})
					})

					fb.categoryCollection.where("storeid", "==", state.storeid).orderBy("name", "asc").onSnapshot(querySnapshot => {
						commit('setPOSCategories', [])
						commit('setPOSModifiers', [])
						querySnapshot.forEach(doc=>{
							if(doc.data().type==='category') commit('setPOSCategories', {id:doc.id, data:doc.data()})
							if(doc.data().type==='modifier') commit('setPOSModifiers',{id:doc.id, data:doc.data()})
						})
					})
		
					fb.itemsCollection.where("storeid", "==", state.storeid).where("public", "==", true).orderBy("name", "asc").onSnapshot(querySnapshot => {
						commit('setMenuitems', [])
						commit('setModifieritems', [])
						querySnapshot.forEach(doc=>{
							if(doc.data().type==='item') commit('setMenuitems', {id:doc.id, data:doc.data()})
							if(doc.data().type==='modifier') commit('setModifieritems', {id:doc.id, data:doc.data()})
						})
					})

					if(window.localStorage.getItem(state.storeid)) state.cart = JSON.parse(window.localStorage.getItem(state.storeid))
					if(window.localStorage.carnumber) state.carnumber = window.localStorage.carnumber
					if(window.localStorage.custaddress) state.custaddress = window.localStorage.custaddress
					if(window.localStorage.custgeo) state.custgeo = window.localStorage.custgeo
					if(window.localStorage.custlandmark) state.custlandmark = window.localStorage.custlandmark
					if(window.localStorage.ordertype) state.ordertype = window.localStorage.ordertype
					if(window.localStorage.orderlocation) state.orderlocation = JSON.parse(window.localStorage.orderlocation)
					if(window.localStorage.getItem(`${state.storeid}History`)) state.pastOrders = JSON.parse(window.localStorage.getItem(`${state.storeid}History`))

					commit("setIsBusy", false)

				}else{
					window.location.replace("https://orderuptoday.com")
				}
			})	
		},
		fetch806Items({commit, state}){
            fb.unavailableCollection.where("storeid", "==", state.storeid).where("location", "==", state.orderlocation.id).onSnapshot(querySnapshot=>{
                let unavailablelist = []
                querySnapshot.forEach(doc=>{
                    unavailablelist.push(doc.data().id)
                })
				commit("setUnavailablelist", unavailablelist)
                if(unavailablelist.length<1) commit("setUnavailablelist", [])
            })
		}
	},
	mutations: {
		setStoreid(state, val){
			state.storeid = val
		},
		setStoreinfo(state, val) {
			state.storeinfo = val
		},
		setAppNotice(state, val){
			state.appnotice = val
		},
		setCategories(state, val) {
			(val.id) ? state.menusections = [...state.menusections, val] : state.menusections =[]
		},
		setModifiers(state, val) {
			(val.id) ? state.modifiers = [...state.modifiers, val] : state.modifiers =[]
		},
		setPOSCategories(state, val) {
			(val.id) ? state.posmenusections = [...state.posmenusections, val] : state.posmenusections =[]
		},
		setPOSModifiers(state, val) {
			(val.id) ? state.posmodifiers = [...state.posmodifiers, val] : state.posmodifiers =[]
		},
		setMenuitems(state, val) {
			(val.id) ? state.menuitems = [...state.menuitems, val] : state.menuitems =[]
		},
		setModifieritems(state, val) {
			(val.id) ? state.modifieritems = [...state.modifieritems, val] : state.modifieritems =[]
		},
		setLocations(state, val) {
			(val.id) ? state.locations = [...state.locations, val] : state.locations =[]
		},
		setAllLocations(state, val) {
			(val.id) ? state.alllocations = [...state.alllocations, val] : state.alllocations =[]
		},
		setCruzStores(state, val) {
			(val.id) ? state.cruzstores = [...state.cruzstores, val] : state.cruzstores =[]
		},
		setOrdertype(state, val) {
			state.ordertype = val
			window.localStorage.setItem('ordertype', state.ordertype)
		},
		setCarnumber(state, val) {
			state.carnumber = val
			window.localStorage.setItem('carnumber', state.carnumber)
		},
		setGeo(state, val) {
			state.custgeo = val
			window.localStorage.setItem('custgeo', state.custgeo)
		},
		setAddress(state, val) {
			state.custaddress = val
			window.localStorage.setItem('custaddress', state.custaddress)
		},
		setLandmark(state, val) {
			state.custlandmark = val
			window.localStorage.setItem('custlandmark', state.custlandmark)
		},
		setOrderlocation(state, val) {
			state.orderlocation = val
			window.localStorage.setItem('orderlocation', JSON.stringify(state.orderlocation))
		},
		setOrderid(state, val) {
			state.orderid = val
		},
		setCart(state, val) {
			state.cart = [...state.cart, val]
			window.localStorage.setItem(state.storeid, JSON.stringify(state.cart))
		},
		initCart(state, val) {
			state.cart = val
			window.localStorage.setItem(state.storeid, JSON.stringify(state.cart))
		},
		updateCart(state, val){
			state.cart.splice(val,1)
			window.localStorage.setItem(state.storeid, JSON.stringify(state.cart))
		},
		setSectionname(state, val) {
			state.sectionname = val
		},
		setCurrentUser(state, val) {
			state.currentUser = val
		},
		setIsMobile(state, val) {
			state.isMobile = val
		},
		setIsBusy(state, val) {
			state.isBusy = val
		},
		setUnavailablelist(state, val){
			state.unavailablelist = val
		},
		setCruzLifeVendors(state, val){
			state.cruzLifeVendors = val
		},
		setCruzLifeVendorCategory(state, val){
			state.cruzLifeVendorCategory = val
		},
		setCruzLifeDirectory(state, val){
			state.cruzLifeDirectory = val
		},
		setCruzLifeFeed(state, val){
			state.cruzLifeFeed = val
		},
		setIsLoggedIn(state, val){
			state.isLoggedIn = val
		},
		removeFeed(state, val){
			state.cruzLifeFeed.splice(val, 1)
		},
		setStagingFeed(state, val){
			state.stagingFeed.unshift(val)
		},
		clearStagingFeed(state){
			state.stagingFeed = []
		},


	},
	modules: {
	}
})

fb.auth.onAuthStateChanged(user => {
	if (user) {
		store.commit("setCurrentUser", user)
		store.commit("setIsLoggedIn", true)
	}
})

/*fb.firebase.auth().signInAnonymously().catch(()=> {
                
})*/

const params = new URLSearchParams(window.location.search)
let isMobile = (params.get('p')) ? true : false
console.log("is mobile?", isMobile)
store.commit('setIsMobile', isMobile)
store.dispatch("fetchCruzLife")
