<template>
    <div class="row mb-10">

        <div class="col-12 my-4" >
            <div class="card card-alt" style="border:0; padding:0;" >
                <div class="card-body">
                    <small>Post type:</small>
                    <select class="form-control my-3" v-model="feed.type">
                        <option value="post">Post</option>
                        <option value="news">News</option>
                        <option value="event">Event</option>
                    </select>
                    <input v-model="feed.title" v-show="feed.type!= 'post'" class="form-control my-3" placeholder="Title" />
                   <textarea v-model="feed.body" wrap="hard" rows="6" class="form-control my-3" placeholder="What's on your mind?"/>
                   <button class="my-3 btn btn-block btn-outline-dark">
                       Add image: <i class="fas fa-image" ></i> 
                   </button>
                   <hr/>
                   <button class="my-3 btn btn-block btn-dark btn-lg" :disabled="isBusy" @click="submitPost">
                       <span v-if="!isBusy">Submit Post</span>
                        <div v-if="isBusy" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                   </button>
                </div>
            </div>
        </div>

        <div class="col-12 mb-10" >
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    Vue.use(require('vue-moment'))
    const fb = require('../firebaseConfig.js')
    var oWebViewInterface = window.nsWebViewInterface;

    export default {
        name: 'CruzlifeFeedAdd',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            
        },
        computed:{
              
        },
        data(){
            return{
                isBusy:false,
                feed:{
                    title:'',
                    type:'post',
                    body:'',
                    img:'',
                    postDate:'',
                    status: true,
                    excerpt:'',
                    author:'system',
                    location:'cruzlife',
                },
                feedError: true,
            }
        },
        methods:{
            submitPost(){
                this.isBusy = true
                this.feedError = false
                if(!this.feed.title && this.feed.type!='post') this.feedError = true
                if(!this.feed.body) this.feedError = true
                if(this.feedError===false){
                    this.feed.postDate = Math.floor(Date.now() / 1000)
                    fb.feedCollection.add(this.feed).then((doc)=>{
                        console.log(doc.id)
                        this.feed = {
                            title:'',
                            type:'post',
                            body:'',
                            img:'',
                            postDate:'',
                            status:true,
                            excerpt:'',
                            author:'system',
                            location:'cruzlife',
                        }
                        oWebViewInterface.emit('submitPost', null)
                        this.isBusy = false
                        window.scrollTo(0,0)
                    })
                }else{
                    Vue.$toast.error('Please fill in all the required text fields.', {position:'top'})
                    this.isBusy = false
                    window.scrollTo(0,0)
                }
            }
        },
        mounted(){
            window.scrollTo(0,0)
        }
    }
</script>
<style >

</style>

