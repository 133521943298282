<template>
    <div  style="padding:3.5rem .2rem;" class="row">
        <div class="col-lg-3">
        </div>
        <div class="col-lg-6">
            <Header  />
            <div class="row" style="padding-bottom:5rem; padding-top:0.5rem;">
                <div class="col-12">
                    <h4><strong>&nbsp;</strong></h4>
                    <input type="text" placeholder="Search menu items ..." class="my-3 form-control" v-model="txtsearch"/>
                    <ul class="list-group my-3 pointer" v-for="(item, index) in menuitems" :key="index" @click="menuitemClick(index)" data-toggle="modal" data-target="#modifiermodal">
                        <li class="list-group-item card-alt">
                            <div class="row">
                                <div :class="(item.data.photo) ? 'col-8' : 'col-12'" >
                                    <strong>{{item.data.name}}</strong>
                                    <br><span class="small" ><em>{{item.data.desc}}</em></span>
                                    <span class="text-secondary"><br>---<br>{{item.data.price | currency}}</span>
                                </div>
                                <div class="col-4" align="right" :style="'border-radius:0.5rem; padding:0; margin:0; background-image:url('+item.data.photo+'); background-size:cover;background-repeat: no-repeat;background-position: center;'" v-if="item.data.photo">
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
        <div class="col-lg-3">
        </div>
        <!-- Modal modifiers -->
        <div v-if="activeitem.data" class="modal fade" id="modifiermodal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>{{activeitem.data.name}} </strong></h4>
                <button type="button" id="btnModalClose" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 5px;">
                  <div class="col-12">
                      <center><img style="width:80%; height:auto; max-width:300px; border-radius:1rem;" :src="activeitem.data.photo" /></center>
                      <p class="my-3"><em>{{activeitem.data.desc}}</em></p>
                  </div>
                  <div class="col-12 my-3">
                      <div style="font-weight:500;" class="my-4" v-for="(modifier, index) in modifiers" :key="index">
                          <span v-if="!modifier.data.multiselect">
                              {{modifier.data.name}} <span class="badge1 badge-secondary1" style="font-size:12px; font-weight:400;"><em>{{(modifier.data.required) ? '(Required)' : '(Optional)'}}</em></span>
                          </span>

                          <!--- single-option modifiers -->
                          <select class="form-control" name="selmodifier" v-if="!modifier.data.multiselect" v-model="itemoptions[index]" @change="updateAddonPricing" :data-required="modifier.data.required">

                              <option :value="{name:'None', price:0}" v-if="modifier.data.required" disabled></option>

                              <option :value="{name:'None', price:0}" v-if="!modifier.data.required">None</option>

                              <option v-for="(option, index) in moditems(modifier.id)" :key="index" :value="{mid:modifier.id, id: option.id, name: option.data.name, price:option.data.price}">
                                  {{option.data.name}}  {{(option.data.price>0) ? '(add $' + option.data.price  + ')' : ''}}
                              </option>
                          </select>

                          <!--- multioption modifiers -->
                          <div v-if="modifier.data.multiselect">
                              <span v-if="modifier.data.multiselect">{{modifier.data.name}}</span>
                              <div class="form-check my-3" v-for="(option, i) in moditems(modifier.id)" :key="i">
                                  <input class="form-check-input my-2" type="checkbox" name="selmodifier" :id="'ch'+option.id" :value="{mid:modifier.id, id: option.id, name: option.data.name, price:option.data.price}" v-model="itemoptions" @change="updateAddonPricing"/>
                                  <label class="form-check-label" style="font-weight:400; font-size:16px;" :for="'ch'+option.id" >
                                      {{option.data.name}} {{(option.data.price>0) ? '(add $' + option.data.price  + ')' : ''}}
                                  </label>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12">
                      <strong>Order Notes</strong>
                      <textarea rows="2" class="form-control" placeholder="Order notes..." v-model="itemnotes"/>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-3" style="padding-left:7px !important; padding-right:7px !important;">
                      <select class="form-control" v-model="itemqty">
                          <option v-for="i in 10" :key="i">{{i}}</option>
                      </select>
                  </div>
                  <div align="right" class="col-9" style="padding-left:7px !important; padding-right:7px !important;">
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal1" aria-label="Close1" @click="btnAddClick" >ADD {{lineitemtotal | currency}} TO ORDER</button> <!-- :disabled="!itemValid" -->
                  </div>
              </div>
            </div>
          </div>
        </div>



    </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/HeaderAlt.vue'
export default {
    name: 'OrderItem',
    props: {

    },
    filters: {
        currency: function (value) {
            if (!value) return 'View Options'
            return ('$'+value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2}))
        }
    },
    components:{
        Footer, Header
    },
    computed:{
        storeInfo(){
            return this.$store.state.storeinfo
        },
        storeId(){
            return this.$store.state.storeid
        },
        pastOrders(){
          return this.$store.state.pastOrders
        },
        isMobile(){
            return this.$store.state.isMobile
        },
        unavailablelist(){
            return this.$store.state.unavailablelist
        },
        menusections(){
            const category = this.$store.state.menusections
            const catid = this.$route.params.catid
            const filter = function(cat){
                                return (cat.id===catid)
                            }
            return category.filter(filter)
        },
        menuitems(){
            let search = ''
            if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
            const catid = this.$route.params.catid
            const items = this.$store.state.menuitems
            const filter = (item)=>{
                                return (item.data.section===catid && item.data.name.toLowerCase().includes(search) && !this.unavailablelist.includes(item.id))
                            }

            return items.filter(filter)
        },
        modifiers(){
            const modifiers = this.$store.state.modifiers
            const modifierlist = this.activeitem.data.modifiers
            const filter = (modifier)=>{
                                return (modifierlist.includes(modifier.id) && !this.unavailablelist.includes(modifier.id))
                            }

            return modifiers.filter(filter)
        },
        modifieritems(){
            return this.$store.state.modifieritems
        },
        orderid(){
            return this.$store.state.orderid
        },
        orderloc(){
            return this.$store.state.orderlocation
        },
        lineitemtotal(){
            return (Number(this.itemqty) * (Number(this.itemprice) + Number(this.itemaddonprice)))
        },
        cart(){
            return this.$store.state.cart
        },
        itemValid(){
            for(var i=0; i<this.itemoptions.length; i++){
                let mname = this.itemoptions[i].name
                if(!document.getElementsByName("selmodifier")[i]) return false
                let required = document.getElementsByName("selmodifier")[i].getAttribute("data-required")
                if(required && mname==='None'){
                    return false
                }
            }

            return true
        },
    },
    data(){
        return{
            txtsearch:'',
            activeitem:{},
            itemqty: 1,
            itemoptions:[],
            itemaddons:[],
            itemprice:0,
            itemaddonprice:0,
            itemname:'',
            itemid:'',
            itemnotes:'',
            modifiererror:'*',
        }
    },
    methods:{
        modifierinfo(modifierid){
            const modifiers = this.modifiers
            const filter = (modifier)=>{
                                return (modifier.id===modifierid)
                            }

            return modifiers.filter(filter)
        },
        catitems(catid){
            const items = this.menuitems
            const filter = (item)=>{
                                return (item.data.section===catid)
                            }

            return items.filter(filter)
        },
        moditems(modid){
            const modlist = this.modifieritems
            const filter = (mod)=>{
                                return (mod.data.section===modid && !this.unavailablelist.includes(mod.id))
                            }

            return modlist.filter(filter)
        },
        menuitemClick(index){
            this.activeitem = this.menuitems[index]
            this.itemqty = 1
            this.itemprice = Number(this.activeitem.data.price)
            this.itemaddonprice = 0
            this.itemname = this.activeitem.data.name
            this.itemoptions = []
            this.itemaddons = []
            this.modifiererror = '*'
            this.itemnotes = ''

            let j=0
            let modifierlist = this.activeitem.data.modifiers

            for(var i=0; i<modifierlist.length; i++){
                let modifier = this.modifierinfo(modifierlist[i])
                if(modifier[0].data.multiselect === false){ 
                    this.itemoptions[j] = {name:'None', price:0}
                    j++
                }
            }

        },
        updateAddonPricing(){
            this.itemaddonprice = 0
            for(var i=0; i<this.itemoptions.length; i++){
                this.itemaddonprice += Number(this.itemoptions[i].price)
            }

            for(var j=0; j<this.itemaddons.length; j++){
                this.itemaddonprice += Number(this.itemaddons[j].price)
            }
        },
        btnAddClick(){
            for(var i=0; i<this.itemoptions.length; i++){
                let mname = this.itemoptions[i].name
                let required = document.getElementsByName("selmodifier")[i].getAttribute("data-required")
                if(required && mname==='None'){
                    this.$toast.error('Missing required options!', {position:'top'})
                    this.modifiererror = '(Required)'
                    return false
                }
            }
            let cartobj = {qty: Number(this.itemqty), itemid: this.activeitem.id, item: this.itemname, modifiers: this.itemoptions, price:Number(this.lineitemtotal), subtotal:Number(this.lineitemtotal), notes:this.itemnotes}
            this.$store.commit('setCart', cartobj)
            document.getElementById("btnModalClose").click()
        },
    },
    mounted(){
        let storeId = this.$route.params.store
		if(this.storeId !== storeId) {
			this.$store.commit("setIsBusy", null)
			this.$store.dispatch('fetchStoreProfile', storeId)
		}else{
            this.$store.dispatch('fetch806Items')
        }
        window.scrollTo(0,0)
    }
}
</script>