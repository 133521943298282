<template>
    <div>
        <div class="row" style="border-bottom:0px solid #f1f1f1; margin-bottom:1rem;">
            <div class="col-7">
                <p style="font-size:13px;">
                   New <strong>{{(orderType=='pickup') ? 'pick up' : orderType}}</strong> order <br>from {{storeInfo.name}}, {{(orderLoc.data) ? orderLoc.data.name : ''}} 
                </p>
            </div>
            <div class="col-5" align="right">
                <button type="button" class="btn btn-outline-dark btn-sm bold" data-toggle="modal" data-target="#headerCancelOrder"><i class="fas fa-undo"></i> Start Over</button>
            </div>
        </div>

        <!-- Modal cancel order-->
        <div class="modal fade" id="headerCancelOrder" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Restart Order?</strong></h4>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 20px;">
                  <p>Are you sure you want to restart this order?</p>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-secondary btn-block"  data-dismiss="modal" aria-label="Close">No</button>
                  </div>
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close" @click="cancelOrderClick">Yes</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      orderid(){
          return this.$store.state.orderid
      },
      orderType(){
          return this.$store.state.ordertype
      },
      orderLoc(){
          return this.$store.state.orderlocation
      },
      carNumber(){
          return this.$store.state.carnumber
      },
      custAddress(){
          return this.$store.state.custaddress
      },
  },
  data(){
      return{
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
      }
  },
  methods:{
      cancelOrderClick(){
          this.$store.dispatch('cancelOrder')
      },
  },
  mounted(){
      let storeid = this.$route.params.store
      if(!window.localStorage.ordertype) {
          setTimeout(()=>{this.$router.push(`/${storeid}`)}, 1500)
      }
    
  }
}
</script>
