<template>
    <div>
        <div align="right" class="my-3" v-if="!isMobile">
            <button @click="$router.push(`/${storeId}/order/menu`)" class="btn btn-sm btn-outline-dark"><i class="fas fa-arrow-left"></i> Back</button>
        </div>
        <div class="row my-5 text-secondary" align="center" v-show="pastOrders.length<1">
            <div class="col-12 card-body" :class="(isIOS=='true') ? 'mb-ios' : ''">
                <em><i class="mr-2 fas fa-clipboard-list"></i> Sorry, no orders found on this device...</em>
            </div>
        </div>
        <div class="row" v-show="pastOrders.length>0">
            <div class="col-12 " :class="(isIOS=='true') ? 'mb-ios' : ''">
                <ul class="list-group my-3" v-for="(order, index) in pastOrders" :key="index">
                    <li class="list-group-item" data-toggle="modal" data-target="#reorder-cart">
                        <h6 class="small text-muted">{{order.id | convertdate}}</h6>
                        <h6 class="small">{{(order.orderType) ? `${order.orderType} Order` : "Pick-up Order"}} {{order.id}}</h6>
                        <h6>{{order.storename}}, {{order.pickuplocation.data.address.city}}</h6>
                        <hr size="1" class="no-margin" />
                        <div class="row my-1" v-for="(item, index) in order.details" :key="index">
                            <div class="ml-1 col-6">
                                <span>- {{item.qty}} {{item.item}}</span>
                            </div>
                            <div class="col-5" align="right">
                                <span>${{item.price|currency}}</span>
                            </div>
                            <div class="col-12" >
                                <span class="alert-info small" v-if="item.notes"><em><strong>Note:</strong> {{item.notes}}</em></span>
                            </div>
                        </div>
                        <hr size="1" class="no-margin" />
                        <div class="my-2 col-12 bold" >
                            <h6 align="right" class="bold">Total: ${{order.total | currency}}</h6>
                        </div>
                        <div class="alert alert-info" v-if="order.notes">
                            {{order.notes}}
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
var moment = require('moment')
export default {
  name: 'OrderHistory',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return 0
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, h:mm a")
      },
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      pastOrders(){
          return this.$store.state.pastOrders
      },
      isIOS(){
          if(window.localStorage.isIOS) return window.localStorage.isIOS
          return this.$store.state.isIOS
      },
      isMobile(){
          return this.$store.state.isMobile
      },
  },
  data(){
      return{
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
      }
  },
  methods:{
      
  },
  mounted(){
      window.scrollTo(0,0)
  }
}
</script>
