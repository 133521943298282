<template>
    <div>
        <div class="row" style="border-top:1px solid #f1f1f1; padding:1rem 0.7rem; position:fixed; bottom:0; right:0; left:0; background:#fff;" v-show="cart.length>0">
            <!--div class="col-5">
                <button type="button" class="btn btn-secondary btn-block">Cancel Order</button>
            </div-->
            <div class="col-12 " :class="(isIOS=='true') ? 'mb-10' : ''">
                <button type="button" class="btn btn-dark btn-block btn-lg1" @click="btnCartClick" data-toggle="modal" data-target="#ordercart"  v-if="locationActive">VIEW ORDER:  {{cartCount}} ITEM(S) - ${{cartTotal | currency}} </button>
                <p class="text-danger" align="center" v-if="!locationActive"><em>{{inactiveLocationMsg}}</em></p>
            </div>
        </div>


        <!-- Modal view order-->
        <div class="modal fade" id="ordercart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>Point of Sale Order</strong></h5>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 5px;">
                  <div  v-show="cart.length<1">
                      <em>Your cart is empty...</em>
                  </div>
                  <ul class="list-group list-group-flush" v-show="cart.length>0">
                      <li class="list-group-item">
                          <div>
                              <h4 align="center"><strong>{{storeInfo.name}}</strong></h4>
                              <!--p align="center" v-if="orderLoc.data" style="font-size:15px;">{{orderLoc.data.name}}<br>{{orderLoc.data.phone}}</p-->
                          </div>
                      </li>
                      <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                          <div class="row">
                              <div class="col-1"><h1 style="font-size:25px;">{{item.qty}}</h1></div>
                              <div class="col-6">
                                  {{item.item}}
                                  <span style="font-size:15px; color:#777;" v-for="(option, i) in item.modifiers" :key="i">
                                      <br><em v-if="option.name!='None'"> - {{option.name}} <span v-if="option.price>0"> (${{option.price}})</span></em>
                                  </span>
                                  <span v-if="item.notes">
                                      <em class="badge badge-secondary">{{item.notes}}</em>
                                  </span>
                              </div>
                              <div class="col-1" align="right"><a href="#!" class="btn-link text-secondary" @click="$emit('remove-cart-item', index)" ><i class="fas fa-trash-alt"></i></a></div>
                              <div class="col-4" align="right" style="text-align:right;">${{item.subtotal | currency}}</div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-12" align="right"><span class="badge badge-dark">{{cartCount}} item(s)</span></div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right">Subtotal</div>
                              <div class="col-4" align="right">${{cartTotal | currency}}</div>
                          </div>
                      </li>
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.tax">
                          <div class="row">
                              <div class="col-8" align="right">Taxes <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.tax : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderTaxes | currency}}</div>
                          </div>
                      </li>

                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.service">
                          <div class="row">
                              <div class="col-8" align="right">Service Charge <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.service : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderService | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.delivery && orderType=='delivery'">
                          <div class="row">
                              <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                              <div class="col-4" align="right">${{orderDelivery | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                              <div class="col-4" align="right"><h5><strong>${{orderTotal | currency}}</strong></h5></div>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block mb-3"  data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#posCheckoutModal" v-if="locationActive && cart.length>0">CHECKOUT <i class="fas fa-chevron-right ml-3"></i></button>

                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close" v-if="locationActive && cart.length<1">CONTINUE <i class="fas fa-chevron-right ml-3"></i></button>
                  </div>
              </div>
            </div>
          </div>
        </div>



        <!-- Modal view order-->
        <div class="modal fade" id="posCheckoutModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>Point of Sale Order</strong></h5>
                <button id="closeCheckoutModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 5px;">
                  <ul class="list-group " v-show="cart.length>0">
                      <!-- <li class="list-group-item">
                          <input class="form-control form-control-lg" type="text" placeholder="Enter customer name" v-model="customername" />
                      </li> -->
                      <li class="list-group-item">
                          <div class="row col-12">
                          <div class="my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.cashonpickup">
                              <div class="form-check">
                                  <input class="form-check-input" id="cashonpickup" type="radio" name="payoption" value="cashonpickup" v-model="payoption">
                                  <label class="form-check-label radio-label" for="cashonpickup">
                                      Pay <strong>CASH</strong>
                                  </label>
                              </div>
                          </div>
                          
                          <div class="my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.cardonpickup && orderType!='delivery'">
                              <div class="form-check">
                                  <input class="form-check-input" id="cardonpickup" type="radio" name="payoption" value="cardonpickup" v-model="payoption">
                                  <label class="form-check-label radio-label" for="cardonpickup">
                                      Pay <strong>DEBIT/CREDIT CARD</strong>
                                  </label>
                              </div>
                          </div>
                          </div>

                          <div class="my-3">
                              <input class="form-control form-control-lg" type="number" step="1" placeholder="Enter cash amount" :disabled="payoption!='cashonpickup'" v-model="cashamount" />
                          </div>
                          <!--div class="card col-12 my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.creditcard">
                              <div class="form-check">
                                  <input class="form-check-input" id="creditcard" type="radio" name="payoption"  value="creditcard" v-model="payoption">
                                  <label class="form-check-label radio-label" for="creditcard"  >
                                      Pay online with <strong>credit card</strong>
                                  </label>
                              </div>
                          </div-->

                      </li>

                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right">Subtotal</div>
                              <div class="col-4" align="right">${{cartTotal | currency}}</div>
                          </div>
                      </li>
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.tax">
                          <div class="row">
                              <div class="col-8" align="right">Taxes <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.tax : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderTaxes | currency}}</div>
                          </div>
                      </li>

                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.service">
                          <div class="row">
                              <div class="col-8" align="right">Service Charge <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.service : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderService | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.delivery && orderType=='delivery'">
                          <div class="row">
                              <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                              <div class="col-4" align="right">${{orderDelivery | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                              <div class="col-4" align="right"><h5><strong>${{orderTotal | currency}}</strong></h5></div>
                          </div>
                      </li>

                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right"><h5><strong>Change</strong></h5></div>
                              <div class="col-4" align="right"><h5><strong>${{orderChange | currency}}</strong></h5></div>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block mb-3" aria-label="Close" @click="btnCheckoutClick" v-if="locationActive && cart.length>0" :disabled="Number(orderChange)<0 || isbusy">
                          <span v-show="!isbusy">COMPLETE <i class="fas fa-chevron-right ml-3"></i></span>
                          <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
const fb = require('../firebaseConfig.js')
//var moment = require('moment')
export default {
  name: 'PosFooter',
  props: ['poscart', 'activeloc'],
  filters: {
      currency: function (value) {
        if (!value || value<0) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{

  },
  computed:{
      storeInfo(){
          //console.log(this.$store.state.storeinfo.pagesettings)
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      orderid(){
          return this.$store.state.orderid
      },
      orderType(){
          return 'pointofsale'
      },
      orderLoc(){
          return this.activeloc
      },
      orderTotal(){
          return (this.cartTotal + this.orderTaxes + this.orderService)
      },
      orderChange(){
          if(this.payoption!='cashonpickup') return ('0.00')
          return (Number(this.cashamount) - Number(this.orderTotal))
      },
      orderDelivery(){
          return 0
      },
      orderService(){
          let fee = (this.orderLoc.data) ? this.orderLoc.data.fees.service/100 : 0
          return Number(this.cartTotal) * fee 
      },
      orderTaxes(){
          let fee = (this.orderLoc.data) ? this.orderLoc.data.fees.tax/100 : 0
          return Number(this.cartTotal) * fee 
      },
      lineitemtotal(){
          return (this.itemqty * (this.itemprice + this.itemaddonprice))
      },
      cart(){
          return this.poscart
      },
      cartTotal(){
          let total = 0
          for(var i=0; i<this.cart.length; i++){
              total += Number(this.cart[i].subtotal)
          }

          return total
      },
      cartCount(){
          let count = 0
          for(var i=0; i<this.cart.length; i++){
              count += Number(this.cart[i].qty)
          }

          return count
      },
      locationActive(){
          const locations = this.$store.state.locations
          const activeloc = this.$store.state.orderlocation.id

          let filter = (loc)=>{
                            return loc.id===activeloc
                        }

          locations.filter(filter)

          if(locations.filter(filter)[0] && !locations.filter(filter)[0].data.public) return false

          return true
      },
      inactiveLocationMsg(){
          return this.$store.state.inactiveLocationMsg
      },
      carNumber(){
          return this.$store.state.carnumber
      },
      isIOS(){
          if(window.localStorage.isIOS) return window.localStorage.isIOS
          return this.$store.state.isIOS
      },
  },
  data(){
      return{
          isbusy:false,
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
          payoption:'cashonpickup',
          cashamount:'',
          customername:'POS COSTUMER',
          customer:{fname:'POS', lname:'CUSTOMER', fullname:'', emailaddress:'pointofsale@orderuptoday.com', mobile:'18680000000', carnumber:'', address:'', landmark:'', geo:''},
      }
  },
  methods:{
      btnCartClick(){

      },
      delCartItem(index){
          this.$store.commit('updateCart', index)
      },
      async btnCheckoutClick(){
          this.isbusy = true
			this.customername = 'POS COSTUMER'
          let orderid = Math.floor(Date.now()/1000)
          this.customer.fullname = this.customername.toUpperCase()

          var orderStatus= (this.storeInfo.pagesettings.kitchenmodule) ? 'pending' : 'complete'



          let order = {id: orderid, customer: this.customer, pickuplocation: this.orderLoc, ordertype: 'pointofsale', status:orderStatus, paymethod: this.payoption, storeid: this.storeId, details:this.cart, notes:'', fees:{tax: this.orderTaxes, delivery: this.orderDelivery, service: this.orderService}, subtotal: this.cartTotal, total: this.orderTotal, lastupdate:orderid}

          await fb.ordersCollection.add(order).then((doc) => {
              this.orderdoc = doc.id
          }).catch(err=>{
              console.log(err)
              this.isbusy = false
          }) 


          for(var i=0; i<this.cart.length; i++){
              fb.cartitemsCollection.add({
                  orderid: orderid,
                  customer: this.customer,
                  itemid: this.cart[i].itemid,
                  itemname: this.cart[i].item,
                  qty: this.cart[i].qty,
                  storeid: this.storeId,
                  location: this.orderLoc,
                  ordertype: this.orderType,
              }).then(doc => {
                  console.log(doc.id)
              }).catch(err=>{
                  console.log(err)
                  this.isbusy = false
              })  

              for(var j=0; j<this.cart[i].modifiers.length; j++){
                  if(!this.cart[i].modifiers[j].id) continue
                  fb.cartitemsCollection.add({
                      orderid: orderid,
                      customer: this.customer,
                      itemid: this.cart[i].modifiers[j].id,
                      itemname: this.cart[i].modifiers[j].name,
                      qty: this.cart[i].qty,
                      storeid: this.storeId,
                      location: this.orderLoc,
                      ordertype: this.orderType,
                  }).then(() => {

                  }).catch(err=>{
                      console.log(err)
                      this.isbusy = false
                  })  
              }
          } 

          //save customer info
          /*let custid = this.customer.emailaddress.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
          this.customer.lastupdated = moment().unix()
          fb.storesCollection.doc(this.storeId).collection('customers').doc(custid).set(this.customer).then(()=>{
              //console.log(doc.id)

          })*/

          this.isbusy = false

          this.$emit('reset-cart')
          this.$emit('reset-menu')

          this.customername = ''
          this.cashamount = ''
          this.payoption = 'cashonpickup'

          document.getElementById("closeCheckoutModal").click()

          window.scrollTo(0,0)

          this.isbusy = false

      },
      cancelOrderClick(){
          let msg = "Are you sure you want to start over this order?"
          var r = confirm(msg)
          if (r == true) {
              this.$store.state.cart = []
              window.localStorage.clear()
              //Vue.$toast.open('Order cancelled!')
              let url = `/${this.storeId}`
              window.location.replace(url)
          }
      },
  },
  mounted(){
      //if(!this.$store.state.orderid) this.$store.commit('setOrderid', this.$route.params.orderid)
      window.scrollTo(0,0)

      //document.getElementById("footermodal").click()
      if(document.getElementById("btnModalClose")) document.getElementById("btnModalClose").click()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.mb-10{
  margin-bottom: 5rem;
}

</style>
