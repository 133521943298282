<template>
    <div class="row mb-5">
        <AppHeader title="Santa Cruz Hub" v-if="!isMobile"/>
        <div class="col-12 my-3">
            <!-- <nav>
                <div class="mobile nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-bio-tab" data-toggle="tab" href="#nav-bio" role="tab" aria-controls="nav-bio" aria-selected="true">Bio</a>
                    <a class="nav-item nav-link" id="nav-posts-tab" data-toggle="tab" href="#nav-posts" role="tab" aria-controls="nav-posts" aria-selected="false">My Posts</a>
                    <a class="nav-item nav-link" id="nav-events-tab" data-toggle="tab" href="#nav-events" role="tab" aria-controls="nav-events" aria-selected="false">My Events</a>
                </div>
            </nav> -->
        </div>
        <div class="col-12 my-3">
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-bio" role="tabpanel" aria-labelledby="nav-bio-tab">
                    <MyProfile />
                </div>
                <div class="tab-pane fade" id="nav-posts" role="tabpanel" aria-labelledby="nav-posts-tab">
                    <MyPosts />
                </div>
                <div class="tab-pane fade" id="nav-events" role="tabpanel" aria-labelledby="nav-events-tab">
                    <MyEvents />
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
    import Vue from 'vue'
    import AppHeader from "@/components/AppHeader.vue"
    import MyProfile from "@/components/CruzLifeProfileBio.vue"
    import MyPosts from "@/components/CruzLifeProfilePosts.vue"
    import MyEvents from "@/components/CruzLifeProfileEvents.vue"
    Vue.use(require('vue-moment'))

    export default {
        name: 'CruzLifeProfile',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            AppHeader, MyProfile, MyPosts, MyEvents,
        },
        computed:{
            isMobile(){
                return this.$store.state.isMobile
            },
            currentUser(){
                return this.$store.state.currentUser
            },
        },
        data(){
            return{
                
            }
        },
        methods:{
              
        },
        async mounted(){
            console.log(this.currentUser)
            window.scrollTo(0,0)
        }
    }
</script>

