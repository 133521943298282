<template>
    <div>
        <div align="right" class="my-3" v-if="!isMobile">
            <button @click="$router.push(`/${storeId}/order/menu`)" class="btn btn-sm btn-outline-dark"><i class="fas fa-arrow-left"></i> Back</button>
        </div>
        <div class="row">
            <div class="col-12 " :class="(isIOS=='true') ? 'mb-ios' : ''">
                <ul class="list-group my-3 mb-10" >
                    <li class="list-group-item">
                        <h4>{{storeInfo.name}}</h4>
                        <div class="my-2" v-for="(location, index) in locations" :key="index">
                            <p>
                                Our app accepts online ordering for both delivery and pick up orders. Order and pay with a click of your finger. Have your order delivered to your location hot and fresh. Skip the line and go right up to the counter to collect your order with our Click & Collect service.
                            </p>
                            <p>
When you place your order, you get an email receipt with all of your order details. Show them to us when collecting your order. It’s that simple!
                            </p>
                            <p>
To get started, just start browsing our famous menu. Get special exclusive promotions and deals when you use the app. Enjoying your meal from Boomerang Cafe has never been so easy!
                            </p>
                            <hr />
                            <h5>
                                <span class="text-muted small">Locations:</span>
                                <br/>------<br/>
                                {{ location.data.name }}
                            </h5>
                            <p align="">
                                <a href="#!" class="btn-link text-dark">
                                    <i class="fas fa-map-marker-alt"></i>
                                    {{ location.data.address.street }}, {{ location.data.address.city }}
                                </a>
                                <br />
                                <i class="fas fa-phone"></i> {{ location.data.phone }} <br/> 
                                <i class="fas fa-envelope"></i> {{ location.data.email }}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
var moment = require('moment')
export default {
  name: 'StoreInfo',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return 0
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, h:mm a")
      },
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      locations() {
        return this.$store.state.locations
      },
      isIOS(){
          if(window.localStorage.isIOS) return window.localStorage.isIOS
          return this.$store.state.isIOS
      },
      isMobile(){
          return this.$store.state.isMobile
      },
  },
  data(){
      return{

      }
  },
  methods:{
      
  },
  mounted(){
      window.scrollTo(0,0)
  }
}
</script>
