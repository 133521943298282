import Vue from 'vue'
import NProgress from 'vue-nprogress'
import App from './App.vue'
import router from './router'
import {store} from './store'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast, {
	position: 'bottom-right',
	type:'success',
	duration:1500
})

Vue.use(NProgress)
Vue.config.productionTip = false
const nprogress = new NProgress()

const fb = require('./firebaseConfig.js')

// handle page reloads
let app
fb.auth.onAuthStateChanged(user => {
	//console.log(user);
    if (!app) {
		new Vue({
			nprogress,
			router,
			store,
			user,
			render: h => h(App)
		}).$mount('#app')
	}
})
