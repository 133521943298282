<template>
    <div  style="padding:0 .2rem;">

        <div class="row bg-white" style="position:fixed; top:0; left:0; right:0; z-index:50; border-bottom:1px solid #f1f1f1;">
            <div class="col-7">
                <!--input type="text" placeholder="Search menu items ..." class="my-3 form-control ml-4" v-model="txtsearch"/--><h5 class="ml-4 my-3"><!--strong>{{storeInfo.name.toUpperCase()}} - MENU</strong--></h5>
            </div>
            <div class="col-5" align="right">
                <button class="my-3 btn btn-outline-dark btn-sm mr-4" @click="$router.push(`/${storeId}`)">
                    Order Now
                </button>
            </div>
        </div>

        <div class="row" style="padding-bottom:5rem; padding-top:4.5rem;">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 col-12">

                <ul class="list-group my-4 pointer" v-for="(cat, index) in menusections" :key="index">
                    <li class="list-group-item active bg-dark">
                        <strong>{{cat.data.name}}</strong>
                    </li>
                    <li class="list-group-item" v-for="(item, index) in catitems(cat.id)" :key="index">
                        <div class="row">
                            <div :class="(item.data.photo) ? 'col-9' : 'col-12'">
                                {{item.data.name}}
                                <br><span class="small" ><em>{{item.data.desc}}</em></span>
                                <br><span class="text-secondary">${{item.data.price | currency}}</span>
                            </div>
                            <div class="col-3" style="background:#fff;" v-if="item.data.photo">
                                <img :src="item.data.photo" style="height:80px; width:auto;" v-if="item.data.photo"/>
                            </div>
                        </div>

                    </li>
                </ul>

            </div>
            <div class="col-lg-3"></div>
        </div>


    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast, {
  // One of the options
  position: 'top-right',
  type: 'success'
})

//const fb = require('../firebaseConfig.js')

export default {
  name: 'ViewMenu',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{

  },
  computed:{
      storeInfo(){
          //console.log(this.$store.state.storeinfo.pagesettings)
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      menusections(){
          return this.$store.state.menusections
      },
      menuitems(){
          let search = ''
          if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
          const items = this.$store.state.menuitems
          const filter = (item)=>{
                              //return (item.data.name.toLowerCase().includes(search) && !this.unavailablelist.includes(item.id))

                              return (item.data.name.toLowerCase().includes(search))
                          }

          return items.filter(filter)
      },
  },
  data(){
      return{
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
          modifiererror:'*',
          unavailablelist:[],
      }
  },
  methods:{
      catitems(catid){
          const items = this.$store.state.menuitems
          const filter = function(item){
                              return (item.data.section===catid)
                          }

          return items.filter(filter)
      },
  },
  mounted(){
      /*setTimeout(()=>{
          fb.unavailableCollection.where("storeid", "==", this.storeId).onSnapshot(querySnapshot=>{
              this.unavailablelist.length = 0
              querySnapshot.forEach(doc=>{
                  this.unavailablelist.push(doc.data().id)
              })
              if(this.unavailablelist.length<1) this.unavailablelist = []
          })
      }, 1500)*/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .list-group-item.active {
        border-color: #343a40!important;
    }

    .vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .fixed-header{
        padding:10px 20px !important;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    .cover-img{
        background-size: cover;
        background-position: center;
    }

    .category-tile{
        background-size: cover;
        background-position: center;
        margin:auto;
        border: 2px solid #ffffff; 
        background:#000000  cover center; 
        height:150px;
        cursor:pointer;
        z-index: 0 !important;
    }

    .hide-menu{
        display:none;
    }


    div.transbox {
        background-color: #000000;
        opacity: 0.55 !important;
        margin:0;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:-10 !important;
    }

    .logo-img{
        position:absolute;
        bottom:25px;
    }

    .category-title {
      margin:3.5rem auto !important;
      color:#fff !important;
      text-align: center;
      font-weight:400;
      z-index:10 !important;
      font-size:16px !important;
    }

    a:hover{
        text-decoration:none;
    }

    

    body{
        font-size:18px;
    }

</style>
