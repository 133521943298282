<template>
    <div class="row">
        <CruzBar v-if="!$store.state.isMobile" />
        <div class="col-12 my-3" v-if="!isMobile"> 
            &nbsp;
        </div>
        <div class="col-lg-3">
        </div>
        <div class="col-lg-6 my-4">
            <Header class="my-3" />
			<div class="row" v-if="appnotice.active">
				<div class="alert alert-info">
					<h5>Notice</h5>
					<div class="my-4" v-html="appnotice.notice">
						
					</div>
				</div>
			</div>
            <div class="row" style="padding-bottom:5rem;">
                <div class="col-12 my-2" v-show="cart.length<1 && pastOrders.length>0">
                    <button class="btn btn-block btn-dark" @click="$router.push(`/${storeId}/reorder`)"><i class="fas fa-bolt"></i> Re-order</button>
                </div>
                <div class="col-12 my-2">
                    <!--h4>Menu Categories</h4-->
                    <div class="row">
                        <div class="col-6 category-tile " v-for="(cat, index) in menusections" :key="index" :style="'background-image: url('+ cat.data.photo +'); border-radius:5px; '" @click="catClick(cat.id, cat.data.name)" >
                            <div class="category-title">
                                {{cat.data.name.toUpperCase()}}
                            </div>
                            <div class="transbox" style="border-radius:5px;"></div>
                        </div>
                        <div class="col-6"></div>
                    </div>  
                </div>
            </div>
            <Footer />
        </div>
        <div class="col-lg-3">
        </div>

    </div>
</template>

<script>
import CruzBar from '@/components/CruzBar.vue'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
export default {
    name: 'OrderMenu',
    filters: {
        currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        }
    },
    components:{
        Header, Footer, CruzBar
    },
    computed:{
        storeInfo(){
            return this.$store.state.storeinfo
        },
        storeId(){
            return this.$store.state.storeid
        },
		appnotice() {
			return this.$store.state.appnotice;
		},
        pastOrders(){
          return this.$store.state.pastOrders
        },
        isMobile(){
            return this.$store.state.isMobile
        },
        unavailablelist(){
            return this.$store.state.unavailablelist
        },
        menusections(){
            const sections = this.$store.state.menusections
            const filter = (section)=>{
                                return (!this.unavailablelist.includes(section.id))
                            }
            return sections.filter(filter)
        },
        menuitems(){
            return this.$store.state.menuitems
        },
        modifiers(){
            return this.$store.state.modifiers
        },
        modifieritems(){
            return this.$store.state.modifieritems
        },
        orderid(){
            return this.$store.state.orderid
        },
        orderloc(){
            return this.$store.state.orderlocation
        },
        cart(){
            return this.$store.state.cart
        }
    },
    data(){
        return{
            txtsearch:'',
        }
    },
    methods:{
        catitems(catid){
            const items = this.menuitems
            const filter = function(item){
                                return (item.data.section===catid)
                            }

            return items.filter(filter)
        },
        catClick(catid, catname){
            let url = `/${this.storeId}/order/menu/${catid}`
            this.$store.commit('setSectionname', catname.toUpperCase())
            this.$router.push(url)
        },
    },
    mounted(){
        let storeId = this.$route.params.store
		if(this.storeId !== storeId) {
			this.$store.commit("setIsBusy", null)
			this.$store.dispatch('fetchStoreProfile', storeId)
		}else{
            this.$store.dispatch('fetch806Items')
        }
        window.scrollTo(0,0)
    }
}
</script>
