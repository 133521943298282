<template>
    <div class="row mb-5"> 

        <div class="col-12 col-lg-4 my-3" align="center">
            <p class="text-muted"><em>Your events will appear here...</em></p>
        </div>
        
    </div>
</template>

<script>
    import Vue from 'vue'
    Vue.use(require('vue-moment'))
    //const fb = require('../firebaseConfig.js')

    export default {
        name: 'CruzLifeProfileEvents',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
        },
        computed:{
              
        },
        data(){
            return{
                
            }
        },
        methods:{
              
        },
        async mounted(){
            

            window.scrollTo(0,0)

        }
    }
</script>

