<template>
    <div > 
        <ul class="list-group ">
            <li @click="dismissModal('')" class="list-group-item pointer">
                Home
            </li>
            <li @click="dismissModal('food')" class="list-group-item pointer">
                Food and Drink
            </li>
            <li @click="dismissModal('alcohol')" class="list-group-item pointer">
                Alcohol
            </li>
            <li @click="dismissModal('beauty')" class="list-group-item pointer">
                Beauty Products
            </li>
            <li @click="dismissModal('grocery')" class="list-group-item pointer">
                Fruits & Vegetables
            </li>
            <!--li @click="dismissModal('hardware')" class="list-group-item pointer">
                Hardware
            </li-->
            <li @click="dismissModal('pharmacy')" class="list-group-item pointer">
                Pharmacy
            </li>
        </ul>
    </div>

</template>
<script>

    export default {
        name: 'CruzBarMenu',
        props: {

        },
        filters: {
            currency: function (value) {
                if (!value) return ''
                return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
            }
        },
        components:{
            
        },
        computed:{
            storeInfo(){
                return this.$store.state.storeinfo
            },
        },
        data(){
            return{
                
            }
        },
        methods:{
          
            dismissModal(cat){
                document.getElementById('btnCloseMenu').click()
                this.$router.push(`/cruzlife/${cat}`)
            },
            async goHome(){
                document.getElementById('btnCloseMenu').click()
                location.href="/cruzlife"
            },
        },
        mounted(){

        }
    }
</script>

