<template>
    <div class="row mb-5">

        <!-- <div class="col-12 col-lg-4 my-3" align="center">
            <img class="profile-img img-fluid" src="https://dummyimage.com/500x500/f1f1f1/dddddd&text=image+not+found" />
        </div> -->
        <div class="col-12 col-lg-8 my-3"> 
            <ul class="list-group list-no-border">
                <li class="list-group-item">
                    <h5 class="my-3">{{currentUser.displayName}}</h5>
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Email:</small><br/>
                    {{currentUser.email}}
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Mobile:</small><br/>
                    {{currentUser.phoneNumber}}
                </li>
                <!-- <li class="list-group-item">
                    <small class="text-muted">Date of Birth:</small><br/>
                    17 April 1983
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Status:</small><br/>
                    Married
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Sex:</small><br/>
                    Male
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Profession:</small><br/>
                    ICT Consultant
                </li>
                <li class="list-group-item">
                    <small class="text-muted">Interests:</small><br/>
                    Travelling, Liming
                </li> -->
                <li class="list-group-item">
                    &nbsp;
                </li>
                <li class="list-group-item">
                    <button class="btn btn-dark btn-block" @click="signOut">
                        Logout
                    </button>
                </li>
                <li class="list-group-item">
                    &nbsp;
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    Vue.use(require('vue-moment'))
    //const fb = require('../firebaseConfig.js')

    export default {
        name: 'CruzLifeProfileBio',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
        },
        computed:{
            currentUser(){
                return this.$store.state.currentUser
            },
        },
        data(){
            return{
                
            }
        },
        methods:{
            signOut(){
                this.$store.dispatch('logout')
            }
        },
        async mounted(){
            

            window.scrollTo(0,0)

        }
    }
</script>

