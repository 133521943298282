<template>
	<div class=""  v-show="pageload==true">
		<div id="navbar">
		<!--nav style="height:40px;" class="navbar navbar-expand-lg navbar-light bg-white">
			<p class="mr-2 my-2" v-if="validkey"><strong>{{storeinfo.name}} - {{locationname}}</strong></p> 

			<img v-if="!validkey" src="../assets/orderup-logo.png" class="img-fluid mr-2" style="height:40px;"/>   

			<button id="liveordersmobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>	
		</nav-->
		<!--div style1="position:fixed; bottom:0; left:0; right:0; z-index:99999; border-top:1px solid #ccc; padding:1rem; background:#fff;" class="" align="right" id="navbarSupportedContent">

			<a href="#!" class="ml-auto btn-link btn btn my-2"  @click="navbarClick('orders')"><i class="fas fa-clipboard-list"></i> Orders</a> 

			<a href="#!" class="btn btn-link btn"  @click="navbarClick('quickmenu')"><i class="fas fa-book-open"></i> Menu</a> 

			<button type="button" class="btn btn-link" v-if="activeloc.data && activeloc.data.public===true" @click="toggleOnlineStatus(false)"><i class="fas fa-toggle-on"></i> Online</button>

			<button type="button" class="btn btn-link text-secondary mr-1" v-if="activeloc.data && activeloc.data.public===false" @click="toggleOnlineStatus(true)"><i class="fas fa-toggle-off"></i> Offline</button>

			<button class="btn btn-link btn-sm" data-toggle="modal" data-target="#editleadtime"><i class="fas fa-clock"></i> {{leadtime}} mins</button>

		</div-->

		</div>		
			<div class="row card-body" style="margin-top:1rem;"  v-if="validkey===false">
			<h3 class="col-12"><strong>{{storeinfo.name}}</strong></h3>
			<div class="col-12 my-3"><p>Choose a location to continue. <br><em>You will need to the location key provided by your account administrator to continue.</em> </p></div>
			<p class="my-5" align="center" v-show="locations.length<1 && validkey"><em>No locations found for this account...</em></p>
			<div class=" mb-5 col-lg-4 pointer" v-for="(location, index) in locations" :key="index">
                <div class="card btn btn-secondary bg-secondary" data-toggle="modal" data-target="#liveorderskey" @click="openLocation(location)" style="height:140px;">
					<div class="card-body">
                    <h3 align="center" ><strong>{{location.data.name}}</strong></h3>
                    <p align="center" class="small">
                        <i class="fas fa-map-marker-alt"></i> {{location.data.address.street}}, {{location.data.address.city}}
                    </p>
                    </div>
                </div>
            </div>
            </div>
           <div class="col-12"  style="padding-top:5rem;" align="right" v-if="validkey==true && activeloc.data && activeloc.data.name">
			</div> 

		<div v-if="validkey && activetab=='orders'" class="row">			
			<nav class="col-12 bg-white" style="z-index:60; position:fixed; top:0px; padding-top:10px;" id="liveorderstabs">
				<div class="nav nav-tabs" id="nav-tab" role="tablist">
					<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Pending <span class="badge badge-primary">{{pendingorders}}</span></a>
					<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Ready <span class="badge badge-success">{{readyorders}}</span></a>
					<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Past </a>
				</div>
			</nav>
			<div class="tab-content col-12" id="nav-tabContent" style="padding-top:0px;">
				<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
					<ScheduledOrders @pendingcount="pendingOrderCount" :location="activeloc" />
				</div>
				<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
					<ReadyOrders @readycount="readyOrderCount" :location="activeloc" />
				</div>
				<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
					<PastOrders :location="activeloc" />
				</div>
				<div class="tab-pane fade" id="nav-pos" role="tabpanel" aria-labelledby="nav-pos-tab">.
				</div>
				<div class="tab-pane fade" id="nav-leadtime" role="tabpanel" aria-labelledby="nav-leadtime-tab">.
				</div>
			</div>
		</div>
		<div class="row" v-if="validkey && activetab=='quickmenu'">
			<div class="col-12">
				<QuickMenu  :location="activeloc"/>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import ScheduledOrders from '@/components/ScheduledOrdersAlt.vue'
import ReadyOrders from '@/components/ReadyOrders.vue'
import PastOrders from '@/components/PastOrders.vue'
import QuickMenu from '@/components/QuickMenu.vue'
const fb = require('../firebaseConfig.js')
export default {
  name: 'KitchenOrders',
  components: {
    ScheduledOrders, ReadyOrders, PastOrders, QuickMenu, 
  },
  computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		locations(){
			let locations = this.$store.state.locations
			if(this.txtsearch.length<3) return locations
			const search = this.txtsearch.toLowerCase()
			const filter = function(loc){
								return (loc.data.address.street.toLowerCase().includes(search) || loc.data.address.city.toLowerCase().includes(search) || loc.data.address.state.toLowerCase().includes(search) || loc.data.address.zip.toLowerCase().includes(search) || loc.data.address.country.toLowerCase().includes(search))
							}
			return locations.filter(filter)
		},
		keyFormValid(){
			if(!this.txtkey || this.txtkey.length<10) return false
			return true
		},
		leadtimeFormValid(){
			if(!this.leadtime) return false
			return true
		},
  },
  data(){
		return{
			storeinfo:[],
			storeid:'',
			pendingorders:0,
			readyorders:0,
			txtsearch:'',
			activeloc:{},
			txtkey:'',
			txtkeyerror:'',
			validkey:null,
			leadtime:'',
			locationname:'',
			activetab: 'orders',
			pageload:null,
		}
  },
  methods:{
		toggleOnlineStatus(status){
			let msg = (status) ? "Turn ON online ordering?" : "Turn OFF online ordering?"
			var r = confirm(msg)
			if (r == true) {
				fb.locationsCollection.doc(this.activeloc.id).update({
					public: status,
				})
				.then(()=> {
					Vue.$toast.open('Online status saved!')
					this.activeloc.data.public = status
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
			document.getElementById('liveordersmobilemenu').click()
		},
		pendingOrderCount(params){
			this.pendingorders = params
		},
		readyOrderCount(params){
			this.readyorders = params
		},
		openLocation(loc){
			this.activeloc = loc
			this.txtkey = ''
			this.txtkeyerror = ''
			this.locationname = loc.data.name
		},
		viewAllClick(){
			localStorage.removeItem('locKey')
			location.href = `/${this.storeid}/live-orders`
		},
		btnKeyClick(){
			if(this.txtkey != String(this.activeloc.id).toUpperCase()) {
				this.txtkeyerror = "Invalid location key. Please contact your store admin for assistance."
				return false
			}else{
				this.validkey = true
				this.leadtime = this.activeloc.data.leadtime
				localStorage.setItem('locKey', String(this.activeloc.id))
				document.getElementById('btnLiveOrdersModalClose').click()
				this.$router.push(`/${this.storeid}/live-orders/${this.activeloc.id}`)

			}
		},
		updateLeadtime(){
			fb.locationsCollection.doc(this.activeloc.id).update({
				leadtime: this.leadtime,
			}).then(()=>{
				Vue.$toast.open('Lead time saved!')
			})
		},
		navbarClick(tab){
			this.activetab = tab
			document.getElementById('liveordersmobilemenu').click()
		},
  },
  async mounted(){

		const storeid = this.$route.params.store
		this.storeid = storeid

		await fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
			if (doc.exists) {
				if(this.currentUser.uid != doc.data().user) {
					this.$store.dispatch('logout')
					return false
				}
				this.$store.dispatch('fetchStoreProfile', storeid)
				this.storeinfo = doc.data()
				this.pageload = true
			} else {
				console.log("No such document!")
				this.$router.push('/login')
			}
		})

		let location = null

		if(this.$route.params.location){
			location = this.$route.params.location
		}else{
			this.validkey = false
			return false
		}
		
		fb.locationsCollection.doc(location).onSnapshot((doc) =>{
			if (doc.exists) {
				this.activeloc = {id:doc.id, data:doc.data()}
				this.validkey = true
				this.leadtime = doc.data().leadtime
				this.locationname = doc.data().name

			} else {
				console.log("No such document!")

			}
		})

  }
}
</script>
<style>
	.tab-pane1{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}
</style>
