<template>
    <div style="padding:0px;" class="row">
        <CruzBar v-if="!isMobile"/>
        <div class="col-lg-3">
        </div>
        <div class="col-lg-6" :class="(storeInfo.cruzlife && !isMobile) ? 'my-4' : ''">
            <div align="center" class="busy-spinner" v-if="isBusy===null && !isMobile">
                <div align="center" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="row" v-if="isBusy===false">
                <div class="col-12 alert alert-info" align="center" v-if="appMsg">
                    {{appMsg}}
                </div>
                <div class="col-12 mb-2" align="center" style="margin-top:0px; border-radius:0px; background-repeat: none; background-size: cover; background-position: center; padding:1rem 0rem; min-height:200px;" :style="'background-image: url(' + coverPhoto + ')'" >
                    <h3 v-if="storeInfo.pagesettings && !storeInfo.pagesettings.logo" class="title" style="background:#fff; padding:1rem; margin:2rem auto; width:90%;"><strong>{{storeInfo.name}}</strong></h3>
                    <img :src="storeInfo.pagesettings.logo" class="img-fluid img-thumbnail avatar-img rounded-circle mb-4" width="200" height="200" v-if="storeInfo.pagesettings && storeInfo.pagesettings.logo"/>
                </div>
                <div align="center" class="col-12 my-3" style="padding:0;">
                    <h4 class="text-dark">Main Menu <i class="fas fa-book-open small"></i></h4>
                </div>
                <div class="card col-6 category-tile " v-for="(cat, index) in menuSections" :key="index" :style="'background-image: url('+ cat.data.photo +'); border-radius:5px;'" @click="activeTitle=cat.data.name; activeCategory=cat.id" data-toggle="modal" data-target="#viewMenuItems">
                    <div class="category-title">
                        {{cat.data.name.toUpperCase()}}
                    </div>
                    <div class="transbox" style=" border-radius:5px;"></div>
                </div>
                <div class="col-6"></div>
                <div class="col-12 my-3 mb-5" style="padding:0;">
                    <h5 class="mb-4"></h5>
                    <h4 class="my-2" align="center">Customer Reviews <small><i class="far fa-star"></i><i class="far fa-star"></i><i class="far fa-star"></i></small></h4>
                    <p align="center" class="mb-5"><em>There are no reviews...</em></p>
                    <p align="right" class="small my-5" v-if="!viewmode">
                        <span class="small my-5" style="font-weight:400;">
                          <a style="color:#fff;" href="https://orderuptoday.com">
                              powered by:
                              <br><img src="../assets/orderup-logo.png" class="img-fluid mr-2" style="height:30px; display:none;"/>
                          </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
        </div>
        <div class="row" style="border-top:1px solid #f1f1f1; padding:1rem 0.7rem; position:fixed; bottom:0; right:0; left:0; background:#fafafa;">
            <div class="col-12" :class="(isIOS=='true') ? 'mb-ios' : ''">
                <button type="button" class="btn btn-danger btn-lg btn-block" @click="$router.push('/'+storeId+'/start-order')">START MY ORDER <i class="fas fa-chevron-right ml-3"></i></button>
            </div>
        </div>
        <!-- Show starter menu-->
        <div class="modal fade" id="viewMenuItems" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>{{activeTitle}}</strong></h4>
                <button id="btnOrderTypeClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:0 15px;">
                  <ul class="list-group my-3" v-for="(item, index) in menuItems" :key="index">
                      <li class="list-group-item pointer" @click="$router.push('/'+storeId+'/start-order')" data-dismiss="modal" aria-label="Close">
                          <div class="row" style="min-height:90px; overflow:hidden;">
                              <div :class="(item.data.photo) ? 'col-8' : 'col-12'"  style="line-height:1.2rem;">
                                  <strong>{{item.data.name}}</strong>
                                  <br><small><em>{{item.data.desc}}</em></small>
                                  <span class="text-secondary"><br>---<br>{{item.data.price | currency}}</span>
                              </div>
                              <div class="col-3 ml-3" align="right" style="padding:0;" v-if="item.data.photo">
                                  <img :src="item.data.photo" style="width:100%; height:auto; border-radius:5px;" class=""/>
                              </div>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="modal-footer">
                  <div class="col-12">
                      <button type="button" class="btn btn-danger btn-block btn-lg" data-dismiss="modal" aria-label="Close" @click="$router.push('/'+storeId+'/start-order')">START MY ORDER</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import CruzBar from '@/components/CruzBar.vue'
let moment = require("moment")
export default {
    name: 'Order',
    props: {
        msg: String
    },
    filters: {
        currency (value) {
            if (!value) return '$0.00'
            return '$'+value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        },
        displaytime (value){
            if(!value) return ''
            return moment(value, 'HH:mm').format("hh:mm a")
        },
    },
    components:{
        CruzBar,
    },
    computed:{
        storeId(){
            return this.$store.state.storeid
        },
        storeInfo(){
            return this.$store.state.storeinfo
        },
        coverPhoto(){
            return (this.storeInfo.pagesettings) ? this.storeInfo.pagesettings.coverphoto : null
        },
        isBusy(){
            return this.$store.state.isBusy
        },
        appMsg(){
            return this.$store.state.appMsg
        },
        poweredbytext(){
            return this.$store.state.poweredbytext
        },
        menuSections(){
            return this.$store.state.menusections
        },
        menuItems(){
            let cat = this.activeCategory
            const menuitems = this.$store.state.menuitems
            const filter = (menuitem)=>{
                                //console.log(cat, menuitem.data.section)
                                return (menuitem.data.section===cat)
                            }
            return menuitems.filter(filter)
        },
        isMobile(){
            return this.$store.state.isMobile
        },
        isIOS(){
            if(window.localStorage.isIOS) return window.localStorage.isIOS
            return this.$store.state.isIOS
        },
    },
    data(){
        return{
            activelist:null,
            showlogo:true,
            viewmode:'',
            activeTitle:'',
            activeCategory:'',
        }
    },
    methods:{
        
    },
    mounted(){
        window.scrollTo(0,0);
        let storeId = this.$route.params.store
		if(this.storeId !== storeId) {
			this.$store.commit("setIsBusy", null)
			this.$store.dispatch('fetchStoreProfile', storeId)
		}
        
		if(window.localStorage[storeId]) this.$router.push(`/${storeId}/order/menu`)
        if(!this.storeId){
            this.$store.dispatch('fetchStoreProfile', storeId)
        }

        const params = new URLSearchParams(window.location.search)
        this.viewmode = params.get('v') || null
        this.$store.state.isIOS = params.get('p') || null
        if(params.get('p')) window.localStorage.setItem('isIOS', String(params.get('p')))
        if(this.viewmode) {
            this.showlogo = false
        }
    }
}
</script>