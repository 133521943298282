<template>
    <div class="row mb-5">

        <AppHeader title="Santa Cruz Hub" v-if="!isMobile && !showSearch" :showSearchButton="true" @showSearchBar="btnSearchClick"/>
        <div class="col-12 search-bar"  v-show="showSearch"> 
            <div class="row no-gutters">
                <div class="col-11 my-2">
                    <input id="txt-search" ref="searchTxt" type="text" class="form-control" :vlaue="txtSearch" @input="txtSearch = $event.target.value" placeholder="Search vendors..." autofocus/>
                </div>
                <div class="col-1" align="right">
                    <button class="btn h3 btn-lg" @click="btnCloseSearchClick(txtSearch)"><i class="fas fa-times"></i></button>
                </div>
            </div>
        </div>

        <div :class="(!isMobile) ? 'col-12 my-4' : ''"> 
            &nbsp;
        </div>

        <div class="col-12 my-4" v-show="!showSearch"> 
            <!-- <h5 class="bold text-muted">Category: {{shopCategory}}</h5> -->
            <div style="inline-block; overflow-x:scroll;">
                <button class="btn btn-sm mr-2 my-1" :class="(cruzLifeVendorCategory=='all') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('all')">All Categories</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='alcohol') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('alcohol')">Alcohol</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='beauty') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('beauty')">Beauty & Personal Care</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='food') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('food')">Food & Drink</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='general') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('general')">General Store</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='produce') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('produce')">Produce Market</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='grocery') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('grocery')">Grocery</button>
                <button class="btn btn-sm btn-outline-dark mr-2 my-1" :class="(cruzLifeVendorCategory=='pharmacy') ? 'btn-dark text-white': 'btn-outline-dark'" @click="btnCategoryClick('pharmacy')">Pharmacy</button>
            </div>
        </div>

        <div class="col-12 col-md-6 col-lg-4 my-3 mb-3 pointer" v-for="(vendor, index) in cruzLifeVendors" :key="index">
            <div class="card card-alt" @click="vendorTileClick(vendor.id)">
                <div class="card-img-container">
                    <img :src="`https://orderuptoday.imgix.net/${vendor.data.pagesettings.coverphoto1}`" class="card-img-top">
                </div>
                <div class="card-body">
                    <img :src="`https://orderuptoday.imgix.net/${vendor.data.pagesettings.logo1}`" style="float:right; border-radius:10%; width:30%; height:auto; margin-top:-8rem;">
                    <p class="card-title bold">{{vendor.data.name}}</p>
                </div>
            </div>
        </div>

        <div class="col-12 my-5 card-body text-muted" align="center" style="padding:3rem;" v-if="Array.isArray(cruzLifeVendors) && cruzLifeVendors.length<1">
            <p><em>Sorry, no vendors were found in this category.<br/>Are you from Santa Cruz and do you sell products in this category? Send an email to <a href="mailto:support@orderuptoday.com">support@orderuptoday.com</a> to get listed.</em></p>
        </div>

    </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue"
import Vue from 'vue'
Vue.use(require('vue-moment'))
var oWebViewInterface = window.nsWebViewInterface
const fb = require('../firebaseConfig.js')


export default {
    name: 'CruzLife',
    props: {

    },
    filters: {
        currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        }
    },
    components:{
        AppHeader,
    },
    computed:{
        isMobile(){
            return this.$store.state.isMobile
        },
        cruzLifeVendorCategory(){
            return this.$store.state.cruzLifeVendorCategory
        },
        cruzLifeVendors(){
            let vendors = this.$store.state.cruzLifeVendors
            let search  = this.txtSearch.toLowerCase()
            if(this.txtSearch.length<3 && this.showSearch) return vendors
            if(this.cruzLifeVendorCategory=='all' && !this.showSearch) return vendors
            const filter = (vendor)=>{
                return (this.showSearch) ? vendor.data.name.toLowerCase().includes(search) : vendor.data.cruzlife.includes(this.cruzLifeVendorCategory) 
            }
            return vendors.filter(filter)
        },

    },
    data(){
        return{
            vendors: [],
            emptyCat: false,
            shopCategory: 'All Categories',
            txtSearch: '',
            showSearch: false,
        }
    },
    methods:{
        async dismissModal(cat){
            document.getElementById('btnCloseMenu').click()
            this.$router.push(`/cruzlife/${cat}`)

            await fb.storesCollection.where("cruzlife", "==", cat).onSnapshot((querySnapshot) =>{
            
                this.vendors.length=0

                querySnapshot.forEach((doc)=>{
                    this.vendors.push({id:doc.id, data:doc.data()})
                })

                if(this.vendors.length<1) {
                    this.emptyCat = true
                }else{
                    this.emptyCat = false
                }

            })
        },

        category(){
            let cat = this.$route.params.category
            return cat.charAt(0).toUpperCase() + cat.slice(1)
        },
        btnCategoryClick(cat){
            this.txtSearch = ""
            this.showSearch = false
            this.$store.commit("setCruzLifeVendorCategory", cat)
        },
        btnSearchClick(){
            this.showSearch = true
            this.$store.commit("setCruzLifeVendorCategory", "all")
            setTimeout(()=>{
                this.$refs.searchTxt.$el.focus()
                document.getElementById("txt-search").focus()
            }, 800)
        },
        btnCloseSearchClick(val){
            this.txtSearch = val
            this.showSearch=false
        },
        async goHome(){
            document.getElementById('btnCloseMenu').click()
            location.href="/cruzlife"
        },

        vendorTileClick(vendor){
            let eventData = {url:`${vendor}`}
            if (this.$store.state.isMobile) {
                oWebViewInterface.emit('vendorClick', eventData) 
            }else{
                this.$router.push(`/${vendor}`)
            }
        }
    },
    mounted(){
        oWebViewInterface.on('showSearch', ()=>{
            this.btnSearchClick()
            window.scrollTo(0,0)
        })
    }
}
</script>

