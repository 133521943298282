<template>
	<div class="" style="margin-bottom:100px;" v-show="pageload==true">
		<button @click="topFunction" id="myBtn" class="btn btn-primary btn-lg floating-button" title="Go to top"><i class="fas fa-chevron-up"></i></button>
		<AdminBar />
		

		<div class="col-12 mb-2" style="padding-top:5rem;">

			<div class="row">
				<h2 class="col-lg-6 mb-4"><strong class="">{{storeinfo.name}} - Locations</strong></h2>

				<!--div class="col-lg-6">
					<a :href="'/'+storeid" target="storepage" style="float:right;" class="btn btn-primary mr-3">View Store <i class="fas fa-desktop"></i></a>

					<a :href="'/'+storeid+'/menu-editor'" target="liveorders" style="float:right;" class="btn btn-primary mr-3">Menu Editor <i class="fas fa-hamburger"></i></a>
				</div-->
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="!currentUser.emailVerified">
				Please verify your email. <a href="#!" @click="verifyEmail">Click here to resend verification link?</a> 
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="storeinfo.pagesettings && !storeinfo.pagesettings.logo">
				Please upload your company logo and cover photo. <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="locations.length<1">
				Please create at least one location for your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="menuItems.length<1">
				Please add your menu to your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>
			
		</div>

		<div class="" >
				<SitesDashboard />
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import SitesDashboard from '@/components/SitesDashboard.vue'
import AdminBar from '@/components/AdminBar.vue'

const fb = require('../firebaseConfig.js')
var moment = require('moment')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'Dashboard',
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("YYYY-MM-DD hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components: {
    AdminBar, SitesDashboard,
  },
  computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		locations(){
			return this.$store.state.locations
		},
		menuItems(){
			return this.$store.state.menuitems
		},


  },
  data(){
		return{
			storeinfo:[],
			storeid:'',
			pendingorders:0,
			readyorders:0,
			datacollection: {},
			orders:[],
			payments:[],
			validkey:null,
			pageload:null,
		}
  },
  methods:{
		topFunction(){
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		},
		verifyEmail(){
			fb.auth.currentUser.sendEmailVerification().then(()=>{
				Vue.$toast.open('Verification email sent!')
			})
		},
		toggleOnlineStatus(status){
			let msg = (status) ? "Turn ON online ordering?" : "Turn OFF online ordering?"
			var r = confirm(msg)
			if (r == true) {
				fb.storesCollection.doc(this.storeid).update({
					isopen: status,
				})
				.then(()=> {
					Vue.$toast.open('Online ordering status successfully updated!')
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
		},
		togglePublishStatus(status){
			let msg = (status) ? "Publish your store?" : "Unpublish your store?"
			var r = confirm(msg)
			if (r == true) {
				fb.storesCollection.doc(this.storeid).update({
					public: status,
				})
				.then(()=> {
					Vue.$toast.open('Store published status successfully updated!')
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
		},
		pendingOrderCount(params){
			this.pendingorders = params
		},
		readyOrderCount(params){
			this.readyorders = params
		},
		signOut(){
			this.$store.dispatch('logout')
		},
  },
  async mounted(){

		//console.log(this.currentUser.uid)

		const storeid = this.$route.params.store
		this.storeid = storeid

		await fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
			if (doc.exists) {
				if(this.currentUser.uid != doc.data().user) {
					this.$store.dispatch('logout')
					return false
				}
				this.$store.dispatch('fetchStoreProfile', storeid)
				this.storeinfo = doc.data()
				this.pageload = true
			} else {
				console.log("No such document!")
				this.$router.push('/login')
			}
		})

		
	}
}
</script>

<style>
	.dashboard-count {font-size:80px; color:#fff;}
	.dashboard-title {color:#fff;}

	.dashboard-tabs1 .tab-pane{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}

	.floating-button{
		display: ;
		position: fixed;
		bottom: 20px;
		right: 30px;
		z-index: 80;
		font-size: 18px;
		padding: 15px;
		border-radius: 100%;
		width:60px;
		height:60px;
	}
</style>