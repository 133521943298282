<template>
    <div class="row">
        <div class="col-12 my-4" v-if="customers.length<1">
            <p align="center"><em>Your customers will appear here when added ...</em></p>
        </div>
        <div class="col-12 my-2" v-if="customers.length>0">

            <input type="text" class="form-control" v-model="txtsearch" placeholder="Search customers..." />

        </div>
        <div class="row col-12 my-2" v-if="customers.length>0">

            <div class="col-lg-1 my-2" align="">Filter:</div>
            <div class="col-lg-2 my-2">
                <select class="form-control" v-model="filter.date">
                    <option value="0">Today</option>
                    <option value="7">Last 7 days</option>
                    <option value="30">Last 30 days</option>
                    <option value="365">Last 365 days</option>
                    <!--option value="-1">Custom</option>
                    <option>This month</option>
                    <option>Last month</option>
                    <option>This year</option>
                    <option>Last year</option-->
                </select>
            </div>
            <div class="col-lg-3 my-2">
                <select class="form-control" v-model="filter.menucategory">
                    <option value="0">- Menu Category -</option>
                    <option v-for="(item, index) in items" :key="index">{{item.data.name}}</option>
                </select>
            </div>
            <div class="col-lg-2 my-2" v-if="filter.menucategory!='0'">
                <select class="form-control" v-model="filter.menuitem" >
                    <option value="0">- Menu Item -</option>
                    <option v-for="(item, index) in items" :key="index">{{item.data.name}}</option>
                </select>
            </div>
            <div class="col-lg-2 my-2">
                <button type="button" class="btn btn-primary btn-block"><i class="fas fa-filter"></i> Apply Filter</button>
            </div>
        </div>
        <div class="my-2 col-12">
            <!--div class="row col-12 text-white">
                <div align="center" class="col-12 col-lg-6 my-2">
                    <div align="center" class="card card-body bg-primary">
                        Customer Count: {{customerlist.length}}
                    </div>
                </div>

            </div-->
            <div class="table-responsive-lg my-4">
                <table class="table table-bordered" v-show="customerlist.length>0">
                  <thead class="thead-primary">
                  <tr>
                      <th colspan="5" class=""><h4>Customer Count: {{customerlist.length}}</h4></th>
                  </tr>
                  <tr>
                      <th>#</th>
                      <th>Full Name</th>
                      <th>Contact</th>
                      <th>Last Order</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(cust, index) in customerlist" :key="index" class="small">
                      <td>{{index+1}}</td>
                      <td><strong>{{cust.data.fullname}}</strong> <small></small></td>
                      <td>{{cust.data.emailaddress}} / {{cust.data.mobile}}</td>
                      <td>{{cust.data.lastupdated | convertdate}}</td>
                      <td>
                          <div class="input-group-append">
                              <button class="btn btn-link dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Options</button>
                              <div class="dropdown-menu">
                                  <a class="dropdown-item" href="#!" @click="recentOrders(cust.data)" data-toggle="modal" data-target="#viewcustorders">View Most Recent Orders</a>
                                  <!--a class="dropdown-item" href="#">Deactivate Customer</a-->
                                  <!--a class="dropdown-item" href="#!">Delete Customer Data</a-->
                              </div>
                          </div>
                      </td>
                  </tr>
                  </tbody>
                </table>
            </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="viewcustorders" tabindex="-1" role="dialog" aria-hidden="true" v-if="activecustomer">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"><strong>Most recent orders for {{activecustomer.fullname}}</strong></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <p class="my-3" v-show="orders.length<1"><em>No orders were found...</em></p>
                  <div class="table-responsive-lg" v-show="orders.length>0">
                      <table class="table table-bordered">
                        <thead class="thead-primary">
                        <tr>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Total</th>
                            <th>Location</th>
                            <th>ID</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in orders" :key="index">
                                <td align="center">
                                    <span v-if="order.data.ordertype==='pickup'" class="badge badge-warning mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                                    <span v-if="order.data.ordertype==='curbside'" class="badge badge-info mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                                    <span v-if="order.data.ordertype==='delivery'" class="badge badge-primary mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                                </td>
                                <td>{{order.data.id | convertdate}}</td>
                                <td>${{order.data.total | currency}}</td>
                                
                                <td>{{order.data.pickuplocation.data.name}}</td>
                                <td>
                                    <button class="btn btn-link" type="button" data-toggle="modal" data-target="#viewcustomercart" @click="viewOrderDetails(order.data.id, order.data)">{{order.data.id}}</button>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



        <!-- Customer Cart Modal -->
        <div class="modal fade" id="viewcustomercart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Order - {{activeorder}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <ViewCart :singleorder="singleorder" :orderLoc="orderloc" :orderid="activeorder" :cart="cart"/>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
import ViewCart from '@/components/ViewCart.vue'
const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'ScheduleOrders',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("YYYY-MM-DD hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart,
  },
  computed:{
      customerlist(){
          let custlist = this.customers
          if(this.txtsearch.length<4) return custlist 
          const search = this.txtsearch.toLowerCase()
          
          const filter = function(cust){
                          return(cust.data.fullname.toLowerCase().includes(search) || cust.data.emailaddress.toLowerCase().includes(search) || cust.data.mobile.toLowerCase().includes(search))
                      }

          return custlist.filter(filter)
      },
      items(){
          return this.$store.state.menuitems
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          orderloc:{},
          activeorder:'',
          cart:[],
          txtsearch:'',
          pendingcount:0,
          customers:[],
          activecustomer:'',
          filter:{date:'0', menuitem:'0', menucategory:'0',},
      }
  },
  methods:{
      viewOrderDetails(orderid, order){
          this.activeorder = orderid
          this.singleorder = order
          this.orderloc = order.pickuplocation.data
          this.cart = order.details
      },
      recentOrders(customer){
          this.activecustomer = customer
          fb.ordersCollection.where("status", "in", ["ready", "complete"]).where("customer.emailaddress", "==", customer.emailaddress).where("storeid", "==", this.storeid).orderBy("id", "desc").limit(100).get().then(querySnapshot=>{
              this.orders.length = 0
              querySnapshot.forEach((doc)=>{
                  this.orders.push({id: doc.id, data:doc.data()})
              })
              if( this.orders.length == 0) this.orders = []
          })
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      fb.storesCollection.doc(storeid).collection('customers').orderBy("fullname", "asc").onSnapshot((querySnapshot)=>{
          let j=0
          this.customers.length=0
          querySnapshot.forEach((doc)=>{
              this.customers.push({index:j, id:doc.id, data:doc.data()})
              j++
          })

          if(this.customers.length==0) this.customers = []
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

  

</style>
