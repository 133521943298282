<template>
    <div style="padding:0; margin:0;">   
        <div class="my-5 card-body" v-show="ordersubmitted">
            <h1 align="center"><i class="far fa-check-circle"></i><br>Thank you!</h1>
            <h5 align="center" class="text-muted">Your order was submitted and will be ready in approximately <span class="bold">{{(orderLoc.data) ? orderLoc.data.leadtime : ''}}</span> minutes. 
            Please contact <strong class="text-muted"><a class="text-muted" :href="`tel:${orderLoc.data.phone}`"><u>{{orderLoc.data.phone}}</u></a></strong> if you have any questions about your order.</h5>
            <h5 align="center" class="text-dark my-4"><small style="font-size:12px;" class="text-muted">Your order number is</small> <br>{{orderid}}</h5>
            <h2 align="center" class="text-dark my-4"><small style="font-size:12px;" class="text-muted">Your order total 
                <em v-if="summaryType=='delivery'">(includes delivery and tip)</em></small> <br>${{summaryTotal | currency}}</h2>
            <ul class="list-group">
                <li class="list-group-item " >
                    <h4><strong>Your Order</strong></h4>
                </li>
                <li class="list-group-item" v-for="(item, index) in summarycart" :key="index">
                    <div class="row">
                        <div class="col-2"><h1>{{item.qty}}</h1></div>
                        <div class="col-6 h5">
                            {{item.item}}
                            <span v-for="(option, i) in item.modifiers" :key="i">
                                <br><em v-if="option.name!='None'"> - {{option.name}} <span v-if="option.price>0"> (${{option.price}})</span></em>
                            </span>
                        </div>
                        <div class="col-4 h5" align="right">${{item.price | currency}}</div>
                        <div class="col-12" v-if="item.notes">
                            <div class="alert alert-info">
                                Item Note: {{item.notes}}
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item" v-if="notes">
                    <div class="col-12 alert alert-info">
                        {{notes}}
                    </div>
                </li>
            </ul>
            <p class="my-5" align="center">
                <button class="btn btn-lg btn-dark btn-block" @click="orderComplete">START NEW ORDER</button>
            </p>
        </div>

        <div align="right" class="my-3" v-show="!ordersubmitted">
            <button @click="$router.push(`/${storeId}/order/menu`)" class="btn btn-sm btn-outline-dark"><i class="fas fa-arrow-left"></i> Continue shopping</button>
        </div>
		<div  v-if="(isLocationDeliveryActive && orderType == 'delivery') || orderType !== 'delivery'">
        <ul class="list-group list-group-flush my-2 mb-4" v-show="!ordersubmitted">
            <li class="list-group-item" style="border-bottom:0; padding-bottom:5rem;">
                <div class="row">
                    <div class="col-12 my-2">
                        <h5>Contact Information</h5>
                        <p class="small">* = require field</p>
                    </div>
                    <div class="col-12 my-2"><input type="text" class="form-control " placeholder="*First name" v-model="customer.fname"/></div>
                    <div class="col-12 my-2"><input type="text" class="form-control " placeholder="*Last name" v-model="customer.lname"/></div>
                    <div class="col-12 my-2"><input type="email" class="form-control " placeholder="*Email address" v-model="customer.emailaddress"/></div>                  
                    <div class="col-12 my-2"><input type="number" class="form-control " placeholder="*Mobile number" v-model="customer.mobile"/></div>
                    <div class="col-12 my-2">
                        <input type="number" class="form-control " placeholder="*Confirm Mobile number" v-model="confirmPhone"/>
                        <span v-if="customer.mobile !== confirmPhone" class="text-danger">Phone numbers do not match!</span>
                    </div>
                    <div class="col-12 my-2" v-show="orderType==='curbside'"><input type="text" class="form-control " placeholder="Vehicle license plate" v-model="customer.carnumber"/></div>
                    <div class="col-12 my-2" v-show="orderType==='delivery'"><input type="text" class="form-control " placeholder="Delivery address" v-model="customer.address" readonly/></div>
                    <div class="col-12 my-2" v-show="orderType==='delivery'"><input type="hidden" class="form-control " placeholder="Delivery address" v-model="customer.geo" readonly/></div>
                    <div class="col-12 my-2" v-show="orderType==='delivery'"><textarea rows="2" class="form-control " placeholder="Landmarks to help find your delivery address" v-model="customer.landmark"/></div>                   
                    <div class="col-12 my-2"><textarea rows="2" class="form-control" placeholder="Order notes"  v-model="notes"/></div>
                </div>
                <!--- Tip Delivery Driver --->
                <div class="row " v-show="orderType=='delivery'">
                    <div class=" col-12 my-4">
                        <h5>Would you like to tip the delivery driver?</h5>
                        <div class="card col-12 my-3" style="padding:0.5rem;">
                            <div class="form-check">
                                <input class="form-check-input" id="tipzero" type="radio" name="tipOption" value="0" v-model="tipOption">
                                <label class="form-check-label radio-label" for="tipzero">
                                    No, not this time
                                </label>
                            </div>
                        </div>
                        <div class="card col-12 my-3" style="padding:0.5rem;">
                            <div class="form-check">
                                <input class="form-check-input" id="tipfive" type="radio" name="tipOption" value="5" v-model="tipOption">
                                <label class="form-check-label radio-label" for="tipfive">
                                    Yes, add $5 to my total
                                </label>
                            </div>
                        </div>
                        <div class="card col-12 my-3" style="padding:0.5rem;">
                            <div class="form-check">
                                <input class="form-check-input" id="tipten" type="radio" name="tipOption" value="10" v-model="tipOption">
                                <label class="form-check-label radio-label" for="tipten">
                                    Yes, add $10 to my total
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Tip Delivery Driver --->
                <div class="row my-3">
                    <div class="col-12">
                        <h5>Select a payment option</h5>
                        <div class="card col-12 my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.cashonpickup">
                            <div class="form-check">
                                <input class="form-check-input" id="cashonpickup" type="radio" name="payoption" value="cashonpickup" v-model="payoption" data-toggle="modal" data-target="#payCashModal">
                                <label class="form-check-label radio-label" for="cashonpickup" data-toggle="modal" data-target="#payCashModal">
                                    Pay <strong>${{orderTotal | currency}}</strong> in-person with <strong>cash</strong>
                                </label>
                            </div>
                        </div>
                        <div class="card col-12 my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.cardonpickup && orderType!='delivery'">
                            <div class="form-check">
                                <input class="form-check-input" id="cardonpickup" type="radio" name="payoption" value="cardonpickup" v-model="payoption">
                                <label class="form-check-label radio-label" for="cardonpickup" >
                                    Pay <strong>${{orderTotal | currency}}</strong> in-person with <strong>debit/credit card</strong>
                                </label>
                            </div>
                        </div>
                        <div class="card col-12 my-3" style="padding:0.5rem;" v-if="orderLoc.data && orderLoc.data.paymentoptions.creditcard">
                            <div class="form-check">
                                <input class="form-check-input" id="creditcard" type="radio" name="payoption"  value="creditcard" v-model="payoption" @change="btnCreditCardClick" data-toggle="modal" data-target="#payCardModal">
                                <label class="form-check-label radio-label" for="creditcard"   data-toggle="modal" data-target="#payCardModal">
                                    Pay <strong>${{orderTotal | currency}}</strong> online with <strong>credit card</strong>
                                </label>
                            </div>
                        </div>
                        <div class="my-3" align="center">
                            <button type="button" class="btn btn-outline-dark btn-block" data-toggle="modal" data-target="#reviewCart">
                                Review my order
                            </button>
                        </div>
                        <div class="my-2" align="center">
                            <button type="button" class="btn btn-lg btn-dark btn-block mb-4" v-if="(payoption=='cashonpickup' || payoption=='cardonpickup') && locationActive" :disabled="!payFormValid  || isbusy" @click="btnPayLaterClick">
                                <span v-show="!isbusy">SUBMIT ORDER FOR {{(orderType=='pickup') ? 'PICK UP' : orderType.toUpperCase()}} </span>
                                <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                            </button>
                            <!-- credit card fields-->
                            <div class="mb-4" v-show="payoption=='creditcard' && locationActive">
                                <input type="hidden" value="" name="stripeamount" id="stripeamount" v-model="stripeamount">
                                <fieldset>
                                  <div class="">  
                                      <label for="card-element" style="width:100%; text-align:left;" class="my-2">
                                        <h5>Credit Card Details</h5>
                                        <p class="small"><em>You will be charged an additional ${{fees|currency}} {{orderCurrency}} as a credit card processing fee. This charge will appear on your credit card statment as 'SOCAISLAND* {{descriptor.toUpperCase()}}'.</em></p>
                                      </label>
                                      <div id="card-element" class="col-12 form-control "></div>
                                      <!-- Used to display form errors. -->
                                      <div id="card-errors" class="my-2" role="alert" style="color:#ff0000;"> </div>
                                  </div>
                                </fieldset> 
                                <button type="button" :data-secret="clientsecret" id="card-button" class="btn btn-lg btn-dark btn-block btn-lg1 my-2"  :disabled="!payFormValid || isbusy" @click="btnPayClick">
                                      <span v-show="!isbusy">PAY NOW & SUBMIT ORDER</span>
                                      <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                                </button>
                            </div>
                            <!-- /credit card fields -->
                            <p class="text-danger" align="center" v-if="!locationActive">{{inactiveLocationMsg}}</p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
		</div>
		<div class="col-12" v-if="!isLocationDeliveryActive && orderType == 'delivery'">
			<div class="alert alert-info">
				<div class="my-4">
					<h5>Dear Valued Customer,</h5>
					Unfortunately delivery is currently unavailable at this location.
				</div>
				<div class="my-3">
					<button class="btn btn-dark col-12 col-md-6" @click="changeOrderTypeClick">Change my order to a Pick-up order</button>
				</div>
				<div class="my-3">
					<button class="btn btn-outline-dark col-12 col-md-6" @click="cancelOrderClick">Cancel my order</button>
				</div>
			</div>
		</div>

        <div class="mb-5 my-5">
        </div>

        <!-- Modal pay cash-->
        <div class="modal fade" id="payCashModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong></strong></h4>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px;">
                  <p>Do you need to get any change?</p>
                  <select class="form-control" v-model="needCashChange">
                      <option :value="true">Yes, I need change</option>
                      <option :value="false">No, I have the exact amount</option>
                  </select>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close">Okay</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal pay card-->
        <div class="modal fade" id="payCardModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Republic Bank and Scotiabank Notice <i class="small fas fa-info-circle"></i></strong></h4>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 20px;">
                  <p>Attention Republic Bank and Scotiabank Credit Card Customers. Please contact and notify your bank's credit card center about the transaction before attempting to make a payment.</p>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close">Okay</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal review order-->
        <div class="modal fade" id="reviewCart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>{{(orderType=='pickup') ? 'Pick Up' : orderType | capitalize}} Order</strong></h5>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                  <div align="center" class="my-5" v-show="cart.length<1">
                      <em>Your cart is empty...</em>
                  </div>
                  <ul class="list-group list-group-flush" v-show="cart.length>0">
                      <li class="list-group-item">
                          <div >
                              <h4 align="center"><strong>{{storeInfo.name}}</strong></h4>
                              <p align="center">
                                <span v-if="orderLoc.data">{{orderLoc.data.name}}<br>{{orderLoc.data.address.street}}, {{orderLoc.data.address.city}}  </span>
                              </p>
                          </div>
                      </li>
                      <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                          <div class="row">
                              <div class="col-auto"><h1 class="bold">{{item.qty}}</h1></div>
                              <div class="col-5">
                                  <h5>
                                        {{item.item}}
                                        <span class="small" v-for="(option, i) in item.modifiers" :key="i">
                                            <em v-if="option.name!='None'"><hr size="1" class="no-margin" />  {{option.name}} <span v-if="option.price>0"> (add ${{option.price}})</span></em>
                                        </span>
                                  </h5>
                              </div>
                              <div class="col-auto" align="right"><a href="#!" class="btn-link text-secondary" @click="delCartItem(index)" ><i class="fas fa-trash-alt"></i></a></div>
                              <div class="col-3" align="right">${{item.subtotal | currency}}</div>
                              <div class="col-12" v-if="item.notes">
                                    <div class="alert alert-info">
                                        {{item.notes}}
                                    </div>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-12" align="right"><span class="badge badge-dark">{{cartCount}} item(s)</span></div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right">Subtotal</div>
                              <div class="col-4" align="right">${{cartTotal | currency}}</div>
                          </div>
                      </li>
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.tax">
                          <div class="row">
                              <div class="col-8" align="right">Taxes <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.tax : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderTaxes | currency}}</div>
                          </div>
                      </li>

                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.service">
                          <div class="row">
                              <div class="col-8" align="right">Service Charge <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.service : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderService | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.delivery && orderType=='delivery'">
                          <div class="row">
                              <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                              <div class="col-4" align="right">${{orderDelivery | currency}}</div>
                          </div>
                      </li>

                      <li class="list-group-item" v-if="tipOption>0">
                          <div class="row">
                              <div class="col-8" align="right">Driver's Tip </div>
                              <div class="col-4" align="right">${{tipOption}}.00</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                              <div class="col-4" align="right"><h5><strong>${{orderTotal | currency}}</strong></h5></div>
                          </div>
                      </li>
                  </ul>
              </div>
              <!-- <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block mb-3"  data-dismiss="modal" aria-label="Close" @click="btnCheckoutClick" v-if="locationActive && cart.length>0">CHECKOUT <i class="fas fa-chevron-right ml-3"></i></button>
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close" v-if="locationActive && cart.length<1">CONTINUE <i class="fas fa-chevron-right ml-3"></i></button>
                      <p class="text-danger" align="center" v-if="!locationActive"><em>{{inactiveLocationMsg}}</em></p>
                  </div>
              </div> -->
            </div>
          </div>
        </div>
    </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
    name: 'Checkout',
    props: {

    },
    filters: {
        currency: function (value) {
            if (!value) return 0
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        },
        capitalize: function (string) {
            if (!string) return ''
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
    components:{
        
    },
    computed:{
        storeInfo(){
            return this.$store.state.storeinfo
        },
        storeId(){
            return this.$store.state.storeid
        },
        pastOrders(){
            return this.$store.state.pastOrders
        },
        orderid(){
            return this.$store.state.orderid
        },
        orderType(){
            return this.$store.state.ordertype
        },
        orderLoc(){
            return this.$store.state.orderlocation
        },
        orderCurrency(){
            if(this.orderLoc.data) return this.orderLoc.data.currency?.toUpperCase()
            return 'TTD'
        },
        orderTotal(){
            return (this.cartTotal + this.orderTaxes + this.orderService + this.orderDelivery + Number(this.tipOption))
        },
        orderDelivery(){
            return (this.orderLoc.data && this.orderType==='delivery') ? Number(this.orderLoc.data.fees.delivery) : 0
        },
        orderService(){
            let fee = (this.orderLoc.data) ? Number(this.orderLoc.data.fees.service/100) : 0
            return Number(this.cartTotal) * fee 
        },
        orderTaxes(){
            let fee = (this.orderLoc.data) ? Number(this.orderLoc.data.fees.tax/100) : 0
            return Number(this.cartTotal) * fee 
        },
        lineitemtotal(){
            return (this.itemqty * (Number(this.itemprice) + Number(this.itemaddonprice)))
        },
        cart(){
            return this.$store.state.cart
        },
        cartTotal(){
            let total = 0
            for(var i=0; i<this.cart.length; i++){
                total += Number(this.cart[i].subtotal)
            }

            return total
        },
        cartCount(){
            let count = 0
            for(var i=0; i<this.cart.length; i++){
                count += Number(this.cart[i].qty)
            }

            return count
        },
        carNumber(){
            return this.$store.state.carnumber
        },
        payFormValid(){
            if(!this.customer.fname) return false
            if(!this.customer.lname) return false
            if(!this.customer.emailaddress) return false
            let isemail =  /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(this.customer.emailaddress)
            if(!isemail) return false
            if(!this.customer.mobile) return false
            if(this.confirmPhone!==this.customer.mobile) return false
            if(this.orderType=='curbside' && !this.customer.carnumber) return false
            window.localStorage.setItem("SantaCruzProfile" , JSON.stringify(this.customer))
            return true
        },
		isLocationDeliveryActive(){
			const locations = this.$store.state.locations
            const activeloc = this.$store.state.orderlocation.id
			return locations.find((loc) => loc.id === activeloc)?.data.deliveryoptions.delivery
		},
        locationActive(){
            const locations = this.$store.state.locations
            const activeloc = this.$store.state.orderlocation.id

            let filter = (loc)=>{
                                return loc.id===activeloc
                            }

            locations.filter(filter)

            if(locations.filter(filter)[0] && !locations.filter(filter)[0].data.public) return false

            return true
        },
        inactiveLocationMsg(){
            return this.$store.state.inactiveLocationMsg
        },
        poweredbytext(){
            return this.$store.state.poweredbytext
        },
        api_url(){
            return this.$store.state.api_url
        },
        ccfee(){
            if(!this.orderTotal) return '0.00'
            let trxnfee = 0
            let mdr = 0
            if(this.orderLoc.data.currency ==='usd'){
                trxnfee =  Number(0.30)
                mdr = Number(1 - 0.049)
            }else if(this.orderLoc.data.currency ==='ttd'){
                trxnfee =  Number(3.00)
                mdr =  Number(1 - 0.059)
            }else {
                trxnfee =  Number(1.00)
                mdr =  Number(1 - 0.059)
            }
            let amount = Number(this.orderTotal)
            return Number(((amount + trxnfee)/mdr ) - amount).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        },
    },
    data(){
        return{
            txtsearch:'',
            activeitem:{},
            itemqty: 1,
            itemoptions:[],
            itemaddons:[],
            itemprice:0,
            itemaddonprice:0,
            itemname:'',
            itemid:'',
            customer:{fname:'', lname:'', fullname:'', emailaddress:'', mobile:'', carnumber:'', address:'', landmark:'', geo:''},
            confirmPhone:'',
            notes:'',
            payoption: '',
            tipOption: 0,
            order:[],
            ordersubmitted:false,
            stripeamount:0,
            fees:0,
            clientsecret:'',
            showstripe:false,
            isbusy:false,
            descriptor:'SOCAISLAND*',
            stripemounted:false,
            stripe:null,
            card:null,
            summarycart:[],
            showorder:false,
            orderdoc:'',
            needCashChange:true,
            summaryTotal:0,
            summaryNotes:'',
            summaryType:'',
        }
    },
    methods:{
        toggleOrder(){
            (this.showorder) ? this.showorder=false : this.showorder=true
        },
        async btnPayLaterClick(){

            this.isbusy = true
            let orderid = Math.floor(Date.now()/1000)
            this.$store.commit('setOrderid', orderid)
            this.customer.carnumber = this.carNumber.toUpperCase()
            this.customer.fullname = `${this.customer.fname} ${this.customer.lname}`
            this.customer.fullname = this.customer.fullname.toUpperCase()

            let smsnumber = (this.customer.mobile).replace(/\D/g, '')
            if(smsnumber.length==11) smsnumber = `${smsnumber}` 
            if(smsnumber.length==10) smsnumber = `1${smsnumber}` 
            if(smsnumber.length<10) smsnumber = `1868${smsnumber}`

            this.customer.mobile = smsnumber
            this.confirmPhone = smsnumber

            let order = {id: orderid, customer: this.customer, pickuplocation: this.orderLoc, ordertype: this.orderType, status:'pending', paymethod: this.payoption, storeid: this.storeId, storename: this.storeInfo.name, details:this.cart, notes:this.notes, fees:{tax: this.orderTaxes, delivery: this.orderDelivery, service: this.orderService}, subtotal: this.cartTotal, drivertip: Number(this.tipOption), total: this.orderTotal, lastupdate:orderid, needCashChange:this.needCashChange}

            await fb.ordersCollection.add(order).then((doc) => {
                this.orderdoc = doc.id
            }).catch(err=>{
                console.log(err)
                this.isbusy = false
            }) 

            for(var i=0; i<this.cart.length; i++){
                fb.cartitemsCollection.add({
                    orderid: orderid,
                    customer: this.customer,
                    itemid: this.cart[i].itemid,
                    itemname: this.cart[i].item,
                    qty: this.cart[i].qty,
                    storeid: this.storeId,
                    location: this.orderLoc,
                    ordertype: this.orderType,
                }).then(doc => {
                    console.log(doc.id)
                }).catch(err=>{
                    console.log(err)
                    this.isbusy = false
                })  

                for(var j=0; j<this.cart[i].modifiers.length; j++){
                    if(!this.cart[i].modifiers[j].id) continue
                    fb.cartitemsCollection.add({
                        orderid: orderid,
                        customer: this.customer,
                        itemid: this.cart[i].modifiers[j].id,
                        itemname: this.cart[i].modifiers[j].name,
                        qty: this.cart[i].qty,
                        storeid: this.storeId,
                        location: this.orderLoc,
                        ordertype: this.orderType,
                    }).then(() => {

                    }).catch(err=>{
                        console.log(err)
                        this.isbusy = false
                    })  
                }
            } 

            //save customer info
            let custid = this.customer.emailaddress.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
            this.customer.lastupdated = moment().unix()
            fb.storesCollection.doc(this.storeId).collection('customers').doc(custid).set(this.customer).then(()=>{
            })
            await fetch(`${this.api_url}/email/neworder/${orderid}!${this.orderType}!${encodeURI(this.storeInfo.name)} (${this.orderLoc.data.name})!${encodeURI(this.customer.emailaddress)}!${encodeURI(this.customer.fullname)}!${encodeURI(this.orderLoc.data.leadtime)}!${encodeURI(this.orderLoc.data.email)}!${this.orderdoc}!${this.storeId}`).then(()=> {
                return true
            })

            window.localStorage.clear()

            let pastOrders = this.pastOrders
            pastOrders.unshift({id: orderid, pickuplocation: this.orderLoc, ordertype: this.orderType, storeid: this.storeId, storename: this.storeInfo.name, details: this.$store.state.cart, notes:this.notes, fees:{tax: this.orderTaxes, delivery: this.orderDelivery, service: this.orderService}, subtotal: this.cartTotal, drivertip: Number(this.tipOption), total: this.orderTotal})
            window.localStorage.setItem(`${this.storeId}History`, JSON.stringify(pastOrders))
            window.localStorage.setItem("SantaCruzProfile" , JSON.stringify(this.customer))

            this.summaryType = this.orderType
            this.summarycart = this.$store.state.cart
            this.summaryTotal = this.orderTotal
            this.$store.state.cart = []
            this.$store.state.custaddress = ''
            this.$store.state.custlandmark = ''
            this.$store.state.carnumber = ''
            
            this.ordersubmitted = true
            window.scrollTo(0,0)
            this.isbusy = false
            //SEND SMS
            if(this.orderLoc.data.receiveorders.sms){
                await fetch(`${this.api_url}/sms/neworder/${this.orderdoc}!${this.orderType}!${encodeURI(this.orderLoc.data?.phone)}!${encodeURI(this.customer.fullname)}`).then(()=> {
                    return true
                })
            }
        },
        async btnCreditCardClick(){
            if(this.payoption!='creditcard') return false
            if(this.stripemounted) return false
            this.stripemounted = true
            this.stripeamount = Math.trunc((Number(this.orderTotal) + Number(this.ccfee))*100)
            this.fees = Number(this.ccfee)
            this.descriptor = this.storeInfo.name.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
            this.currency = this.orderLoc.data.currency
            this.stripe = Stripe('pk_live_NO5i9A0rJetFGqZ99mLPeSpP') //pk_test_itCmziCcsavym8T71gROTOhj  pk_live_NO5i9A0rJetFGqZ99mLPeSpP
            var elements = this.stripe.elements()
            let style = {
                base: {
                    color: '#32325d',
                    fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '1.15em',
                    '::placeholder': {
                            color: '#aab7c4'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a'
                }
            }
            // Create an instance of the card Element.
            this.card = elements.create('card', {style: style})
            // Add an instance of the card Element into the `card-element` <div>.
            this.card.mount('#card-element');
            // Handle real-time validation errors from the card Element.
            let displayError = document.getElementById('card-errors')
            this.card.addEventListener('change', (event)=> {
                this.isbusy = false
                if (event.error) {
                    displayError.textContent = event.error.message
                } else {
                    displayError.textContent = ''
                }
            })
            let submitButton = document.getElementById('card-button')
        },
        async btnPayClick(){
            this.isbusy = true

            let orderid = Math.floor(Date.now()/1000)
            this.$store.commit('setOrderid', orderid)
            this.customer.carnumber = this.carNumber.toUpperCase()
            this.customer.fullname = `${this.customer.fname} ${this.customer.lname}`
            this.customer.fullname = this.customer.fullname.toUpperCase()

            let smsnumber = (this.customer.mobile).replace(/\D/g, '')
            if(smsnumber.length==11) smsnumber = `${smsnumber}` 
            if(smsnumber.length==10) smsnumber = `1${smsnumber}` 
            if(smsnumber.length<10) smsnumber = `1868${smsnumber}`

            this.customer.mobile = smsnumber
            this.confirmPhone = smsnumber

            if(this.clientsecret==''){

                if(this.stripeamount <1) return false

                let striperesponse = await fetch(`${this.api_url}/stripe/create/${orderid}!${this.stripeamount}!${encodeURI(this.customer.fullname)}!${encodeURI(this.storeInfo.name)} (${encodeURI(this.orderLoc.data.name)})!${encodeURI(this.customer.emailaddress)}!${this.currency}!${encodeURI(this.storeInfo.name)}!${this.descriptor}`).then((response)=> {
                    return response.json()
                })

                this.clientsecret = striperesponse.client_secret
                this.pytintent = striperesponse.payment_intent
            }
            this.stripe.confirmCardPayment(this.clientsecret,
                {
                    payment_method: {
                    card: this.card,
                    billing_details: {
                    name: this.customer.fullname,
                    },
                },
            }).then(async (result) =>{
                if (result.error) {
                    document.getElementById('card-errors').textContent = result.error.code + " - " + result.error.decline_code
                    this.isbusy = false
                } else{
                    console.log('The payment has been processed!')
                    let order = {id: orderid, customer: this.customer, pickuplocation: this.orderLoc, ordertype: this.orderType, status:'pending', paymethod: this.payoption, storeid: this.storeId, storename: this.storeInfo.name, details:this.cart, notes:this.notes, fees:{tax: this.orderTaxes, delivery: this.orderDelivery, service: this.orderService}, subtotal: this.cartTotal, total: this.orderTotal, lastupdate:orderid, paymentintent:this.pytintent, ccfees:Number(this.ccfee), drivertip: Number(this.tipOption), needCashChange:this.needCashChange}
                    await fb.ordersCollection.add(order).then((doc) => {
                        this.orderdoc = doc.id
                    }).catch(err=>{
                        console.log(err)
                    }) 

                    for(var i=0; i<this.cart.length; i++){
                        fb.cartitemsCollection.add({
                            orderid: orderid,
                            customer: this.customer,
                            itemid: this.cart[i].itemid,
                            itemname: this.cart[i].item,
                            qty: this.cart[i].qty,
                            storeid: this.storeId,
                            location: this.orderLoc,
                            ordertype: this.orderType,
                        }).then(() => {
                            //console.log(doc.id)
                        }).catch(err=>{
                            console.log(err)
                        })  

                        for(var j=0; j<this.cart[i].modifiers.length; j++){
                            if(!this.cart[i].modifiers[j].id) continue
                            fb.cartitemsCollection.add({
                                orderid: orderid,
                                customer: this.customer,
                                itemid: this.cart[i].modifiers[j].id,
                                itemname: this.cart[i].modifiers[j].name,
                                qty: this.cart[i].qty,
                                storeid: this.storeId,
                                location: this.orderLoc,
                                ordertype: this.orderType,
                            }).then(()=> {
                                //console.log(doc.id)
                            }).catch(err=>{
                                console.log(err)
                            })  
                        }
                    } 

                    //save customer info
                    let custid = this.customer.emailaddress.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
                    this.customer.lastupdated = moment().unix()
                    await fb.storesCollection.doc(this.storeId).collection('customers').doc(custid).set(this.customer).then(()=>{
                    })

                    //SEND EMAIL
                    await fetch(`${this.api_url}/email/neworder/${orderid}!${this.orderType}!${encodeURI(this.storeInfo.name)} (${this.orderLoc.data.name})!${encodeURI(this.customer.emailaddress)}!${encodeURI(this.customer.fullname)}!${encodeURI(this.orderLoc.data.leadtime)}!${encodeURI(this.orderLoc.data.email)}!${this.orderdoc}!${this.storeId}`).then(()=> {
                        return true
                    })

                    //SEND SMS
                    if(this.orderLoc.data.receiveorders.sms){
                        await fetch(`${this.api_url}/sms/neworder/${this.orderdoc}!${this.orderType}!${encodeURI(this.orderLoc.data?.phone)}!${encodeURI(this.customer.fullname)}`).then(()=> {
                            return true
                        })
                    }

                    this.clientsecret = ''
                    this.isbusy = false 

                    window.localStorage.clear()

                    let pastOrders = this.pastOrders
                    pastOrders.unshift({id: orderid, pickuplocation: this.orderLoc, ordertype: this.orderType, storeid: this.storeId, storename: this.storeInfo.name, details: this.$store.state.cart, notes:this.notes, fees:{tax: this.orderTaxes, delivery: this.orderDelivery, service: this.orderService}, subtotal: this.cartTotal, drivertip: Number(this.tipOption), total: this.orderTotal})
                    window.localStorage.setItem(`${this.storeId}History`, JSON.stringify(pastOrders))
                    window.localStorage.setItem("SantaCruzProfile" , JSON.stringify(this.customer))
                    this.summaryType = this.orderType
                    this.summarycart = this.$store.state.cart
                    this.summaryTotal = this.orderTotal
                    this.$store.state.cart = []
                    this.$store.state.custaddress = ''
                    this.$store.state.custlandmark = ''
                    this.$store.state.carnumber = ''
                    this.ordersubmitted = true   
                    window.scrollTo(0,0)

                }
            })
        },
        orderComplete(){
            this.$router.push(`/${this.storeId}`)
        },
        /* cancelOrderClick(){
            let msg = "Are you sure you want to cancel this order?"
            var r = confirm(msg)
            if (r == true) {
                this.$store.state.cart = []
                window.localStorage.clear()
                window.localStorage.setItem(`${this.storeId}History`, JSON.stringify(this.pastOrders))
                let url = `/${this.storeId}`
                this.$router.push(url)
            }
        }, */
		cancelOrderClick(){
			this.$store.dispatch('cancelOrder')
		},
		changeOrderTypeClick(){
			this.$store.commit("setOrdertype", "pickup");
		},
    },
    mounted(){
        window.scrollTo(0,0)
        let storeId = this.$route.params.store
        if(this.storeId !== storeId) this.$store.dispatch('fetchStoreProfile', storeId)
        //if(window.localStorage.getItem(`${storeId}Profile`)) this.customer = JSON.parse(window.localStorage.getItem(`${storeId}Profile`))
		if(window.localStorage.getItem("SantaCruzProfile")) this.customer = JSON.parse(window.localStorage.getItem("SantaCruzProfile"))
		this.confirmPhone = this.customer.mobile
        this.customer.address = window.localStorage.getItem('custaddress')
        this.customer.geo = window.localStorage.getItem('custgeo')

        if(!window.localStorage.ordertype) this.$router.push(`/${storeId}`)
    }
}
</script>
