<template>
    <div class="row">
        <div class="row col-lg-12">
            <div class="col-lg-7 mb-5">
                <input type="text" class="form-control form-control" v-model="txtSearch" placeholder="Search expense categories..." />
            </div>
            <div class="col-lg-5 mb-5" align="right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addExpenceCategory" @click="categoryName=''"><i class="fas fa-plus"></i> New Expense Category</button>
            </div>
        </div>

        <div v-if="categories.length<1" align="center" class="col-12">
            <em>Expense categories will appear here when added...</em>
        </div>

        <div class="col-lg-12">

            <ul class="list-group my-2 mb-5">

                <!-- category listing -->

                <li class="list-group-item" v-for="(cat, index) in categories" :key="index">
                    <div class="row ">
                        <div class="col-lg-9 col-6 pointer" @click="activeSection = cat.id; activeCategoryName = cat.data.name; activeitem=''">
                            <h5 class="my-2">
                                {{cat.data.name}} 
                                <!--<br><small>{{cat.data.accountType}} / {{cat.data.detailType}}</small>-->
                            </h5>
                        </div>

                        <div class="col-lg-1 col-3" align="right">
                            <!--div class="custom-control custom-switch my-1">
                                <input type="checkbox" class="custom-control-input" :id="'cat'+cat.id" value="true" v-model="cat.data.public" @change="toggleCategory(cat.id, cat.data.public)">
                                <label class="custom-control-label" :for="'cat'+cat.id">
                                    <span v-show="cat.data.public">Disable</span>
                                    <span v-show="!cat.data.public">Enable</span>
                                </label>
                            </div-->
                        </div>
                        <div class="col-lg-2 col-2" align="right">
                            <div class="btn-group">
                                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Actions
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#!" @click="activeSection = cat.id; activeCategoryName = cat.data.name; activeitem=''">Edit Category</a>
                                    <!--a class="dropdown-item" href="#!" @click="deleteCategory(cat.id, cat.data.name)">Delete Category and Items</a-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="activeSection==cat.id" :ref="'catref'+cat.id">
                        <div class="col-lg-7" align="left">
                            <label>Name</label>
                            <input class="form-control mb-3" type="text" v-model="cat.data.name"/>
                            
                            <label>Account Type</label>
                            <select class="form-control mb-3" v-model="cat.data.accountType" @change="expenseCategory.detailType=null">
                                <option value="fixedassets">Fixed Assets</option>
                                <option value="costofgoodssold">Cost of Goods Sold</option>
                            </select>

                            <label>Detail Type</label>
                            <select class="form-control mb-3" v-model="cat.data.detailType">
                                <option value="equipment">Machinery and Equipment</option>
                                <option value="costoflabour">Cost of Labour</option>
                                <option value="costofgoods">Direct Cost of Goods Sold</option>
                            </select>

                            <label>Description</label>
                            <textarea class="form-control mb-3" rows="2" v-model="cat.data.desc"/>

                            <div align="right">
                                <button type="button" class="btn btn-secondary col-3 mr-2 my-4" @click="activeSection=''; cat.data.name = activeCategoryName">Close</button> 

                                <button type="button" class="btn btn-primary col-3 my-4" @click="updateCategory(cat.id, cat.data.name, cat.data.accountType, cat.data.detailType, cat.data.desc)">Update</button>
                            </div>
                        </div>
                        
                    </div>
                </li>
            </ul>

        </div>

        <!-- Modal add category-->
        <div class="modal fade" id="addExpenceCategory" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>New Expense Category</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      
                      <div class="col-lg-6 my-3">
                          <label>Account Type</label>
                          <select class="form-control" v-model="expenseCategory.accountType" @change="expenseCategory.detailType=null">
                              <option value="fixedassets">Fixed Assets</option>
                              <option value="costofgoodssold">Cost of Goods Sold</option>
                          </select>
                      </div>
                      <div class="col-lg-6 my-3">
                          <label>Detail Type</label>
                          <select class="form-control" v-model="expenseCategory.detailType">
                              <option v-if="expenseCategory.accountType=='fixedassets'" value="equipment">Machinery and Equipment</option>
                              <option v-if="expenseCategory.accountType=='costofgoodssold'" value="costoflabour">Cost of Labour</option>
                              <option v-if="expenseCategory.accountType=='costofgoodssold'" value="costofgoods">Direct Cost of Goods Sold</option>
                          </select>
                      </div>
                      <div class="col-lg-6 my-3">
                          <label>Category Name</label>
                          <input type="text" class="form-control" v-model="expenseCategory.name"/>
                      </div>
                      <div class="col-12 my-3">
                          <label>Description</label>
                          <textarea rows="3" class="form-control" v-model="expenseCategory.desc" />
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Close</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="saveCategoryClick" :disabled="!categoryFormValid">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        
    </div>
</template>

<script>

import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

const fb = require('../firebaseConfig.js')

export default {
  name: 'ExpenseCategories',
  props: {

  },
  filters: {

  },
  components:{
      
  },
  computed:{
      categoryFormValid(){
          //console.log(this.expenseCategory.detailType)
          if(!this.expenseCategory.name) return false
          if(!this.expenseCategory.detailType) return false
          return true
      },
      itemFormValid(){
          if(!this.newitem.name) return false
          //if(!this.newitem.desc) return false
          if(!this.newitem.price) return false
          return true
      },
  },
  data(){
      return{
          expenseCategory:{name:'', accountType:'costofgoodssold', detailType:'costofgoods', desc:''},
          storeId:'', 
          categoryName:'',  
          categories:[],
          activeCategory:'',
          activeCategoryName:'',
          activeSection:'',
          activeRef:'',
          txtSearch:'',
          isBusy:false,
      }
  },
  methods:{
      activeItemTab(item, ref){
          //alert(item)
          this.activeitem = item
          this.activeRef = 'ref'+item
          this.activeSection = ''
          //console.log("ref ", this.activeRef)
          if(item){
              this.$nextTick(() => {
                  this.$refs[item][0].$el.scrollIntoView({ behavior: 'smooth' })
              })
          }

          if(ref){
              //console.log("return", ref)
              this.$nextTick(() => {
                  //console.log("ref-list", this.$refs)
                  this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block:'center' })
              })
          }

      },
      updateCategory(id, name, accountType, detailType, desc){
          this.activeSection = ''
          fb.expenseCategoryCollection.doc(id).update({
              name: name,
              accountType: accountType,
              detailType: detailType,
              desc: desc,
              date: new Date(),
          }).then(()=>{
              Vue.$toast.success('Category updated!', {position:'bottom-right'})
          })
      },
      toggleCategory(id, status){
          //console.log(id, status)
          fb.expenseCategoryCollection.doc(id).update({
              public:status,
          }).then(()=>{
              Vue.$toast.success('Category updated!', {position:'bottom-right'})
          })
      },
      async saveCategoryClick(){
          this.expenseCategory.id = Math.floor(Date.now()/1000)
          this.expenseCategory.date = new Date()
          this.expenseCategory.public = true
          this.expenseCategory.total = 0
          this.expenseCategory.storeid = this.storeId
          await fb.expenseCategoryCollection.add(this.expenseCategory).then((doc)=>{
              this.activeSection = doc.id
              this.$nextTick(() => {
                  this.$refs['catref'+doc.id][0].scrollIntoView({ behavior: 'smooth', block:'center' })
              })
              Vue.$toast.success('New expense category created!',{position:'bottom-right'})
              this.activeCategoryName = this.expenseCategory.name
          })
      },
      deleteCategory(cat, catname){
          var r = confirm(`Delete category ${catname} and its menu items?`)
          if (r == true) {
              fb.expenseCategoryCollection.doc(cat).delete().then(()=>{
                  Vue.$toast.success('Category and items deleted!', {position:'bottom-right'})
              })
          }
      },
  },
  async mounted(){
      this.storeId = this.$route.params.store
      let storeId = this.storeId

      await fb.storesCollection.doc(storeId).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })      

      await fb.expenseCategoryCollection.where("storeid","==",storeId).orderBy("name", "asc").onSnapshot((querySnapshot) =>{
          let i=0
          this.categories.length=0
          querySnapshot.forEach((doc)=>{
              this.categories.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })  


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .pointer{cursor:pointer;}

</style>
