<template>
    <div class="row mb-10">
        <AppHeader title="Santa Cruz Directory" :showAddButton="showAddButton" @btnShowFormClick="btnShowFormClick" v-if="!isMobile"/>
        <div class="col-12 my-4" v-if="!isMobile"> 
            &nbsp;
        </div>

         <div class="col-12 my-3 app-header-alt1"  v-show="!showForm">
                <input :value="txtSearch" @input="txtSearch = $event.target.value" class="form-control" placeholder="What are you looking for?"/>
                <span class="text-muted small">e.g electrician</span>
        </div>

        <div class="col-12" v-show="!showForm">  
            <div align="center" class="my-5" v-if="Array.isArray(directorySearch) && directorySearch.length<1">
                <em>No results found...</em>
            </div>
            <ul class="list-group card-alt my-2" v-for="(d, index) in directorySearch" :key="index">
                <li class="list-group-item no-border">
                    <h6 class="my-2 pointer" @click="activeContact = (activeContact===d.id) ? '' : d.id">{{d.data.bname}}
                    <br/><!-- <small class="text-muted" style="font-weight:400;">{{d.data.category.join(", ").toUpperCase()}}</small> --></h6>
                    <div class="alert alert-info" v-if="activeContact===d.id">
                        <p>
                            <i class="fas fa-user"></i> <strong>{{d.data.contactFName}}</strong>
                            <a class="directory-action" @click="directoryActionClick({action: 'phone', data: d.data.phone})"><i class="fas fa-phone-square"></i> {{d.data.phone}}</a>
                            <a class="directory-action" @click="directoryActionClick({action: 'email', data: d.data.email})"><i class="fas fa-envelope" v-if="d.data.email"></i> {{d.data.email}}</a>
                            <a class="directory-action" @click="directoryActionClick({action: 'website', data: d.data.website})"
							style="overflow-wrap: break-word;">
								<i class="fas fa-globe" v-if="d.data.website"></i> {{d.data.website}}</a> 
                            <a class="directory-action" @click="directoryActionClick({action: 'address', data: d.data.address})"><i class="fas fa-map-marker-alt" v-if="d.data.address"></i> {{d.data.address}}</a> 
                        </p>
                        <p v-show="d.data.description">
                            ---<br/>
                            {{d.data.description}}
                        </p>
                    </div>

                </li>
            </ul>
        </div>

        <div class="col-12 my-2" v-show="showForm"> 
            <ul class="list-group">
                <li class="list-group-item">
                    <div v-show="!signupSuccess">
                        <h5 class="my-4">New Business Listing</h5>
                        <input type="text" v-model="businessInfo.bname" class="form-control my-3" placeholder="*Business name" />
                        <input type="text" v-model="businessInfo.contactFName" class="form-control  my-3" placeholder="*First name" />
                        <input type="text" v-model="businessInfo.contactLName" class="form-control  my-3" placeholder="*Last name" />
                        <input type="number" v-model="businessInfo.phone" class="form-control  my-3" placeholder="*Phone number" />
                        <input type="email" v-model="businessInfo.email" class="form-control  my-3" placeholder="Email address" />
                        <input type="text" v-model="businessInfo.website" class="form-control  my-3" placeholder="Website" />
                        <textarea rows="4" v-model="businessInfo.description" class="form-control  my-3" placeholder="Description... " />
                        <h6 class="my-4">Business Categories <br/>
                            <small>Select all that applies to your business</small>
                        </h6>
                        <label :for="cat" class="my-2" style="display:block;" v-for="(cat, index) in businessCategories" :key="index" >
                            <input type="checkbox" :id="cat" :value="cat" v-model="businessInfo.category">
                            {{cat.toUpperCase()}}
                        </label> 
                        <hr class="my-4"/>
                        <label for="terms" class="my-3">
                            <input type="checkbox" id="terms" value="true" v-model="checkedAgree">
                            I agree to the terms and conditions and privacy policy of the Santa Cruz Hub platform.
                        </label>          
                        <button class="btn btn-dark btn-block my-4" :disabled="!isFormValid || isBusy" @click="btnSubmitClick">
                            <span v-if="!isBusy">Submit for Review</span>
                            <div v-if="isBusy" class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </button>
                        <p>
                            <button class="btn btn-outline-dark btn-block" @click="btnHideFormClick" v-if="!$store.state.isMobile">Cancel</button>
                        </p>
                    </div>
                    <div align="center" class="my-5" v-show="signupSuccess">
                        <p>
                            <em>
                                <strong>Thank you for your submission!</strong> <br/>We shall review and notify you when your listing is public.
                            </em>
                        </p>
                        <p>
                            <button class="btn btn-dark" @click="signupSuccess=false; showForm=false"  v-if="!$store.state.isMobile">Continue</button>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
  
    </div>
</template>

<script>
    import Vue from 'vue'
    import AppHeader from "@/components/AppHeader.vue"
    Vue.use(require('vue-moment'))
    const fb = require('../firebaseConfig.js')
    var oWebViewInterface = window.nsWebViewInterface
    export default {
        name: 'CruzLifeInfo',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            AppHeader,
            
        },
        computed:{
            cruzLifeDirectory(){
                return this.$store.state.cruzLifeDirectory
            },
            directorySearch(){
                 let directory = this.cruzLifeDirectory
                 let search = this.txtSearch.toLowerCase()
                 if(search.length<3) return directory
                 const filter = (d)=>{
                     return (d.data.bname.toLowerCase().includes(search) || 
                     d.data.category.some((a)=>(a).replace("_", " ").includes(search)))
                 }
                 return directory.filter(filter) 
            },
            isFormValid(){
                if(!this.businessInfo.bname) return false
                if(!this.businessInfo.contactFName) return false
                if(!this.businessInfo.contactLName) return false
                if(!this.businessInfo.phone) return false
                if(this.businessInfo.category.length<1) return false
                if(!this.checkedAgree) return false
                return true
            }, 
            isMobile(){
                return this.$store.state.isMobile
            },
        },
        data(){
            return{
                activeContact:'',
                signupSuccess:false,
                showForm:false,
                readMore:false,
                isBusy: false,
                directory: [],
                txtSearch:'',
                showAddButton: true,
                businessInfo:{
                    bname:'',
                    contactFName:'',
                    contactLName:'',
                    description:'',
                    address:'',
                    email:'',
                    phone:'',
                    website:'',
                    location:'cruzlife',
                    status:false,
                    views:0,
                    category:[],
                },
                checkedAgree:false,
                businessCategories:[
                    "accommodations", 
                    "accounting", 
                    "air_conditioning", 
                    "agricultural_supplies", 
                    "animal_service", 
                    "app_development", 
                    "appliance", 
                    "appliance_repairs", 
                    "auto_sales", 
                    "auto_service_repairs", 
                    "bakery_dessert_shop", 
                    "banking", 
                    "barbershop", 
                    "bars", 
                    "beauty_products", 
                    "beauty_salon", 
                    "business_supplies_equipment", 
                    "cafe_bistro", 
                    "car_rental", 
                    "catering", 
                    "children_daycare",
                    "cleaning_services",
                    "clothing_footwear_accessories",   
                    "computers_accessories",
                    "consumer_electronics", 
                    "delivery_courier_service", 
                    "digital_services", 
                    "education_training", 
                    "engineering_services", 
                    "entertainment_supplies", 
                    "equipment_rental", 
                    "event_management", 
                    "financial_services", 
                    "florist", 
                    "food_distributor",
                    "food_stall",  
                    "funeral_services", 
                    "furniture", 
                    "games_toys", 
                    "gaming", 
                    "gas_station", 
                    "geriatric_nursing",
                    "general_contractor", 
                    "graphic_design", 
                    "grocery", 
                    "gym", 
                    "hair_care", 
                    "hardware", 
                    "heavy_transport", 
                    "herbalist", 
                    "home_electronics",
                    "home_kitchen",
                    "home_garden", 
                    "house_of_worship", 
                    "insurance", 
                    "installation_electronic_security",
                    "jewellery", 
                    "landscaping", 
                    "laundry_service", 
                    "legal_services", 
                    "liquor", 
                    "live_entertainment", 
                    "makeup_skin_care_services", 
                    "mobile_devices_accessories", 
                    "nail_care", 
                    "painting", 
                    "pediatrics",
                    "personal_trainer", 
                    "pharmacy", 
                    "photography", 
                    "photo_studio",
                    "plant_nursery",
                    "plumbing", 
                    "printing", 
                    "private_medical", 
                    "private_security", 
                    "real_estate_agent", 
                    "restaurant",  
                    "restaurant_equipment", 
                    "safety_supplies", 
                    "shipping", 
                    "signs", 
                    "stationery_arts_craft", 
                    "tattoo_artist",
                    "therapist", 
                    "tile_flooring", 
                    "tours_excursions", 
                    "transport", 
                    "travel_agency", 
                    "tyres_rims", 
                    "variety_store", 
                    "venue_rental", 
                    "videography", 
                    "waste_disposal", 
                    "web_development",
                    "welding", 
                    "wellness_services", 
                    "woodworking_masonry" 
                ],
            }
        },
        methods:{
            btnSubmitClick(){
                this.isBusy = true
                
                fb.directoryCollection.add(this.businessInfo).then((doc)=>{
                    console.log(doc.id)
                    this.signupSuccess = true
                    this.isBusy = false
                    this.businessInfo = {
                        bname:'',
                        contactFName:'',
                        contactLName:'',
                        description:'',
                        email:'',
                        phone:'',
                        website:'',
                        location:'cruzlife',
                        status:false,
                        views:0,
                        category:[],
                    }
                    this.checkedAgree = false
                    window.scrollTo(0,0)
                })    
            },
            btnHideFormClick(){
                this.showForm=false
                this.showAddButton = true
                window.scrollTo(0,0)
            },
            btnShowFormClick(){
                this.showForm=true
                this.showAddButton = false
                window.scrollTo(0,0)

            },
            directoryActionClick(data){
                if(this.isMobile){
                    oWebViewInterface.emit('directoryActionClick', data)
                }else{
                    switch(data.action){
                        case "phone":
                        location.href=`tel:+${data.data}`
                        break;
                        case "email":
                        location.href=`mailto:${data.data}`
                        break;
                        case "website":
                        location.href=`${data.data}`
                        break;
                    }
                }
            }
        },
        mounted(){
            window.scrollTo(0,0)
            oWebViewInterface.on('addBusiness', (eventData)=>{
                this.showForm = eventData
                this.signupSuccess = false
                window.scrollTo(0,0)
            })
        }
    }
</script>

