<template>
	<div class="container home-page">
		<div id="navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-white">
			<a class="navbar-brand" href="/"><img src="../assets/orderup-logo.png" class="img-fluid" style="height:60px;"/></a>
			<button id="mobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div align="right" class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav w-100">
					<!--li class="nav-item ml-auto">
						<a class="nav-link" href="/#pickupdelivery">Pick up & Delivery</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#tableordering" >Table Ordering</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#roomservice">Room Service</a>
					<li>
					<li class="nav-item">
						<a class="nav-link" href="/#pricing">Pricing</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#faq">FAQ</a>
					</li-->
					<li class="nav-item ml-auto my-1">
						<a href="/login" class="btn btn-outline-dark btn-sm" type="button">Sign in</a>
					</li>
					<li class="nav-item my-1 ml-3">
						<a href="/signup" class="btn btn-dark btn-sm" type="button">Sign up</a>
					</li>
				</ul>
			</div>
		</nav>
		</div>

		<div id="home" class=" my-5" style="padding-top:50px;">

<p class="my-5"><u>Last revised: September 2020</u></p>

<h1 class="my-5">Privacy Policy<br></h1>

<p><strong>Scope and Definitions. </strong><br>
This policy is meant to help you understand how Soca Islands LLC’s and Aeon Distributors’ owned and operated service, Order Up App (“we, us, our”) collects your information; why we collect it; when we share it; and how you can manage your information.
“Information” refers to information that you provide to the Order Up App which can personally identify you, such as your name, email address, billing information, or other data that can be reasonably linked to such personal information by the Order Up App;
“Website” refers to the Order Up App’s web-based service, mobile-application and/or website; and
“The Order Up App Merchants” refer to the restaurants, other Order Up App partners, and their vendors who fulfill your orders.
</p>

<p><strong>What Information We Collect.  </strong><br>
The Order Up App collects your Information, such as the following:
<ul>
<li>Name;</li>
<li>Phone number;</li>
<li>Email address;</li>
<li>Delivery address;</li>
<li>Order information and history;</li>
<li>Device information;</li>
<li>Browser information;</li>
<li>OS information;</li>
<li>Cookies. <em>Please note that cookies are stored on your device, and that our server logs may retain Information in cookies about your session when you enter and use our Website so that we can provide you with a more customized the Order Up App experience and further improve our Website. Our server logs may also record system information, such as device type and browser information, when you view our Website. You can delete or reject cookies on your device, but if you reject them, you will be unable to use the Order Up App’s services.</em></li>
<li>GPS data and IP address. We may use your IP address and GPS data to help fulfill your orders based on where you are. </li>
</ul>
</p>

<p><strong>Why We Collect Such Information. </strong><br>
The Order Up App may use your Information in various ways, including but not limited to:
<ul>
<li>Providing our services;</li>
<li>Maintaining, securing, and improving our services;</li>
<li>Developing new the Order Up App services;</li>
<li>Personalizing our services;</li>
<li>Analyzing performance;</li>
<li>Providing Information to the Order Up App Merchants to fulfill your order;</li>
<li>Communicating with and provide support to you and the Order Up App Merchants;</li>
<li>Improving and further develop our Website, including but not limited to keeping your session active and customizing your user experience; or</li>
<li>Sending you transactional emails (e.g., email receipts for your order); and</li>
<li>Sending you marketing emails, which will only be sent to you with your consent and will include “unsubscribe” links for you to click on if you do not wish for the Order Up App to send you such emails in the future.</li>
</ul>
</p><p>
We use and own non-personally identifiable information for various business purposes, including but not limited to maintaining, securing, and improving our services; developing new Order Up App services; personalizing our services; analyzing performance; providing metrics to our partners; communicating with and providing support to you and the Order Up App Merchants; and improving and further developing our Website.
</p>

<p><strong>When We Share Your Information. </strong><br>
The Order Up App does not share Information with other companies, organizations, or individuals outside of the Order Up App, except in the following cases:
<ul>
<li>With your consent. We will get your consent to share your Information with a the Order Up App merchant when ordering with that merchant, so that we can share with that merchant your name, email address, phone number, and other Information.</li>
<li>With domain administrators. </li>
<li>For external processing. We provide personal information to the Order Up App merchants and other service providers, credit-card processors, point-of-sale providers, SMS providers, hosting providers, and other trusted contractors providing services to process Information on our behalf, based on our instructions and in compliance with the Order Up App’s privacy policy and any other appropriate confidentiality and security measures. For instance, we may use service providers to help us with customer support or process credit-card information.</li>
<li>For legal reasons. We will share Information if we have a good-faith belief that access, use, preservation, or disclosure of the Information is reasonably necessary to:
<ul>
<li>Meet any applicable law, regulation, legal process, or enforceable governmental request;</li>
<li>Enforce the applicable, including investigation of potential violations;</li>
<li>Detect, prevent, or otherwise address fraud, security, or technical issues; or</li>
<li>Protect against harm to the rights, property, or safety of the Order Up App, the users thereof, or the public as required or permitted by law.</li>
</ul></li>
</ul>
</p>
<p>
The Order Up App may share non-personally identifiable information publicly and with our partners (such as the Order Up App merchants). For instance, we may allow specific partners to collect information from your browser or device for advertising or measurement purposes using their own cookies or similar technologies.
</p>

<p><strong>How You Can Delete Your Information? </strong><br>
If you would like the Order Up App to delete your information, we can help you do that. Please email us at support@orderuptoday.com to submit your request to delete in writing, so that we can take care of your request.
</p>

<p><strong>Other Terms.  </strong><br>
By submitting an order on our Website, you agree to comply with all terms of this Privacy Policy and further agree that we may process your Information.
</p>

<p>While we use industry standard security measures to protect against the loss, misuse, and alteration of the information under our control, there is no guarantee that our service cannot be compromised.
</p>

<p>If you have a privacy complaint or request, please contact us first. If you have a dispute or complaint about the Order Up App’s privacy practices or you have a request to change your information, please contact us first regarding your issue. We will try to resolve it! You can contact us at: support@orderuptoday.com 
</p>

<p>
Our Website may contain links to other sites and services, which are owned and controlled by others. Those websites have their own applicable privacy policies, and you should review those policies.
</p>
<p>
We may change this privacy policy at any time. Notice of any significant revision will be called out on this website for a reasonable period of time.
</p>






		</div>

		<!-- Footer -->
		<div id="footer" class="row" style="margin-bottom:0; border-top:1px solid #f1f1f1; padding:1rem 0 0 0;">
			<div class="col-12">
				<p align="left"><a class="mr-2" href="https://wa.me/18683522658"><i class="fab fa-whatsapp mr-1"></i>+1 (868) 352-2658</a>  <a class="mr-2" href="mailto:support@orderuptoday.com"><i class="fas1 fa-envelope1 mr-1"></i>support@orderuptoday.com</a> <a href="https://socaislands.freshdesk.com/" class="mr-2" target="_support">Support.</a> <a href="/terms" class="mr-2" target="_terms">Terms.</a> <a href="/privacy" class="mr-2" target="_terms">Privacy.</a> 
				<br><small>The Order Up app is a service provided by Soca Islands LLC and Aeon Distributors Ltd. &copy;{{copyyear}}.</small></p>
			</div>
		</div>
		<!-- /Footer -->
		
	</div>
</template>

<script>
// @ is an alias to /src
var moment = require('moment')

	
	export default {
		name: 'Home',
		components: {

		},
		data(){
			return{
				faqnum:0,
			}
		},
		computed:{
			copyyear(){
				return moment().format('YYYY')
			},
		},
		methods:{

		},
		mounted(){
			/*var prevScrollpos = window.pageYOffset;
			if(screen.width>990){
				window.onscroll = function() {

					var currentScrollPos = window.pageYOffset
					if (prevScrollpos > currentScrollPos) {
						document.getElementById("navbar").style.top = "0"
						//console.log("y", prevScrollpos)
					} else {
						document.getElementById("navbar").style.top = "-90px"
					}
					prevScrollpos = currentScrollPos
				}
			}*/

			//console.log(screen.width)
		}
	}
</script>
<style>
#navbar{
  background-color: #fff; /* Black background color */
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  left:0;
  width: 100%; /* Full width */
  padding:0 6.5rem;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index:100;
  border-bottom: 1px solid #f1f1f1;

}

.nav-link{
  font-size:18px;
  font-weight:500;
}

.home-page a:not(.btn){
	color:#343a40;
}

@media (max-width: 990px){
	.nav-link{
		font-size:18px;

	}
}

@media (max-width: 900px){
	#navbar{
		background-color: #fff; /* Black background color */
		position: fixed; /* Make it stick/fixed */
		top: 0; /* Stay on top */
		left:0;
		width: 100%; /* Full width */
		padding:0 1rem;
		transition: top 0.3s; /* Transition effect when sliding down (and up) */
		z-index:100;
	}
}
</style>
