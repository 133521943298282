<template>
    <div class="row mb-5">
        <AppHeader title="Santa Cruz Hub" v-if="$store.state.isMobile"/>

        <div class="col-12" style="padding-top:4rem;"> 
            <div class="row card-body pointer">
                <div class="col-12 col-lg-6 card-body" v-for="(cat, index) in categories" :key="index" > 
                    <div class="row card-alt" style="min-height:225px;">
                        <div class="col-5 pointer" :style="`background-image: url(https://source.unsplash.com/${cat.photo}/500x300); background-position:center;`">
                                
                        </div>
                        <div class="col-7">
                            <div class="card-body">
                                <p class="card-text">
                                    <span class="small">February 14 2021</span> <br/>
                                    {{cat.title}}
                                </p>
                                <p class="card-text">
                                    FREE
                                </p>
                                <p class="card-text small text-muted">
                                    Host: Soca Islands
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import AppHeader from '@/components/AppHeader.vue'

    Vue.use(require('vue-moment'))

    const fb = require('../firebaseConfig.js')


    export default {
        name: 'CruzLife',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            AppHeader,
        },
        computed:{
              
        },
        data(){
            return{
                categories:[
                    {
                        date:'Jan 28 2021', 
                        title: 'Trinidad Carnival 2022 ', 
                        text: 'Councillor for Maracas, Santa Cruz and La Fillete, Lyndon Lara has assured that traffic woes experienced in the morning peak time hours by motorists has not gone unnoticed.', 
                        photo:'kctt4tL1dkE'
                    },
                    {
                        date:'Jan 28 2021', 
                        title: 'Uber Soca Cruise', 
                        text: 'Councillor for Maracas, Santa Cruz and La Fillete, Lyndon Lara has assured that traffic woes experienced in the morning peak time hours by motorists has not gone unnoticed.', 
                        photo:'kctt4tL1dkE'
                    }, 
                ],
                showFeatured: false,
                featuredVendors: [],
            }
        },
        methods:{
              
        },
        async mounted(){
            await fb.storesCollection.where("featured", "==", true).onSnapshot((querySnapshot) =>{
                
                this.featuredVendors.length=0

                querySnapshot.forEach((doc)=>{
                    this.featuredVendors.push({id:doc.id, data:doc.data()})
                })

                if(this.featuredVendors.length<1) {
                    this.showFeatured = false
                } else{
                    this.showFeatured = true
                }

            })

            window.scrollTo(0,0)

            !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');

        }
    }
</script>

