<template>
    <div class="row mb-10">
        <AppHeader title="Santa Cruz Feed" @btnAddPostClick="btnAddPostClick" :showAddPostButton="true" v-if="!$store.state.isMobile"/>
        
        <div v-if="stagingFeed.length>0" class="alert alert-info bold pointer" style="position:fixed; z-index:90; left:40%; top:1rem;" @click="$store.dispatch('loadFeedPosts')">
            View {{stagingFeed.length}} {{stagingFeed.length==1 ? 'New Post' : 'New Posts'}}
        </div>
        <div class="col-12 my-2" v-if="showForm">
            <CruzLifeFeedAdd @btnCancelPost="showForm=false" />
        </div>

        <div class="col-12 my-4" v-if="!showForm">
            <div class="card card-alt" align="center" style="border:0; padding:0;" >
                <div class="card-body">
                    <small>{{ new Date() | moment("dddd, MMMM Do YYYY") }}</small><br/>
                    <a class="weatherwidget-io" :href="weatherUrl" data-label_1="La Pastora Settlement" data-label_2="" data-days="5" data-font="Roboto" data-mode="Both" data-theme="pure" >La Pastora Settlement</a>
                </div>
            </div>
        </div>
        
        <div class="col-12" v-if="!showForm"> 
            <div class="row" >
                <div class="col-12 my-5 text-muted card-body" align="center" v-if="Array.isArray(feed) && feed.length<1"> 
                    <em><i class="fas fa-stream"></i> No posts found...</em>
                </div>
                
                <div class="col-12 my-3" :class="(index%2==0) ? 'rightgutter' : 'leftgutter' " v-for="(feed, index) in feed" :key="index">
                    <div class="card">
                        <div class="card-img-container pointer" data-toggle="modal" data-target="#feedModal" @click="activeFeed = feed.data"  v-if="feed.data.img">
                            <img :src="`https://orderuptoday.imgix.net/${feed.data.imgFileName}?q=60&auto=format`" alt="..." class="card-img-top"  v-if="feed.data.img">
                        </div>
                        <div class="card-body ">
                            <h4 data-toggle="modal" data-target="#feedModal" class="card-title pointer" v-if="feed.data.title" @click="activeFeed = feed.data">
                                {{feed.data.title}}
                            </h4>
                            <div data-toggle="modal" data-target="#feedModal" class="card-text my-3 pointer excerpt" @click="activeFeed = feed.data">
                                {{feed.data.body}}
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <small class="text-muted">{{timeAgo(feed.data.postDate)}}</small>
                                </div>
                                <div class="col-6" align="right">
                                    <!-- <i @click="btnSharePostClick(feed.data)" class="fas fa-share-alt mr-4 pointer" v-if="isMobile"></i> -->
                                    <i @click="btnLikePostClick(feed.id)" class="fa-heart mr-4 pointer" :class="(feed.data.likes && feed.data.likes.includes(currentUser.uid)) ? 'fas' : 'far'"></i>
                                    <i @click="btnCommentPostClick(feed.id)" class="far fa-comment-alt pointer"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal View Post -->
        <div class="modal fade" id="feedModal" tabindex="-1" role="dialog" data-backdrop="static">
            <div class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered1" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card-body" v-if="activeFeed.img">
                            <img :src="`https://orderuptoday.imgix.net/${activeFeed.imgFileName}?q=60&auto=format`" class="img-fluid rounded">
                        </div>
                        <div class="card-body" v-if="activeFeed.title">
                            <h4 class="bold">{{activeFeed.title}}</h4>
                        </div>
                        <div class="card-body" style="white-space: pre-line; font-weight:400;">
                            {{activeFeed.body}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AppHeader from "@/components/AppHeader.vue"
    import CruzLifeFeedAdd from "@/components/CruzLifeFeedAdd.vue"
    var oWebViewInterface = window.nsWebViewInterface
    var moment = require('moment')

    export default {
        name: 'CruzLife',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            AppHeader, CruzLifeFeedAdd,
        },
        computed:{
            feed(){
                return this.$store.state.cruzLifeFeed
            },
            isMobile(){
                return this.$store.state.isMobile
            },
            isLoggedIn(){
                return this.$store.state.isLoggedIn
            },
            currentUser(){
                return this.$store.state.currentUser
            },
            stagingFeed(){
                return this.$store.state.stagingFeed
            },
        },
        data(){
            return{
                showFeatured: false,
                showForm: false,
                activeFeed: {},
                weatherUrl: '',
            }
        },
        methods:{
            timeAgo(t){
                return moment.unix(t).fromNow()
            },
            btnAddPostClick(){
                this.showForm = true
                window.scrollTo(0,0)
            },
            btnCloseModalClick(){
                oWebViewInterface.emit('feedActionClick', {action: "cancelPost", data:false})
                document.getElementById("btn-post-modal-close").click()
            },
            btnSharePostClick(post){
                if(!this.isMobile){
                    console.log(post)
                }else{
                    oWebViewInterface.emit('feedActionClick', {action: "sharePost", data:post})
                }
            },
            btnLikePostClick(post){
                if(this.isLoggedIn){
                    console.log(post)
                }else{
                    if(!this.isMobile){
                        alert("Please login to like this post.")
                    }else{
                        oWebViewInterface.emit('feedActionClick', {action: "likePost", data:"Please login to like this post."})
                    }
                }
            },
            btnCommentPostClick(post){
                console.log(post)
            },
        },
        mounted(){
            this.weatherUrl = 'https://forecast7.com/en/10d73n61d47/la-pastora-settlement/'
            window.scrollTo(0,0)
            !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');
            oWebViewInterface.on('newPost', (eventData)=>{
                this.showForm = eventData
                window.scrollTo(0,0)
            })
        }
    }
</script>
<style >

</style>

