<template>
    <div class="row">
        <div class="col-12">
            <input type="text" class="form-control my-3" @input="txtsearch=$event.target.value" placeholder="Search pending orders..."  v-if="orders.length>6" />
        </div>

        <div class="col-12" align="center" v-if="orders.length<1">
            <em>Pending orders will appear here ...</em>
        </div>
        <div class="col-lg-4 mb-4"  v-for="(order, index) in ordersList" :key="index" >
            <div class="card pointer1" data-toggle="modal" data-target="#pendingorderscart"  :style="(order.data.status==='processing') ? 'border:2px #28a745 solid !important;' : ''">
                <div class="card-body" align="center">
                    <span v-if="order.data.ordertype==='pickup'" class="badge badge-warning mb-3">{{String(order.data.ordertype).toUpperCase()}} Order #{{order.data.id}}</span>
                    <span v-if="order.data.ordertype==='curbside'" class="badge badge-info mb-3">{{String(order.data.ordertype).toUpperCase()}} Order #{{order.data.id}}</span>
                    <span v-if="order.data.ordertype==='delivery'" class="badge badge-primary mb-3">{{String(order.data.ordertype).toUpperCase()}} Order #{{order.data.id}}</span>
                   <span v-if="order.data.ordertype==='pointofsale'" class="badge badge-dark mb-3">{{String(order.data.ordertype).toUpperCase()}} Order #{{order.data.id}}</span>
                    <h6 class="clip-text"><strong>{{order.data.customer.fullname}}</strong></h6>
                    <ul class="list-group pointer" @click="btnProcessClick(order)">
                        <li class="list-group-item" v-for="(item, index) in order.data.details" :key="index">
                            <div class="row">
                                <div class="col-1"><h4>{{item.qty}}</h4></div>
                                <div class="col-10" align="left" style="font-size:25px;">
                                    <strong>{{item.item}}</strong>
                                    <span style="font-size:20px;" v-for="(option, i) in item.modifiers" :key="i">
                                        <br><em v-if="option.name!='None'"> - {{option.name}}</em>
                                    </span>
                                    <span v-if="item.notes">
                                        <em class="badge badge-secondary">{{item.notes}}</em>
                                    </span>
                                </div> 
                            </div>
                        </li>
                    </ul>
                  <button class="btn btn-success my-3" v-if="order.data.status=='processing'"  @click="btnReadyClick(order)">Order Ready</button>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
//import ViewCart from '@/components/ViewCart.vue'

const fb = require('../firebaseConfig.js')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


var moment = require('moment')
export default {
    name: 'ScheduleOrdersAlt',
    props: ['location'],
    filters: {
        currency(value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        },
        convertdate(value){
            if(!value) return ''
            return moment.unix(value).format("MMM DD - hh:mm a")
        },
        capitalize: function (string) {
            if (!string) return ''
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
    components:{
        
    },
    computed:{
        ordersList(){
            let orders = this.orders
            if(!this.txtsearch) return this.orders
            const search = this.txtsearch.toLowerCase()
            let filter = function(order){
                            return(order.data.customer.fullname.toLowerCase().includes(search) || order.data.customer.emailaddress.toLowerCase().includes(search) || order.data.customer.mobile.toLowerCase().includes(search) || order.id.includes(search))
                        }
            return orders.filter(filter)
        },
        storeInfo(){
            return this.$store.state.storeinfo
        },
        storeId(){
            return this.$store.state.storeid
        },
        api_url(){
            return this.$store.state.api_url
        },
    },
    data(){
        return{
            storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
            storelocations:[],
            storeid:'',
            orders:[],
            orderid:'',
            singleorder:{},
            cart:[],
            txtsearch:'',
            pendingcount:0,
            orderLoc:'',
        }
    },
    methods:{
        btnCancelClick(order){
            var r = confirm("Are you sure you want to cancel order #"+order.data.id);
            if (r == true) {
                fb.ordersCollection.doc(String(order.id)).update({
                    status: "cancel",
                    lastupdate:Math.floor(Date.now()/1000),
                })
                .then(async ()=> {
                    Vue.$toast.open("Order status successfully updated!")
                    document.getElementById('btnModalClose').click()

                    let emailresponse = await fetch(`${this.api_url}/email/ordercancel/${order.data.id}!${order.data.ordertype}!${encodeURI(this.storeInfo.name)}!${encodeURI(order.data.customer.emailaddress)}!${encodeURI(order.data.customer.fullname)}!${encodeURI(order.data.pickuplocation.data.leadtime)}!${order.data.id}!${this.storeId}`).then(()=> {
                            return true
                        })

                        console.log("email response", emailresponse)

                })
                .catch((error)=> {
                    console.error("Error updating document: ", error)
                })
            } else {
                return false
            }
        },
        btnProcessClick(order){
            let newState='processing'
            if(order.data.status==='processing') newState='pending'
            fb.ordersCollection.doc(String(order.id)).update({
                status: newState,
                lastupdate:Math.floor(Date.now()/1000),
            })
            .then(async ()=> {
                Vue.$toast.open("Order status successfully updated!")
                let emailresponse = await fetch(`${this.api_url}/email/orderprocessing/${order.data.id}!${order.data.ordertype}!${encodeURI(this.storeInfo.name)}!${encodeURI(order.data.customer.emailaddress)}!${encodeURI(order.data.customer.fullname)}!${encodeURI(order.data.pickuplocation.data.leadtime)}!${order.data.id}!${this.storeId}`).then(()=> {
                    return true
                })
                console.log("email response", emailresponse)
            })
            .catch((error)=> {
                console.error("Error updating document: ", error)
            })
        },
        btnReadyClick(order){
            var r = confirm("Change order status to 'Ready for Pickup' for order #"+order.data.id);
            if (r == true) {
                fb.ordersCollection.doc(String(order.id)).update({
                    status: "ready",
                    lastupdate:Math.floor(Date.now()/1000),
                })
                .then(async ()=> {
                    Vue.$toast.open("Order status successfully updated!")
                })
                .catch((error)=> {
                    console.error("Error updating document: ", error)
                })
            } else {
                return false
            }
        },
        btnPrintClick(){

        },
    },
    mounted(){
        const storeid = this.$route.params.store
        this.storeid = storeid

        fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
            if (doc.exists) {
                this.storeinfo = doc.data()
            } else {
                console.log("No such document!")
            }
        })
        fb.ordersCollection.where("storeid", "==", storeid).where("pickuplocation.id", "==", this.location.id).where("status", "in", ["pending", "processing"]).orderBy("id", "asc").onSnapshot((querySnapshot)=>{
            let j=0
            this.orders.length=0
            querySnapshot.forEach((doc)=>{
                this.orders.push({index:j, id:doc.id, data:doc.data()})
                j++
            })
            if(this.orders.length==0) this.orders=[]
            this.$emit("pendingcount", this.orders.length)
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .fixed-header{
        padding:20px 20px;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    .clip-text{
        white-space: nowrap; 
        width: 100%; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a:hover{
        text-decoration:none;
    }

    body{
        font-size:18px;
    }

</style>
