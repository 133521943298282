<template>
	<div>
        <div class="row card-body" align="center" >
            <div class="col-12">
                <img style="width:300px; height:auto;" class="img-fluid pointer" :src="(currentImg) ? currentImg : imgSrc" @click="btnEditImageClick" />
                <br/>
                <button class="btn btn-block btn-outline-dark my-1" @click="btnEditImageClick">
                    Click to add/edit image <i class="fas fa-image" ></i> 
                </button>
            </div>  
        </div>

        <input id="fileInput" type="file" name="image" accept="image/*" @change="setImage" /> 
        <a id="launchImageCropperModal" data-toggle="modal" data-target="#imgCroppperModal"></a>

        <!-- Modal -->
		<div class="modal fade" id="imgCroppperModal" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
				<div class="modal-content">
					<div class="modal-body">
                        <a id="closeImageCropperModal" data-dismiss="modal" ></a>
						<div class="row">
                            <div class="cropper-preview col-12">
                                <vue-cropper
                                    ref="cropper"
                                    :src="imgSrc"
                                    :aspect-ratio="1/1"
                                    :min-container-width="360"
                                    :min-container-height="360"
                                    alt=""
                                />
                            </div>
                        </div>
					</div>
                    <div class="modal-footer">
						<div class="col" align="left">
							<button type="button" class="btn btn-outline-primary" data-dismiss="modal" @click="btnCancelUploadClick">Cancel Upload</button>
						</div>
						<div class="col" align="right">
							<button type="button" class="btn btn-primary" @click="btnSaveUploadClick">
                                <span v-show="!isBusy">Crop and Save</span>
                                <span v-show="isBusy">{{(loadProgress>99) ? 'Saving Image...' : `Upload - ${Math.ceil(loadProgress)}%`}}</span>
                            </button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
    import Vue from 'vue'
	import VueToast from 'vue-toast-notification'
	import 'vue-toast-notification/dist/theme-sugar.css'
    import VueCropper from 'vue-cropperjs'
    import 'cropperjs/dist/cropper.css'

    Vue.use(VueToast)

	const fb = require("../firebaseConfig")
	export default {
		name: 'ImageUploader',
		filters: {

		},
        props:["docId", "currentImg", "folder", "type"],
		components: {
			VueCropper,
		},
		computed:{
            userProfile(){
                return this.$store.state.userProfile
            }
		},
		data(){
			return{
				isBusy: false,
                imgSrc: this.$store.state.imgPlaceholder,
                cropImg: '',
                data: null,
                loadProgress: '0',
			}
		},
		methods:{
            setImage(e) {
                document.getElementById("launchImageCropperModal").click()
                const file = e.target.files[0]
                if (file.type.indexOf('image/') === -1) {
                    Vue.$toast.error("Please select an image file.", {position:"top"})
                    return
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader()
                    reader.onload = (event) => {
                    this.$refs.cropper.replace(event.target.result)
                    }
                    reader.readAsDataURL(file)
                } else {
                    Vue.$toast.error("Sorry, FileReader API not supported.", {position:"top"})
                }
            },
            btnEditImageClick(){
                document.getElementById("fileInput").click()
			},
            btnCancelUploadClick(){
                document.getElementById("fileInput").value = null
            },
            btnSaveUploadClick(){
                this.loadProgress = 0
                this.isBusy = true
                this.cropImg = this.$refs.cropper.getCroppedCanvas()
                this.imgSrc = this.cropImg.toDataURL('image/jpeg')

                let fileName = (this.type=="profile") ? `${this.userProfile.uuid}.jpg` : document.getElementById("fileInput").files[0].name

                let metadata = {
                    cacheControl: (this.type=="profile") ? 'none' : 'public,max-age=300',
                    contentType: 'image/jpeg'
                }
                this.cropImg.toBlob((blob)=>{
                    var uploadTask = fb.storageRef.child(`${this.folder}/${fileName}`).put(blob, metadata)

                    uploadTask.on(fb.firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        //console.log('Upload is ' + progress + '% done')
                        this.loadProgress = progress
                        switch (snapshot.state) {
                            case fb.firebase.storage.TaskState.PAUSED: 
                                break
                            case fb.firebase.storage.TaskState.RUNNING: 
                                break
                        }
                    }, 
                    (error) => {
                        switch (error.code) {
                        case 'storage/unauthorized':
                            Vue.$toast.error("There was error uploding your file.", {position:"top"})
                            break
                        case 'storage/canceled':
                            Vue.$toast.error("There was error uploding your file.", {position:"top"})
                            break
                        case 'storage/unknown':
                            Vue.$toast.error("There was error uploding your file.", {position:"top"})
                            break
                        }
                        this.isBusy = false
                    }, 
                    () => {
                        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                            
                            if(this.type=="profile"){
                                fb.customerCollection.doc(this.docId).update({
                                    imgFile: downloadURL,
                                    imgFileName: `${this.folder}/${fileName}`,
                                }).then(()=>{
                                    this.resetImgUploader()
                                })
                            }else{
                                this.$emit("downloadURL", {imgFile: downloadURL, imgFileName:`${this.folder}/${fileName}`})
                                this.resetImgUploader()
                            }
                        })
                    })
                })
            },
            resetImgUploader(){
                document.getElementById("fileInput").value = null
                document.getElementById("closeImageCropperModal").click()
                this.isBusy = false
            }
		},
		mounted(){
            
        }
	}
</script>

<style >
    input[type="file"] {
        display: none
    }
    .cropper-hidden{
        min-height: 70vh;
        max-height: 70vh;
    }
    @media only screen and (max-width: 600px) {
		.cropper-hidden{
            min-width: 60vh;
            max-width: 60vh;
        }
	}
</style>
