<template>
    <div>
        <div id="maintenance" v-if="storeinfo.public===false" class="pb-100"><!-- menu categories -->
          <div class="row">
              <div class="card-body">
                  
                  <center><img v-if="storeinfo.pagesettings.logo" width="150" :src="storeinfo.pagesettings.logo" class="img-fluid img-thumbnail avatar-img rounded-circle mb-4" alt="JM's Grill" /></center>
                  <h2 v-if="!storeinfo.pagesettings.logo" align="center" class="my-2 mb-5">{{storeinfo.name}}</h2>
                  <ul class="list-group">
                      <li class="list-group-item active my-5">
                          <h3 align="center"><i class="fas fa-tool"></i> Sorry, online ordering is currently disabled.</h3>
                      </li>
                  </ul>
              </div>
          </div>
       </div>
    

      <div id="single" class="row" v-show="activetab=='single'"><!-- item details -->
          <div align="right" class="fixed-header"><a :href="'/'+storeid" > <i class="fas fa-angle-left"></i> Back to menu</a> | <a href="javascript:window.history.back();" > <i class="fas fa-angle-left"></i> Back to items</a></div>
          <div class="card-body py-100">
              
              <h4 class="bold">{{menutitle}}</h4>
              <p v-show="itemmodifiers.length<1"><em>This item has no additional options. Tap the 'ADD' button to add this item to your order.</em></p>
              <p v-show="itemmodifiers.length>0"><em>Customise your order.</em></p>
              <ul class="list-group my-3" v-show="itemmodifiers.length>0">
                  <li class="list-group-item" v-for="(modifier, index) in itemmodifiers" :key="index">
                      <div class="row">
                          <label class="bold">{{modifier.data.name}} <small><em>{{(modifier.data.required) ? '(required)' : '(optional)'}}</em></small></label>
                          <select class="form-control txt-center" v-model="modifyoptions[index]" @change="updateAddonPrice">
                              <option value="0" v-if="!modifier.data.required">None</option>
                              <option v-for="(option, i) in modifier.data.options" :key="i" :value="{label: option.label, price:option.price}">{{option.label}}</option>
                          </select>
                      </div>
                  </li>
              </ul>
              
          </div>
          <ul class="list-group list-group-flush fixed-footer">
              <li class="list-group-item">
                  <div class="row">
                      <div class="col">
                          <!--input class="form-control txt-center" type="number" value="1" min="0" max="10" step="1" /-->
                          <select class="form-control txt-center" v-model="lineitemqty"> 
                              <option v-for="i in 10" :key="i" class="txt-center">{{i}}</option>
                          </select>
                      </div>
                      <div class="col-8">
                          <button type="button" class="btn btn-primary btn-block" @click="btnAddClick"><strong>ADD ${{lineitemtotal|currency}}</strong></button>
                      </div>
                  </div>
              </li>
              <li class="list-group-item" v-show="cart.length>0">
                  <div class="row">
                      
                          <button type="button" class="btn btn-link btn-block" @click="btnCartClick" ><strong><i class="fas fa-shopping-basket"></i> VIEW ORDER ${{carttotal|currency}}</strong></button>
                     
                  </div>
              </li>
          </ul>
      </div><!-- /item details -->

      <div id="shopcart" class="row" v-show="activetab=='shopcart'"><!-- shopping cart -->
          <div align="right" class="fixed-header"><a href="#!" @click="btnBackClick('home')"> <i class="fas fa-angle-left"></i> Back to menu</a></div>
          <div class="card-body py-100 pb-100">
              
              <h4 class="bold"><i class="fas fa-shopping-basket"></i> Your Order</h4>
              <p v-show="cart.length<1"><em>Items you add to your order will appear here.</em></p>
              <ul class="list-group my-4" v-show="cart.length>0">
                  <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                      <div class="row">
                          <div class="col">
                              <strong>{{item.item}} x {{item.qty}} </strong><br>
                              <small v-for="(modifier, i) in item.modifiers" :key="i"><em> - {{modifier}}</em><br></small>
                          </div>
                          <div class="col-3" align="center">
                              ${{item.subtotal|currency}}
                          </div>
                          <div class="col-2" align="center">
                              <a href="#!" @click="btnDelete(index)"><i class="fas fa-trash"></i></a>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item ">
                      <div align="right">
                          <span class="badge badge-primary my-4">{{cartcount}} Items</span>
                      </div>
                  </li>
                  <li class="list-group-item active">
                      <div class="row">
                          <div class="col">
                            <p>Sub Total</p>
                          </div>
                          <div class="col-3" align="right">
                            <p>${{cartsubtotal|currency}}</p>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item active">
                      <div class="row">
                          <div class="col">
                            <p>Delivery</p>
                          </div>
                          <div class="col-3" align="right">
                            <p>$0.00</p>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item active">
                      <div class="row">
                          <div class="col">
                            <p>Fees</p>
                          </div>
                          <div class="col-3" align="right">
                            <p>${{cartfees|currency}}</p>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item active">
                      <div class="row">
                          <div class="col">
                            <p><strong>Total</strong></p>
                          </div>
                          <div class="col-3" align="right">
                            <p><strong>${{carttotal|currency}}</strong></p>
                          </div>
                      </div>
                  </li>
              </ul>
              <ul class="list-group list-group-flush fixed-footer">
                  <li class="list-group-item">
                      <div class="row" align="center">
                          
                              <button type="button" class="btn btn-primary btn-lg btn-block" @click="btnCheckoutClick" v-show="storeinfo.isopen" :disabled="!checkoutformvalid"><strong><i class="fas fa-receipt"></i> CHECKOUT ${{carttotal|currency}}</strong></button>
                              <button class="btn btn-primary btn-block btn-lg" disabled v-show="!storeinfo.isopen"><strong><i class="far fa-clock"></i> Sorry, online orders are closed.</strong></button>
                         
                      </div>
                  </li>
              </ul>
          </div>
      </div><!-- /shopping cart -->

      <div id="checkout" class="row" v-show="activetab=='checkout'"><!-- checkout form -->
          <div align="right" class="fixed-header"><a href="#!" @click="btnBackClick('home')"> <i class="fas fa-angle-left"></i> Back to menu</a></div>
          <div align="center" class="card-body py-100 pb-100" v-show="ordersuccess">
              <h2 align="center" class="bold"><i class="fas fa-check-circle"></i><br>
              Thank you for your order.</h2>
              <p align="center">Your order will be ready for pickup in 30 minutes.</p>
              <a class="btn btn-primary my-2" :href="'/'+storeid"><strong>Continue</strong></a>
          </div>
          <div class="card-body py-100 pb-100" v-show="!ordersuccess">
              
              <h4 align="left" class="bold"><i class="fas fa-receipt"></i> Checkout</h4>
              <p align="left"><em>Please fill in the form below to submit your order.</em></p>
              <div class="my-4 mb-5">
                  <select class="form-control my-2 mb-3" v-model="paymethod">
                      <option value="0" disabled>- Choose payment method -</option>
                      <option value="pay-on-pickup" :disabled="!storeinfo.paymentoptions.cashonpickup">Pay on Pickup</option>
                      <option value="credit-card" :disabled="!storeinfo.paymentoptions.creditcard">Credit Card</option>
                      <option value="pay-on-delivery" :disabled="!storeinfo.paymentoptions.cashondelivery">Pay on Delivery</option>
                  </select>
                  <input type="text" placeholder="Enter your full name" class="form-control mb-3" v-model="customer.fullname" />
                  <input type="email" placeholder="Enter your email address" class="form-control mb-3" v-model="customer.emailaddress" />
                  <input type="number" placeholder="Enter your mobile number" class="form-control mb-3" v-model="customer.mobile" />
                  <select class="form-control my-2 mb-3" v-model="pickuplocation">
                      <option value="0" disabled>- Choose a pickup location -</option>
                      <option v-for="(branch, index) in storelocations" :key="index">
                      {{branch.data.address.street}}, {{branch.data.address.city}}</option>
                  </select>
                  <textarea rows="3" class="form-control" v-model="ordernotes" placeholder="Order notes e.g. Roger will be picking up my order."></textarea>
              </div>
              <ul class="list-group mb-4">
                  <li class="list-group-item">
                      <div class="row">
                          <div class="col">
                            <p><strong>Total</strong></p>
                          </div>
                          <div class="col-3" align="right">
                            <p><strong>${{carttotal|currency}}</strong></p>
                          </div>
                      </div>
                  </li>
              </ul>
              <p class="mb-4 my-4" align="center"><small>By continuing, you agree to allow OrderUp.app to provide details, including your name, email and telephone number to the merchant, subject to OrderUp.app <a href="/privacy-policy/">privacy policy</a> and <a href="/user-terms/">terms and conditions</a>. </small></p>
              <button type="button" class="btn btn-primary btn-lg btn-block" :disabled="!paymentformvalid && !isbusy" @click="btnSubmitClick" v-show="paymethod=='pay-on-pickup' || paymethod=='pay-on-delivery'"><strong><i class="fas fa-paper-plane"></i> SUBMIT ORDER</strong></button>

              <button type="button" class="btn btn-primary btn-lg btn-block" v-show="paymethod=='credit-card'" :disabled="!paymentformvalid && !isbusy" @click="btnSubmitClick"><strong><i class="fas fa-credit-card"></i> PAY NOW</strong></button>
          </div>
      </div><!-- /checkout form -->

      <div id="reorder" class="row" v-show="activetab=='reorder'"><!-- re-order -->
          <div align="right" class="fixed-header"><a href="#!" @click="btnBackClick('home')"> <i class="fas fa-angle-left"></i> Back to menu</a></div>
          <div class="card-body py-100">
              
              <h4 class="mb-4 bold"><i class="far fa-clock"></i> Your Most Recent Order</h4>
              <ul class="list-group"  v-show="recentorder.id">
                  <li class="list-group-item active">
                      <div class="row">
                          <div class="col"><strong>Order No.: {{recentorder.id}}</strong></div>
                          <div class="col-5" align="right"><button type="button" class="btn btn-light" @click="btnRepeatClick"><strong><i class="fas fa-plus"></i> </strong></button></div>
                      </div>
                  </li>
                  <li class="list-group-item" v-for="(item, index) in recentorder.details" :key="index">
                      <div class="row">
                          <div class="col">
                              <strong>{{item.item}} x {{item.qty}} </strong><br>
                              <small v-for="(modifier, i) in item.modifiers" :key="i"><em> - {{modifier}}</em><br></small>
                          </div>
                          <div class="col-3" align="right">
                              ${{item.subtotal|currency}}
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item ">
                  </li>
                  <li class="list-group-item">
                      <div class="row">
                          <div class="col">
                            <p>Sub Total</p>
                          </div>
                          <div class="col-3" align="right">
                            <p>${{recentorder.subtotal|currency}}</p>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item">
                      <div class="row">
                          <div class="col">
                            <p>Fees</p>
                          </div>
                          <div class="col-3" align="right">
                            <p>${{recentorder.fees|currency}}</p>
                          </div>
                      </div>
                  </li>
                  <li class="list-group-item">
                      <div class="row">
                          <div class="col">
                            <p><strong>Total</strong></p>
                          </div>
                          <div class="col-3" align="right">
                            <p><strong>${{recentorder.total|currency}}</strong></p>
                          </div>
                      </div>
                  </li>
              </ul>
              <p v-show="recentorder.length<1"><em>No orders found...</em></p>
          </div>
      </div><!-- /re-order -->

      <div id="locations" class="row" v-show="activetab=='locations'"><!-- locations -->
          <div align="right" class="fixed-header"><a href="#!" @click="btnBackClick('home')"> <i class="fas fa-angle-left"></i> Back to menu</a></div>
          <div class="card-body py-100">
              
              <h4 class="mb-4 bold"><i class="fas fa-map-marker-alt" aria-hidden="true"></i> Pickup Locations</h4>
              <ul class="list-group">
                  <li class="list-group-item active" v-for="(branch, index) in storelocations" :key="index">
                      <h4><strong>{{branch.data.address.street}}, {{branch.data.address.city}}</strong></h4>
                      <p><a class="btn btn-primary" :href="'mailto:'+branch.data.email"><i class="fas fa-envelope mr-2"></i> {{branch.data.email}} </a>
                      <br><a class="btn btn-primary" :href="'tel:'+branch.data.phone"><i class="fas fa-phone mr-2"></i> {{branch.data.phone}} </a></p>
                  </li>
              </ul>
          </div>
      </div><!-- /locations -->

    </div>
</template>

<script>
const fb = require('../firebaseConfig.js')
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  filters: {
      currency: function (value) {
        if (!value) return 0
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{

  },
  computed:{
      menulist(){
          let items = this.menuitemslist
          const search = this.currentsection
          const filter = function(item){
                              return (item.data.section===search && item.data.public)
                          }

          return items.filter(filter)
      },
      menucategory(){
          let category = this.sectionslist
          const filter = function(item){
                              return item.data.public
                          }

          return category.filter(filter)
      },
      lineitemtotal(){
          return (this.lineitemqty * (Number(this.lineitemprice) + Number(this.lineaddonprice)))
      },
      carttotal(){
          return (this.cartsubtotal + this.cartfees)
      },
      cartsubtotal(){
          let total = 0
          for(var i=0; i<this.cart.length; i++){
              total += Number(this.cart[i].subtotal)
          }
          return total
      },
      cartfees(){
          return Number(this.cartsubtotal*0.015)
      },
      cartcount(){
          let total = 0
          for(var i=0; i<this.cart.length; i++){
              total += Number(this.cart[i].qty)
          }
          return total
      },
      paymentformvalid(){
          if(this.cart.length<1) return false
          if(!this.customer.fullname || !this.customer.emailaddress || !this.customer.mobile || this.pickuplocation=='0' || this.paymethod=='0') return false
          return true
      },
      checkoutformvalid(){
          if(this.cart.length<1) return false
          return true
      },
  },
  data(){
      return{
          sectionslist:[],
          menuitemslist:[],
          modifierslist:[],
          currentsection:'',
          sectiontitle:'',
          currentmenu:'',
          menutitle:'',
          lineitemprice:0,
          lineitemqty:1,
          lineaddonprice:0,
          lineaddons:[],
          itemmodifiers:[],
          modifyoptions:[],
          cart:[],
          customer:{fullname:'', emailaddress:'', mobile:''},
          isbusy:false,
          pickuplocation:0,
          paymethod:0,
          activetab:'single',
          ordernotes:'',
          recentorder:[],
          ordersuccess:false,
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          category:'',
          item:{},
      }
  },
  methods:{
      serviceUnavailable(){
          alert("Service currently unavailable!")
      },
      btnSectionClick(id, name){
          this.currentsection = id
          this.sectiontitle = name
          this.activetab = 'items'
      },
      btnMenuClick(id, data){
          this.currentmenu = id
          this.menutitle = data.name
          this.lineitemprice = Number(data.price)
          this.lineitemqty = 1
          this.lineaddonprice = 0
          this.ordernotes = ''
          if(data.modifiers){
              //console.log(data.name, ' has modifiers')
              this.itemmodifiers = this.modifierslist.filter(function(modifier){
                                        //console.log(modifier.data.name)
                                        return (data.modifiers.includes(modifier.id) && modifier.data.public)
                                    })
              this.modifyoptions.length=0
              for(var i=0; i<this.itemmodifiers.length; i++){
                  this.modifyoptions[i] = (this.itemmodifiers[i].data.required) ? {label:this.itemmodifiers[i].data.options[0].label, price:Number(this.itemmodifiers[i].data.options[0].price)} : '0'

              }
              //console.log(this.modifyoptions)
          }else{
              //console.log(data.name, ' has NO modifiers')
              this.itemmodifiers = []
              this.modifyoptions.length=0
          }
          this.activetab = 'single'
      },
      updateAddonPrice(){
          this.lineaddonprice = 0
          for(var i=0; i<this.modifyoptions.length; i++){
              if(this.modifyoptions[i].price) this.lineaddonprice += Number(this.modifyoptions[i].price)
          }

      },
      btnAddClick(){
          let lineitemqty = this.lineitemqty
          let lineitemtotal = Number(this.lineitemtotal)
          let menutitle = this.menutitle
          let modifiers = []
          for(var i=0; i<this.modifyoptions.length; i++){
              if(this.modifyoptions[i].label) modifiers = [...modifiers, this.modifyoptions[i].label]
          }
          let cartobj = {qty:lineitemqty, item:menutitle, modifiers: modifiers, price:lineitemtotal, subtotal:lineitemtotal}
          this.cart = [...this.cart, cartobj]

         // console.log(this.cart)
         localStorage.setItem("orderupcart", JSON.stringify(this.cart))
         //this.activetab = 'home'
      },
      btnCheckoutClick(){
          this.activetab = 'checkout'
          
      },
      btnCartClick(){
        this.activetab = 'shopcart'
      },
      btnLocationClick(){
        this.activetab = 'locations'
      },
      btnDelete(index){
          this.cart.splice(index,1)
          localStorage.setItem("orderupcart", JSON.stringify(this.cart))
      },
      btnBackClick(page){
        this.activetab = page
        this.ordersuccess = false
      },
      btnSubmitClick(){
          let $this = this
          let ordernum = Math.floor(Date.now()/1000)
          let storeid = this.storeid
          let subtotal = this.cartsubtotal
          let total = this.carttotal
          let fees = this.cartfees
          let status = 'pending'
          let lastupdate = ordernum
          let customer = this.customer
          let cart = this.cart
          let orderdate = new Date()
          let paymethod = this.paymethod
          let pickuplocation = this.pickuplocation
          let notes = this.ordernotes

          let order = {id:ordernum, orderdate:orderdate, customer:customer, details:cart, status:status, lastupdate:lastupdate, total:Number(total), subtotal:Number(subtotal), fees:Number(fees), paymethod:paymethod, pickuplocation:pickuplocation, storeid:storeid, notes:notes}

          fb.customerordersCollection.doc(String(ordernum)).set(order)
          .then(function() {
              console.log("Document successfully written!")
              localStorage.setItem("orderuporder", JSON.stringify(order))
              localStorage.removeItem("orderupcart")
              $this.ordersuccess = true
              //console.log(order)
          })
          .catch(function(error) {
              console.error("Error writing document: ", error)
          })
      },
      btnReorderClick(){
          this.activetab = 'reorder'
          if(localStorage.orderuporder) {
              this.recentorder = JSON.parse(localStorage.orderuporder)
          }else{
              this.recentorder.length=0
          }
          //console.log(this.recentorder)
      },
      btnRepeatClick(){
          this.cart.length = 0
          this.cart = this.recentorder.details
          this.customer = this.recentorder.customer
          this.activetab = 'shopcart'
          localStorage.setItem("orderupcart", JSON.stringify(this.cart))
      }

  },
  mounted(){
      let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid
      const category = this.$route.params.category
      this.category = category
      const item = this.$route.params.item
      this.item = item

      fb.storesCollection.doc(storeid).onSnapshot(function(doc) {
          if (doc.exists) {
              $this.storeinfo = doc.data()
             // console.log($this.storeinfo)
          } else {
              console.log("No such document!")
          }
      })


      /*fb.sectionsCollection.doc(category).get().then((doc)=> {
          this.sectiontitle = doc.data().name
      }).catch(function(error) {
          console.log("Error getting document:", error)
      })*/

      fb.menuitemsCollection.doc(item).get().then((doc)=> {
          this.menutitle = doc.data().name
          this.item = doc.data()
      }).catch(function(error) {
          console.log("Error getting document:", error)
      })

      /*fb.menuitemsCollection.where("section", "==", category).orderBy("name", "asc").onSnapshot(function(querySnapshot){
          let j=0
          $this.menuitemslist.length=0
          querySnapshot.forEach(function(doc){
              $this.menuitemslist.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
      })*/

      fb.modifiersCollection.where("storeid", "==", storeid).orderBy("name", "asc").onSnapshot(function(querySnapshot){
          let k=0
          $this.modifierslist.length=0
          querySnapshot.forEach(function(doc){
              $this.modifierslist.push({index:k, id:doc.id, data:doc.data()})
              k++
          })



         /* if(data.modifiers){
              //console.log(data.name, ' has modifiers')
              this.itemmodifiers = this.modifierslist.filter(function(modifier){
                                        //console.log(modifier.data.name)
                                        return (data.modifiers.includes(modifier.id) && modifier.data.public)
                                    })
              this.modifyoptions.length=0
              for(var i=0; i<this.itemmodifiers.length; i++){
                  this.modifyoptions[i] = (this.itemmodifiers[i].data.required) ? {label:this.itemmodifiers[i].data.options[0].label, price:this.itemmodifiers[i].data.options[0].price} : '0'

              }
              //console.log(this.modifyoptions)
          }else{
              //console.log(data.name, ' has NO modifiers')
              this.itemmodifiers = []
              this.modifyoptions.length=0
          }*/

      })

      if(localStorage.orderupcart) {
          this.cart = JSON.parse(localStorage.orderupcart)
      }else{
          this.cart.length=0
      }
      
      /*if(localStorage.orderuporder) {
          this.recentorder = JSON.parse(localStorage.orderuporder)
      }else{
          this.recentorder.length=0
      }*/

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .fixed-header{
        padding:10px 20px !important;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    .cover-img{
        background-size: cover;
        background-position: center;
    }

    .category-tile{
        background-size: cover;
        background-position: center;
        margin:auto;
        border: 2px solid #ffffff; 
        background:#000000  cover center; 
        height:150px;
        cursor:pointer;
    }


    div.transbox {
        background-color: #000000;
        opacity: .6;
        margin:0;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:0;
    }

    .logo-img{
        position:absolute;
        bottom:25px;
    }

    .category-title {
      margin:auto;
      color:#fff;
      text-align: center;
      font-weight:600;
      z-index:50;
    }

    a:hover{
        text-decoration:none;
    }

    

    body{
        font-size:18px;
    }

</style>
