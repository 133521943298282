<template>
	<div class="container home-page">
		<div id="navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-white">
			<a class="navbar-brand" href="/"><img src="../assets/orderup-logo.png" class="img-fluid" style="height:60px;"/></a>
			<button id="mobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div align="right" class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav w-100">
					<!--li class="nav-item ml-auto">
						<a class="nav-link" href="/#pickupdelivery">Pick up & Delivery</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#tableordering" >Table Ordering</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#roomservice">Room Service</a>
					<li>
					<li class="nav-item">
						<a class="nav-link" href="/#pricing">Pricing</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#faq">FAQ</a>
					</li-->
					<li class="nav-item ml-auto my-1">
						<a href="/login" class="btn btn-outline-dark btn-sm" type="button">Sign in</a>
					</li>
					<li class="nav-item my-1 ml-3">
						<a href="/signup" class="btn btn-dark btn-sm" type="button">Sign up</a>
					</li>
				</ul>
			</div>
		</nav>
		</div>

		<div id="home" class=" my-5" style="padding-top:50px;">

<p class="my-5">
<u>Last revised: September 2020</u>
</p>

<h1 class="my-5">Terms of Use</h1>

<p>
<strong>Applicable Terms.</strong><br>
By using Soca Islands LLC's and Aeon Distributors’ owned and operated service, the Order Up app (“we, us, our”), you agree to be bound to The Order Up app’s User Privacy Policy.  The Order Up app’s User Privacy Policy, and the additional terms below (“Additional Terms”), together are referred to as the “Terms.” It is important for you to read each of these documents, as they form a legal agreement between you and the Order Up app regarding your use of the Service.
</p>

<p>
Using the Service. You may use the Service to browse, locate, and order food (“Merchant Service”) offered by a the Order Up app merchant (“Merchant”). Merchants may also be referred herein as “Service Providers.”
</p>

<p>
<strong>Important Disclaimers. </strong><br>
THE ORDER UP APP DOES NOT PARTICIPATE IN THE PROVISION OF ANY MERCHANT SERVICE, AND DOES NOT REPRESENT OR ENDORSE AND WILL NOT BE RESPONSIBLE FOR: (A) THE RELIABILITY OR PERFORMANCE OF ANY SERVICE PROVIDER; (B) THE SAFETY, QUALITY, ACCURACY, RELIABILITY, INTEGRITY, OR LEGALITY OF ANY MERCHANT SERVICE; OR (C) THE TRUTH OR ACCURACY OF THE DESCRIPTION OF ANY MERCHANT SERVICE, OR OF ANY ADVICE, OPINION, OFFER, PROPOSAL, STATEMENT, DATA OR OTHER INFORMATION PROVIDED BY A SERVICE PROVIDER DISPLAYED, DISTRIBUTED, OR PURCHASED THROUGH THE SERVICE. THE ORDER UP APP DISCLAIMS ALL LIABILITY FOR THE ACTIONS, ERRORS OR OMISSIONS OF SERVICE PROVIDERS.
</p>

<p>
<strong>Verifying Merchant Details.</strong><br>
Although the Order Up app may in some instances attempt to verify certain information provided by Merchants, the Order Up app has no obligation to do so and makes no representations regarding the accuracy of such verification. It is your responsibility to verify the identity, qualifications and status of a Merchant.
</p>

<p>
<strong>Privacy.</strong><br>
To make use of certain Merchant Services, you may be required to provide information about yourself such as your name, address, and billing details to the Order Up app. Please note that the Order Up app’s User Privacy Policy explains how we treat your information when using the Service. You agree that any such information you provide to the Order Up app will always be accurate, correct and up to date. You agree that the Order Up app may share your information with Service Providers or payment processors in accordance with the Terms for purposes of making use of the Merchant Services. You agree that Service Providers may use your information, including sharing with each other, in accordance with the Merchant Service Terms (defined below).
</p>


<p>
<strong>Merchant Service Terms.</strong><br>
In addition to the Terms, each Merchant Service may also be subject to: (a) additional terms and conditions that may be disclosed to you at the time of your purchase of the Merchant Service; and (b) any terms and conditions or other requirements that may be imposed as a matter of applicable law. Your order, purchase, or use of a Merchant Service constitutes acceptance of the Terms and acceptance of the Merchant’s additional terms and conditions and privacy policy (collectively, the “Merchant Service Terms”).
</p>

<p>
<strong>Purchasing a Merchant Service. </strong><br>
When you submit an order using a Merchant Service, you are committing to purchase that Merchant Service from the Merchant, so please review the Merchant Service description carefully before purchasing it. A Service Provider may change the pricing and availability of a Merchant Service at any time before you purchase it. A Service Provider may also post rescheduling and cancellation policies in the Merchant Service description or Merchant Service Terms that may limit your ability to reschedule or cancel the Merchant Service without penalty.
</p>

<p>
Merchant Services are provided solely by the participating Merchants and the Order Up app is not offering or selling the Merchant Services. Merchant is solely responsible for the terms of all of its Merchant Services and for honouring all purchased Merchant Services that are sold through the Service. Your purchase or use of a Merchant Service constitutes acceptance of the terms and conditions stated on the specific Merchant Service.
</p>

<p>
The Order Up app may make available to you, various payment processing methods to facilitate the purchase of a Merchant Service. You must abide by any relevant terms and conditions or other legal agreement, whether with the Order Up app or a third party, that governs your use of a given payment processing method. The Order Up app may add or remove payment processing methods at its sole discretion and without notice to you.
</p>

<p>
Once your purchase is complete, the Order Up app or the Order Up app’s payment processor may charge your credit card or other form of payment that you indicate for any Merchant Service ordered, along with any additional applicable amounts (including any Taxes). “Taxes” means any duties, customs fees, or taxes (other than income tax) associated with the sale of a Merchant Service, including any related penalties or interest. You agree to pay and are solely responsible for all amounts due for your purchase of a Merchant Service, including Taxes.
</p>

<p>
<strong>Compliance with Laws. </strong><br>
You must comply with any and all applicable laws, including the reporting and payment of any taxes arising in connection with your use of the Service. The reporting and payment of any such applicable taxes are your responsibility.
</p>

<p>
<strong>Refunds. </strong><br>
You may have rights under the applicable Merchant’s policies to request a partial or full refund of the Merchant Service. The Order Up app does not handle refunds and is not responsible for a Merchant Service’s cancellation or refund policies.
</p>

<p>
<strong>Release. </strong><br>
You agree to release the Order Up app and its agents, contractors, officers and employees from all claims, demands and damages (actual and consequential) arising out of or in any way connected with your dispute with any Service Provider, advertiser or other third party in connection with the Service. You agree that you will not involve the Order Up app in any litigation or other dispute arising out of or related to any transaction, agreement, or arrangement with any Service Provider, advertiser or other third party in connection with the Service. If you attempt to do so, you will pay all costs and attorney’s fees of the Order Up app and will provide indemnification as set forth below. However, nothing in these terms will constitute a waiver of any rights, claims, or defenses that you may have with respect to a payment transaction under your card issuer agreement, the card association rules or applicable state and federal laws, such as the federal Truth in Lending Act or Electronic Fund Transfer Act.

If you are a California resident, you hereby expressly waive California Civil Code §1542, which states: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if not known by him must have materially affected his settlement with the debtor.”
</p>


<p>
<strong>Termination. </strong><br>
These Additional Terms will continue to apply until terminated by either you or the Order Up app as set out in this paragraph. If you want to terminate these Additional Terms, you may do so by ceasing your use of the Service and any purchased Merchant Services. The Order Up app may, in its sole and absolute discretion without liability to you or any third party, terminate your use of the Service for any reason, including without limitation inactivity or violation of these Additional Terms or other policies the Order Up app may establish from time to time. Upon termination of your use of the Service, you remain liable for all payment transactions and any other obligations you have incurred. Upon termination, The Order Up app has the right to prohibit your access to the Service and to refuse future access to the Service by you. Those provisions that by their nature should survive termination of these Additional Terms will survive any termination of these Additional Terms.
</p>



		</div>

		<!-- Footer -->
		<div id="footer" class="row" style="margin-bottom:0; border-top:1px solid #f1f1f1; padding:1rem 0 0 0;">
			<div class="col-12">
				<p align="left"><a class="mr-2" href="https://wa.me/18683522658"><i class="fab fa-whatsapp mr-1"></i>+1 (868) 352-2658</a>  <a class="mr-2" href="mailto:support@orderuptoday.com"><i class="fas1 fa-envelope1 mr-1"></i>support@orderuptoday.com</a> <a href="https://socaislands.freshdesk.com/" class="mr-2" target="_support">Support.</a> <a href="/terms" class="mr-2" target="_terms">Terms.</a> <a href="/privacy" class="mr-2" target="_terms">Privacy.</a> 
				<br><small>The Order Up app is a service provided by Soca Islands LLC and Aeon Distributors Ltd. &copy;{{copyyear}}.</small></p>
			</div>
		</div>
		<!-- /Footer -->
		
	</div>
</template>

<script>
// @ is an alias to /src
var moment = require('moment')

	
	export default {
		name: 'Home',
		components: {

		},
		data(){
			return{
				faqnum:0,
			}
		},
		computed:{
			copyyear(){
				return moment().format('YYYY')
			},
		},
		methods:{

		},
		mounted(){
			var prevScrollpos = window.pageYOffset;
			if(screen.width>990){
				window.onscroll = function() {

					var currentScrollPos = window.pageYOffset
					if (prevScrollpos > currentScrollPos) {
						document.getElementById("navbar").style.top = "0"
						//console.log("y", prevScrollpos)
					} else {
						document.getElementById("navbar").style.top = "-90px"
					}
					prevScrollpos = currentScrollPos
				}
			}

			//console.log(screen.width)
		}
	}
</script>
<style>
#navbar{
  background-color: #fff; /* Black background color */
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  left:0;
  width: 100%; /* Full width */
  padding:0 6.5rem;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index:100;
  border-bottom: 1px solid #f1f1f1;

}

.nav-link{
  font-size:18px;
  font-weight:500;
}

.home-page a:not(.btn){
	color:#343a40;
}

@media (max-width: 990px){
	.nav-link{
		font-size:18px;

	}
}

@media (max-width: 900px){
	#navbar{
		background-color: #fff; /* Black background color */
		position: fixed; /* Make it stick/fixed */
		top: 0; /* Stay on top */
		left:0;
		width: 100%; /* Full width */
		padding:0 1rem;
		transition: top 0.3s; /* Transition effect when sliding down (and up) */
		z-index:100;
	}
}
</style>
