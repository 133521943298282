<template>
    <div>
        <ul class="list-group" id="receipt">
            <li class="list-group-item">
                <div align="left" class="row">
                    <div class="col-6">
                        <h4 align="left"><strong>{{storeInfo.name || singleorder.storename}}</strong>, <span v-if="orderLoc.address">{{orderLoc.name}}</span></h4>

                        <p align="left"><span class="small">{{singleorder.id | convertdate}}
                        </span><br><span class="badge badge-primary">Order for {{singleorder.ordertype | capitalize}} {{(singleorder.customer && singleorder.customer.carnumber) ? '- ' + singleorder.customer.carnumber.toUpperCase() : ''}}  #{{singleorder.id}}</span></p>

                        <p class="modal-title">
                            {{(singleorder.customer)? singleorder.customer.fullname : ''}} 

                            <br>Tel: <a :href="(singleorder.customer) ? 'tel:+'+ singleorder.customer.mobile : ''">{{(singleorder.customer) ? singleorder.customer.mobile : ''}}</a>

                        </p>

                        <p class="modal-title my-2" v-if="singleorder.customer && singleorder.customer.address"><a :href="`http://www.google.com/maps/place/${singleorder.customer.geo}`"  target="map" class="btn-link">{{(singleorder.customer)? singleorder.customer.address : ''}} <!--i class="fas fa-directions"></i--></a><br><em class="text-primary"> {{singleorder.customer.landmark}}</em></p>
                    </div>
                    <div class="col-6" align="right">
                        <button type="button" id="printbutton" class="btn btn-secondary  my-4"  data-dismiss="modal1" aria-label="Close1" @click="btnPrintClick(orderid)">Print Order <i class="fas fa-print"></i></button>
                    </div>
                    
                </div>
            </li>
            <li class="list-group-item" v-if="singleorder.notes">
                <div class="alert alert-info">
                    {{singleorder.notes}}
                </div>
            </li>
            <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                <div class="row">
                    <div class="col-2"><h1 class="bold">{{item.qty}}</h1></div>
                    <div class="col-7">
                        <h5>
                            {{item.item}}
                            <span class="small" v-for="(option, i) in item.modifiers" :key="i">
                                <em v-if="option.name!='None'"><hr size="1" class="no-margin" /> {{option.name}} <span v-if="option.price>0"> (add ${{option.price}})</span></em>
                            </span>
                        </h5>
                    </div>
                    <div class="col-3 h5" align="right">${{item.subtotal | currency}}</div>
                    <div class="col-12" v-if="item.notes"> 
                        <div class="alert alert-info">
                            {{item.notes}}
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-12" align="right">
                        <span class="badge badge-primary">{{cart.length}} item(s)</span>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                    <div class="col-8" align="right">Subtotal</div>
                    <div class="col-4" align="right">${{singleorder.subtotal | currency}}</div>
                </div>
            </li>
            <li class="list-group-item" v-if="singleorder.fees && singleorder.fees.tax">
                <div class="row">
                    <div class="col-8" align="right">Taxes <em class="small">({{(orderLoc.fees) ? orderLoc.fees.tax : '0'}}%)</em></div>
                    <div class="col-4" align="right">${{singleorder.fees.tax | currency}}</div>
                </div>
            </li>

            <li class="list-group-item" v-if="singleorder.fees && singleorder.fees.service">
                <div class="row">
                    <div class="col-8" align="right">Service Charge <em class="small">({{(orderLoc.fees) ? orderLoc.fees.service : '0'}}%)</em></div>
                    <div class="col-4" align="right">${{singleorder.fees.service | currency}}</div>
                </div>
            </li>
            
            <li class="list-group-item" v-if="singleorder.ordertype=='delivery'">
                <div class="row">
                    <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                    <div class="col-4" align="right">${{singleorder.fees.delivery | currency}}</div>
                </div>
            </li>
            
            <li class="list-group-item">
                <div class="row">
                    <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                    <div class="col-4" align="right"><h5><strong>${{(singleorder.ordertype=='pointofsale') ?  (singleorder.total - singleorder.fees.delivery) : singleorder.total | currency}}</strong></h5></div>
                </div>
            </li>

            <li class="list-group-item" v-if="singleorder.drivertip>0 && singleorder.status=='ready'">
                    <div class="row">
                        <div class="col-8" align="right">Tip</div>
                        <div class="col-4" align="right">${{singleorder.drivertip | currency}}</div>
                    </div>
            </li>
            <li class="list-group-item" v-if="singleorder.needCashChange===true && singleorder.status=='ready'">
                <div class="row">
                    <div class="col-12" align="center"><em>This customer needs to get change.</em></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
var moment = require('moment')
export default {
  name: 'ViewCart',
  props: ['singleorder', 'orderLoc', 'orderid', 'cart'],
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD - hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
  },
  data(){
      return{

      }
  },
  methods:{
      btnPrintClick(){
          
      },
  },
  mounted(){
      
  }
}
</script>
