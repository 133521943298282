<template>
    <div class="row" style="padding:0; margin:0;">   

        <div class="col-lg-3">
        </div>
        <div class="col-lg-6">
            <!-- <div class="" align="center">
                <img src="../assets/orderup-logo.png" class="img-fluid my-4" style="height: 65px; width:auto;" />
            </div> -->

            <p align="center" class="my-3"><a href="/login" class="btn btn-link">Already have an account? Sign in.</a></p>

            <ul class="list-group mb-5" style="" v-show="step=='1'">
                <li class="list-group-item">
                    <p class="my-2 mb-4">* = required</p>
                    <h5>Business Information</h5>
                    <input type="text" class="form-control mb-4" placeholder="Enter your company name*" v-model="name"/>
                    <input type="text" class="form-control my-4" placeholder="Enter your company website (optional)" v-model="website" />

                    <select class="form-control my-4" v-model="address.country">
                        <option value="0" disabled>Address: Country*</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="BB">Barbados</option>
                        <option value="JM">Jamaica</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VG">Saint Vincent</option>
                        <option value="TT">Trinidad and Tobago</option>
                    </select>

                    <input type="text" class="form-control my-4 " placeholder="Address: Street*" v-model="address.street" v-show="address.country!='0'"/>

                    <input type="text" class="form-control my-4 " placeholder="Address: City*" v-model="address.city" v-show="address.country!='0'"/>

                    <input type="text" class="form-control  my-4" placeholder="Address: Parish" v-model="address.state" v-show="address.country!='TT' && address.country!='0'" />

                    <h5 class="mb-5">&nbsp;</h5>
                    <h5>Contact Information</h5>
                    <input type="text" class="form-control mb-4" placeholder="Contact first name*"  v-model="contact.fname"/>
                    <input type="text" class="form-control mb-4" placeholder="Contact last name*"  v-model="contact.lname"/>
                    <input type="email" class="form-control my-4" placeholder="Contact email address*"  v-model="contact.email" />
                    <input type="number" class="form-control my-4 mb-5" placeholder="Contact phone number*"  v-model="contact.phone" />

                    
                    
                    <h5>Create Password</h5>
                    <input type="password" class="form-control" placeholder="Enter password*"  v-model="password" @focus="passwordhint=true"/><span class="small text-danger" v-if="passwordhint===true && password.length<8">Password must be at least 8 characters.</span>
                    <input type="password" class="form-control my-4" placeholder="Confirm password*" v-model="confirmpassword"/>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-6">
                        </div>
                        <div class="col-lg-6" align="right">
                            <button type="button" class="btn btn-primary btn-block" @click="saveProfile" :disabled="!stepOneValid">
                                <span v-show="!isbusy">Create Account</span>
                                <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                            </button>
                        </div>
                    </div>
                </li>
            </ul>

        </div>
        <div class="col-lg-3">
        </div>

    </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
//var moment = require('moment')
import Dropzone from 'dropzone'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'


export default {
  name: 'SignUp',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{
      
  },
  computed:{
      stepOneValid(){
          if(!this.name) return false
          if(!this.contact.fname) return false
          if(!this.contact.lname) return false
          if(!this.contact.email) return false
          if(!this.contact.phone) return false
          if(this.address.country == '0') return false
          if(!this.address.street) return false
          if(!this.address.city) return false
          if(!this.password) return false
          if(!this.confirmpassword) return false
          if(this.password.length<8) return false
          if(this.confirmpassword != this.password) return false

          return true
      },
  },
  data(){
      return{
          step: '1',
          isbusy:false,
          myDropzone:null,
          coverimgDropzone:null,
          contact:{
              fname:'',
              lname:'',
              fullname:'',
              email:'',
              jobtitle:'',
              phone:'',
          },
          password:'',
          confirmpassword:'',
          address:{
              city:'',
              country:'0',
              state:'',
              street:'',
              zip:'00000',
          },
          website:'',
          public:true,
          active:true,
          userid:'',
          name:'',
          passwordhint:'',
          storeid:'',
      }
  },
  methods:{
      async saveProfile(){
          this.isbusy = true
          let userdata = await fb.auth.createUserWithEmailAndPassword(this.contact.email, this.password).then(user=>{
              user.user.updateProfile({
                  displayName: `${this.contact.fname} ${this.contact.lname}`,
                  phoneNumber: this.contact.phone
              })
              fb.auth.currentUser.sendEmailVerification()
              return user.user
          })
          let storeid = this.name.toLowerCase().replace(/[^\w\s]/gi, '').replace(/_/gi, '').replace(/\s+/g, '')
          console.log(storeid)
          await fb.storesCollection.doc(storeid).set({
              user: userdata.uid,
              address: this.address,
              website: this.website,
              active: true,
              public: true,
              name: this.name,
              passfees: true,
              pagesettings:{coverphoto:'', logo:'', themecolor:'#000000'}
          }).then(()=>{

          }).catch((err)=>{
              console.log(error)
              this.isbusy = false
          })

          this.contact.fullname = `${this.contact.fname} ${this.contact.lname}`

          await fb.storesCollection.doc(storeid).collection('contact').doc(storeid).set(this.contact).then(()=>{
              this.$router.push(`/${storeid}/dashboard`)
          }).catch((err)=>{
              console.log(error)
              this.isbusy = false
          })
          this.isbusy = false
          return false
      },
      maxfilesexceeded () {
              alert('file too big')
       },
       async afterComplete(file) {
            try {

                if(file.status=='error'){
                    alert("File is too big!")
                    return false
                }

                var metadata = {
                  contentType: "image/png"
                }
                
                let imageRef = fb.storageRef.child(`profile/${this.currentUser.uid}/profile.png`)
                let imageFile = 'profile/'+this.currentUser.uid+'/profile.png'
                await imageRef.put(file, metadata)
                var downloadURL = await imageRef.getDownloadURL()

                let ts = Math.floor(Date.now() / 1000)

                fb.customerCollection.doc(String(this.currentUser.uid)).update({
                    img: downloadURL,
                    imgfile: imageFile,
                    dateupdate: ts,
                }).then(function() {
                    fb.auth.currentUser.updateProfile({
                        photoURL: downloadURL
                    }).then(function() {
                      // Update successful.
                      console.log("Upload successful...")
                    }).catch(function() {
                      // An error happened.
                      alert("Please try again later!")
                    });
                }).catch(function(error) {
                    console.log(error)
                    alert("Please try again later!")
                })                  
            } catch (error) {
                console.log(error)
                alert("Please try again later!")
            }
            
            this.myDropzone.removeAllFiles()
            
        },

        async afterCoverPhotoComplete(file) {
            try {

                if(file.status=='error'){
                    alert("File is too big!")
                    //this.$refs.imgDropZone.removeFile(file)
                    return false
                }

                var metadata = {
                  contentType: "image/png"
                }
                
                let imageRef = fb.storageRef.child(`profile/${this.currentUser.uid}/profile.png`)
                let imageFile = 'profile/'+this.currentUser.uid+'/profile.png'
                await imageRef.put(file, metadata)
                var downloadURL = await imageRef.getDownloadURL()

                let ts = Math.floor(Date.now() / 1000)

                fb.customerCollection.doc(String(this.currentUser.uid)).update({
                    img: downloadURL,
                    imgfile: imageFile,
                    dateupdate: ts,
                }).then(function() {
                    fb.auth.currentUser.updateProfile({
                        photoURL: downloadURL
                    }).then(function() {
                      // Update successful.
                      console.log("Upload successful...")
                    }).catch(function() {
                      // An error happened.
                      alert("Please try again later!")
                    });
                }).catch(function(error) {
                    console.log(error)
                    alert("Please try again later!")
                })                  
            } catch (error) {
                console.log(error)
                alert("Please try again later!")
            }
            
            this.coverimgDropzone.removeAllFiles()
            
        },
  },
  mounted(){

  /*
      this.myDropzone = new Dropzone("div#logoimage",{ 
          url: 'https://httpbin.org/post',
          thumbnailWidth: 250,
          thumbnailHeight: 250,
          maxFilesize: 4,
          maxFile:1,
          addRemoveLinks: true,
          croppedfile:4,
          acceptedFiles: ".jpg, .jpeg, .png", 
          complete: (file)=>{
              var myDropZone = this
              myDropZone.removeFile(file)
          },
          dictDefaultMessage:'<span class="fas fa-camera"></span> Edit.</p>',
          transformFile: (file, done)=> { 
              // Create Dropzone reference for use in confirm button click handler
              var myDropZone = this;
              var editor = document.createElement('div');
              editor.style.position = 'fixed';
              editor.style.left = 0;
              editor.style.right = 0;
              editor.style.top = 0;
              editor.style.bottom = 0;
              editor.style.zIndex = 9999;
              editor.style.backgroundColor = '#000';
              document.body.appendChild(editor);

              // Create cancel button at the top left of the viewport
              var buttonCancel = document.createElement('button');
              buttonCancel.style.position = 'absolute';
              buttonCancel.style.left = '15px';
              buttonCancel.style.top = '15px';
              buttonCancel.style.zIndex = 9999;
              buttonCancel.textContent = 'Cancel';
              buttonCancel.classList.add("btn", "btn-danger");
              //editor.appendChild(buttonCancel);
              buttonCancel.addEventListener('click', function() {
                  myDropZone.removeAllFiles(true)
                  document.body.removeChild(editor);
              });

              // Create confirm button at the top left of the viewport
              var buttonConfirm = document.createElement('button');
              buttonConfirm.style.position = 'absolute';
              buttonConfirm.style.right = '15px';
              buttonConfirm.style.top = '15px';
              buttonConfirm.style.zIndex = 9999;
              buttonConfirm.textContent = 'Save Image';
              buttonConfirm.classList.add("btn", "btn-white");
              editor.appendChild(buttonConfirm);
              buttonConfirm.addEventListener('click', ()=> {
                  // Get the canvas with image data from Cropper.js
                  var canvas = cropper.getCroppedCanvas({
                    width: 1000,
                    height: 1000
                  });
                  // Turn the canvas into a Blob (file object without a name)
                  canvas.toBlob((blob)=> {
                      myDropZone.createThumbnail(
                          blob,
                          myDropZone.options.thumbnailWidth,
                          myDropZone.options.thumbnailHeight,
                          myDropZone.options.thumbnailMethod,
                          false, 
                          (dataURL)=> {
                              myDropZone.emit('thumbnail', file, dataURL);
                              //myDropZone.emit('complete', file);
                              //console.log(dataURL)
                              this.afterComplete(blob)
                              //myDropZone.removeFile(file)
                              done(blob);
                      });
                  });

                  document.body.removeChild(editor);
              });

              // Create an image node for Cropper.js
              var image = new Image();
              image.src = URL.createObjectURL(file);
              editor.appendChild(image);                        
              // Create Cropper.js
              var cropper = new Cropper(image, { aspectRatio: 1 });
          },
      })


      this.coverimgDropzone = new Dropzone("div#coverimage",{ 
          url: 'https://httpbin.org/post',
          thumbnailWidth: 250,
          thumbnailHeight: 250,
          maxFilesize: 4,
          maxFile:1,
          addRemoveLinks: true,
          croppedfile:4,
          acceptedFiles: ".jpg, .jpeg, .png", 
          complete: (file)=>{
              var coverimgDropzone = this
              coverimgDropzone.removeFile(file)
          },
          dictDefaultMessage:'<span class="fas fa-camera"></span> Edit.</p>',
          transformFile: (file, done)=> { 
              // Create Dropzone reference for use in confirm button click handler
              var coverimgDropzone = this;
              var editor = document.createElement('div');
              editor.style.position = 'fixed';
              editor.style.left = 0;
              editor.style.right = 0;
              editor.style.top = 0;
              editor.style.bottom = 0;
              editor.style.zIndex = 9999;
              editor.style.backgroundColor = '#000';
              document.body.appendChild(editor);

              // Create cancel button at the top left of the viewport
              var buttonCancel = document.createElement('button');
              buttonCancel.style.position = 'absolute';
              buttonCancel.style.left = '15px';
              buttonCancel.style.top = '15px';
              buttonCancel.style.zIndex = 9999;
              buttonCancel.textContent = 'Cancel';
              buttonCancel.classList.add("btn", "btn-danger");
              //editor.appendChild(buttonCancel);
              buttonCancel.addEventListener('click', function() {
                  coverimgDropzone.removeAllFiles(true)
                  document.body.removeChild(editor);
              });

              // Create confirm button at the top left of the viewport
              var buttonConfirm = document.createElement('button');
              buttonConfirm.style.position = 'absolute';
              buttonConfirm.style.right = '15px';
              buttonConfirm.style.top = '15px';
              buttonConfirm.style.zIndex = 9999;
              buttonConfirm.textContent = 'Save Image';
              buttonConfirm.classList.add("btn", "btn-white");
              editor.appendChild(buttonConfirm);
              buttonConfirm.addEventListener('click', ()=> {
                  // Get the canvas with image data from Cropper.js
                  var canvas = cropper.getCroppedCanvas({
                    width: 1000,
                    height: 1000
                  });
                  // Turn the canvas into a Blob (file object without a name)
                  canvas.toBlob((blob)=> {
                      coverimgDropzone.createThumbnail(
                          blob,
                          coverimgDropzone.options.thumbnailWidth,
                          coverimgDropzone.options.thumbnailHeight,
                          coverimgDropzone.options.thumbnailMethod,
                          false, 
                          (dataURL)=> {
                              coverimgDropzone.emit('thumbnail', file, dataURL);
                              //coverimgDropzone.emit('complete', file);
                              //console.log(dataURL)
                              this.afterCoverPhotoComplete(blob)
                              //coverimgDropzone.removeFile(file)
                              done(blob);
                      });
                  });

                  document.body.removeChild(editor);
              });

              // Create an image node for Cropper.js
              var image = new Image();
              image.src = URL.createObjectURL(file);
              editor.appendChild(image);                        
              // Create Cropper.js
              var cropper = new Cropper(image, { aspectRatio: 1 });
          },
      })

      */
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.settings-logo .dropzone{
        padding:0 !important; 
        background:none !important; 
        border:none !important;
        background-size:contain !important; 
        height:10rem; 
        width:10rem;
}
.settings-logo .dropzone .dz-message{
    margin:0 !important;  
    border:0;
    background: #fff; 
    padding: 0.25rem; 
    padding-top: 0.5rem; 
    height: 40px; 
    width:80px;
    bottom:0; 
    right:0;
    position:absolute;
}
.settings-logo .dropzone:hover{
    background: #fff; 
}
.settings-logo .dropzone .dz-button{
    border:none;
    line-height:0;
    color: #283e59;
    background: #fff;
}


.settings-cover .dropzone{
        padding:0 !important; 
        background:none !important; 
        border:none !important;
        background-size:contain !important; 
        height:10rem; 
        width:100%;
}
.settings-cover .dropzone .dz-message{
    margin:0 !important;  
    border:0;
    background: #fff; 
    padding: 0.25rem; 
    padding-top: 0.5rem; 
    height: 40px; 
    width:80px;
    bottom:0; 
    right:5%;
    position:absolute;
}
.settings-cover .dropzone:hover{
    background: #fff; 
}
.settings-cover .dropzone .dz-button{
    border:none;
    line-height:0;
    color: #283e59;
    background: #fff;
}


</style>
