<template>
	<div style="padding: 0px" class="row">
		<CruzBar v-if="!isMobile" />
		<div class="col-lg-3"></div>
		<div class="col-lg-6 my-5">
			<div align="center" class="busy-spinner" v-if="isBusy===null && !isMobile">
				<div align="center" class="spinner-border text-primary" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</div>
		<div class="row" v-if="isBusy===false">
			<div class="col-12">
			<input
				type="text"
				placeholder="Search locations ..."
				class="my-2 form-control form-control-lg"
				v-model="txtsearch"
				v-if="locations.length > 5"
			/>

			<div class="row" v-if="appnotice.active">
				<div class="alert alert-info">
					<h5>Notice</h5>
					<div class="my-4" v-html="appnotice.notice">
						
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-11" style="padding-right: 0 !important">
				<p class="my-4">
					Select a {{ storeInfo.name }} location from below to start your
					order.
				</p>
				</div>
			</div>

			<ul
				class="my-2 list-group"
				v-for="(location, index) in locations"
				:key="index"
			>
				<li
				class="list-group-item card-alt"
				v-if="location.data.active === true"
				>
				<h5
					class="pointer"
					@click="
					activelist == location.id
						? (activelist = '')
						: (activelist = location.id)
					"
				>
					{{ location.data.name }}
					<span
					style="float: right; font-size: 10px"
					class="small"
					v-html="openInfo(index)"
					></span>
				</h5>
				<p align="" class="small">
					<i class="fas fa-clock"></i> {{ location.data.leadtime }} mins.
					<br />
					<a
					href="#!"
					class="btn-link text-dark"
					style="font-size: 13px !important"
					><i class="fas fa-map-marker-alt"></i>
					{{ location.data.address.street }},
					{{ location.data.address.city }}</a
					>
					<br />
					<i class="fas fa-phone"></i> {{ location.data.phone }} / 
					<i class="fas fa-envelope"></i> {{ location.data.email }}
				</p>

				<div class="row my-4" v-if="location.data.public && (isOpen(index) || location.data.preorder)">
					<!--&& isOpen(index) && activelist==location.id-->
					<div
					class="col-4"
					style="
						padding-left: 7px !important;
						padding-right: 7px !important;
					"
					>
					<button
						class="btn btn-dark btn-block"
						type="button"
						:disabled="!location.data.deliveryoptions.pickup"
						data-toggle="modal1"
						data-target="#setordertype1"
						@click="continueOrder('pickup', location)"
					>
						<i class="fas fa-user-friends"></i><br />Pick up
					</button>
					</div>
					<div
					class="col-4"
					style="
						padding-left: 7px !important;
						padding-right: 7px !important;
					"
					>
					<button
						class="btn btn-dark btn-block"
						type="button"
						:disabled="!location.data.deliveryoptions.curbside"
						data-toggle="modal1"
						data-target="#setordertype1"
						@click="continueOrder('curbside', location)"
					>
						<i class="fas fa-car"></i><br />Curbside
					</button>
					</div>
					<div
					class="col-4"
					style="
						padding-left: 7px !important;
						padding-right: 7px !important;
					"
					>
					<button
						class="btn btn-dark btn-block"
						type="button"
						:disabled="!location.data.deliveryoptions.delivery"
						data-toggle="modal"
						data-target="#setordertype"
						@click="orderTypeClick('delivery', location)"
					>
						<i class="fas fa-truck"></i><br />Delivery
					</button>
					</div>
				</div>

				<div
					class="my-4"
					v-if="!isOpen(index) && !location.data.preorder"
				>
					<p style="margin-top: 40px"><strong>Opening Hours</strong></p>
					<table class="table">
					<tbody>
						<tr>
						<td>Monday</td>
						<td>
							{{
							location.data.openhours.monday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.monday.open
								? location.data.openhours.monday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.monday.open
								? location.data.openhours.monday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Tuesday</td>
						<td>
							{{
							location.data.openhours.tuesday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.tuesday.open
								? location.data.openhours.tuesday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.tuesday.open
								? location.data.openhours.tuesday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Wednesday</td>
						<td>
							{{
							location.data.openhours.wednesday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.wednesday.open
								? location.data.openhours.wednesday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.wednesday.open
								? location.data.openhours.wednesday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Thursday</td>
						<td>
							{{
							location.data.openhours.thursday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.thursday.open
								? location.data.openhours.thursday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.thursday.open
								? location.data.openhours.thursday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Friday</td>
						<td>
							{{
							location.data.openhours.friday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.friday.open
								? location.data.openhours.friday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.friday.open
								? location.data.openhours.friday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Saturday</td>
						<td>
							{{
							location.data.openhours.saturday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.saturday.open
								? location.data.openhours.saturday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.saturday.open
								? location.data.openhours.saturday.to
								: ""
							}}
						</td>
						</tr>

						<tr>
						<td>Sunday</td>
						<td>
							{{
							location.data.openhours.sunday.open
								? "Open"
								: "Closed"
							}}
						</td>
						<td>
							{{
							location.data.openhours.sunday.open
								? location.data.openhours.sunday.from
								: ""
							}}
						</td>
						<td>
							{{
							location.data.openhours.sunday.open
								? location.data.openhours.sunday.to
								: ""
							}}
						</td>
						</tr>
					</tbody>
					</table>
				</div>

				<div
					class="col-12 text-danger"
					align="center"
					v-if="!location.data.public"
				>
					<em>{{ inactiveLocationMsg }}</em>
				</div>
				</li>
			</ul>
			</div>
		</div>
		</div>

		<div class="col-lg-3"></div>

		<!-- Modal order type-->
		<div
		class="modal fade"
		id="setordertype"
		tabindex="-1"
		role="dialog"
		aria-hidden="true"
		>
		<div
			class="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered1"
			role="document"
		>
			<div class="modal-content">
			<div class="modal-body" style="padding: 0 15px">
				<div class="my-4">
				<div
					class="form-group col-12 my-3"
					v-show="ordertype === 'delivery'"
				>
					<button
					type="button"
					class="btn btn-dark btn-block my-4"
					@click="useLocationClick"
					>
					<span v-show="!mapbusy">Use my current location</span>
					<span v-show="mapbusy"
						><span
						class="spinner-border spinner-border-sm"
						role="status"
						aria-hidden="true"
						></span>
						Please wait ...</span
					>
					</button>

					<h5 align="center" class="mb-3"><strong>or</strong></h5>
					<div id="locationField">
					<input
						id="autocomplete"
						type="text"
						class="form-control"
						placeholder="Enter your address..."
						v-model="customer.address"
						@focus="geolocate()"
					/>
					</div>
					<div
					v-show="showmap"
					id="map"
					class="my-4"
					style="height: 230px"
					></div>
				</div>
				</div>
				<p align="center" class="bold text-danger">{{mapError}}</p>
			</div>
			<div class="modal-footer">
				<div align="right" class="col-6">
				<button
					id="btnCancel"
					type="button"
					class="btn btn-secondary btn-block"
					data-dismiss="modal"
					aria-label="Close"
					@click="cancelLocation()"
				>
					Cancel
				</button>
				</div>
				<div align="right" class="col-6">
				<button
					type="button"
					class="btn btn-dark btn-block"
					data-dismiss="modal1"
					aria-label="Close"
					:disabled="!orderTypeFormValid"
					@click="continueOrder"
				>
					Continue
				</button>
				</div>
			</div>
			</div>
		</div>
		</div>
	</div>
</template>
<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCa_wfx8AREGB152TyklSifwk07mshHVyY&libraries=places"></script>
<script>

import CruzBar from "@/components/CruzBar.vue";

let moment = require("moment");
var oWebViewInterface = window.nsWebViewInterface;

export default {
  name: "Order",
  props: {
    msg: String,
  },
  filters: {
    currency(value) {
      if (!value) return "";
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    displaytime(value) {
      if (!value) return "";
      return moment(value, "HH:mm").format("hh:mm a");
    },
  },
  components: {
    CruzBar,
  },
  computed: {
    storeInfo() {
      return this.$store.state.storeinfo;
    },
    storeId() {
      return this.$store.state.storeid;
    },
	appnotice() {
      return this.$store.state.appnotice;
    },
    coverPhoto() {
      return this.storeInfo.pagesettings &&
        this.storeInfo.pagesettings.coverphoto
        ? this.storeInfo.pagesettings.coverphoto
        : "";
    },
    locations() {
      let locations = this.$store.state.locations;
      if (this.txtsearch.length < 3) return locations;
      const search = this.txtsearch.toLowerCase();
      const filter = function (loc) {
        return (
          loc.data.address.street.toLowerCase().includes(search) ||
          loc.data.address.city.toLowerCase().includes(search) ||
          loc.data.address.state.toLowerCase().includes(search) ||
          loc.data.address.zip.toLowerCase().includes(search) ||
          loc.data.address.country.toLowerCase().includes(search)
        );
      };
      return locations.filter(filter);
    },
	isBusy(){
		return this.$store.state.isBusy
	},
	isMobile(){
		return this.$store.state.isMobile
	},
    ordertype() {
      return this.$store.state.ordertype;
    },
    orderTypeFormValid() {

      return true;
    },
    inactiveLocationMsg() {
      return this.$store.state.inactiveLocationMsg;
    },
    poweredbytext() {
      return this.$store.state.poweredbytext;
    },
  },
  data() {
    return {
      txtsearch: "",
      ordertime: {
        orderhour: "hh",
        orderminute: "mm",
        orderampm: "pm",
      },
      ordertypename: "",
      carnumber: "",
      timeerror: "",
      neworder: false,
      activelist: null,
      customer: { address: "", geo: "" },
      googleplaces: {},
      googleapikey: "AIzaSyCa_wfx8AREGB152TyklSifwk07mshHVyY",
      autocomplete: null,
      map: null,
      mapError:'',
      infoWindow: null,
      marker: null,
      geocoder: null,
      showmap: false,
      mapbusy: false,
      showlogo: true,
      viewmode: "",
      isbusy: true,
      isIOS: null,
    };
  },
  methods: {
      cancelLocation(){
      },
    openInfo(index) {
      let day = String(moment().format("dddd")).toLowerCase();
      let locations = this.locations[index].data.openhours[day];
      let opentime = moment(locations.from, "HH:mm");
      let closetime = moment(locations.to, "HH:mm");
      let timenow = moment();
      let html = "";

      if (locations.open && timenow.isBetween(opentime, closetime)) {
        let closetime = moment(locations.to, "HH:mm").format("hh:mm a");
        html = `<span class="badge badge-success" style="font-weight:400; font-size:11px;"><i class="far fa-clock"></i> Open until ${closetime} </span>`;
      } else {
        html = (this.locations[index].data.preorder) ? 
		`<span class="badge badge-danger" style="font-weight:400; font-size:11px;"><i class="far fa-clock"></i> Closed: PRE-ORDER</span>` : 
		`<span class="badge badge-danger" style="font-weight:400; font-size:11px;"><i class="far fa-clock"></i> Closed</span>`
      }

      return html;
    },
    isOpen(index) {
      let day = String(moment().format("dddd")).toLowerCase();
      let locations = this.locations[index].data.openhours[day];
      let opentime = moment(locations.from, "HH:mm");
      let closetime = moment(locations.to, "HH:mm");
      let timenow = moment();

      return locations.open && timenow.isBetween(opentime, closetime);
    },
    orderTypeClick(ordertype, loc) {
      this.$store.commit("setOrdertype", ordertype);
      this.$store.commit("setOrderlocation", loc);

      if (ordertype === "pickup") this.ordertypename = "Pickup";
      if (ordertype === "curbside") this.ordertypename = "Curbside";
      if (ordertype === "delivery") this.ordertypename = "Delivery";

      this.ordertime = { orderhour: "hh", orderminute: "mm", orderampm: "pm" };
    },
    continueOrder(ordertype, loc) {
        if (ordertype && loc) {
            this.$store.commit("setOrdertype", ordertype);
            this.$store.commit("setOrderlocation", loc);
        }

        if (
            this.$store.state.ordertype == "delivery" &&
            (!this.customer.address || this.customer.address.length < 2)
        ) {
            this.mapError = "Please enter a valid address for delivery."
            return false;
        }

        this.$store.commit("setAddress", this.customer.address);
        this.$store.commit("setGeo", this.customer.geo);
        document.getElementById('btnCancel').click()  
        let url = `/${this.storeId}/order/menu`;
        this.$router.push(url);
    },
    startOrder() {
      this.neworder = true;
    },
    geolocate() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
            const geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };

            const circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy,
            });

            this.autocomplete.setBounds(circle.getBounds());
            });
        }
    },
    useLocationClick() {
      if (this.$store.state.isMobile) {
        this.mapbusy = true;
        oWebViewInterface.emit("getLocation", null);
      } else if (navigator.geolocation && !this.$store.state.isMobile) {
        this.mapbusy = true;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            this.map.setCenter(pos);
            this.map.setZoom(17);

            this.marker = new google.maps.Marker({
              position: pos,
              map: this.map,
              draggable: true,
              animation: google.maps.Animation.DROP,
            });

            this.geocodeLatLng();

            this.showmap = true;
            this.mapbusy = false;

            google.maps.event.addListener(this.marker, "dragend", () => {
              this.geocodeLatLng();
            });
          },
          () => {
            this.mapError = "Sorry... Geolocation service failed."
            this.mapbusy = false;
          }
        );
      } else {
        this.mapError = "Sorry... We cannot get your location."
      }
    },
    geocodeLatLng() {
        this.map.setCenter(this.marker.position);

        this.geocoder.geocode(
            { location: this.marker.position },
            (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        this.infoWindow.setContent(results[0].formatted_address);
                        document.getElementById("autocomplete").value =
                            results[0].formatted_address;
                        this.customer.address = results[0].formatted_address;
                        this.customer.geo = `${this.marker
                            .getPosition()
                            .lat()}, ${this.marker.getPosition().lng()}`;
                        this.infoWindow.open(this.map, this.marker);
                        this.mapError = ""
                        console.log(this.customer.address, this.customer.geo);
                    } else {
                        this.mapError = "No results found"
                    }
                } else {
                    this.mapError = "Geocoder failed due to: " + status
                }
            }
        )
    },
  },
  mounted() {
        window.scrollTop = 0;
        let storeId = this.$route.params.store
		if(this.storeId !== storeId) {
			this.$store.commit("setIsBusy", null)
			this.$store.dispatch('fetchStoreProfile', storeId)
		}
        
		if(window.localStorage[storeId]) this.$router.push(`/${storeId}/order/menu`)
        if(!this.storeId){
            this.$store.dispatch('fetchStoreProfile', storeId)
        }
        const params = new URLSearchParams(window.location.search)
        this.viewmode = params.get('v') || null
        this.$store.state.isIOS = params.get('p') || null
        if(params.get('p')) window.localStorage.setItem('isIOS', String(params.get('p')))
        if(this.viewmode) {
            this.showlogo = false
        }

		//Initialize Google Map
        this.infoWindow = new google.maps.InfoWindow();
        this.geocoder = new google.maps.Geocoder();

        this.map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 10.7134787, lng: -61.478583199999996 },
            zoom: 17,
            disableDefaultUI: true,
        });

        this.marker = new google.maps.Marker({
            position: { lat: 10.7134787, lng: -61.478583199999996 },
            map: this.map,
            draggable: true,
            animation: google.maps.Animation.DROP,
        });

        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete")
        );

        this.autocomplete.setComponentRestrictions({
            country: ["tt"],
        });

        this.autocomplete.bindTo("bounds", this.map);

        this.autocomplete.setFields(["address_component", "geometry"]);

        this.autocomplete.addListener("place_changed", () => {
            this.infoWindow.close();
            this.marker.setVisible(false);
            const place = this.autocomplete.getPlace();

            if (!place.geometry) {
                this.mapError = "No details available for input: '" + place.name + "'"
                return;
            }

            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
                this.map.setZoom(17);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(17);
            }

            this.marker.setPosition(place.geometry.location);
            this.marker.setVisible(true);
            this.infoWindow.open(this.map, this.marker);
            google.maps.event.addListener(this.marker, "dragend", () => {
                this.geocodeLatLng();
            });

            this.showmap = true;
            this.infoWindow.setContent(document.getElementById("autocomplete").value);
            this.customer.address = document.getElementById("autocomplete").value;
            this.customer.geo = `${this.marker
                .getPosition()
                .lat()}, ${this.marker.getPosition().lng()}`;

            console.log(this.customer.address, this.customer.geo);

            
        });

        oWebViewInterface.on("setLocation", (loc) => {
            console.log("location", loc)
            const pos = {
                lat: loc.latitude,
                lng: loc.longitude,
            }

            this.map.setCenter(pos);
            this.map.setZoom(17);

            this.marker = new google.maps.Marker({
                position: pos,
                map: this.map,
                draggable: true,
                animation: google.maps.Animation.DROP,
            });

            this.geocodeLatLng();

            this.showmap = true;
            this.mapbusy = false;

            google.maps.event.addListener(this.marker, "dragend", () => {
                this.geocodeLatLng();
            });

            this.mapbusy = false;
        });
    },
};
</script>