<template>
    <div class="row" style="margin-bottom:5rem; padding-top:1rem;">

        <div class="col-12 cat-header" v-if="!$store.state.isMobile">
            <div class="row">
                <div class="col-1 " align="right">
                    <h4 class="pointer bold my-1">
                        <i class="fas fa-arrow-left" @click="$router.back()"></i>
                    </h4>
                </div>
                <div class="col-10 my-1 ">
                    <h4 class="bold">{{category()}}</h4>
                </div>
                
            </div>
        </div>

        <div class="col-12 my-5" v-if="!$store.state.isMobile"> 
            &nbsp;
        </div>

        <div class="col-12 col-md-6 col-lg-4 mb-3 pointer" v-for="(vendor, index) in vendors" :key="index">
            <div class="card card-alt" @click="vendorTileClick(vendor.id)">
                <div class="card-img-container">
                    <img :src="`https://orderuptoday.imgix.net/${vendor.data.pagesettings.coverphoto1}`" class="card-img-top">
                </div>
                <div class="card-body">
                    <img :src="`https://orderuptoday.imgix.net/${vendor.data.pagesettings.logo1}`" style="float:right; border-radius:10%; width:30%; height:auto; margin-top:-8rem;">
                    <p class="card-title"><small class="bold">{{vendor.data.name}}</small></p>
                </div>
            </div>
        </div>

        <div class="col-12" align="center" style="padding:3rem;" v-if="emptyCat===true">
            <p><em>No vendors found.<br/>Are you from Santa Cruz and do you sell products in this category? Send an email to <a href="mailto:support@orderuptoday.com">support@orderuptoday.com</a> to get listed.</em></p>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    Vue.use(require('vue-moment'))
    var oWebViewInterface = window.nsWebViewInterface
    const fb = require('../firebaseConfig.js')


    export default {
        name: 'CruzLifeCategory',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
        },
        computed:{
              
        },
        data(){
            return{
                vendors: [],
                emptyCat: false,
            }
        },
        methods:{
            async dismissModal(cat){
                document.getElementById('btnCloseMenu').click()
                this.$router.push(`/cruzlife/${cat}`)

                await fb.storesCollection.where("cruzlife", "==", cat).onSnapshot((querySnapshot) =>{
                
                    this.vendors.length=0

                    querySnapshot.forEach((doc)=>{
                        this.vendors.push({id:doc.id, data:doc.data()})
                    })

                    if(this.vendors.length<1) {
                        this.emptyCat = true
                    }else{
                        this.emptyCat = false
                    }

                })
            },

            category(){
                let cat = this.$route.params.category
                return cat.charAt(0).toUpperCase() + cat.slice(1)
            },
            async goHome(){
                document.getElementById('btnCloseMenu').click()
                location.href="/cruzlife"
            },

            vendorTileClick(vendor){
                let eventData = {url:`${vendor}/start-order`}
                if (this.$store.state.isMobile) {
                    oWebViewInterface.emit('vendorClick', eventData) 
                }else{
                    this.$router.push(`/${vendor}/start-order`)
                }
            }
        },
        async mounted(){
            await fb.storesCollection.where("cruzlife", "==", this.$route.params.category).onSnapshot((querySnapshot) =>{
                
                this.vendors.length=0

                querySnapshot.forEach((doc)=>{
                    this.vendors.push({id:doc.id, data:doc.data()})
                })

                if(this.vendors.length<1) {
                    this.emptyCat = true
                } 

            })

            window.scrollTo(0,0)
        }
    }
</script>

