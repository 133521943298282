 <template>
	<div id="navbar">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">

            <a href="/"><img src="../assets/orderup-logo.png" class="img-fluid mr-2" style="height:40px;"/></a>   

            <button id="liveordersmobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div align="right" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav w-100">
                    <li class="nav-item ml-auto">
                        <a class="nav-link" href="#!"></a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="btn dropdown-toggle btn-link" href="#!" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Admin
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <a :href="'/'+storeid+'/dashboard'" class="btn btn-link dropdown-item">Dashboard <!--i class="fas fa-cog"></i--></a>
                            
                            <a :href="'/'+storeid+'/settings'" class="btn btn-link dropdown-item">Settings <!--i class="fas fa-cog"></i--></a>

                            <a :href="'/'+storeid+'/locations'" class="btn btn-link dropdown-item">Locations</a> 
                            
                            <a :href="'/'+storeid+'/promotions'" class="btn btn-link dropdown-item">Promotions</a> 

                            <a :href="'/'+storeid+'/expenses'" class="btn btn-link dropdown-item">Expenses</a> 
                            
                            <div class="dropdown-divider"></div>

                            <a :href="'/'+storeid+'/menu-editor'" class="btn btn-link dropdown-item">Menu Editor <!--i class="fas fa-cog"></i--></a>

                            <a :href="'/'+storeid" target="_viewstore" class="btn btn-link dropdown-item">View Store <!--i class="fas fa-cog"></i--></a>
                            
                            
                        </div>
                    </li>

                    <!--li class="nav-item">
                        <a :href="'/'+storeid+'/dashboard'" style="" class="btn btn-link">Dashboard</a>
                    </li>

                    <li class="nav-item">
                        <a :href="'/'+storeid+'/menu-editor'" style="" class="btn btn-link">Menu Editor</a>
                    </li>

                    <li class="nav-item">
                        <a :href="'/'+storeid+'/settings'" style="" class="btn btn-link">Settings </a>
                    </li>

                    <li class="nav-item">
                        <a :href="'/'+storeid+'/promotions'" style="" class="btn btn-link">Promotions </a>
                    </li-->

                    <li class="nav-item">
                        <a href="https://socaislands.freshdesk.com/" target="support" style="" class="btn btn-link">Support </a>
                    </li>

                    <!--li class="nav-item">
                        <a :href="'/'+storeid+'/account-billing'" style="" class="btn btn-link">Billing</a>
                    </li-->

                    <li class="nav-item">
                        <a href="#!" style="" class="btn btn-link" @click="signOut">Logout</a>  
                    </li>

                    <li class="nav-item">
                        <a href="#!" style="" class="btn btn-link">Store ID: <strong>{{storeid}}</strong></a>
                    </li>
                    
                </ul>
            </div>
        </nav>
    </div>
 </template>
<script>
    import Vue from 'vue'
    import VueToast from 'vue-toast-notification'

    Vue.use(VueToast, {
        position: 'bottom-right',
        type:'success',
        duration:1500
    })


    export default {
    name: 'AdminBar',
    props: {

    },
    filters: {
        currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
        }
    },
    components:{

    },
    computed:{
        
    },
    data(){
        return{
            storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
            storelocations:[],
            storeid:'',
            orders:[],
            singleorder:{},
            cart:[],
            txtsearch:'',
            pendingcount:0,
            activesection:'',
            contact:{},
            ordermanagement:{receiveorders:{}, deliveroptions:{}, paymentoptions:{}},
        }
    },
    methods:{
            signOut(){
                this.$store.dispatch('logout')
            },
    },
    mounted(){
        //let $this = this
        const storeid = this.$route.params.store
        this.storeid = storeid

        /*fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
            if (doc.exists) {
                this.storeinfo = doc.data()
            } else {
                console.log("No such document!")
            }
        })

        fb.storesCollection.doc(`${storeid}/contact/${storeid}`).onSnapshot((doc) =>{
            if (doc.exists) {
                this.contact = doc.data()
            } else {
                console.log("No such document contact!")
            }
        })*/
    }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
