<template>
	<div class="" style="margin-bottom:100px;" v-show="pageload==true">
		<button @click="topFunction" id="myBtn" class="btn btn-primary btn-lg floating-button" title="Go to top"><i class="fas fa-chevron-up"></i></button>
		<AdminBar />
		

		<div class="col-12 mb-5" style="padding-top:5rem;">

			<div class="row">
				<h2 class="col-lg-6 mb-4"><strong class="">{{storeInfo.name}}</strong></h2>

				<!--div class="col-lg-6">
					<a :href="'/'+storeid" target="storepage" style="float:right;" class="btn btn-primary mr-3">View Store <i class="fas fa-desktop"></i></a>

					<a :href="'/'+storeid+'/menu-editor'" target="liveorders" style="float:right;" class="btn btn-primary mr-3">Menu Editor <i class="fas fa-hamburger"></i></a>
				</div-->
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="!currentUser.emailVerified">
				Please verify your email. <a href="#!" @click="verifyEmail">Click here to resend verification link?</a> 
			</div>

			<!--div class="alert alert-primary my-3" role="alert" v-if="storeInfo.pagesettings && !storeInfo.pagesettings.logo">
				Please upload your company logo and cover photo. <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="locations.length<1">
				Please create at least one location for your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div>

			<div class="alert alert-primary my-3" role="alert" v-if="menuItems.length<1">
				Please add your menu to your store.  <a href="#!">Click here to learn how <i class="fas fa-play-circle"></i></a>.
			</div-->
			
		</div>

		<nav>
			<div class="nav nav-tabs" id="nav-tab" role="tablist">
				<a class="nav-item nav-link active" id="nav-expenses-home-tab" data-toggle="tab" href="#nav-expenses-home" role="tab" aria-controls="nav-expenses-home" aria-selected="true"><i class="fas fa-chart-pie"></i> Dashboard</a>

				<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-expenses" role="tab" aria-controls="nav-profile" aria-selected="false"><i class="fas fa-chart-line"></i> Expenses</a>

				<a class="nav-item nav-link" id="nav-vendors-tab" data-toggle="tab" href="#nav-vendors" role="tab" aria-controls="nav-vendors" aria-selected="false"><i class="fas fa-people-carry"></i> Vendors</a>

				<a class="nav-item nav-link" id="nav-menu-tab" data-toggle="tab" href="#nav-expense-categories" role="tab" aria-controls="nav-camcel" aria-selected="false"><i class="fas fa-file-alt"></i> Expense Categories</a>

				<!--a class="nav-item nav-link" id="nav-customers-tab" data-toggle="tab" href="#nav-customers" role="tab" aria-controls="nav-profile" aria-selected="false"><i class="fas fa-users"></i> Customers</a>

				<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-menueditor" role="tab" aria-controls="nav-contact" aria-selected="false"><i class="far fa-chart-bar"></i> Menu Reports</a>

				<a class="nav-item nav-link" id="nav-cancel-tab" data-toggle="tab" href="#nav-locations" role="tab" aria-controls="nav-camcel" aria-selected="false"><i class="fas fa-map-marked"></i> Locations</a-->

				
			</div>
		</nav>
		<div class="tab-content dashboard-tabs" id="nav-tabContent">
			<div class="tab-pane fade show active" id="nav-expenses-home" role="tabpanel" aria-labelledby="nav-expenses-home-tab">.

				<div class="row">

					<!-- right column -->
					<div class="col-lg-6 col-md-push-6">
						<div class="card mb-3">
							<div class="card-body ">
								<line-chart :chart-data="datacollection"></line-chart>
							</div>
						</div>
						<!--div class="card mb-3 bg-primary">
							<div class="card-body">
								<div class="" align="center">
									<h1 class="dashboard-count">100</h1>
									<p class="dashboard-title">Pending Orders</p>
								</div>
							</div>
						</div>
						<div class="card mb-3 bg-primary">
							<div class="card-body" align="center">
								<div class="" align="center">
									<h1 class="dashboard-count">100</h1>
									<p class="dashboard-title">Orders Ready for Pickup</p>
								</div>
							</div>
						</div-->
					</div>
					<!-- /right column -->


					<!-- left column -->
					<div class="col-lg-6 col-md-pull-6">
						<div class="card mb-3 bg-primary1 text-white1">
							<div class="row mb-1">
								<div class="card-body col-12" align="center">
									<div class="card-body">
										<h6>Today's Expenses</h6>
										<h3>${{ todayExpenses[0] | currency}}</h3>
									</div>
									<!--p class="small">Yesterday: ${{ yesterdayExpenses[0] | currency}}</p-->
								</div>
								<!--div class="card-body col-lg-6" align="center">
									<div class="card-body">
										<h6>Today's Expenses</h6>
										<h3>{{todayExpenses[1]}}</h3>
									</div>
									<p class="small">Yesterday: {{ yesterdayExpenses[1] }}</p>
								</div-->
							</div>
						</div>

						<div class="card mb-3">
							<div class="row mb-1">
								<div class="card-body col-lg-6" align="center">
									<div class="card-body">
										<h6>Weekly Expenses</h6>
										<h3>${{ weekExpenses[0] | currency}}</h3>
									</div>
									<p class="small">Last Week: ${{ lastweekExpenses[0] | currency}}</p>
								</div>
								<div class="card-body col-lg-6" align="center">
									<div class="card-body">
										<h6>Monthly Expenses</h6>
										<h3>${{ monthExpenses[0] | currency}}</h3>
									</div>
									<p class="small">Last Month: ${{ lastmonthExpenses[0] | currency}}</p>
								</div>
							</div>
						</div>

						<!--div class="card mb-3">
							<div class="row mb-1">
								<div class="card-body col-lg-6" align="center">
									<div class="card-body">
										<h6>Weekly Orders</h6>
										<h3>{{ weekExpenses[1] }}</h3>
									</div>
									<p class="small">Last Week: {{ lastweekExpenses[1] }}</p>
								</div>
								<div class="card-body col-lg-6" align="center">
									<div class="card-body">
										<h6>Monthly Orders</h6>
										<h3>{{monthExpenses[1]}}</h3>
									</div>
									<p class="small">Last Month: {{ lastmonthExpenses[1]}}</p>
								</div>
							</div>
						</div-->
						
					</div>
					<!-- /left column -->

				</div>

			</div>
			<div class="tab-pane fade" id="nav-expenses" role="tabpanel" aria-labelledby="nav-expenses-tab">.
				<ExpensesDashboard />
			</div>
			<div class="tab-pane fade" id="nav-vendors" role="tabpanel" aria-labelledby="nav-vendors-tab">.
				<VendorsDashboard />
			</div>
			<div class="tab-pane fade" id="nav-expense-categories" role="tabpanel" aria-labelledby="nav-expenses-category-tab">.
				<ExpensesCategories />
			</div>
			<!--div class="tab-pane fade" id="nav-menueditor" role="tabpanel" aria-labelledby="nav-menueditor-tab">.
				<MenuDashboard />
			</div>
			<div class="tab-pane fade" id="nav-locations" role="tabpanel" aria-labelledby="nav-locations-tab">.
				<SitesDashboard />
			</div>
			<div class="tab-pane fade" id="nav-expense-categories" role="tabpanel" aria-labelledby="nav-expense-categories-tab">.
				<BillPayDashboard />
			</div-->
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'

import LineChart from './LineChart.js'

import ExpensesCategories from '@/components/ExpensesCategories.vue'
import VendorsDashboard from '@/components/VendorsDashboard.vue'
import ExpensesDashboard from '@/components/ExpensesDashboard.vue'
/*import SitesDashboard from '@/components/SitesDashboard.vue'
import MenuDashboard from '@/components/MenuDashboard.vue'
import CustomerDashboard from '@/components/CustomerDashboard.vue'
import BillPayDashboard from '@/components/BillPayDashboard.vue'*/
import AdminBar from '@/components/AdminBar.vue'

const fb = require('../firebaseConfig.js')
var moment = require('moment')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'Expenses',
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("YYYY-MM-DD hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components: {
      AdminBar, LineChart, ExpensesCategories, VendorsDashboard, ExpensesDashboard,
  },
  computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		todayExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(0, 'days').startOf('Day').unix()
			let cursorEnd = moment().subtract(0, 'days').endOf('Day').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return [sales, count]
		},
		yesterdayExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(1, 'days').startOf('Day').unix()
			let cursorEnd = moment().subtract(1, 'days').endOf('Day').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		lastweekExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(1, 'weeks').startOf('Week').unix()
			let cursorEnd = moment().subtract(1, 'weeks').endOf('Week').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return [sales, count]
		},
		weekExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(0, 'weeks').startOf('Week').unix()
			let cursorEnd = moment().subtract(0, 'weeks').endOf('Week').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		lastmonthExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(1, 'month').startOf('Month').unix()
			let cursorEnd = moment().subtract(1, 'month').endOf('Month').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return [sales, count]
		},
		monthExpenses(){
			let sales = 0
			let count = 0
			let cursorStart = moment().subtract(0, 'month').startOf('Month').unix()
			let cursorEnd = moment().subtract(0, 'month').endOf('Month').unix()
			const orders = this.orders
			const filter = (order)=>{
								if (order.id>=cursorStart && order.id< cursorEnd) {
									sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									count++
								}
								return(order.id>=cursorStart && order.id< cursorEnd)
							}
			orders.filter(filter)
			return  [sales, count]
		},
		locations(){
			return this.$store.state.locations
		},
		menuItems(){
			return this.$store.state.menuitems
		},


  },
  data(){
		return{
			storeInfo:[],
			storeId:'',
			pendingorders:0,
			readyorders:0,
			datacollection: {},
			orders:[],
			expenses:[],
			payments:[],
			validkey:null,
			pageload:null,
		}
  },
  methods:{
		topFunction(){
			document.body.scrollTop = 0
			document.documentElement.scrollTop = 0
		},
		verifyEmail(){
			fb.auth.currentUser.sendEmailVerification().then(()=>{
				Vue.$toast.open('Verification email sent!')
			})
		},
  },
  async mounted(){

		//console.log(this.currentUser.uid)

		const storeId = this.$route.params.store
		this.storeId = storeId

		await fb.storesCollection.doc(storeId).onSnapshot((doc) =>{
			if (doc.exists) {
				if(this.currentUser.uid != doc.data().user) {
					this.$store.dispatch('logout')
					return false
				}
				this.$store.dispatch('fetchStoreProfile', storeId)
				this.storeInfo = doc.data()
				this.pageload = true
			} else {
				console.log("No such document!")
				this.$router.push('/login')
			}
		})

		let cursorStart = moment().subtract(1, 'month').startOf('Month').unix()

		let expenseData = []

		await fb.expensesCollection.where("storeid", "==", storeId).where("paymentDate", ">=", cursorStart).orderBy("paymentDate", "desc").onSnapshot(querySnapshot=>{
			this.expenses = []
			querySnapshot.forEach((doc)=>{
				//console.log("id", doc.id)
				this.expenses.push(doc.data())
			})
			if(this.expenses.length==0) {
				this.expenses = []
			}

			//render graph

			let duration = 7

			console.log("size", this.expenses.length)

			let labels = []
			//let dataset = []
			
			for(var d=duration; d>-1; d--){

				labels.push(moment().subtract(d, 'd').startOf('day').format('MMM DD'))
				
				let spend = 0
				let cursorStart = moment().subtract(d, 'd').startOf('day').unix()
				let cursorEnd = moment().subtract(d, 'd').endOf('day').unix()

				const filter = (expense)=>{
									if (expense.paymentDate>=cursorStart && expense.paymentDate< cursorEnd) {
										spend += expense.total
										console.log(spend)
									}
									return(expense.paymentDate>=cursorStart && expense.paymentDate<cursorEnd)
								}

				this.expenses.filter(filter)
				//dataset.push(spend)
				expenseData.push(spend)
			}

			/*console.log(this.expenses)

			this.datacollection = {
				labels: labels,
				datasets: [
					{
						//label: `Last ${duration} days`,
						label:'Expenses',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						borderColor: '#dc3545',
						pointBackgroundColor: '#dc3545',
						data: expenseData,
						lineTension:0,
					}
					
				],
			}*/
		})




		await fb.ordersCollection.where("storeid", "==", storeId).where("status", "in", ["pending", "ready", "complete"]).where("id", ">=", cursorStart).onSnapshot(querySnapshot=>{
			this.orders = []
			querySnapshot.forEach((doc)=>{
				this.orders.push(doc.data())
			})
			if(this.orders.length==0) this.orders = []
		

			//render graph

			let duration = 7

			let labels = []
			let dataset = []
			for(var d=duration; d>-1; d--){
				labels.push(moment().subtract(d, 'd').startOf('day').format('MMM DD'))
				
				let sales = 0
				let cursorStart = moment().subtract(d, 'd').startOf('day').unix()
				let cursorEnd = moment().subtract(d, 'd').endOf('day').unix()

				const filter = (order)=>{
									if (order.id>=cursorStart && order.id< cursorEnd) {
										sales += (order.ordertype=='pointofsale') ? Number(order.total - order.fees.delivery) : Number(order.total)
									}
									return(order.id>=cursorStart && order.id< cursorEnd)
								}

				this.orders.filter(filter)
				dataset.push(sales)
			}

			this.datacollection = {
				labels: labels,
				datasets: [
					{
						//label: `Last ${duration} days`,
						label:'Expenses',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						borderColor: '#dc3545',
						pointBackgroundColor: '#dc3545',
						data: expenseData,
						lineTension:0,
					},
					{
						//label: `Last ${duration} days`,
						label:'Income',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						borderColor: '#007bff',
						pointBackgroundColor: '#007bff',
						data: dataset,
						lineTension:0,
					}
				],
			}

		})




	}
}
</script>

<style>
	.dashboard-count {font-size:80px; color:#fff;}
	.dashboard-title {color:#fff;}

	.dashboard-tabs1 .tab-pane{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}

	.floating-button{
		display: ;
		position: fixed;
		bottom: 20px;
		right: 30px;
		z-index: 80;
		font-size: 18px;
		padding: 15px;
		border-radius: 100%;
		width:60px;
		height:60px;
	}
</style>