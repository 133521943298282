<template>
    <div>
        <div class="col-12 app-header">
            <div class="row">
                <div class="col">
                    <h5 class="bold my-1" align="left" @click="$router.push('/cruzlife/shop')">{{title}} </h5>
                </div>
                <div class="col-auto h5 my-1" align="right">
                    <i class="fas fa-plus pointer" v-if="showAddPostButton" @click="$emit('btnAddPostClick')"></i>
                    <i class="fas fa-search pointer" v-if="showSearchButton" @click="$emit('showSearchBar')"></i>
                    <i class="fas fa-plus pointer" v-if="showAddButton" @click="$emit('btnShowFormClick')"></i>
                </div>
                <div class="col-1 h5 my-1" align="right">
                    <i class="fas fa-bars pointer" data-toggle="modal" data-target="#appMenu"></i>
                </div>
            </div>
        </div>

        <!-- Modal CruzLife menu-->
        <div class="modal fade" id="appMenu" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header" style="border-bottom:0;">
                        <button id="btnCloseMenu" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" class="h3">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="padding-bottom:0;">
                        <ul class="list-group list-group-flush mb-5">
                            <li @click="$router.push('/cruzlife/shop')" class="list-group-item pointer" data-dismiss="modal">
                                <h5><i class="fas fa-shopping-cart mr-2"></i> Buy Online</h5>
                            </li>
                            <li @click="$router.push('/cruzlife/directory')" class="list-group-item pointer" data-dismiss="modal">
                                <h5><i class="fas fa-list mr-2"></i> Business Directory</h5>
                            </li>
                            
                            <li @click="$router.push('/cruzlife/info')" class="list-group-item pointer" data-dismiss="modal">
                                <h5><i class="fas fa-info-circle mr-2"></i> Santa Cruz Info</h5>
                            </li>
                            <!-- <li @click="$router.push('/cruzlife/feed')" class="list-group-item pointer" data-dismiss="modal" >
                                <h5><i class="fas fa-stream mr-2"></i> Feed</h5>
                            </li>
                            <li @click="$router.push('/cruzlife/profile')" class="list-group-item pointer" data-dismiss="modal">
                                <h5><i class="fas fa-user mr-2"></i> My Profile</h5>
                            </li> -->
                        </ul>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppHeader',
        props: ["showSearchButton", "showAddButton", "title", "showAddPostButton"],
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            
        },
        computed:{
              
        },
        data(){
            return{
                
            }
        },
        methods:{

        },
        mounted(){
            
        }
    }
</script>

