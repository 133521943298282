<template>
    <div class="row">
      
        <div class="col-12 my-1" align="center">
            <div class="btn-group card-body col-lg-6" role="group" >
                <button type="button" class="btn" :class="(activeTab=='menuitems') ? 'btn-primary' : 'btn-outline-secondary' " @click="activeTab = 'menuitems'">Menu Items</button>

                <button type="button" class="btn" :class="(activeTab=='modifiers') ? 'btn-primary' : 'btn-outline-secondary' " @click="activeTab = 'modifiers'">Variations</button>

            </div>
            <div class="btn-group">
                <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#menufilterform">Apply Filter</button>
                <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                </button>
                <div class="dropdown-menu">
                    <a class="dropdown-item" href="#"><i class="fas fa-file-csv"></i> Export .csv</a>
                </div>
            </div>

            <button type="button" class="btn btn-outline-primary ml-3"  href="#!"><i class="fas fa-print"></i> </button>
        </div>
        
        <div class="col-12 my-1">
            <div align="center" class="my-5" v-if="orders.length<1 && !isbusy">
                <em>Search query returned zero results...</em>
            </div>

            <div class="my-5 d-flex justify-content-center" v-if="isbusy">
                <div align="center" class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            
            <div class="my-3"  v-show="orders.length>0 && activeTab=='menuitems' && !isbusy">
                <ul class="list-group my-3" v-for="(category, index) in categories" :key="index">
                    <li class="list-group-item">
                       <h3>{{category.data.name}}</h3>

                       <div class="" v-for="(item, index) in items" :key="index">
                          <div class="row my-2 bg-light" style="border-bottom:0px dotted #eee; padding:10px 0;" v-if="item.data.section==category.id">
                              <div class="col-7 col-lg-3">{{item.data.name}}</div>
                              <div class="col-5 col-lg-9">{{getItemSold(item.data.name)}}</div>
                          </div>
                       </div>

                    </li>
                </ul>
            </div>

            <div class="my-4"  v-show="orders.length>0 && activeTab=='modifiers' && !isbusy">
                <ul class="list-group my-3" v-for="(modifier, index) in modifiers" :key="index">
                    <li class="list-group-item">
                       <h3>{{modifier.data.name}}</h3>

                       <div class="" v-for="(item, index) in itemModifiers" :key="index">
                          <div class="row my-2 bg-light" style="border-bottom:0px dotted #eee; padding:10px 0;" v-if="item.data.section==modifier.id">
                              <div class="col-7 col-lg-3">{{item.data.name}}</div>
                              <div class="col-5 col-lg-9">{{getModifierSold(item.id)}}</div>
                          </div>
                       </div>
                       
                    </li>
                </ul>
            </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="menufilterform" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Filter Sales</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.date">
                              <option value="0">Today</option>
                              <option value="7">Last 7 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="365">Last 365 days</option>
                              <option value="-1">Custom</option>
                              <!--option value="this-month">This month</option>
                              <option value="last-month">Last month</option>
                              <option value="this-year">This year</option>
                              <option value="last-year">Last year</option-->
                          </select>
                      </div>
                      <div class="row col-12 my-3" v-show="filter.date=='-1'">
                          <date-range-picker ref="picker" v-model="daterange" :autoApply="true" :ranges="false" :maxDate="maxDate" @update="datePickerUpdate" class="col-12">
                              <!--template v-slot:input="picker" style="min-width: 350px;">
                                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                              </template-->
                          </date-range-picker>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.locations">
                              <option value="0">ALL Locations</option>
                              <option v-for="(location, index) in storelocations" :key="index" :value="location.id">{{location.data.name}}</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.ordertype">
                              <option value="0">ALL Order Types</option>
                              <option value="curbside">Curbside</option>
                              <option value="delivery">Delivery</option>
                              <option value="pickup">Pickup</option>
                              <option value="pointofsale">Point of Sale</option>
                          </select>
                      </div>
                      <!--div class="col-12 my-3">
                          <select class="form-control" v-model="filter.paymenttype">
                              <option value="0">ALL Payment Types</option>
                              <option value="creditcard">Credit Card</option>
                              <option value="cashondelivery">Pay on Delivery</option>
                              <option value="cashonpickup">Pay on Pickup</option>                   
                          </select>
                      </div-->
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="applyFilters">Apply</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="vieworder" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Order - {{activeorder}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <ViewCart :singleorder="singleorder" :orderLoc="orderloc" :orderid="activeorder" :cart="cart"/>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



    </div>
</template>

<script>
import ViewCart from '@/components/ViewCart.vue'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'SalesDashboard',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, HH:mm")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart, DateRangePicker,
  },
  computed:{
      salesList(){
          let sales = this.orders
          if(!this.txtsearch) return this.orders
          const search = this.txtsearch.toLowerCase()
          const filter = function(sale){
                              return (sale.data.itemname.toLowerCase().includes(search))
                          }
          return sales.filter(filter)
      },
      categories(){
          return this.$store.state.menusections
      },
      items(){
          return this.$store.state.menuitems
      },
      modifiers(){
          return this.$store.state.modifiers
      },
      itemModifiers(){
          return this.$store.state.modifieritems
      },
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          activeorder:'',
          filter:{date:'0', locations:'0', ordertype:'0', paymenttype:'0',},
          orderloc:{},
          daterange:{startDate: moment(), endDate: moment()},
          maxDate: moment().format('YYYY-MM-DD'),
          activeTab: 'menuitems',
          isbusy:false,
      }
  },
  methods:{
      datePickerUpdate(){
          //console.log("date", this.daterange)
      },
      applyFilters(){
          this.isbusy = true

          let filter = this.filter

          let ordersref = fb.cartitemsCollection.where("storeid", "==", this.storeid)

          if(filter.date != '-1') {
              let filterdate = moment().subtract(Number(filter.date),'d').startOf('day').unix()
              //console.log('date', filterdate)
              ordersref = ordersref.where("orderid", ">=", filterdate)
          }else{
              let startfilterdate = moment(this.daterange.startDate).startOf('day').unix()
              let endfilterdate = moment(this.daterange.endDate).endOf('day').unix()
              console.log('date',startfilterdate,endfilterdate)
              ordersref = ordersref.where("orderid", ">=", startfilterdate).where("orderid", "<=", endfilterdate)
          }

          if(filter.locations != '0') ordersref = ordersref.where("location.id", "==", filter.locations)

          if(filter.ordertype != '0') ordersref = ordersref.where("ordertype", "==", filter.ordertype)

          //if(filter.paymenttype != '0') ordersref = ordersref.where("paymethod", "==", filter.paymenttype)

          ordersref.onSnapshot((querySnapshot)=>{
              let j=0
              this.orders.length=0
              querySnapshot.forEach((doc)=>{
                  //console.log(doc.id)
                  this.orders.push({index:j, id:doc.id, data:doc.data()})
                  j++
              })
              if(this.orders.length<1) this.orders = []

              this.isbusy = false
          })


      },
      viewOrderDetails(id, order){
          this.activeorder = id
          this.singleorder = order
          this.orderloc = order.pickuplocation.data
          this.cart = order.details
      },
      getItemSold(itemId){
          let count = 0
          const sales = this.salesList
          const filter = (sale)=>{
                      if(sale.data.itemname==itemId) count++
                      return (sale.id)
                  }
          sales.filter(filter)
          return count
      },
      getModifierSold(itemId){
          let count = 0
          const sales = this.salesList
          const filter = (sale)=>{
                      if(sale.data.itemid==itemId) count++
                      return (sale.id)
                  }
          sales.filter(filter)
          return count
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      fb.locationsCollection.where("storeid", "==", storeid).onSnapshot((querySnapshot)=>{
          let i=0
          this.storelocations.length=0
          querySnapshot.forEach((doc)=>{
              this.storelocations.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })

      let filterdate = moment().subtract(0,'d').startOf('day').unix()

      fb.cartitemsCollection.where("storeid", "==", storeid).where("orderid", ">=", filterdate).onSnapshot((querySnapshot)=>{
          let j=0
          this.orders.length=0
          querySnapshot.forEach((doc)=>{
              this.orders.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
          if(this.orders.length<1) this.orders = []
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    

</style>
