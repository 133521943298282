<template>
	<div class="container home-page">
		<div id="navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-white">
			<a class="navbar-brand" href="/"><img src="../assets/orderup-logo.png" class="img-fluid" style="height:60px;"/></a>
			<button id="mobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div align="right" class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav w-100">
					<!--li class="nav-item ml-auto">
						<a class="nav-link" href="/#pickupdelivery">Pick up & Delivery</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#tableordering" >Table Ordering</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#roomservice">Room Service</a>
					<li>
					<li class="nav-item">
						<a class="nav-link" href="/#pricing">Pricing</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/#faq">FAQ</a>
					</li-->
					<li class="nav-item ml-auto my-1">
						<a href="/login" class="btn btn-outline-dark btn-sm" type="button">Sign in</a>
					</li>
					<li class="nav-item my-1 ml-3">
						<a href="/signup" class="btn btn-dark btn-sm" type="button">Sign up</a>
					</li>
				</ul>
			</div>
		</nav>
		</div>

		<div id="home" class="row my-5" style="padding-top:50px;">

			<div class="col-lg-4 my-3">
				<div class="card card-body">
					<h4><strong>Getting Started</strong></h4>
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div id="footer" class="row" style="margin-bottom:0; border-top:1px solid #f1f1f1; padding:1rem 0 0 0;">
			<div class="col-12">
				<p align="left"><a class="mr-2" href="https://wa.me/18683522658"><i class="fab fa-whatsapp mr-1"></i>+1 (868) 352-2658</a>  <a class="mr-2" href="mailto:support@orderuptoday.com"><i class="fas1 fa-envelope1 mr-1"></i>support@orderuptoday.com</a> <a href="https://socaislands.freshdesk.com/" class="mr-2" target="_support">Support.</a> <a href="/terms" class="mr-2" target="_terms">Terms.</a> <a href="/privacy" class="mr-2" target="_terms">Privacy.</a> 
				<br><small>The Order Up app is a service provided by Soca Islands LLC and Aeon Distributors Ltd. &copy;{{copyyear}}.</small></p>
			</div>
		</div>
		<!-- /Footer -->
		
	</div>
</template>

<script>

// @ is an alias to /src
var moment = require('moment')

	
	export default {
		name: 'Home',
		components: {

		},
		data(){
			return{
				faqnum:0,
			}
		},
		computed:{
			copyyear(){
				return moment().format('YYYY')
			},
		},
		methods:{

		},
		mounted(){
			/*var prevScrollpos = window.pageYOffset;
			if(screen.width>990){
				window.onscroll = function() {

					var currentScrollPos = window.pageYOffset
					if (prevScrollpos > currentScrollPos) {
						document.getElementById("navbar").style.top = "0"
						//console.log("y", prevScrollpos)
					} else {
						document.getElementById("navbar").style.top = "-90px"
					}
					prevScrollpos = currentScrollPos
				}
			}*/

			//console.log(screen.width)
		}
	}
</script>
<style>
@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);

#navbar{
  background-color: #fff; /* Black background color */
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  left:0;
  width: 100%; /* Full width */
  padding:0 6.5rem;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index:100;
  border-bottom: 1px solid #f1f1f1;

}

.nav-link{
  font-size:18px;
  font-weight:500;
}

.home-page a:not(.btn){
	color:#343a40;
}

@media (max-width: 990px){
	.nav-link{
		font-size:18px;

	}
}

@media (max-width: 900px){
	#navbar{
		background-color: #fff; /* Black background color */
		position: fixed; /* Make it stick/fixed */
		top: 0; /* Stay on top */
		left:0;
		width: 100%; /* Full width */
		padding:0 1rem;
		transition: top 0.3s; /* Transition effect when sliding down (and up) */
		z-index:100;
	}
}
</style>
