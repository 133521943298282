<template>
    <div class="row" style="padding:1rem 0; margin:0;">   
        <div class="col-lg-4">

        </div>
        <div class="col-lg-4" v-show="showpasswordform">
            <div class="" align="center">
                <img src="../assets/orderup-logo.png" class="img-fluid my-42" style="height: 40px; width:auto;" />
            </div>

            <ul class="list-group mb-3" style="padding:30px 0;">
                <li class="list-group-item">
                    <p align="center" class=" my-3"><strong>Enter the new password for the account.</strong></p>
                    
                    <input type="email" class="form-control my-3" placeholder="Enter your email address" v-model="emailaddress" readonly />
                    
                    <input type="password" class="form-control " placeholder="Enter your new password" v-model="password"/>
                    <span class="small">Password needs to be at least 8 characters.</span>

                    <input type="password" class="form-control my-3" placeholder="Confirm your new password" v-model="password1" />

                    
                    <button type="button" class="btn btn-primary btn-lg btn-block my-4" :disabled="!resetValid || isbusy" @click="btnResetClick">
                        <span v-show="!isbusy">Continue</span>
                        <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                    </button>
                    
                </li>
            </ul>

        </div>
        <div class="col-lg-4">
        </div>
    </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
const fb = require('../firebaseConfig.js')

export default {
  name: 'Auth',
  props: {

  },
  filters: {
      
  },
  components:{
      
  },
  computed:{
      currentUser(){
          return this.$store.state.currentUser
      },
      resetValid(){
          if(!this.password) return false
          if(!this.password1) return false
          if(this.password != this.password1) return false
          return true
      }

  },
  data(){
      return{
          isbusy: false,
          emailaddress:'',
          password:'',
          password1:'',
          showpasswordform: false,
          actioncode:'',
      }
  },
  methods:{
     btnResetClick(){
        this.isbusy = true
        fb.auth.confirmPasswordReset(this.actioncode, this.password).then(()=>{
            fb.auth.signInWithEmailAndPassword(this.emailaddress, this.password).then(user=>{
                 Vue.$toast.open('Account password updated!')
                let userdata = user.user
                this.$store.commit('setCurrentUser', userdata)
                fb.storesCollection.where("user", "==", userdata.uid).get().then(doc=>{
                    let storeid = doc.docs[0].id
                    //this.$router.push(`/${storeid}/dashboard`)
                    location.href = `/${storeid}/dashboard`
                    this.isbusy = false
                }).catch((error)=>{
                    console.log(error)
                    this.isbusy = false
                })

            }).catch((error)=> {
                console.log(error)
                this.isbusy = false
            })
            this.isbusy = false
        }).catch((error)=> {
            console.log(error)
            //alert("Reset password failed! Please try again.")
            location.href = (this.currentUser) ? "/login" : "/login"
            this.isbusy = false
        });
     },
  },
  mounted(){
      const params = new URLSearchParams(window.location.search)
      let mode = params.get('mode') || null
      this.actioncode = params.get('oobCode') || null
      let apikey = params.get('oobCode') || null

      switch (mode) {
          case 'resetPassword':
            fb.auth.verifyPasswordResetCode(this.actioncode).then((email)=> {
                this.emailaddress = email
                this.showpasswordform = true
            }).catch(()=>{
                alert("Reset password link is invalid! Please try again.")
                location.href = (this.currentUser) ? "/login" : "/login"
            })

            break
          case 'recoverEmail':
            
            break
          case 'verifyEmail':
            fb.auth.applyActionCode(this.actioncode).then(()=> {
                alert("Email verified... thank you.")
                location.href = (this.currentUser) ? "/login" : "/login"
            }).catch(()=> {
                alert("Email verification failed! Please login and try again.")
                location.href = (this.currentUser) ? "/login" : "/login"
            })
            break
          default:
            location.href="/"
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
