<template>
    <div class="row">
        <div class="col-12">
            <input type="text" class="form-control my-3"  @input="txtsearch=$event.target.value"  placeholder="Search ready orders..."  v-if="orders.length>10" />
        </div>

        <div class="col-12" align="center" v-if="orders.length<1">
            <em>Orders ready for pickup will appear here ...</em>
        </div>
        <div class="col-sm-6 my-2"  v-for="(order, index) in ordersList" :key="index" >
            <div class="card pointer" data-toggle="modal" data-target="#readyorderscart" @click="viewOrder(order)" :style="(order.data.status==='processing') ? 'border:1px #28a745 solid !important;' : ''">
                <div class="card-body" align="center">
                    
                    <span v-if="order.data.ordertype==='pickup'" class="badge badge-warning mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <span v-if="order.data.ordertype==='curbside'" class="badge badge-info mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <span v-if="order.data.ordertype==='delivery'" class="badge badge-primary mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <span v-if="order.data.paymethod==='creditcard'" class="badge badge-success mb-4 ml-2">PAID</span>

                    <h5 class="clip-text"><strong>{{order.data.customer.fullname}}</strong></h5>
                    <p class="small"><i class="fas fa-phone"></i> {{order.data.customer.mobile}}</p>
                    
                    <strong>Order #{{order.data.id}}</strong>
                    <p class="small">{{order.data.id | convertdate }}</p>
                  

                </div>
            </div>
        </div>



        <!-- Modal view order-->
        <div class="modal fade" id="readyorderscart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                
                <button id="btnDeliverModalClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 25px;">


                  <ViewCart :singleorder="singleorder" :orderLoc="orderLoc" :orderid="orderid" :cart="cart"/>


                  <div align="center" class="col-12 my-4" >
                      <button type="button" class="btn btn-link btn-lg  mb-3"  data-dismiss="modal1" aria-label="Close1" @click="btnCancelClick(orderid)">Cancel this Order <i class="fas fa-times"></i></button>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-primary btn-lg btn-block" @click="btnDeliverClick(orderid)"> Delivered to Customer <i class="fas fa-chevron-right"></i></button>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>




    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import ViewCart from '@/components/ViewCart.vue'

const fb = require('../firebaseConfig.js')

var moment = require('moment')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})

export default {
  name: 'ReadyOrders',
  props: ['location'],
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD - hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart,
  },
  computed:{
      ordersList(){
          let orders = this.orders
          if(!this.txtsearch) return this.orders
          const search = this.txtsearch.toLowerCase()
          let filter = function(order){
                          return(order.data.customer.fullname.toLowerCase().includes(search) || order.data.customer.emailaddress.toLowerCase().includes(search) || order.data.customer.mobile.toLowerCase().includes(search) || order.id.includes(search))
                      }
          return orders.filter(filter)
      },
      storeInfo(){
          //console.log(this.$store.state.storeinfo.pagesettings)
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      api_url(){
          return this.$store.state.api_url
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          orderid:'',
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          orderLoc:'',
      }
  },
  methods:{
      viewOrder(order){
          this.singleorder = order.data
          this.orderid = order.id
          this.cart = order.data.details
          this.orderLoc = order.data.pickuplocation.data
      },
      btnCancelClick(id){
          var r = confirm("Are you sure you want to cancel order #"+this.singleorder.id);
          if (r == true) {
              fb.ordersCollection.doc(String(id)).update({
                  status: "cancel",
                  lastupdate:Math.floor(Date.now()/1000),
              })
              .then(async ()=> {
                  Vue.$toast.open("Order status successfully updated!")
                  document.getElementById('btnModalClose').click()

                  let emailresponse = await fetch(`${this.api_url}/email/ordercancel/${this.singleorder.id}!${this.singleorder.ordertype}!${encodeURI(this.storeInfo.name)}!${encodeURI(this.singleorder.customer.emailaddress)}!${encodeURI(this.singleorder.customer.fullname)}!${encodeURI(this.singleorder.pickuplocation.data.leadtime)}!${this.orderid}!${this.storeId}`).then(()=> {
                        return true
                    })

                    console.log("email response", emailresponse)

              })
              .catch((error)=> {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error)
              })
          } else {
              return false
          }
      },
      btnDeliverClick(id){
          var r = confirm("Order #" + this.singleorder.id + " delivered to customer?");
          if (r == true) {
              fb.ordersCollection.doc(String(id)).update({
                  status: "complete",
                  lastupdate:Math.floor(Date.now()/1000),
              })
              .then(async ()=> {
                  Vue.$toast.open("Order status successfully updated!")
                  document.getElementById('btnDeliverModalClose').click()

                  let emailresponse = await fetch(`${this.api_url}/email/ordercomplete/${this.singleorder.id}!${this.singleorder.ordertype}!${encodeURI(this.storeInfo.name)}!${encodeURI(this.singleorder.customer.emailaddress)}!${encodeURI(this.singleorder.customer.fullname)}!${encodeURI(this.singleorder.pickuplocation.data.leadtime)}!${this.orderid}!${this.storeId}`).then(()=> {
                      return true
                  })

                  console.log("email response", emailresponse)

              })
              .catch((error)=> {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error)
              })
          } else {
              return false
          }
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })


      fb.ordersCollection.where("storeid", "==", storeid).where("pickuplocation.id", "==", this.location.id).where("status", "==", "ready").orderBy("id", "asc").onSnapshot((querySnapshot)=>{
          let j=0
          this.orders.length=0
          querySnapshot.forEach((doc)=>{
              this.orders.push({index:j, id:doc.id, data:doc.data()})
              j++
          })

          if(this.orders.length==0) this.orders=[]
          
          this.$emit("readycount", this.orders.length)
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .fixed-header{
        padding:20px 20px;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    a:hover{
        text-decoration:none;
    }

    body{
        font-size:18px;
    }

</style>
