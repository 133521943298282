<template>
	<div class="row">
		<div class="col-12">
			<h3 class="my-3"><strong>Admin Delivery Locations</strong></h3>
		</div>
		<div class="col-12">
			<ul class="list-group">
				<li class="list-group-item" v-for="store in stores" :key="store.id">
					<div class="form-group form-check my-2">
						<h6><strong>{{store.data.name}}</strong></h6>
						<div class="form-group form-check" v-for="loc in locations.filter((l) => l.data.storeid === store.id)" :key="loc.id">
							<input type="checkbox" class="form-check-input" :id="loc.id" :checked="loc.data.deliveryoptions.delivery" @change="updateDeliveryOption(loc.id, loc.data.deliveryoptions)">
							<label class="form-check-label" :for="loc.id">{{loc.data.name}}</label>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'
const fb = require('../firebaseConfig.js')

export default {
	name: 'AdminLDeliveryLocations',
	components: {},
	computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		locations(){
			return this.$store.state.alllocations
		},
		stores(){
			return this.$store.state.cruzLifeVendors
		},
	},
	data(){
		return{
		}
	},
	methods:{
		updateDeliveryOption(locId, deliveryoptions){
			deliveryoptions.delivery = !deliveryoptions.delivery
			console.log(deliveryoptions.delivery)
			fb.locationsCollection.doc(locId).update({
				deliveryoptions: deliveryoptions,
			}).then(()=>{
				Vue.$toast.open('Lead time saved!')
			})
		},
	},
	async mounted(){

	}
}
</script>
