<template>
	<div class="container home-page">
		<div id="navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-white">
			<a class="navbar-brand" href="#!" @click="navClick('home')"><img src="../assets/orderup-logo.png" class="img-fluid" style="height:60px;"/></a>
			<button id="mobilemenu" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div align="right" class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav w-100">
					<li class="nav-item ml-auto">
						<a class="nav-link" href="#!" @click="navClick('pickupdelivery')">Pick-up & Delivery</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#!" @click="navClick('tableordering')" >Table Ordering</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#!" @click="navClick('roomservice')">Room Service</a>
					<li>
					<li class="nav-item">
						<a class="nav-link" href="#!" @click="navClick('pricing')">Pricing</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#!" @click="navClick('faq')">FAQs</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="https://socaislands.freshdesk.com/" target="_support">Support</a>
					</li>
					<li class="nav-item ml-3 my-1">
						<a href="/login" class="btn btn-outline-dark btn-sm" target="_login" type="button">Sign in</a>
					</li>
					<li class="nav-item my-1 ml-3">
						<a href="/signup" class="btn btn-dark btn-sm" target="_signup" type="button">Sign up</a>
					</li>
				</ul>
			</div>
		</nav>
		</div>

		<div id="home" class="row my-5" style="padding-top:50px;">
			<div class="alert1 alert-dark1 col-12" role="alert">
				<p align="right" class="col-12 my-1 small"><a class="mr-2" href="https://wa.me/18683522658"><i class="fab fa-whatsapp mr-1"></i>+1 (868) 352-2658</a>  <a href="mailto:support@orderuptoday.com"><i class="fas1 fa-envelope1 mr-1"></i>support@orderuptoday.com</a></p>
			</div>
			<div class="col-lg-5 order-lg-2 card-body" align="right">
				<img src="../assets/orderup-img1.jpg" class="img-fluid" />
			</div>
			<div class="row col-lg-7 order-lg-1">
				<div class="col-12">
					<h2 class="my-3"><strong>An easy and affordable way to accept online orders.</strong></h2>
					<p>
						The Order Up app is a safe and reliable online ordering platform for contactless pickup, curbside, delivery, table-ordering and room service.
					</p>
					<ul>
						<li>No setup fees or subscriptions.</li>
						<li>Customized online menu for direct ordering.</li>
						<li>Customer-friendly features like easy reordering and group ordering.</li>
						<li>Manage your operations from your control panel.</li>
						<li><strong>Own your data... with the Order Up app you own the customer.</strong></li>
						<!--li>**Accept credit card payments in your local currency and get paid in USD <small><em>(special conditions apply).</em></small></li-->
					</ul>
				</div>
				<div class="col-12 mb-3">
					<button class="btn btn-link text-dark btn-lg"><i class="fas fa-play-circle mr-3"></i> See how it works</button>
				</div>

				<div class="col-lg-6 my-2">
					<a  href="/signup" target="_signup" class="btn btn-dark btn-lg btn-block">GET STARTED</a>
				</div>
				<div class="col-lg-6 my-2">
					<a href="/socaislandsbarandgrill" target="_sample" class="btn btn-outline-dark btn-lg btn-block">TRY IT NOW</a>
				</div>
				
			</div>
			
		</div>

		<!-- features -->

		<div class="row my-5">
			<h2 class="my-3 col-12"><strong>Features</strong></h2>
			<p class="text-secondary col-12">The Order Up app control panel gives you full control of your menu, pricing, images, specials and more. Get key insights into customer habits, while building your own database that can be used for future promotional and marketing activities.</p>
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-book-open"></i></h1>
				<h5><strong>Smart Menus</strong></h5>
				<p align="">
					Add photos to your digital menus to assist your customers with their ordering decisions.
				</p>
				</div>
			</div>
			<!--div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-filter"></i></h1>
				<h5><strong>Product Filtering</strong></h5>
				<p align="">
					Your customers can search or filter your items by choice such as Vegan or Gluten free.
				</p>
				</div>
			</div-->
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-magic"></i></h1>
				<h5><strong>Easy to Implement</strong></h5>
				<p align="">
					The Order Up app is an easy-to-use web-based application. Get started with just a few clicks.
				</p>
				</div>
			</div>
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-users"></i></h1>
				<h5><strong>Own Your Data</strong></h5>
				<p align="">
					With the Order Up app, you own your customers' information and they order directly from you.
				</p>
				</div>
			</div>
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-user-shield"></i></h1>
				<h5><strong>Secure Payments</strong></h5>
				<p align="">
					Customers can pay securely online for their orders using a credit or debit/credit card.
				</p>
				</div>
			</div>
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-mobile-alt"></i><i class="fas fa-qrcode"></i></h1>
				<h5><strong>No App Needed</strong></h5>
				<p align="">
					Your customers access your menu and start ordering from their mobile devices.
				</p>
				</div>
			</div>
			<div class="col-lg-4 my-2">
				<div class=" card card-body bg-dark text-white" align="center">
				<h1><i class="fas fa-smile-beam"></i></h1>
				<h5><strong>Improve Operations</strong></h5>
				<p align="">
					Eliminate time consuming phone orders, improve order accuracy and your customer experience.
				</p>
				</div>
			</div>

		</div>

		<!-- / features -->

		<!-- Pick up and delivery -->
		<div id="pickupdelivery" class="row my-5">
			<div class="col-lg-5 card-body" align="right">
				<img src="../assets/orderup-img2.jpg" class="img-fluid" />
			</div>
			<div class="col-lg-7">
				<div class="">
					<h2 class="my-3"><strong>Pick-up, Curbside and Delivery</strong></h2>
					<h3 class="text-secondary mb-4">An ordering system designed for Your Customers to order direct.</h3>
					<p>
						The Order Up app is a secure and reliable online ordering platform that enables you to sell food and beverages online via your own website. It can be rolled out quickly for a single restaurant or cafe, or customised as a fully branded enterprise-level solution for businesses with multiple locations. 
					</p>

					<p>
						Let customers choose how they want to get their food. Fulfillment options  match what your business offers, whether that’s pickup, curbside, or your own delivery service.
					</p>
					<p>
						Perfect for bakeries, doubles vendors, street food vendors, fast casual restaurants, cafes, roti shops, food trucks, fresh fruits and vegetable stalls, butchers, seafood vendors, farmer's markets, takeaway service and more.
					</p>
					<ul>
						<li>Reduce food order errors.</li>
						<li>Offer pick-up, curbside and delivery options.</li>
						<li>Make group ordering simple with the Group Ordering feature <em>(in beta)</em>.</li>
						<li>Custom orderable mobile site. No app download required.</li>
						<li>Reduce labour costs.</li>
					</ul>
				</div>
				<div class="my-3">
					<a  href="/signup" class="btn btn-dark btn-lg">GET STARTED WITH ORDER UP</a>
				</div>
			</div>
			
		</div>
		<!-- /Pick up and delivery -->


		<!-- table ordering -->
		<div  id="tableordering" class="row my-5">

			<div class="col-lg-5 order-lg-2 card-body">
				<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active" data-interval="3500">
							<img src="../assets/orderup-img3.jpg" class="img-fluid"/>
						</div>
						<div class="carousel-item" data-interval="3500">
							<img src="../assets/orderup-img2.jpg" class="img-fluid"/>
						</div>

					</div>
				</div>
				
			</div>
			
			<div class="col-lg-7 order-lg-1">
				<div class="">
					<h2 class="my-3"><strong>Table Ordering</strong></h2>
					<h3 class="text-secondary mb-4">Offer contactless ordering and payment at the table.</h3>
					<p>
						A next-gen customer experience; perfect for bars, pubs, fast casual restaurants, cafes, street food vendors, food trucks and more. Our contactless table ordering system configures easily to suit your unique operational requirements. 
					</p>

					<p>
						There's no app to download and therefore really simple for your customers to not only place the first order, but to keep on ordering without the hassle of lining up or waiting on their server. For families and groups it's even more convenient as they can order and pay for their own items.
					</p>
					<p>
						Your customers can now scan a smart QR code with their own device, order food and drink, pay and either have the order brought to their table or receive an sms notifying them that the order is ready.
					</p>
					<ul>
						<li><strong>No Tablets, No Kiosks, No Downloads, No Fuss.</strong></li>
						<li>No app download required.</li>
						<li>Speed up table service and improve table turnover.</li>
						<li>Limit contact with staff and risk.</li>
					</ul>
				</div>
				<div class="my-4">
					<a  href="mailto: support@orderuptoday.com?subject=The Order Up app: Table Ordering" class="btn btn-dark btn-lg">CONTACT US TO GET STARTED</a>
				</div>	
			</div>
			
			
		</div>
		<!-- /table ordering -->


		<!-- room service -->
		<div id="roomservice" class="row my-5">
			<div class="col-lg-5 card-body">
				<img class="img-fluid" src="../assets/orderup-img4.jpg">
			</div>
			<div class="col-lg-7">
				<div class="">
					<h2 class="my-3"><strong>Room service</strong></h2>
					<h3 class="text-secondary mb-4">Delivery to the Room, Pool Side or even at the Bar. The choice is yours.</h3>
					<p>
						Load your digital menus directly onto your guests own mobile device. No app, tablets or phone calls needed. Your guests simply Scan to start ordering, then you deliver directly to their room. You can also include options for your guests to order additional ammenities, for example, laundry service. Talk about service :) 
					</p>
					<p>
						Alternative systems tend to incur huge capital outlays, infrastructure changes and staff training. The Order Up app provides you with a light weight and cost-effective solution that instantly improves the guest experience while maintaining your existing systems and procedures.
					</p>
					<ul>
						<li><strong>Improve the guest experience.</strong></li>
						<li>No Tablets, No Kiosks, No Downloads, No Fuss.</li>
						<li>No app download required.</li>
						<li>Reduce food order errors.</li>
					</ul>
				</div>
				<div class=" my-4">
					<a  href="mailto: support@orderuptoday.com?subject=The Order Up app: Table Ordering" class="btn btn-dark btn-lg">CONTACT US TO GET STARTED</a>
				</div>
			</div>
			
		</div>
		<!-- /room service -->


		<!-- pricing -->
		<div id="pricing" class="row my-5">
			<!--div class="col-lg-5 card-body">
				<p>VIDEO</p>
			</div-->
			<div class="col-lg-12">
				<div class="">
					<h2 class="my-3"><strong>Pricing</strong></h2>
					<h3 class="text-secondary mb-4">Merchant-friendly pricing. No setup fees. No subscriptions.</h3>
				</div>
				
			</div>
			
			<div class="col-lg-6 bg-light" >
				<div class="card-body">
					<h6  align="center" class="my-3"><strong>OUT OF THE BOX</strong></h6>
					<p  align="center" class="text-secondary mb-5">Access the complete solution with <br>simple, pay-as-you-go pricing.</p>

					<h1 align="center" class="my-5" style="padding-top:3rem; font-size:12rem; line-height:50px;">2%<br><small class="text-secondary" style="font-size:40px;">per order</small></h1>

					<p align="center">Everything you need to manage your operations</p>
					<p align="center">Get continual feature updates for free </p>
					<p align="center">No setup fees, monthly fees, or hidden fees</p>

				</div>
			</div>
			<div class="col-lg-6" style="padding: 5rem 0;">
				<div class="card-body bg-dark text-white">
					<h6 align="center" class="my-3"><strong>CUSTOM ADD-ONS</strong></h6>
					<p align="center" class="">Design a custom package for your business.</p>

					<ul class="list-group list-group-flush">
						<li class="list-group-item bg-transparent">
							<div class="row">
								<div class="col-7">
									Accept payments online <br><small><em>(credit card and debit/credit card)</em></small>
								</div>
								<div class="col-5">
									<em class="small">Please contact our Support team for details.</em>
									<!--4.9% <small>plus $0.30 USD /successful transaction</small-->
								</div>
							</div>
						</li>
						<li class="list-group-item bg-transparent">
							<div class="row">
								<div class="col-7">
									SMS platform fees
								</div>
								<div class="col-5">
									$0.06 <small>USD /message</small>
								</div>
							</div>
						</li>
						<li class="list-group-item bg-transparent">
							<div class="row">
								<div class="col-7">
									Branded domain name <br><small><em>(yourbusiness.com)</em></small>
								</div>
								<div class="col-5">
									$30 <small>USD /year</small>
								</div>
							</div>
						</li>
						<li class="list-group-item bg-transparent">
							<div class="row">
								<div class="col-7">
									Custom mobile app
								</div>
								<div class="col-5">
									$1,500 <small>USD /year</small>
								</div>
							</div>
						</li>
					</ul>
				</div>
				
			</div>
			<!--div class="col-12 my-3">
			</div-->
		</div>
		<!-- /pricing -->


		<!-- FAQs -->
		<div id="faq" class="row my-5">
			<!--div class="col-lg-5 card-body">
				<p>VIDEO</p>
			</div-->
			<div class="col-lg-12 mb-5">
				<div class="">
					<h2 class="my-3"><strong>FAQs</strong></h2>
					<p class="text-secondary mb-4">If you don't find the answers you're looking for, we will be happy to talk with you via phone, chat or email.</p>
				</div>
				<ul class="list-group">
					<li class="list-group-item" :class="(faqnum===1) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(1)">Do I need a Point of Sale system/solution (POS)?</h5>
						<p class="my-4" v-if="faqnum===1">
							No. Our online ordering system has been designed to run independently. If you do have a POS system, depending on the manufacturer we can look at integration if required.
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===2) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(2)">How much does it cost?</h5>
						<p class="my-4" v-if="faqnum===2">
							Our service costs a flat 2% per order. Plain and simple.
							<br>Custom add-ons are available.
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===3) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(3)">Do I need an Internet connection?</h5>
						<p class="my-4" v-if="faqnum===3">
							Yes, you need Internet access to maximise use fo the Order Up app. If you are mobile or do not have an internet connection, you may want to consider using the SMS platform add-on to receive orders.
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===4) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(4)">What hardware is required?</h5>
						<p class="my-4" v-if="faqnum===4">
							No special hardware is required. The Order Up app comes with a web based order receiver that works in any web browser on a tablet, mobile phone or desktop computer.
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===5) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(5)">Can my customers pay online?</h5>
						<p class="my-4" v-if="faqnum===5">
							Yes, online payments are available as a custom add-on. 
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===6) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(6)">Can I update the online ordering system myself?</h5>
						<p class="my-4" v-if="faqnum===6">
							Yes. The Order Up app comes with an easy to use control panel for you to edit everything including your menu and prices, delivery fees, online trading hours and more.
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===7) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(7)">Do you have an app?</h5>
						<p class="my-4" v-if="faqnum===7">
							Our focus is on getting customers to buy from you directly with as little friction as possible. To do that, we don't require customers to download an app. Rather, we optimize our ordering pages for mobile device browsers.
						<br><br>
							However, you can get a mobile app developed as a custom add-on. 
						</p>
					</li>
					<li class="list-group-item" :class="(faqnum===8) ? 'bg-dark text-white' : ''">
						<h5 class="my-1 pointer" @click="openFaq(8)">Is there a binding contract?</h5>
						<p class="my-4" v-if="faqnum===8">
							Nope! If you don’t feel like you are getting enough value, you can walk away at any time.
						</p>
					</li>
				</ul>
				
			</div>
			<div class="col-lg-6 my-3">
				<a  href="/signup" class="btn btn-dark btn-lg btn-block">GET STARTED WITH ORDER UP</a>
			</div>
			<div class="col-lg-6 my-3">
				<a href="/socaislandsbarandgrill" class="btn btn-outline-dark btn-lg btn-block">TRY OUT A DEMO</a>
			</div>
		</div>
		<!-- /FAQs -->

		<!-- Footer -->
		<div id="footer" class="row" style="margin-bottom:0; border-top:1px solid #f1f1f1; padding:1rem 0 0 0;">
			<div class="col-12">
				<p align="left"><a class="mr-2" href="https://wa.me/18683522658"><i class="fab fa-whatsapp mr-1"></i>+1 (868) 352-2658</a>  <a class="mr-2" href="mailto:support@orderuptoday.com"><i class="fas1 fa-envelope1 mr-1"></i>support@orderuptoday.com</a> <a href="https://socaislands.freshdesk.com/" class="mr-2" target="_support">Support.</a> <a href="/terms" class="mr-2"  target="_terms">Terms.</a> <a href="/privacy" class="mr-2" target="_terms">Privacy.</a> 
				<br><small>The Order Up app is a service provided by Soca Islands LLC and Aeon Distributors Ltd. &copy;{{copyyear}}.</small></p>
			</div>
		</div>
		<!-- /Footer -->
		
	</div>
</template>

<!-- Start of Async Drift Code -->
<script>

</script>
<!-- End of Async Drift Code -->

<script>
// @ is an alias to /src
var moment = require('moment')

	
	export default {
		name: 'Home',
		components: {

		},
		data(){
			return{
				faqnum:0,
			}
		},
		computed:{
			copyyear(){
				return moment().format('YYYY')
			},
		},
		methods:{
			openFaq(val){
				this.faqnum = (this.faqnum != val) ? val : 0
			},
			navClick(section){
				let elmnt = document.getElementById(section)
				elmnt.scrollIntoView({ behavior: 'smooth' })
				document.getElementById("mobilemenu").click()
			},
		},
		mounted(){
			var prevScrollpos = window.pageYOffset;
			if(screen.width>990){
				window.onscroll = function() {

					var currentScrollPos = window.pageYOffset
					if (prevScrollpos > currentScrollPos) {
						document.getElementById("navbar").style.top = "0"
						//console.log("y", prevScrollpos)
					} else {
						document.getElementById("navbar").style.top = "-90px"
					}
					prevScrollpos = currentScrollPos
				}
			}


			"use strict";

!function() {
  var t = window.driftt = window.drift = window.driftt || [];
  if (!t.init) {
    if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
    t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
    t.factory = function(e) {
      return function() {
        var n = Array.prototype.slice.call(arguments);
        return n.unshift(e), t.push(n), t;
      };
    }, t.methods.forEach(function(e) {
      t[e] = t.factory(e);
    }), t.load = function(t) {
      var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
      o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
      var i = document.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(o, i);
    };
  }
}();
drift.SNIPPET_VERSION = '0.3.1';
drift.load('2tsedwt2fdnb');

			//console.log(screen.width)
		}
	}
</script>
<style>
#navbar{
  background-color: #fff; /* Black background color */
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  left:0;
  width: 100%; /* Full width */
  padding:0 6.5rem;
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  z-index:100;
  border-bottom: 1px solid #f1f1f1;

}

.nav-link{
  font-size:15px !important;
  font-weight:400;
}

.home-page a:not(.btn){
	color:#343a40;
}

@media (max-width: 990px){
	.nav-link{
		font-size:18px;

	}
}

@media (max-width: 900px){
	#navbar{
		background-color: #fff; /* Black background color */
		position: fixed; /* Make it stick/fixed */
		top: 0; /* Stay on top */
		left:0;
		width: 100%; /* Full width */
		padding:0 1rem;
		transition: top 0.3s; /* Transition effect when sliding down (and up) */
		z-index:100;
	}
}
</style>
