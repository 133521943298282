<template>
	<div class=""  >
		

		<div class="row">
			
			<nav class="col-12 bg-white" style="z-index:60; position:fixed; top:0px; padding-top:0px;" id="adminliveorderstabs">
				<h3 class="my-3"><strong>Admin Live Orders</strong></h3>

				<div class="nav nav-tabs" id="nav-tab" role="tablist">
					<a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Pending <span class="badge badge-primary">{{pendingorders}}</span></a>

					<a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Ready <span class="badge badge-success">{{readyorders}}</span></a>

					<a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Past Orders</a>
				</div>
			</nav>
			<div class="tab-content col-12" id="nav-tabContent" style="margin-top:80px;">
				<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">.

					<ScheduledOrders @pendingcount="pendingOrderCount" />

				</div>
				<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">.
					<ReadyOrders @readycount="readyOrderCount" />
				</div>
				<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">.
					<PastOrders />
				</div>
				
				<div class="tab-pane fade" id="nav-pos" role="tabpanel" aria-labelledby="nav-pos-tab">.
					
				</div>
				

				<div class="tab-pane fade" id="nav-leadtime" role="tabpanel" aria-labelledby="nav-leadtime-tab">.

				</div>

			</div>
		</div>


		<!--div class="row" v-if="validkey && activetab=='quickmenu'">
			<div class="col-12">
				<QuickMenu  :location="activeloc"/>
			</div>
		</div-->


		<!-- Modal view order-->
        <div class="modal fade" id="liveorderskey" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                
                <button id="btnLiveOrdersModalClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 25px;">

					<div class="col-12">
						<label>Enter location key for <strong>{{locationname}}</strong>:</label>
						<input type="text" class="form-control form-control-lg" placeholder="Location key" @input="txtkey=$event.target.value" />
						<p class="text-danger"><strong>{{txtkeyerror}}</strong></p>
					</div>
                  
              </div>
              <div class="modal-footer">
                  <!--div align="center" class="col-6">
                      <button type="button" class="btn btn-primary btn-block btn-lg mb-3"  data-dismiss="modal1" aria-label="Close1" @click="btnProcessClick(orderid)" v-if="singleorder.status!='processing'">Process Order <i class="fas fa-chevron-right"></i></button>
                  </div-->
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-primary btn-block btn-lg mb-3"  data-dismiss="modal1" aria-label="Close1" @click="btnKeyClick()" :disabled="!keyFormValid">Continue <i class="fas fa-chevron-right"></i></button>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>


        <!-- Modal view order-->
        <!--div class="modal fade" id="editleadtime" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 25px;">

					<div class="col-12">
						<label>Enter lead time for <strong>{{locationname}}</strong> <em>(mins)</em>:</label>
						<input type="number" class="form-control form-control-lg" :placeholder="(activeloc.data) ? activeloc.data.leadtime : '15'" @input="leadtime=$event.target.value" />
					</div>
                  
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-primary btn-block btn-lg mb-3"  data-dismiss="modal" aria-label="Close" @click="updateLeadtime()" :disabled="!leadtimeFormValid">Save Lead Time <i class="fas fa-chevron-right"></i></button>
                  </div>
                  
              </div>
            </div>
          </div>
        </div-->


	</div>
</template>

<script>
// @ is an alias to /src
import Vue from 'vue'


import ScheduledOrders from '@/components/AdminScheduledOrders.vue'
import PastOrders from '@/components/AdminPastOrders.vue'
import ReadyOrders from '@/components/AdminReadyOrders.vue'
////import QuickMenu from '@/components/QuickMenu.vue'
//import POS from '@/components/POS.vue'

const fb = require('../firebaseConfig.js')


export default {
  name: 'AdminLiveOrders',
  components: {
    ScheduledOrders, ReadyOrders, PastOrders, 
  },
  computed:{
		currentUser(){
			return this.$store.state.currentUser
		},
		locations(){
			let locations = this.$store.state.locations
			if(this.txtsearch.length<3) return locations
			const search = this.txtsearch.toLowerCase()
			const filter = function(loc){
								return (loc.data.address.street.toLowerCase().includes(search) || loc.data.address.city.toLowerCase().includes(search) || loc.data.address.state.toLowerCase().includes(search) || loc.data.address.zip.toLowerCase().includes(search) || loc.data.address.country.toLowerCase().includes(search))
							}
			return locations.filter(filter)
		},
		keyFormValid(){
			if(!this.txtkey || this.txtkey.length<10) return false
			return true
		},
		leadtimeFormValid(){
			if(!this.leadtime) return false
			return true
		},
  },
  data(){
		return{
			storeinfo:[],
			storeid:'',
			pendingorders:0,
			readyorders:0,
			txtsearch:'',
			activeloc:{},
			txtkey:'',
			txtkeyerror:'',
			validkey:null,
			leadtime:'',
			locationname:'',
			activetab: 'orders',
			pageload:null,
		}
  },
  methods:{
		toggleOnlineStatus(status){
			let msg = (status) ? "Turn ON online ordering?" : "Turn OFF online ordering?"
			var r = confirm(msg)
			if (r == true) {
				fb.locationsCollection.doc(this.activeloc.id).update({
					public: status,
				})
				.then(()=> {
					Vue.$toast.open('Online status saved!')
					this.activeloc.data.public = status
				})
				.catch((error)=> {
					// The document probably doesn't exist.
					console.error("Error updating document: ", error)
				})
			} else {
				return false
			}
			document.getElementById('liveordersmobilemenu').click()
		},
		pendingOrderCount(params){
			this.pendingorders = params
		},
		readyOrderCount(params){
			this.readyorders = params
		},
		openLocation(loc){
			this.activeloc = loc
			this.txtkey = ''
			this.txtkeyerror = ''
			this.locationname = loc.data.name
		},
		viewAllClick(){
			//this.activeloc = {}
			localStorage.removeItem('locKey')
			//this.validkey = false
			//this.leadtime = 0
			//this.$router.push(`/${this.storeid}/live-orders`)
			//this.activetab = 'orders'
			location.href = `/${this.storeid}/live-orders`
		},
		btnKeyClick(){
			if(this.txtkey != String(this.activeloc.id).toUpperCase()) {
				//alert("Invalid location key. Please contact your store admin for assistance.")
				this.txtkeyerror = "Invalid location key. Please contact your store admin for assistance."
				return false
			}else{
				this.validkey = true
				//console.log("lead1", this.activeloc.data.leadtime)
				this.leadtime = this.activeloc.data.leadtime
				localStorage.setItem('locKey', String(this.activeloc.id))
				document.getElementById('btnLiveOrdersModalClose').click()
				this.$router.push(`/${this.storeid}/live-orders/${this.activeloc.id}`)

			}
		},
		updateLeadtime(){
			fb.locationsCollection.doc(this.activeloc.id).update({
				leadtime: this.leadtime,
			}).then(()=>{
				Vue.$toast.open('Lead time saved!')
			})
		},
		navbarClick(tab){
			this.activetab = tab
			document.getElementById('liveordersmobilemenu').click()
		},
  },
  async mounted(){

		/*const storeid = this.$route.params.store
		this.storeid = storeid

		await fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
			if (doc.exists) {
				if(this.currentUser.uid != doc.data().user) {
					this.$store.dispatch('logout')
					return false
				}
				this.$store.dispatch('fetchStoreProfile')
				this.storeinfo = doc.data()
				this.pageload = true
			} else {
				console.log("No such document!")
				this.$router.push('/login')
			}
		})

		let location = null

		if(this.$route.params.location){
			location = this.$route.params.location
		}else{
			this.validkey = false
			return false
		}
		

		fb.locationsCollection.doc(location).onSnapshot((doc) =>{
			if (doc.exists) {
				this.activeloc = {id:doc.id, data:doc.data()}
				this.validkey = true
				this.leadtime = doc.data().leadtime
				this.locationname = doc.data().name

			} else {
				console.log("No such document!")

			}
		})*/


		/*window.onscroll = function() {
			var currentScrollPos = window.pageYOffset
			if(!document.getElementById("liveorderstabs")) return false
			if (currentScrollPos<=50) {
				document.getElementById("liveorderstabs").style.top = "60px"
				document.getElementById("liveorderstabs").style.position = "fixed"
				//console.log("y", currentScrollPos)
			} else {
				//document.getElementById("liveorderstabs").style.position = ""
			}
		}*/

  }
}
</script>
<style>
	.tab-pane1{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}
</style>
