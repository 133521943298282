import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// firebase init goes here
const config = {
	apiKey: "AIzaSyCa_wfx8AREGB152TyklSifwk07mshHVyY",
	authDomain: "order-up-app-tt.firebaseapp.com",
	databaseURL: "https://order-up-app-tt.firebaseio.com",
	projectId: "order-up-app-tt",
	storageBucket: "order-up-app-tt.appspot.com",
	messagingSenderId: "768657978557"
}

firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const auth = firebase.auth()
const currentUser = auth.currentUser

// date issue fix according to firebase
//const settings = {
//    timestampsInSnapshots: true
//}
//db.settings(settings)

// firebase collections
const menuitemsCollection = db.collection('menuitems')
const modifiersCollection = db.collection('modifiers')
const sectionsCollection = db.collection('sections')
const categoryCollection = db.collection('category')
const itemsCollection = db.collection('items')
const itemsTestCollection = db.collection('itemstest')
const customerordersCollection = db.collection('customerorders')
const ordersCollection = db.collection('orders')
const locationsCollection = db.collection('locations')
const storesCollection = db.collection('stores')
const unavailableCollection = db.collection('unavailableitems')
const cartitemsCollection = db.collection('cartitems')
const paymentsCollection = db.collection('payments')
const billpayCollection = db.collection('billpay')
const appmsgCollection = db.collection('appmessages')
const promosCollection = db.collection('promos')
const couponsCollection = db.collection('coupons')
const reviewsCollection = db.collection('reviews')
const expensesCollection = db.collection('expenses')
const expenseItemsCollection = db.collection('expenseitems')
const expenseCategoryCollection = db.collection('expensecategory')
const vendorsCollection = db.collection('vendors')
const directoryCollection = db.collection('directory')
const feedCollection = db.collection('feed')
const profileCollection = db.collection('profiles')
const appnoticeCollection = db.collection('app_notices')

export {
    firebase,
    db,
    auth,
    currentUser,
    directoryCollection,
    feedCollection,
    menuitemsCollection,
    modifiersCollection,
    sectionsCollection,
    categoryCollection,
    expensesCollection,
    expenseCategoryCollection,
    expenseItemsCollection,
    itemsCollection,
    itemsTestCollection,
    customerordersCollection,
    profileCollection,
    locationsCollection,
    ordersCollection,
    storesCollection,
    unavailableCollection,
    cartitemsCollection,
    paymentsCollection,
    billpayCollection,
    appmsgCollection,
    promosCollection,
    couponsCollection,
    reviewsCollection,
    vendorsCollection,
    storageRef,
	appnoticeCollection,
}