<template>
    <div class="row">
        <div class="row col-lg-12">
            <div class="col-lg-9 mb-5">
                <!--input type="text" class="form-control form-control" v-model="txtsearch" placeholder="Search menu items..." /-->
            </div>
            <div class="col-lg-3 mb-5" align="right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addcategory" @click="categoryname=''"><i class="fas fa-plus"></i> Add New Category</button>
            </div>
        </div>

        <div v-if="categories.length<1" align="center" class="col-12">
            <em>Menu items will appear here when added...</em>
        </div>

        <div class="col-lg-12">

            <ul class="list-group my-2 mb-5" v-for="(cat, index) in categories" :key="index">

                <!-- category listing -->

                <li class="list-group-item">
                    <div class="row ">
                        <div class="col-lg-9 pointer" @click="activesection = cat.id; activecategoryname = cat.data.name; activeitem=''">
                            <h4><strong>{{cat.data.name}}</strong></h4>
                        </div>
                        <!--div class="col-lg-2" align="right">
                            
                        </div-->
                        <div class="col-lg-1 col-6" align="right">
                            <div class="custom-control custom-switch my-1">
                                <input type="checkbox" class="custom-control-input" :id="'cat'+cat.id" value="true" v-model="cat.data.public" @change="toggleCategory(cat.id, cat.data.public)">
                                <label class="custom-control-label" :for="'cat'+cat.id">
                                    <span v-show="cat.data.public">Hide</span>
                                    <span v-show="!cat.data.public">Show</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-6" align="right">
                            <div class="btn-group">
                                <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Actions
                                </button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#!" data-toggle="modal" data-target="#additem" @click="addItemClick(cat.id, cat.data.name)">Add Item</a>
                                    <a class="dropdown-item" href="#!" @click="activesection = cat.id; activecategoryname = cat.data.name; activeitem=''">Edit Category</a>
                                    <a class="dropdown-item" href="#!" @click="deleteCategory(cat.id, cat.data.name)">Delete Category and Items</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="activesection==cat.id" :ref="'catref'+cat.id">
                        <div class="col-lg-5" align="right">
                            <input class="form-control mb-3" type="text" v-model="cat.data.name"/>
                            <button type="button" class="btn btn-secondary col-3 mr-2" @click="activesection=''; cat.data.name = activecategoryname">Close</button> 

                            <button type="button" class="btn btn-primary col-3" @click="updateCategory(cat.id, cat.data.name)">Update</button>
                        </div>
                        <div class="col-lg-3">
                            <small v-if="cat.data.photo"><a href="#!" @click="removeImage(cat.data.photo, cat.id)">Remove Image <i class="fas fa-times"></i></a></small>
                            <div class="pointer" style="width:150px; height:150px; background-color:#f1f1f1;" @click="editImage(cat.id, 'category')">
                                <small style="float:left; z-index:100; margin-top:50px; margin-left:10px; padding:5px; background-color:rgba(255,255,255,0.5);">Click to edit image</small>

                                <img :src="cat.data.photo" style="float:left; margin-top:-80px; height:150px; width:auto; border-radius:1rem;" v-if="cat.data.photo"/>
                                
                            </div>
                        </div>
                    </div>
                </li>


                <!-- items listing -->
                <li class="list-group-item" v-for="(item, index) in catitems(cat.id)" :key="index">
                    <div class="row ">
                        <div class="col-lg-9 pointer" @click="activeItemTab(item.id)" v-show="activeitem!=item.id" :ref="'ref'+item.id">
                            <h5>{{item.data.name}} - <em>{{item.data.price | currency}}</em></h5>
                        </div>
                        <!--div class="col-lg-2" align="right">
                            
                        </div-->
                        <div class="col-lg-1 col-6" align="right" v-show="activeitem!=item.id">
                            <div class="custom-control custom-switch my-1">
                                <input type="checkbox" class="custom-control-input" :id="'item'+item.id" value="true" v-model="item.data.public" @change="toggleItem(item.data.name, item.data.public)">
                                <label class="custom-control-label" :for="'item'+item.id">
                                    <span v-show="item.data.public">Hide</span>
                                    <span v-show="!item.data.public">Show</span>
                                </label>
                            </div>
                        </div>
                        <div align="right" class="col-lg-2 col-6" v-show="activeitem!=item.id">
                            <div class="dropdown">
                                <button type="button" class="btn btn-primary btn-sm  dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#!"  @click="activeItemTab(item.id)">Edit Menu Item</a>
                                    <a class="dropdown-item" href="#!" @click="deleteItem(item.id, item.data.name)">Delete Menu Item</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EditItem :itemid="activeitem" :itemref="activeref" v-if="activeitem!='' && activeitem==item.id" @active-item="activeItemTab('', $event)" @upload-image="editImage($event, 'item')" class="my-3" :ref="activeitem"/>
                </li>

            </ul>

        </div>

        <!-- Modal add category-->
        <div class="modal fade" id="addcategory" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Add New Category</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-lg-6 my-3">
                          <label><strong>Category Name</strong></label>
                          <input type="text" class="form-control" v-model="categoryname"/>
                      </div>
                      <div class="col-lg-6 my-3">
                          
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Close</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="saveCategoryClick" :disabled="!categoryFormValid">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



        <!-- Modal add item-->
        <div class="modal fade" id="additem" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Add New Item to {{activecategoryname}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="row col-lg-12">
                          <div class="col-8 my-3" align="right">
                              <input class="form-control" type="text" v-model="newitem.name" placeholder="Item name"/>
                              <small>/255</small>
                          </div>
                          <div class="col-4 my-3">
                              <input class="form-control" type="number" step="0.01"  v-model="newitem.price" placeholder="Item price"/>
                          </div>
                          <div class="col-lg-12" align="right">
                              <textarea rows="2" class="form-control" v-model="newitem.desc" placeholder="Item description (optional)"></textarea>
                              <small>/1000</small>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Close</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="saveItemClick" :disabled="!itemFormValid">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



        <!-- Modal view order-->
        <div class="modal fade" id="updatemenuphoto" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>Crop Image</strong></h5>
                <button id="closeupdatemenuphoto" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearImage">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 15px; width:100%; height:300px;">
                  <div align="center" class="col-12">
                      <img :src="imageurl" ref="source" style="width:100%; max-height:300px;"/>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-secondary btn-block mb-3"  data-dismiss="modal" aria-label="Close" @click="clearImage">CANCEL <i class="fas fa-times-right ml-3"></i></button>
                  </div>
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-primary btn-block mb-3" :disabled="isbusy" @click="saveImage">
                          <span v-show="!isbusy">SAVE IMAGE<i class="fas fa-chevron-right ml-3"></i></span>
                          <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="input-group mb-3" style="visibility:hidden;">
            <div class="custom-file">
                <input type="file" accept="image/png, image/jpeg" class="custom-file-input" id="menuimage" @change="cropImage($event)">
                <label class="custom-file-label">Choose file for logo</label>
            </div>
            <a href="#!" id="showmenuimgmodal" data-toggle="modal" data-target="#updatemenuphoto"></a>
        </div>

    </div>
</template>

<script>

import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import EditItem from '@/components/EditItem.vue'

import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'

Vue.use(VueToast, {
  // One of the options
  position: 'top-right',
  type: 'success'
})

const fb = require('../firebaseConfig.js')

export default {
  name: 'MenuEditor',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return 'View Options'
        return '$'+value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{
      EditItem,
  },
  computed:{
      categoryFormValid(){
          if(!this.categoryname) return false
          return true
      },
      itemFormValid(){
          if(!this.newitem.name) return false
          //if(!this.newitem.desc) return false
          if(!this.newitem.price) return false
          return true
      },
  },
  data(){
      return{
          storelocations:[],
          storeid:'', 
          categoryname:'',  
          categories:[],
          items:[],
          activecategory:'',
          activecategoryname:'',
          activesection:'',
          newitem:{},
          activeitem:'',
          activeref:'',
          txtsearch:'',
          isbusy:false,
          imageurl:null,
          activeimage:'',
          cropper: null,
          imageuploadtype:'',
      }
  },
  methods:{
      activeItemTab(item, ref){
          //alert(item)
          this.activeitem = item
          this.activeref = 'ref'+item
          this.activesection = ''
          //console.log("ref ", this.activeref)
          if(item){
              this.$nextTick(() => {
                  this.$refs[item][0].$el.scrollIntoView({ behavior: 'smooth' })
              })
          }

          if(ref){
              //console.log("return", ref)
              this.$nextTick(() => {
                  //console.log("ref-list", this.$refs)
                  this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block:'center' })
              })
          }

      },
      updateCategory(id, name){
          this.activesection = ''
          fb.categoryCollection.doc(id).update({
              name: name,
          }).then(()=>{
              Vue.$toast.open('Category updated!')
          })
          //console.log("old name", this.activecategoryname)
      },
      catitems(catid){
          const items = this.items
          const filter = function(item){
                              return (item.data.section===catid)
                          }

          return items.filter(filter)
      },
      toggleCategory(id, status){
          //console.log(id, status)
          fb.categoryCollection.doc(id).update({
              public:status,
          }).then(()=>{
              Vue.$toast.open('Category updated!')
          })
      },
      async toggleItem(id, status){
          /*fb.itemsCollection.doc(id).update({
              public:status,
          }).then(()=>{
              Vue.$toast.open('Item updated!')
          })

          let itemName = await fb.itemsCollection.doc(id).get().then((doc)=>{
              if (doc.exists) {
                 return doc.data().name
              } else {
                  return false
              }
          })*/

          fb.itemsCollection.where("storeid", "==", this.storeid).where('name','==', id).get().then((docs)=>{
              docs.forEach((doc)=> {
                  fb.itemsCollection.doc(doc.id).update({
                      public:status,
                  }).then(()=>{
                      Vue.$toast.open('Item updated!')
                  })
              })
          })

      },
      addItemClick(cat, catname){
          this.activecategory = cat
          this.activecategoryname = catname
          this.newitem = {}
      },
      saveCategoryClick(){
          fb.categoryCollection.add({
              name:this.categoryname,
              type: 'category',
              public:true,
              visible:true,
              storeid: this.storeid,
              photo:'',
          }).then((doc)=>{
              //console.log(doc.id)
              this.activesection = doc.id
              this.$nextTick(() => {
                  //console.log(this.$refs['catref'+doc.id])
                  this.$refs['catref'+doc.id][0].scrollIntoView({ behavior: 'smooth', block:'center' })
              })
              Vue.$toast.open('New category created!')
              this.activecategoryname = this.categoryname
          })
      },
      saveItemClick(){
          this.newitem.price = Number(this.newitem.price)
          this.newitem.public = true
          this.newitem.visible = true
          this.newitem.section = this.activecategory
          this.newitem.storeid = this.storeid
          this.newitem.photo = ''
          this.newitem.type = 'item'
          this.newitem.modifiers = []

          fb.itemsCollection.add(this.newitem).then((doc)=>{
              Vue.$toast.open('New item created!')
              this.activeItemTab(doc.id)
              this.$nextTick(() => {
                  this.$refs[doc.id][0].$el.scrollIntoView({behavior:'smooth'})
              }) 
          })
      },
      deleteCategory(cat, catname){
          var r = confirm(`Delete category ${catname} and its menu items?`)
          if (r == true) {
              fb.itemsCollection.where("section", "==", cat).get().then((querySnapshot)=>{
                  querySnapshot.forEach((doc)=> {
                      doc.ref.delete()
                  })
              })

              fb.categoryCollection.doc(cat).delete().then(()=>{
                  Vue.$toast.open('Category and items deleted!')
              })
          }
      },
      deleteItem(item, itemname){
          var r = confirm(`Delete menu item ${itemname}?`)
          if (r == true) {
              fb.itemsCollection.doc(item).delete().then(()=>{
                  Vue.$toast.open('Menu item deleted!')
              })
          }
      },
      editImage(item, itemtype){
          //console.log(item)
          this.activeimage = item
          this.imageuploadtype = itemtype
          //alert(this.activeimage)
          document.getElementById('menuimage').click()
      },
      cropImage(event){
          if(this.cropper)  this.cropper.destroy()
          if (!event.target.files || !event.target.files[0]) {
              this.cropper = null
              this.imageurl = null
              return false
          }

          if (this.imageurl) {
              window.URL.revokeObjectURL(this.imageurl)
          }

          this.imageurl = window.URL.createObjectURL(event.target.files[0])

          this.$nextTick(()=>{
              document.getElementById("showmenuimgmodal").click()
              this.cropper = new Cropper(this.$refs.source, {
                  aspectRatio: 1,
                  type:2,
                  minContainerWidth:600,
                  minContainerHeight:300
              })
          })
      },
      saveImage(){
          this.isbusy = true

          let options = {
              width: 500,
              height: 500,
              minWidth: 500,
              minHeight: 500,
              maxWidth: 1000,
              maxHeight: 1000,
              fillColor: '#fff',
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high',
          }

          this.cropper.getCroppedCanvas(options).toBlob((blob) => {
              let imageupload = fb.storageRef.child(`${this.storeid}/menu/${this.activeimage}.jpg`).put(blob)

              imageupload.on('state_changed', ()=>{

              },(error)=>{
                  // Handle unsuccessful uploads
                  console.log(error)
              },()=>{

                  imageupload.snapshot.ref.getDownloadURL().then((downloadURL)=> {

                      if(this.imageuploadtype==='category'){
                      
                          fb.categoryCollection.doc(this.activeimage).update({
                              photo: downloadURL
                          }).then(()=>{
                              console.log('File available at', downloadURL)
                              document.getElementById('closeupdatemenuphoto').click()
                              Vue.$toast.open('Image added to category!')
                              this.isbusy = false
                          }).catch((err)=>{
                              console.log(err)
                          })
                      }else if(this.imageuploadtype==='item'){
                          fb.itemsCollection.doc(this.activeimage).update({
                              photo: downloadURL
                          }).then(()=>{
                              console.log('File available at', downloadURL)
                              document.getElementById('closeupdatemenuphoto').click()
                              Vue.$toast.open('Image added to item!')
                              this.isbusy = false
                          }).catch((err)=>{
                              console.log(err)
                          })
                      }
                  })
              })
          }, 'image/jpeg', 0.3)

      },
      clearImage(){
          document.getElementById('menuimage').value= ""
      },
      removeImage(itemimg, itemid){
          let r = confirm(`Remove photo from item - ${itemid}?`)
          if(r==true){
              fb.storageRef.child(`${this.storeid}/menu/${itemid}.jpg`).delete().then(()=> {
                  fb.categoryCollection.doc(itemid).update({
                      photo:''
                  }).then(()=>{
                      Vue.$toast.open('Image removed from category!')
                  })

              }).catch((error)=> {
                  console.log(error)
              })
          }
      },
  },
  mounted(){
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })      

      fb.categoryCollection.where("storeid","==",storeid).where("type","==","category").orderBy("name", "asc").onSnapshot((querySnapshot) =>{
          let i=0
          this.categories.length=0
          querySnapshot.forEach((doc)=>{
              this.categories.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })  


      fb.itemsCollection.where("storeid","==",storeid).where("type","==","item").onSnapshot((querySnapshot) =>{
          let j=0
          this.items.length=0
          querySnapshot.forEach((doc)=>{
              this.items.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
      })


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .pointer{cursor:pointer;}

</style>
