<template>
    <div class="row" style="padding:0; margin:0;">   

        <div class="col-lg-4"></div>

        <div class="col-lg-4">
            <div class="row" v-show="step=='4'">
                <div class="col-12 my-5" align="center">
                    <h1><strong><i class="far fa-check-circle"></i></strong></h1>
                    <h4><strong>Success</strong></h4>
                    <p class="">Thank you {{customer.fullname}}. <br>Your payment has been received by {{storeinfo.name}}. A confirmation email has been sent to your email address <em>(you may have to look in your SPAM folder)</em>.</p>
                    <a class="btn btn-link" href="javascript:window.location.reload();">Make another payment.</a>
                </div>
            </div>
            <div class="row" style="padding-top:3rem;" v-show="step=='1'">
                <div class="mb-5">&nbsp;</div>
                <div class="col-12" align="center">
                    <h2><strong>{{storeinfo.name}}</strong></h2>
                    <p class="small"><em>Invoice Payments</em></p>
                </div>
            </div>
            <div class="row" v-show="step=='1'">
                <div class="col-12 my-1"><h5><strong>Invoice Details</strong></h5></div>

                <div class="col-12 my-2" >
                    <select class="form-control" v-model="invoice.store">
                        <option value="0" disabled>-- Company --</option>
                        <option v-if="!storeinfo.billpay">{{storeinfo.name}}</option>
                        <option v-for="(store, index) in storeinfo.billpay" :key="index">{{store}}</option>
                    </select>
                </div>

                <div class="col-12 my-2">
                    <input type="text" class="form-control " placeholder="Invoice No." v-model="invoice.id"/>
                </div>
                
                <div class="col-12 my-2">
                    <textarea rows="2" class="form-control" placeholder="Invoice/Payment notes: e.g. company name" v-model="invoice.notes"/>
                </div>
                <div class="col-4 my-2" >
                    <select class="form-control" v-model="invoice.currency">
                        <option value="bbd">BBD</option>
                        <option value="jmd">JMD</option>
                        <option value="ttd">TTD</option>
                        <option value="usd">USD</option>
                        <option value="xcd">XCD</option>
                    </select>
                </div>
                <div class="col-8 my-2" align="right">
                     <input type="number" class="form-control " placeholder="100.00" step="0.01" v-model="invoice.amount"/>
                     <small v-show="storeinfo.passfees"><em>Processing fees: ${{fees}}</em></small>
                </div>

                <div class="col-6 my-2" >
                    &nbsp;
                </div>
                <div class="col-6 my-2" >
                    <button @click="step='2'" :disabled="!invFormValid" class="btn btn-primary btn-block">NEXT</button>
                </div>
            </div>
            <div class="row my-4" v-show="step=='2'">
                <div class="col-12 my-1">
                    <h5><strong>Billing Information</strong> <br><small><em>As shown on your credit card...</em></small></h5>
                </div>
                
                <div class="col-12 my-2">
                    <input type="text" class="form-control " placeholder="Full name" v-model="customer.fullname"/>
                </div>
                
                <div class="col-12 my-2">
                    <input type="email" class="form-control " placeholder="Email address" v-model="customer.emailaddress"/>
                </div>
                
                <div class="col-12 my-2">
                    <input type="number" class="form-control " placeholder="Mobile number" v-model="customer.mobile"/>
                </div>

                <div class="col-12 my-2" >
                    <select class="form-control" v-model="customer.country">
                        <option value="0" disabled>Address: Country</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="BB">Barbados</option>
                        <option value="JM">Jamaica</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="VG">Saint Vincent</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="US">United States of America</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div class="col-12 my-2" >
                    <input type="text" class="form-control " placeholder="Address: Street" v-model="customer.street" v-show="customer.country!='0'"/>
                </div>
                <div class="col-12 my-2" >
                    <input type="text" class="form-control " placeholder="Address: City" v-model="customer.city" v-show="customer.country!='0'"/>
                </div>
                <div class="col-lg-6 my-2" >
                    <input type="text" class="form-control " placeholder="Address: Parish / State " v-model="customer.state" v-show="customer.country!='TT' && customer.country!='0'" />
                </div>
                <div class="col-lg-6 my-2" >
                    <input type="text" class="form-control " placeholder="Address: Zip" v-model="customer.zip"  v-show="customer.country!='TT' && customer.country!='0'"/>
                </div>
                <div class="col-6 my-2" >
                    <button @click="step='1'" class="btn btn-secondary btn-block">BACK</button>
                </div>
                <div class="col-6 my-2" >
                    <button @click="step='3'" :disabled="!custFormValid" class="btn btn-primary btn-block">NEXT</button>
                </div>
            </div>

            <div class="row my-4"  v-show="step=='3'">
                <div class="col-12 my-1">
                    <h5><strong>Credit Card Details</strong> </h5>
                </div>
                <!-- credit card fields-->

                <div class="mb-4 col-12">
                    <input type="hidden" value="" name="stripeamount" id="stripeamount" v-model="stripeamount">
                    <fieldset>
                      <div class="">  
                          <label for="card-element" style="width:100%; text-align:left;" class="my-2">
                            <!--h5>Credit Card Details</h5-->
                            <p class="small"><em>This charge will appear on your credit card statment as 'SOCAISLAND* {{descriptor.toUpperCase()}}'.</em></p>
                          </label>

                          <div id="card-element" class="col-12 form-control "></div>
                      
                          <!-- Used to display form errors. -->
                          <div id="card-errors" class="my-2" role="alert" style="color:#ff0000;"> </div>
                      </div>
                    </fieldset> 
                    
                </div>

                <div class="col-6 my-2" >
                    <button @click="step='2'" class="btn btn-secondary btn-block">BACK</button>
                </div>
                <div class="col-6 my-2" >
                    <button type="button" :data-secret="clientsecret" id="card-button" class="btn btn-primary btn-block" :disabled="isbusy" @click="btnPayClick">
                          <span v-show="!isbusy">PAY NOW</span>
                          <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                    </button>
                </div>

                <!-- /credit card fields -->
                
            </div>

            <div class="my-5 mb-5" style="padding-bottom:4rem;">
                <p align="center" class="my-5">
                    <small>powered by</small><br>
                    <a href="/"><img src="../assets/orderup-logo.png" style="height:40px;" class="img-fluid"/></a>
                </p>
            </div>
        </div>
        <div class="col-lg-4"></div>
    </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'BillPay',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{

  },
  computed:{
      invFormValid(){
          if(!this.invoice.id) return false
          if(!this.invoice.currency) return false
          if(!this.invoice.amount) return false
          if(this.invoice.amount<1) return false

          return true
      },
      custFormValid(){
          if(!this.customer.fullname) return false
          if(!this.customer.emailaddress) return false
          if(!this.customer.mobile) return false
          if(!this.customer.street) return false
          if(!this.customer.city) return false

          return true
      },
      fees(){
          if(!this.invoice.amount) return '0.00'

          if(this.invoice.currency==='usd'){
              this.trxnfee =  Number(0.30)
              this.mdr = Number(1 - 0.039)
          }else if(this.invoice.currency==='ttd'){
              this.trxnfee =  Number(2.04)
              this.mdr =  Number(1 - 0.049)
          }else {
              this.trxnfee =  Number(0.80)
              this.mdr =  Number(1 - 0.049)
          }

          let amount = Number(this.invoice.amount)
          return Number(((amount + this.trxnfee)/ this.mdr ) - amount).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      stripeFees(){
          if(!this.invoice.amount) return '0.00'

          if(this.invoice.currency==='usd'){
              this.trxnfee =  Number(0.30)
              this.mdr = Number(1 - 0.039)
          }else if(this.invoice.currency==='ttd'){
              this.trxnfee =  Number(2.04)
              this.mdr =  Number(1 - 0.049)
          }else {
              this.trxnfee =  Number(0.80)
              this.mdr =  Number(1 - 0.049)
          }

          let amount = Number(this.invoice.amount)
          return Number(((amount + this.trxnfee)/ this.mdr ) - amount)
      },
      api_url(){
          return this.$store.state.api_url
      }
  },
  data(){
      return{
          trxnfee:0,
          mdr:0,
          stripeamount:0,
          descriptor:'',
          clientsecret:'',
          isloaded: false,
          storeinfo:{},
          storeid:'0',
          step:'1',
          isbusy:false,
          invoice:{
              store:'0',
              id:'',
              notes:'',
              currency:'ttd',
              amount:'',
              fees:0,
          },
          customer:{
            fullname:'',
            emailaddress:'',
            mobile:'',
            street:'',
            city:'',
            state:'',
            zip:'',
            country:0,
          },
          
      }
  },
  methods:{
      async btnPayClick(){
          this.isbusy = true

          let trxnid = Math.floor(Date.now()/1000)

          let fees = (this.storeinfo.passfees) ? parseFloat(this.stripeFees) : 0

          let amount = parseFloat((this.invoice.amount).trim())
          this.stripeamount = Math.trunc((amount + fees) * 100) 


          console.log(this.stripeamount, this.invoice.amount)

          //return false


          if(this.clientsecret==''){

              if(this.stripeamount <1) return false

              let striperesponse = await fetch(`${this.api_url}/stripe/create/${trxnid}!${this.stripeamount}!${encodeURI(this.customer.fullname)}!${encodeURI(this.storeinfo.name)}!${encodeURI(this.customer.emailaddress)}!${this.invoice.currency}!${encodeURI(this.storeinfo.name)}!${this.descriptor}`).then((response)=> {
                return response.json()
              })

              this.clientsecret = striperesponse.client_secret
              this.pytintent = striperesponse.payment_intent
          }

          if(!this.clientsecret) return false 

          this.stripe.confirmCardPayment(this.clientsecret,
              {
                payment_method: {
                card: this.card,
                billing_details: {
                    name: this.customer.fullname,
                    email: this.customer.emailaddress,
                    phone: this.customer.mobile,
                    address:{
                        city: this.customer.city,
                        country: this.customer.country,
                        line1: this.customer.street,
                        postal_code: this.customer.zip || '00000',
                        state:this.customer.state || 'NA',
                    }
                },
              },
          }).then(async (result) =>{
              if (result.error) {
                  document.getElementById('card-errors').textContent = result.error.code + " - " + result.error.decline_code
                  this.isbusy = false
              } else{
                  console.log('The payment has been processed!')

                  this.invoice.amount = Number(this.invoice.amount)
                  this.invoice.fees = fees

                  let trxn = {id: trxnid, customer: this.customer, invoice: this.invoice, lastupdate:trxnid, paymentintent:this.pytintent, fee: fees, mdr: this.mdr, storeid: this.storeid, storename: this.storeinfo.name, stripeamount:this.stripeamount, settled:false}

                  await fb.billpayCollection.add(trxn).then((doc) => {
                      this.orderdoc = doc.id
                  }).catch(err=>{
                      console.log(err)
                  }) 

                  //save customer info
                  let custid = this.customer.emailaddress.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
                  this.customer.lastupdated = moment().unix()
                  
                  await fb.storesCollection.doc(this.storeid).collection('customers').doc(custid).set(this.customer).then(()=>{
                      //console.log(doc.id)
                  })

                  //SEND EMAIL
                  await fetch(`${this.api_url}/email/billpay/${trxnid}!${encodeURI(this.storeinfo.name)}!${encodeURI(this.customer.emailaddress)}!${encodeURI(this.customer.fullname)}!${this.orderdoc}!${this.storeid}!${this.invoice.amount}!${this.invoice.currency}`).then(()=> {
                      return true
                  })

                  this.clientsecret = ''
                  this.isbusy = false 

                  //Load confirmation modal 
                  window.scrollTo(0,0)
                  this.step = '4'

              }
          })
      },
      orderComplete(){
          this.$router.push(`/${this.storeid}`)
      },
  },
  async mounted(){
      window.scrollTo(0,0)
      this.storeid = this.$route.params.storeid
      if(!this.storeid) window.location.href="https://orderuptoday.com"
      await fb.storesCollection.doc(this.storeid).onSnapshot((doc)=>{
          if(doc.exists){
              this.storeinfo = doc.data()
              this.descriptor = this.storeinfo.name.replace(/[^\w\s]/gi, '').replace(/_/gi, '')
              if(!this.storeinfo.billpay || this.storeinfo.billpay.length<1) this.invoice.store = this.storeinfo.name
          }else{
              window.location.href="https://orderuptoday.com"
          }
      })
      this.stripe = Stripe('pk_live_NO5i9A0rJetFGqZ99mLPeSpP') //pk_test_itCmziCcsavym8T71gROTOhj  pk_live_NO5i9A0rJetFGqZ99mLPeSpP
      var elements = this.stripe.elements()
      let style = {
          base: {
              color: '#32325d',
              fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '1.15em',
              '::placeholder': {
                    color: '#aab7c4'
              }
          },
          invalid: {
              color: '#fa755a',
              iconColor: '#fa755a'
          }
      }

      // Create an instance of the card Element.
      this.card = elements.create('card', {style: style})

      // Add an instance of the card Element into the `card-element` <div>.
      this.card.mount('#card-element');

      // Handle real-time validation errors from the card Element.
      let displayError = document.getElementById('card-errors')
      this.card.addEventListener('change', (event)=> {
          this.isbusy = false
          if (event.error) {
              displayError.textContent = event.error.message
          } else {
              displayError.textContent = ''
          }
      })




  }
}
</script>
