<template>
    <div class="row">
        <div class="row col-12">
            <div class="col-lg-7 my-3">
                <input type="text" class="form-control" v-model="txtsearch" placeholder="Search results..." />
            </div>
            <div class="col-lg-2 my-3" align="right">
                <div class="btn-group btn-block">
                    <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#filterform">Apply Filter</button>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"><i class="fas fa-file-csv"></i> Export .csv</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 my-2">
            <div align="center" class="my-5" v-if="orders.length<1">
                <em>Search query returned zero results...</em>
            </div>
            <!--div class="row col-12 text-white" v-if="orders.length>0">
                <div align="center" class="col-12 col-lg-3 my-2">
                    <div align="center" class="card card-body bg-primary">
                        Sales: ${{totalsales[0] | currency}}
                    </div>
                </div>
                <div align="center" class="col-12 col-lg-3 my-2">
                    <div align="center" class="card card-body bg-primary">
                        Count: {{totalsales[1]}}
                    </div>
                </div>
            </div-->
            <div class="table-responsive my-4"  v-show="orders.length>0">
                <table class="table table-bordered">
                  <thead class="thead-primary">
                  <tr>
                      <th colspan="7"><h4>Sales: ${{totalsales[0] | currency}} <span class="ml-4">Count: {{totalsales[1]}}</span></h4></th>
                  </tr>
                  <tr>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Total</th>
                      <!--th>Fees</th>
                      <th>Net</th-->
                      <th>Location</th>
                      <th>Order ID</th>
                      <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(order, index) in salesList" :key="index">
                      <td align="center">

                          <span v-if="order.data.ordertype==='pointofsale'" class="badge badge-dark1 mb-4" style="width:100px;">POS</span>

                          <span v-if="order.data.ordertype==='pickup'" class="badge badge-warning1 mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                          <span v-if="order.data.ordertype==='curbside'" class="badge badge-info1 mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                          <span v-if="order.data.ordertype==='delivery'" class="badge badge-primary1 mb-4" style="width:100px;">{{String(order.data.ordertype).toUpperCase()}}</span>

                      </td>
                      <td><small>{{order.data.id | convertdate}}</small></td>
                      <td><small><strong>{{order.data.customer.fullname}}</strong> <span v-if="order.data.ordertype != 'pointofsale'"><br> {{order.data.customer.emailaddress}} / {{order.data.customer.mobile}} <br>{{order.data.customer.carnumber}}</span></small></td>
                      <td><small>${{(order.data.ordertype=='pointofsale') ?  Number(order.data.total - order.data.fees.delivery).toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2}) : (order.data.total). toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})}}</small></td>
                      <!--td>
                          <small>Order Up Fee <em>(1.5%)</em>: </small>
                          <small><br>Credit Card Fee <em>(3.5% plus $0.99)</em>: </small>
                      </td>
                      <td>
                          ${{order.data.total | currency}}
                      </td-->
                      <td><small>{{order.data.pickuplocation.data.name}}</small></td>
                      <td>
                          <button class="btn btn-link" type="button" data-toggle="modal" data-target="#vieworder" @click="viewOrderDetails(order.data.id, order.data)">{{order.data.id}}</button>
                      </td>
                      <td>
                          <span v-if="order.data.status==='pending'" class="badge badge-warning mb-4" style="width:100px;">{{String(order.data.status).toUpperCase()}}</span>

                          <span v-if="order.data.status==='ready'" class="badge badge-success mb-4" style="width:100px;">{{String(order.data.status).toUpperCase()}}</span>

                          <span v-if="order.data.status==='complete'" class="badge badge-primary mb-4" style="width:100px;">{{String(order.data.status).toUpperCase()}}</span>
                      </td>
                  </tr>
                  </tbody>
                </table>
            </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="filterform" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Filter Sales</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.date">
                              <option value="0">Today</option>
                              <option value="7">Last 7 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="365">Last 365 days</option>
                              <option value="-1">Custom</option>
                              <!--option value="this-month">This month</option>
                              <option value="last-month">Last month</option>
                              <option value="this-year">This year</option>
                              <option value="last-year">Last year</option-->
                          </select>
                      </div>
                      <div class="row col-12 my-3" v-show="filter.date=='-1'">
                          <date-range-picker ref="picker" v-model="daterange" :singleDatePicker="false" :autoApply="true" :ranges="false" :maxDate="maxDate" @update="datePickerUpdate" class="col-12">
                              <!--template v-slot:input="picker" style="min-width: 350px;">
                                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                              </template-->
                          </date-range-picker>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.locations">
                              <option value="0">ALL Locations</option>
                              <option v-for="(location, index) in storelocations" :key="index" :value="location.id">{{location.data.name}}</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.ordertype">
                              <option value="0">ALL Order Types</option>
                              <option value="curbside">Curbside</option>
                              <option value="delivery">Delivery</option>
                              <option value="pickup">Pickup</option>
                              <option value="pointofsale">Point of Sale</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.paymenttype">
                              <option value="0">ALL Payment Types</option>
                              <option value="creditcard">Credit Card</option>
                              <option value="cashondelivery">Pay on Delivery</option>
                              <option value="cashonpickup">Pay on Pickup</option>                   
                          </select>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="applyFilters">Apply</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="vieworder" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Order - {{activeorder}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <ViewCart :singleorder="singleorder" :orderLoc="orderloc" :orderid="activeorder" :cart="cart"/>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
              </div>
            </div>
          </div>
        </div>



    </div>
</template>

<script>
import ViewCart from '@/components/ViewCart.vue'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'


const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'SalesDashboard',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, HH:mm")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart, DateRangePicker,
  },
  computed:{
      salesList(){
          let sales = this.orders
          if(!this.txtsearch) return this.orders
          const search = this.txtsearch.toLowerCase()
          const filter = function(sale){
                              return (sale.id.toLowerCase().includes(search) || sale.data.customer.fullname.toLowerCase().includes(search) || sale.data.customer.emailaddress.toLowerCase().includes(search) || sale.data.customer.mobile.toLowerCase().includes(search))
                          }
          return sales.filter(filter)
      },
      totalsales(){
          let sales = 0
          let count = 0
          let cleansales = 0
          const orders = this.orders
          const filter = (order)=>{
                      if(order.data.ordertype=='pointofsale'){
                          cleansales = Number(order.data.total - order.data.fees.delivery)
                      }else{
                          cleansales = Number(order.data.total)
                      }
                      //console.log(order.data.total, cleansales)
                      sales += Number(cleansales)
                      count++
                      return (order.id)
                  }
          orders.filter(filter)
          return [sales, count]
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          activeorder:'',
          filter:{date:'0', locations:'0', ordertype:'0', paymenttype:'0',},
          orderloc:{},
          daterange:{startDate: moment(), endDate: moment()},
          maxDate: moment().add(1,'days').format('YYYY-MM-DD'),
      }
  },
  methods:{
      datePickerUpdate(){
          //console.log("date", this.daterange)
      },
      applyFilters(){

          let filter = this.filter

          let ordersref = fb.ordersCollection.where("storeid", "==", this.storeid)

          if(filter.date != '-1') {
              let filterdate = moment().subtract(Number(filter.date),'d').startOf('day').unix()
              //console.log('date', filterdate)
              ordersref = ordersref.where("id", ">=", filterdate)
          }else{
              let startfilterdate = moment(this.daterange.startDate).startOf('day').unix()
              let endfilterdate = moment(this.daterange.endDate).endOf('day').unix()
              //console.log('date',startfilterdate,startfilterdate)
              ordersref = ordersref.where("id", ">=", startfilterdate).where("id", "<=", endfilterdate)
          }

          if(filter.locations != '0') ordersref = ordersref.where("pickuplocation.id", "==", filter.locations)

          if(filter.ordertype != '0') ordersref = ordersref.where("ordertype", "==", filter.ordertype)

          if(filter.paymenttype != '0') ordersref = ordersref.where("paymethod", "==", filter.paymenttype)

          ordersref.where("status", "in", ["complete", "pending", "ready"]).orderBy("id", "desc").onSnapshot((querySnapshot)=>{
              let j=0
              this.orders.length=0
              querySnapshot.forEach((doc)=>{
                  //console.log(doc.id)
                  this.orders.push({index:j, id:doc.id, data:doc.data()})
                  j++
              })
              if(this.orders.length<1) this.orders = []
          })


      },
      viewOrderDetails(id, order){
          this.activeorder = id
          this.singleorder = order
          this.orderloc = order.pickuplocation.data
          this.cart = order.details
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      fb.locationsCollection.where("storeid", "==", storeid).onSnapshot((querySnapshot)=>{
          let i=0
          this.storelocations.length=0
          querySnapshot.forEach((doc)=>{
              this.storelocations.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })

      let filterdate = moment().subtract(0,'d').startOf('day').unix()

      fb.ordersCollection.where("storeid", "==", storeid).where("status", "in", ["pending", "ready", "complete"]).where("id", ">=", filterdate).orderBy("id", "desc").onSnapshot((querySnapshot)=>{
          let j=0
          this.orders.length=0
          querySnapshot.forEach((doc)=>{
              this.orders.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
          if(this.orders.length<1) this.orders = []
      })
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    

</style>
