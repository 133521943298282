<template>
    <div class="row">
        <div class="row col-lg-12">
            <div class="col-lg-7 mb-3">
                <input type="text" class="form-control form-control" v-model="txtSearch" placeholder="Search vendors..." />
            </div>
            <div class="col-lg-5 mb-3" align="right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addVendor" @click="saveType='add';vendorinfo={company:'', fname:'', lname:'', email:'', mobile:'', notes:''}"><i class="fas fa-plus"></i> New Vendor</button>
            </div>
        </div>

        <div v-if="vendors.length<1" align="center" class="col-12">
            <em>Expense vendors will appear here when added...</em>
        </div>

        <div class="col-lg-12">
            <div class="table-responsive-lg my-4">
                <table class="table table-bordered" v-show="vendors.length>0">
                    <thead class="thead-primary">
                    <tr>
                        <th colspan="5" class=""><h4>Vendor Count: {{vendors.length}}</h4></th>
                    </tr>
                    <tr>
                        <th>Company</th>
                        <th>Full Name</th>
                        <th>Contact</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(vendor, index) in vendors" :key="index" class="small">
                        <td><strong>{{vendor.data.company}}</strong></td>
                        <td>{{vendor.data.fname}} {{vendor.data.lname}} <small></small></td>
                        <td>{{vendor.data.email}} / {{vendor.data.mobile}}</td>
                        <td>
                            <div class="custom-control custom-switch my-1">
                                <input type="checkbox" class="custom-control-input" :id="'vendor'+vendor.id" value="true" v-model="vendor.data.public" @change="toggleVendor(vendor.id, vendor.data.public)">
                                <label class="custom-control-label" :for="'vendor'+vendor.id">
                                    <span v-show="vendor.data.public">Disable</span>
                                    <span v-show="!vendor.data.public">Enable</span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="input-group-append">
                                <button class="btn btn-link dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Options</button>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#!" @click="saveType='edit'; activeVendor=vendor.id; vendorinfo=vendor.data" data-toggle="modal" data-target="#addVendor">Edit Vendor</a>
                                    <!--a class="dropdown-item" href="#">Deactivate vendoromer</a-->
                                    <!--a class="dropdown-item" href="#!">Delete vendoromer Data</a-->
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Modal add vendor-->
        <div class="modal fade" id="addVendor" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>New Vendor</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-2">
                          <label>*Company</label>
                          <input class="form-control" type="text" v-model="vendorinfo.company" />
                      </div>
                      <div class="col-lg-6 my-2">
                          <label>First Name</label>
                          <input class="form-control" type="text" v-model="vendorinfo.fname" />
                      </div>
                      <div class="col-lg-6 my-2">
                          <label>Last Name</label>
                          <input class="form-control" type="text" v-model="vendorinfo.lname" />
                      </div>
                      <div class="col-lg-6 my-2">
                          <label>*Email</label>
                          <input class="form-control" type="text" v-model="vendorinfo.email" />
                      </div>
                      <div class="col-lg-6 my-2">
                          <label>*Mobile</label>
                          <input class="form-control" type="number" v-model="vendorinfo.mobile" />
                      </div>
                      <div class="col-12 my-2">
                          <label>Notes</label>
                          <textarea class="form-control" rows="2" v-model="vendorinfo.notes" />
                      </div>
                      
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="saveVendorClick" :disabled="!venderFormValid">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        
    </div>
</template>

<script>

import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

const fb = require('../firebaseConfig.js')

export default {
  name: 'VendorsDashboard',
  props: {

  },
  filters: {

  },
  components:{
      
  },
  computed:{
      venderFormValid(){
          if(!this.vendorinfo.company) return false
          if(!this.vendorinfo.email) return false
          if(!this.vendorinfo.mobile) return false
          return true
      },
  },
  data(){
      return{
          vendorinfo:{company:'', fname:'', lname:'', email:'', mobile:'', notes:''},
          storeId:'', 
          categoryName:'',  
          vendors:[],
          activeCategory:'',
          activeVendorName:'',
          activeVendor:'',
          activeRef:'',
          txtSearch:'',
          isBusy:false,
          saveType:'add',
      }
  },
  methods:{
      activeItemTab(item, ref){
          this.activeitem = item
          this.activeRef = 'ref'+item
          this.activeVendor = ''
          if(item){
              this.$nextTick(() => {
                  this.$refs[item][0].$el.scrollIntoView({ behavior: 'smooth' })
              })
          }

          if(ref){
              this.$nextTick(() => {
                  this.$refs[ref][0].scrollIntoView({ behavior: 'smooth', block:'center' })
              })
          }

      },
      updateVendor(id, name, accountType, detailType, desc){
          this.activeVendor = ''
          fb.vendorsCollection.doc(id).update({
              name: name,
              accountType: accountType,
              detailType: detailType,
              desc: desc,
              date: new Date(),
          }).then(()=>{
              Vue.$toast.success('Category updated!', {position:'bottom-right'})
          })
      },
      toggleVendor(id, status){
          //console.log(id, status)
          fb.vendorsCollection.doc(id).update({
              public:status,
          }).then(()=>{
              Vue.$toast.success('Category updated!', {position:'bottom-right'})
          })
      },
      async saveVendorClick(){
          if(this.saveType==='add'){
              let smsnumber = (this.vendorinfo.mobile).replace(/\D/g, '')
              if(smsnumber.length==11) smsnumber = `${smsnumber}` 
              if(smsnumber.length==10) smsnumber = `1${smsnumber}` 
              if(smsnumber.length<10) smsnumber = `1868${smsnumber}`

              this.vendorinfo.mobile = smsnumber

              this.vendorinfo.id = Math.floor(Date.now()/1000)
              this.vendorinfo.date = new Date()
              this.vendorinfo.public = true
              this.vendorinfo.total = 0
              this.vendorinfo.storeid = this.storeId
              await fb.vendorsCollection.add(this.vendorinfo).then((doc)=>{
                  console.log(doc.id)
                  Vue.$toast.success('New vendor created!',{position:'bottom-right'})
              })
          }else if(this.saveType==='edit' && this.activeVendor){
              this.vendorinfo.date = new Date()
              await fb.vendorsCollection.doc(this.activeVendor).update(this.vendorinfo).then(()=>{
                  Vue.$toast.success('Vendor data updated!',{position:'bottom-right'})
              })
          }
      },
      deleteVendor(cat, catname){
          var r = confirm(`Delete category ${catname} and its menu items?`)
          if (r == true) {
              fb.vendorsCollection.doc(cat).delete().then(()=>{
                  Vue.$toast.success('Category and items deleted!', {position:'bottom-right'})
              })
          }
      },
  },
  async mounted(){
      this.storeId = this.$route.params.store
      let storeId = this.storeId

      await fb.storesCollection.doc(storeId).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })      

      await fb.vendorsCollection.where("storeid","==",storeId).orderBy("company", "asc").onSnapshot((querySnapshot) =>{
          let i=0
          this.vendors.length=0
          querySnapshot.forEach((doc)=>{
              this.vendors.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })  


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .pointer{cursor:pointer;}

</style>
