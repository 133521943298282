<template>
    <div class="" style="margin-bottom:100px;">
        <AdminBar />
        

        <div class="col-12 mb-5" style="padding-top:7rem;">
            <h4 class="mb-4">Account Settings</h4>
            <ul class="list-group">
                <li class="list-group-item">
                    <div v-show="activesection!='businessinfo'" @click="toggleSection('businessinfo')" class="pointer">Business Information</div>
                    <div v-show="activesection=='businessinfo'">
                        <h4 class="my-4 mb-5">Business Informaton <a style="float:right;" href="#!" @click="toggleSection('')"><small>Close <i class="fas fa-times"></i></small></a></h4>
                        <div class="row my-3">
                            <div class="col-lg-6 col-12 mb-3">
                                <input class="form-control" placeholder="Business Name" v-model="storeinfo.name"/>
                            </div>
                            <div class="col-lg-6 col-12 mb-3">
                                <input v-model="storeinfo.website" class="form-control" placeholder="Website (optional)" />
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12 col-sm-12 my-3"><span class="bold">Business Address</span></div>
                            <div class="col-lg-8 col-12 mb-3">
                                <input class="form-control" placeholder="Street"  v-model="storeinfo.address.street" />
                            </div>
                            <div class="col-lg-4 col-12 mb-3">
                                <input class="form-control" placeholder="City" v-model="storeinfo.address.city"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-4 col-12 mb-3">
                                <input type="text" class="form-control" placeholder="State" v-model="storeinfo.address.state"/>
                            </div>
                            <div class="col-lg-4 col-12 mb-3">
                                <input type="text" class="form-control" placeholder="Zip" v-model="storeinfo.address.zip"/>
                            </div>
                            <div class="col-lg-4 col-12 mb-3">
                                <input type="text" class="form-control" placeholder="Country" v-model="storeinfo.address.country" readonly/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-12 my-3"><span class="bold">Contact Information<br><small>For the person who manages the business.</small></span></div>
                            <div class="col-lg-3 col-12 mb-3">
                                <input type="text" class="form-control" placeholder="Full name" v-model="contact.fullname" />
                            </div>
                            <div class="col-lg-3 col-12 mb-3">
                                <input type="email" v-model="contact.email" class="form-control" placeholder="Email address" />
                            </div>
                            <div class="col-lg-3 col-12 mb-3">
                                <input v-model="contact.phone" type="text" step="1" class="form-control" placeholder="Mobile" />
                            </div>
                            <div class="col-lg-3 col-12 mb-3">
                                <input v-model="contact.jobtitle" type="text" class="form-control" placeholder="Job title" />
                            </div>
                            
                        </div>
                        <div align="right" class="col-12">
                            <button type="button" class="btn btn-secondary col-3 col-lg-3 mb-5 mr-4" @click="toggleSection('')">Cancel</button>
                            <button type="button" class="btn btn-primary mb-5 col-5 col-lg-3" @click="saveBusinessInfo">Save Information</button>
                        </div>
                    </div>

                </li>
                <li class="list-group-item ">
                   <div v-show="activesection!='custompage'" @click="toggleSection('custompage')" class="pointer">Custom Page Settings</div>

                    <div  v-show="activesection=='custompage'">
                        <h4 class="my-4 mb-5">Custom Page Settings <a style="float:right;" href="#!" @click="toggleSection('')"><small>Close <i class="fas fa-times"></i></small></a></h4>

                        

                        <div class="row mb-3">
                            <div class="col-lg-6 col-12 mb-3">
                                <p align="center" class="mb-4">
                                    <small>Current Logo:</small><br>
                                    <img :src="currentlogo" style="width:100px; height:100px; border-radius:50%;"/>
                                </p>
                                <p align="center">
                                    <small>Current Cover Photo:</small><br>
                                    <img :src="currentcoverphoto" style="width:80%; height:auto;"/>
                                </p>
                            </div>
                            <div class="col-lg-6 col-12 mb-2">
                                <p><strong>Edit business logo</strong></p>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                        <input type="file" accept="image/png, image/jpeg" class="custom-file-input" id="logo" @change="cropLogo($event)">
                                        <label class="custom-file-label" for="logo">Choose file for logo</label>
                                    </div>
                                    <a href="#!" id="showsettingimgmodal" data-toggle="modal" data-target="#updatesettingsphoto"></a>
                                </div>
                                <p><strong>Edit cover photo</strong></p>
                                <div class="input-group mb-3">
                                    <div class="custom-file">
                                        <input type="file" accept="image/png, image/jpeg" class="custom-file-input" id="coverphoto" @change="cropCoverPhoto($event)">
                                        <label class="custom-file-label" for="coverphoto">Choose file for cover photo</label>
                                    </div>

                                    
                                </div>
                                <p><strong>Choose your theme color</strong></p>
                                <ul class="list-group my-1">
                                    <li class="list-group-item my-1">
                                        <div class="row">
                                            <div class="col-8">Black</div>
                                            <div class="col-2" style="background:#000000; "></div>
                                            <div class="col-2" align="right">
                                                <input type="radio" name="themecolor" class="form-check-input" @change="savePageSettings" value="#000000" v-model="storeinfo.pagesettings.themecolor" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <!--ul class="list-group my-1">
                                    <li class="list-group-item my-1">
                                        <div class="row">
                                            <div class="col-8">Blue</div>
                                            <div class="col-2" style="background:#007bff; "></div>
                                            <div class="col-2" align="right">
                                                <input type="radio" name="themecolor" class="form-check-input" @change="savePageSettings" value="#007bff" v-model="storeinfo.pagesettings.themecolor"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                
                                <ul class="list-group my-1">
                                    <li class="list-group-item my-1">
                                        <div class="row">
                                            <div class="col-8">Red</div>
                                            <div class="col-2" style="background:#dc3545; "></div>
                                            <div class="col-2" align="right">
                                                <input type="radio" name="themecolor" class="form-check-input"  @change="savePageSettings" value="#dc3545" v-model="storeinfo.pagesettings.themecolor"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="list-group my-1">
                                    <li class="list-group-item my-3">
                                        <div class="row">
                                            <div class="col-8">Orange</div>
                                            <div class="col-2" style="background:#ffc107; "></div>
                                            <div class="col-2" align="right">
                                                <input type="radio" name="themecolor" class="form-check-input" @change="savePageSettings" value="#ffc107" v-model="storeinfo.pagesettings.themecolor"/>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                
                                <ul class="list-group my-1">
                                    <li class="list-group-item my-3">
                                        <div class="row">
                                            <div class="col-8"><input class="form-control" placeholder="Custom" /></div>
                                            <div class="col-2" style="background:#ffffff; "></div>
                                            <div class="col-2" align="right">
                                                <input type="radio" name="themecolor" class="form-check-input" />
                                            </div>
                                        </div>
                                    </li>
                                </ul-->
                                <!--div class=" mb-3">
                                    <p><strong>Menu Category Layout</strong></p>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="menulayout" value="grid" @change="savePageSettings" v-model="storeinfo.pagesettings.menulayout">
                                        <label class="form-check-label">Grid</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="menulayout" value="list" @change="savePageSettings" v-model="storeinfo.pagesettings.menulayout">
                                        <label class="form-check-label">List</label>
                                    </div>
                                </div-->
                            </div>     
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div v-show="activesection!='bankinfo'" @click="toggleSection('bankinfo')" class="pointer">Company Banking Information</div>

                    <div v-show="activesection=='bankinfo'">
                        <h4 class="my-4 mb-5">Company Banking Information <a style="float:right;" href="#!" @click="toggleSection('')"><small>Close <i class="fas fa-times"></i></small></a></h4>

                        <div class="row mb-3">
                            <div class="col-12 mb-3">
                                <p><em>Please contact us at support@orderuptoday.com for more information.</em></p>
                                
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div v-show="activesection!='onlinepayment'" @click="toggleSection('onlinepayment')" class="pointer">Online Payments Integration</div>

                    <div v-show="activesection=='onlinepayment'">
                        <h4 class="my-4 mb-5">Online Payments Integration <a style="float:right;" href="#!" @click="toggleSection('')"><small>Close <i class="fas fa-times"></i></small></a></h4>

                        <div class="row mb-3">
                            <div class="col-12 mb-3">
                                <p><em>Please contact us at support@orderuptoday.com for more information.</em></p>
                                
                            </div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div v-show="activesection!='posintegration'" @click="toggleSection('posintegration')" class="pointer">POS Integration</div>

                    <div v-show="activesection=='posintegration'">
                        <h4 class="my-4 mb-5">POS Integrations <a style="float:right;" href="#!" @click="toggleSection('')"><small>Close <i class="fas fa-times"></i></small></a></h4>
                        <p><em>Please contact us at support@orderuptoday.com for more information.</em></p>
                    </div>
                </li>
            </ul>
        </div>



        <!-- Modal view order-->
        <div class="modal fade" id="updatesettingsphoto" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>Crop Image</strong></h5>
                <button id="closeupdatesettingsphoto" type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearFiles">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 15px; width:100%; height:300px;">
                  <div align="center" class="col-12">
                      <img :src="imageurl" ref="source" style="width:100%; max-height:300px;"/>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-secondary btn-block mb-3"  data-dismiss="modal" aria-label="Close" @click="clearFiles">CANCEL <i class="fas fa-times-right ml-3"></i></button>
                  </div>
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-primary btn-block mb-3" :disabled="isbusy" v-if="uploadtype==='coverphoto'" @click="saveCoverPhoto">
                          <span v-show="!isbusy">SAVE IMAGE<i class="fas fa-chevron-right ml-3"></i></span>
                          <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                      </button>

                      <button type="button" class="btn btn-primary btn-block mb-3" :disabled="isbusy" v-if="uploadtype==='logo'" @click="saveLogo">
                          <span v-show="!isbusy">SAVE LOGO<i class="fas fa-chevron-right ml-3"></i></span>
                          <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                      </button>
                  </div>
              </div>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import AdminBar from "@/components/AdminBar.vue"
import VueToast from 'vue-toast-notification'
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
const fb = require('../firebaseConfig.js')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'Settings',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{
        AdminBar,
  },
  computed:{
      
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          activesection:'',
          contact:{},
          ordermanagement:{receiveorders:{}, deliveroptions:{}, paymentoptions:{}},
          logo: null,
          coverphoto: null,
          cropper: null,
          hasfile:false,
          coverphotourl: null,
          logourl: null,
          imageurl: null,
          uploadtype: null,
          isbusy: false,
          currentlogo:'',
          currentcoverphoto:'',
      }
  },
  methods:{
      toggleSection(section){
          this.activesection = section
      },
      saveBusinessInfo(){
          fb.storesCollection.doc(this.storeid).update(this.storeinfo).then(()=>{
             Vue.$toast.open('Business info saved!') 
          })

          fb.storesCollection.doc(`${this.storeid}/contact/${this.storeid}`).update(this.contact).then(()=>{
             Vue.$toast.open('Contact info saved!') 
          })
      },
      savePageSettings(){
          fb.storesCollection.doc(this.storeid).update({pagesettings:this.storeinfo.pagesettings}).then(()=>{
             Vue.$toast.open('Custom page settings saved!') 
          })
          //alert(this.storeinfo.pagesettings.menulayout)
      },
      saveOrdermanagement(){
          fb.storesCollection.doc(this.storeid).update({
              deliveryoptions: this.storeinfo.deliveryoptions,
              paymentoptions: this.storeinfo.paymentoptions,
              receiveorders: this.storeinfo.receiveorders,
          }).then(()=>{
             Vue.$toast.open('Order management info saved!') 
          })
      },
      cropCoverPhoto(event){
          //event.target.files
          if(this.cropper)  this.cropper.destroy()
          if (!event.target.files || !event.target.files[0]) {
              this.cropper = null
              this.imageurl = null
              return false
          }

          if (this.imageurl) {
              window.URL.revokeObjectURL(this.imageurl)
          }

          this.imageurl = window.URL.createObjectURL(event.target.files[0])

          this.$nextTick(()=>{
              document.getElementById("showsettingimgmodal").click()
              this.uploadtype = 'coverphoto'
              this.cropper = new Cropper(this.$refs.source, {
                  aspectRatio: 16/9,
                  type:3,
                  minContainerWidth:600,
                  minContainerHeight:300,
                  crop: ()=> {
                      //console.log(e.detail.x);
                      //console.log(e.detail.y);
                  }
              })
          })
      },
      cropLogo(event){
          //event.target.files
          if(this.cropper)  this.cropper.destroy()
          if (!event.target.files || !event.target.files[0]) {
              this.cropper = null
              this.imageurl = null
              return false
          }

          if (this.imageurl) {
              window.URL.revokeObjectURL(this.imageurl)
          }

          this.imageurl = window.URL.createObjectURL(event.target.files[0])

          this.$nextTick(()=>{
              document.getElementById("showsettingimgmodal").click()
              this.uploadtype = 'logo'
              this.cropper = new Cropper(this.$refs.source, {
                  aspectRatio: 1,
                  type:3,
                  minContainerWidth:600,
                  minContainerHeight:300,
                  crop: ()=> {
                      //console.log(e.detail.x);
                     // console.log(e.detail.y);
                  }
              })
          })
      },
      saveCoverPhoto(){
          this.isbusy = true

          let options = {
              width: 1150,
              height: 250,
              minWidth: 1150,
              minHeight: 250,
              //maxWidth: 250,
              //maxHeight: 250,
              fillColor: '#fff',
              //imageSmoothingEnabled: true,
              //imageSmoothingQuality: 'low',
          }

          this.cropper.getCroppedCanvas(options).toBlob((blob) => {
              let logoupload = fb.storageRef.child(`${this.storeid}/logo/coverphoto.jpg`).put(blob)

              logoupload.on('state_changed', ()=>{

              },(error)=>{
                  // Handle unsuccessful uploads
                  console.log(error)
              },()=>{

                  logoupload.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                      
                      fb.storesCollection.doc(this.storeid).update({
                          'pagesettings.coverphoto': downloadURL
                      }).then(()=>{
                          console.log('File available at', downloadURL)
                          this.currentcoverphoto = downloadURL
                          document.getElementById('closeupdatesettingsphoto').click()
                          this.isbusy = false
                      }).catch((err)=>{
                          console.log(err)
                      })
                  })
              })
          }, 'image/jpeg', 0.4)
      },
      saveLogo(){
          this.isbusy = true

          let options = {
              width: 250,
              height: 250,
              minWidth: 250,
              minHeight: 250,
              //maxWidth: 250,
              //maxHeight: 250,
              fillColor: '#fff',
              imageSmoothingEnabled: true,
              imageSmoothingQuality: 'high',
          }

          this.cropper.getCroppedCanvas(options).toBlob((blob) => {
              let logoupload = fb.storageRef.child(`${this.storeid}/logo/logo.jpg`).put(blob)

              logoupload.on('state_changed', ()=>{

              },(error)=>{
                  // Handle unsuccessful uploads
                  console.log(error)
              },()=>{

                  logoupload.snapshot.ref.getDownloadURL().then((downloadURL)=> {
                      
                      fb.storesCollection.doc(this.storeid).update({
                          'pagesettings.logo': downloadURL
                      }).then(()=>{
                          console.log('File available at', downloadURL)
                          this.currentlogo = downloadURL
                          document.getElementById('closeupdatesettingsphoto').click()
                          this.isbusy = false
                      }).catch((err)=>{
                          console.log(err)
                      })
                  })
              })
          })

      },
      clearFiles(){
          document.getElementById('logo').value=''
          document.getElementById('coverphoto').value=''
      },
      signOut(){
          this.$store.dispatch('logout')
      },
  },
  mounted(){
      //let $this = this
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
              this.currentlogo = doc.data().pagesettings.logo
              this.currentcoverphoto = doc.data().pagesettings.coverphoto
          } else {
              console.log("No such document!")
          }
      })

      fb.storesCollection.doc(`${storeid}/contact/${storeid}`).onSnapshot((doc) =>{
          if (doc.exists) {
              this.contact = doc.data()
          } else {
              console.log("No such document contact!")
          }
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
