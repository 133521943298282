<template>
    <div class="row">
        <div class="col-lg-3">

        </div>
        <div class="col-lg-6">
            
            <ul class="list-group" v-if="order.status">
                <li class="list-group-item active bg-dark">
                    <h3 align="center">Status: <strong>{{order.status.toUpperCase()}}</strong></h3>
                </li>
                <li class="list-group-item">
                    <div align="left" class="row">
                        <div class="col-6">
                            <h4 align="left"><strong>{{storeName}}</strong>, <span v-if="order.pickuplocation.data.address">{{order.pickuplocation.data.name}}</span></h4>

                            <p align="left"><span class="small">{{order.id | convertdate}}
                            </span><br><span class="badge badge-dark">Order for {{order.ordertype | capitalize}} {{(order.customer && order.customer.carnumber) ? '- ' + order.customer.carnumber.toUpperCase() : ''}}  #{{order.id}}</span></p>

                            <p class="modal-title">
                                {{(order.customer)? order.customer.fullname : ''}} 

                                <br>Tel: <a :href="(order.customer) ? 'tel:+'+order.customer.mobile : ''">{{(order.customer) ? order.customer.mobile : ''}}</a>

                            </p>

                            <p class="modal-title my-2" v-if="order.customer && order.customer.address"><a :href="`http://www.google.com/maps/place/${order.customer.geo}`"  target="map" class="btn-link">{{(order.customer)? order.customer.address : ''}} <i class="fas fa-directions"></i></a></p>
                        </div>
                        <div class="col-6" align="right">
                            <button type="button" id="printbutton" class="btn btn-secondary  my-4"  data-dismiss="modal1" aria-label="Close1" @click="btnPrintClick">Print Order <i class="fas fa-print"></i></button>
                        </div>
                        
                    </div>
                </li>
                <li class="list-group-item bg-danger text-white">
                    {{order.notes}}
                </li>
                <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                    <div class="row">
                        <div class="col-2"><h1 style="font-size:30px;">{{item.qty}}</h1></div>
                        <div class="col-6">
                            {{item.item}}
                            <span style="font-size:15px; color:#777;" v-for="(option, i) in item.modifiers" :key="i">
                                <br><em v-if="option.name!='None'"> - {{option.name}} <span v-if="option.price>0"> (${{option.price}})</span></em>
                            </span>
                        </div>
                        <div class="col-4" align="right">${{item.subtotal | currency}}</div>
                        <div class="col-12 bg-danger text-white" v-if="item.notes">
                            <div class="my-1"><em>{{item.notes}}</em></div>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-12" align="right"><span class="badge badge-dark">{{cartCount}} item(s)</span></div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-8" align="right">Subtotal</div>
                        <div class="col-4" align="right">${{cartTotal | currency}}</div>
                    </div>
                </li>
                <li class="list-group-item" v-if="order.pickuplocation && order.pickuplocation.data.fees.tax">
                    <div class="row">
                        <div class="col-8" align="right">Taxes <em class="small">({{(order.pickuplocation) ? order.pickuplocation.data.fees.tax : '0'}}%)</em></div>
                        <div class="col-4" align="right">${{orderTaxes | currency}}</div>
                    </div>
                </li>

                <li class="list-group-item" v-if="order.pickuplocation && order.pickuplocation.data.fees.service">
                    <div class="row">
                        <div class="col-8" align="right">Service Charge <em class="small">({{(order.pickuplocation.data.fees) ? order.pickuplocation.data.fees.service : '0'}}%)</em></div>
                        <div class="col-4" align="right">${{orderService | currency}}</div>
                    </div>
                </li>
                
                <li class="list-group-item" v-if="order.pickuplocation && order.pickuplocation.data.fees.delivery && order.ordertype=='delivery'">
                    <div class="row">
                        <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                        <div class="col-4" align="right">${{orderDelivery | currency}}</div>
                    </div>
                </li>
                
                <li class="list-group-item">
                    <div class="row">
                        <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                        <div class="col-4" align="right"><h5><strong>${{orderTotal | currency}}</strong></h5></div>
                    </div>
                </li>
                <li class="list-group-item" v-if="order.drivertip>0">
                    <div class="row">
                        <div class="col-8" align="right">Tip</div>
                        <div class="col-4" align="right">${{order.drivertip | currency}}</div>
                    </div>
                </li>
                <li class="list-group-item" v-if="order.needCashChange===true">
                    <div class="row">
                        <div class="col-12" align="center"><em>Customer needs to receive change.</em></div>
                    </div>
                </li>
            </ul>
              
        </div>

        <div class="col-lg-3">

        </div>
    </div>
</template>

<script>
const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'ViewOrder',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY, h:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{

  },
  computed:{
      orderTotal(){
          return (this.cartTotal + this.orderTaxes + this.orderService + this.orderDelivery)
      },
      orderDelivery(){
          return (this.order.pickuplocation && this.order.ordertype==='delivery') ? this.order.pickuplocation.data.fees.delivery : 0
      },
      orderService(){
          let fee = (this.order.pickuplocation) ? this.order.pickuplocation.data.fees.service/100 : 0
          return Number(this.cartTotal) * fee 
      },
      orderTaxes(){
          let fee = (this.order.pickuplocation) ? this.order.pickuplocation.data.fees.tax/100 : 0
          return Number(this.cartTotal) * fee 
      },
      cartTotal(){
          let total = 0
          for(var i=0; i<this.cart.length; i++){
              total += Number(this.cart[i].subtotal)
          }

          return total
      },
      cartCount(){
          let count = 0
          for(var i=0; i<this.cart.length; i++){
              count += Number(this.cart[i].qty)
          }

          return count
      },
  },
  data(){
      return{
        cart:{},
        order:{},
        storeName:'',
      }
  },
  methods:{
      btnArriveClick(){

      },

      btnPrintClick(){
          window.print()
      },
      
  },
  async mounted(){
      let orderid = this.$route.params.orderid
      let storeId = this.$route.params.store

      await fb.storesCollection.doc(storeId).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeName = doc.data().name
          } else {
              console.log("No such document!")
          }
      })

      await fb.ordersCollection.doc(orderid).get().then((doc)=>{
          if(doc.exists){
              console.log(orderid)
              this.order = doc.data()
              this.cart = doc.data().details
          }else{
              window.location.href = '/'
          }
      })

      window.scrollTo(0,0)
  }
}
</script>

