<template>
    <div class="row">
        <div class="row col-12">
            <div class="col-lg-8 my-3">
                <input type="text" class="form-control" v-model="txtsearch" placeholder="Search results..." />
            </div>
            <div class="col-lg-4 my-3" align="right">
                <div class="btn-group mr-2">
                    <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#filterExpenseForm">Apply Filter</button>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"><i class="fas fa-file-csv"></i> Export .csv</a>
                    </div>
                </div>

                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addNewExpense"><i class="fas fa-plus"></i> New Expense</button>

            </div>
        </div>
        <div class="col-12 my-2">
            <div align="center" class="my-5" v-if="expenseList.length<1">
                <em>Search query returned zero results...</em>
            </div>
            <div class="table-responsive my-4"  v-show="expenseList.length>0">
                <table class="table table-bordered small">
                  <thead class="thead-primary">
                  <tr>
                      <th colspan="7"><h4>Expenses: ${{totalExpenses[0] | currency}} <span class="ml-4">Count: {{totalExpenses[1]}}</span></h4></th>
                  </tr>
                  <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Payee</th>
                      <th>Category</th>
                      <th>Total</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(expense, index) in expenseList" :key="index">
                      <td>
                          {{expense.data.paymentDate | convertdate}}
                      </td>
                      <td>
                          Expense
                      </td>
                      <td>
                          {{expense.data.payee.name.toUpperCase()}}
                      </td>
                      <td>
                          {{expense.data.lineCatName}}
                      </td>
                      <td align="right">
                          ${{expense.data.total | currency}}
                      </td>
                      <td>
                          
                      </td>
                  </tr>
                  </tbody>
                </table>
            </div>
        </div>



        <!-- Modal -->
        <div class="modal fade" id="filterExpenseForm" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Filter Expenses</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.date">
                              <option value="0">Today</option>
                              <option value="7">Last 7 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="365">Last 365 days</option>
                              <option value="-1">Custom</option>
                              <!--option value="this-month">This month</option>
                              <option value="last-month">Last month</option>
                              <option value="this-year">This year</option>
                              <option value="last-year">Last year</option-->
                          </select>
                      </div>
                      <div class="row col-12 my-3" v-show="filter.date=='-1'">
                          <date-range-picker ref="picker" v-model="daterange" :singleDatePicker="false" :autoApply="true" :ranges="false" :maxDate="maxDate" @update="datePickerUpdate" class="col-12">
                          </date-range-picker>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.payee">
                              <option value="0">ALL Payees</option>
                              <option v-for="(vendor, index) in vendors" :key="index" :value="vendor.id">{{vendor.data.company}}</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.category">
                              <option value="0">ALL Expense Categories</option>
                              <option v-for="(cat, index) in expenseCategory" :key="index" :value="cat.id">{{cat.data.name}}</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.payment">
                              <option value="0">ALL Payment Types</option>
                              <option value="cash">Cash</option>
                              <option value="cheque">Cheque</option>
                              <option value="creditcard">Credit Card</option>
                              <option value="bank">Bank Transfer</option>
                          </select>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="applyFilters">Apply</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNewExpense" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>New Expense</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-2">
                          <label>Payee</label>
                          <select class="form-control" v-model="paymentVendor">
                              <option value="0" disabled>- - Select Payee - -</option>
                              <option v-for="(vendor, index) in vendors" :key="index" :value="{id:vendor.id, name:vendor.data.company}">{{vendor.data.company}}</option>
                          </select>
                      </div>
                      <div class="col-lg-5 my-2">
                          <label>Payment Date</label>
                          <date-range-picker ref="picker" v-model="paymentDate" :ranges="false" :singleDatePicker="true" :autoApply="true" :maxDate="maxDate" class="row col-12"/>
                      </div>
                      <div class="col-lg-5 my-2">
                          <label>Payment Method</label>
                          <select class="form-control" v-model="paymentType">
                              <option value="cash">Cash</option>
                              <option value="cheque">Cheque</option>
                              <option value="creditcard">Credit Card</option>
                              <option value="bank">Bank Transfer</option>
                          </select>
                      </div>
                      <div class="col-12 my-2">
                          <div class="table-responsive my-4">
                              <table class="table table-bordered">
                                  <thead class="thead-primary">
                                      <tr>
                                          <th align="center">#</th>
                                          <th>Category</th>
                                          <th>Description</th>
                                          <th>Amount</th>
                                          <th></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="(item, index) in lineItemGroup" :key="index">
                                          <td align="center">{{index+1}}</td>
                                          <td>
                                              <select class="form-control" v-model="lineItemCategory[index]">
                                                  <option value="0" disabled selected>Choose category</option>
                                                  <option v-for="(cat, index) in expenseCategory" :key="index" :value="{id:cat.id, name:cat.data.name}">{{cat.data.name}}</option>
                                              </select>
                                          </td>
                                          <td>
                                              <textarea rows="1" wrap="hard" class="form-control" placeholder="What did you pay for?" v-model="lineItemDesc[index]" />
                                          </td>
                                          <td width="18%">
                                              <input type="number" class="form-control" step="0.01" min="0.01" v-model="lineItemAmount[index]" />
                                          </td>
                                          <td><i class="fas fa-trash" v-show="lineItemGroup.length>1" @click="removeLineItem(index)"></i></td>
                                      </tr>
                                  </tbody>
                              </table>
                              <div class="row">
                                  <div class="col-lg-7">
                                       <button class="btn btn-primary mr-2" @click="lineItemGroup.push(1)"><i class="fas fa-plus mr-2"></i>Add Line</button>
                                       <button class="btn btn-outline-primary" @click="clearItems">Clear All Lines</button>
                                  </div>
                                  <div class="col-lg-5 bold" align="right">
                                       Total: ${{newExpenseTotal}}
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 my-2">
                              <label>Memo <em>(optional)</em></label>
                              <textarea rows="3" wrap="hard" class="form-control" v-model="paymentMemo" />
                          </div>
                          
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-lg-6">
                      <button type="button" class="btn btn-secondary btn-block" data-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                  <div align="right" class="col-lg-6">
                      <button type="button" class="btn btn-primary btn-block" :disabled="!expenseFormValid || isBusy" @click="saveExpense">Save</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
//import ViewCart from '@/components/ViewCart.vue'
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

Vue.use(VueToast)

const fb = require('../firebaseConfig.js')
var moment = require('moment')
export default {
  name: 'ExpensesDashboard',
  props: {

  },
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD, YYYY")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      DateRangePicker,
  },
  computed:{
      expenseFormValid(){
          if(this.paymentVendor=='0') return false
          for(var i=0; i<this.lineItemGroup.length; i++){
              if(!this.lineItemCategory[i]) return false
              if(!this.lineItemDesc[i]) return false
              if(!this.lineItemAmount[i]) return false
          }
          return true
      },
      expenseList(){
          let expenses = this.expenses
          if(!this.txtsearch) return this.expenses
          const search = this.txtsearch.toLowerCase()
          const filter = function(expense){
                              return (expense.id.toLowerCase().includes(search) || expense.data.payee.name.toLowerCase().includes(search))
                          }
          return expenses.filter(filter)
      },
      newExpenseTotal(){
          let total = this.lineItemAmount.reduce((a, b) => (Number(a) + Number(b)), 0)
          return total.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      totalExpenses(){
          let total = 0
          let count = 0
          const expenses = this.expenses
          const filter = (expense)=>{
                      total += Number(expense.data.total)
                      count++
                      return (expense.id)
                  }
          expenses.filter(filter)
          return [total, count]
      },
  },
  data(){
      return{
          isBusy:false,
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveexpenses:{},},
          storelocations:[],
          storeId:'',
          expenses:[],
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          activeorder:'',
          filter:{date:'0', payee:'0', category:'0', payment:'0',},
          orderloc:{},
          daterange:{startDate: moment(), endDate: moment()},
          maxDate: moment().add(1,'days').format('YYYY-MM-DD'),
          vendors:[],
          expenseCategory:[],
          lineItemCategory:[],
          lineItemAmount:[],
          lineItemDesc:[],
          lineItemGroup:[1],
          paymentVendor:'0',
          paymentType:'cash',
          paymentDate: {startDate: moment(), endDate: moment()},
          paymentMemo:'',
      }
  },
  methods:{
      datePickerUpdate(){
          //console.log("date", this.daterange)
      },
      async saveExpense(){
          let postDate = moment().unix()
          let paymentDate = moment(this.paymentDate.startDate).unix()
          let lineItems = []
          let lineCat = []
          let lineCatName = []
          let expenseTotal = 0

          for(var i=0; i<this.lineItemGroup.length; i++){
              lineItems = [...lineItems, {category: this.lineItemCategory[i], desc: this.lineItemDesc[i], amount:Number(this.lineItemAmount[i])}]
              expenseTotal += Number(this.lineItemAmount[i])
              lineCat = [...lineCat, this.lineItemCategory[i].id]
              lineCatName = [...lineCatName, this.lineItemCategory[i].name]
          }

          let expenseId = await fb.expensesCollection.add({
                              billType: 'Expense',
                              postDate: postDate,
                              paymentDate: paymentDate,
                              total: expenseTotal,
                              payee: this.paymentVendor,
                              paymentType: this.paymentType,
                              lineItems: lineItems,
                              lineCat: lineCat,
                              lineCatName: lineCatName,
                              memo: this.paymentMemo,
                              storeid: this.storeId,
                              status: 'complete'
                          }).then((doc)=>{
                              return doc.id
                          })

          //console.log(expenseId)

          for(var j=0; j<lineItems.length; j++){
              lineItems[j].postDate = postDate
              lineItems[j].paymentDate = paymentDate
              lineItems[j].paymentType = this.paymentType
              lineItems[j].payee = this.paymentVendor
              lineItems[j].expenseId = expenseId
              lineItems[j].storeid = this.storeId
              lineItems[j].billType = 'Expense'

              await fb.expenseItemsCollection.add(lineItems[j]).then(()=>{})
              await fb.expenseCategoryCollection.doc(lineItems[j].category.id).update({
                  total: fb.firebase.firestore.FieldValue.increment(lineItems[j].amount)
              }).then(()=>{})

          }

          this.lineItemCategory = []
          this.lineItemAmount = []
          this.lineItemDesc = []
          this.lineItemGroup = [1]
          this.paymentVendor = '0'

          Vue.$toast.success('New expense added', {position: 'bottom-right'})
      },
      removeLineItem(index){
          this.lineItemCategory.splice(index,1)
          this.lineItemAmount.splice(index,1)
          this.lineItemDesc.splice(index,1)
          this.lineItemGroup.splice(index,1)
      },
      clearItems(){
          this.lineItemCategory = []
          this.lineItemAmount = []
          this.lineItemDesc = []
          this.lineItemGroup = [1]
      },
      applyFilters(){

          let filter = this.filter
          let expensesref = fb.expensesCollection.where("storeid", "==", this.storeId)

          if(filter.date != '-1') {
              let filterdate = moment().subtract(Number(filter.date),'d').startOf('day').unix()
              //console.log('date', filterdate)
              expensesref = expensesref.where("paymentDate", ">=", filterdate)
          }else{
              let startfilterdate = moment(this.daterange.startDate).startOf('day').unix()
              let endfilterdate = moment(this.daterange.endDate).endOf('day').unix()
              //console.log('date',startfilterdate,startfilterdate)
              expensesref = expensesref.where("id", ">=", startfilterdate).where("paymentDate", "<=", endfilterdate)
          }

          if(filter.payee != '0') expensesref = expensesref.where("payee.id", "==", filter.payee)

          if(filter.category != '0') expensesref = expensesref.where("lineCat", "array-contains", filter.category)

          if(filter.payment != '0') expensesref = expensesref.where("paymentType", "==", filter.payment)

          expensesref.where("status", "in", ["complete"]).orderBy("paymentDate", "desc").onSnapshot((querySnapshot)=>{
              let j=0
              this.expenses.length=0
              querySnapshot.forEach((doc)=>{
                  //console.log(doc.id)
                  this.expenses.push({index:j, id:doc.id, data:doc.data()})
                  j++
              })
              if(this.expenses.length<1) this.expenses = []
          })


      },
      viewOrderDetails(id, order){
          this.activeorder = id
          this.singleorder = order
          this.orderloc = order.pickuplocation.data
          this.cart = order.details
      },
  },
  async mounted(){
      const storeId = this.$route.params.store
      this.storeId = storeId

      await fb.storesCollection.doc(storeId).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      await fb.locationsCollection.where("storeid", "==", storeId).onSnapshot((querySnapshot)=>{
          let i=0
          this.storelocations.length=0
          querySnapshot.forEach((doc)=>{
              this.storelocations.push({index:i, id:doc.id, data:doc.data()})
              i++
          })
      })

      await fb.vendorsCollection.where("storeid", "==", storeId).onSnapshot((querySnapshot)=>{
          let k=0
          this.vendors.length=0
          querySnapshot.forEach((doc)=>{
              this.vendors.push({index:k, id:doc.id, data:doc.data()})
              k++
          })
          if(this.vendors.length<1) this.vendors = []
      })

      await fb.expenseCategoryCollection.where("storeid", "==", storeId).orderBy("name", "asc").onSnapshot((querySnapshot)=>{
          let l=0
          this.expenseCategory.length=0
          querySnapshot.forEach((doc)=>{
              this.expenseCategory.push({index:l, id:doc.id, data:doc.data()})
              l++
          })
          if(this.expenseCategory.length<1) this.expenseCategory = []
      })

      let filterdate = moment().subtract(0,'d').startOf('day').unix()

      await fb.expensesCollection.where("storeid", "==", storeId).where("paymentDate", ">=", filterdate).orderBy("paymentDate", "desc").onSnapshot((querySnapshot)=>{
          let j=0
          this.expenses.length=0
          querySnapshot.forEach((doc)=>{
              this.expenses.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
          if(this.expenses.length<1) this.expenses = []
      })

      
      
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    

</style>
