<template>
    <div class="row" style="padding:3rem 0; margin:0;">   

        <div class="col-lg-4">

        </div>
        <div class="col-lg-4" v-show="showlogin">
           <!--  <div class="" align="center" v-if="!$store.state.isMobile">
                <img src="../assets/orderup-logo.png" class="img-fluid my-4" style="height: 70px; width:auto;" />
            </div> -->

            <ul class="list-group mb-3" style="padding:30px 0;">
                <li class="list-group-item no-border">
                    <p align="center" class=" my-3">Sign in to your account.</p>
                    <p class="my-3 text-danger" align="center" v-if="loginerror">Please check your login information and try again.</p>
                    <input type="email" class="form-control my-3" placeholder="Enter your email address" @input="login.email=$event.target.value" @focus="loginerror=false" />
                    <input type="password" class="form-control" placeholder="Enter your password" v-model="login.password" @focus="loginerror=false" />
                    <p align="right" class="small"><a href="#!" class="text-dark" data-toggle="modal" data-target="#forgotpassword">Forgot password?</a></p>
                    <button type="button" class="btn btn-dark btn-block my-4" :disabled="!loginValid || isbusy" @click="btnLoginClick">
                        <span v-show="!isbusy">Sign in</span>
                        <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                    </button>
                    
                </li>
            </ul>
            <p align="center" class="my-1" v-if="!$store.state.isMobile"><a href="/signup" class="btn btn-link text-dark">No account? Create one.</a></p>
        </div>
        <div class="col-lg-4">
        </div>

        <!-- Modal view order-->
        <div class="modal fade" id="forgotpassword" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4><strong>Reset Password</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 25px;">

          <div class="col-12">
            <label>We will email a password reset link to the email address on record for your username. <a href="mailto:support@orderuptoday.com">Contact Support</a></label>
            <input type="email" class="form-control mb-3"  placeholder="Your email address" @input="resetemail=$event.target.value" />
            <span class="my-5 text-danger">{{reseterror}}</span>
          </div>
                  
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-6">
                      <button type="button" class="btn btn-dark btn-block"  data-dismiss="modal" aria-label="Close" @click="resetPasswordClick()" :disabled="!resetFormValid">Continue <i class="fas fa-chevron-right"></i></button>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>

    </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
const fb = require('../firebaseConfig.js')

export default {
  name: 'Login',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{
      
  },
  computed:{
      loginValid(){
          if(!this.login.email) return false
          if(!this.login.password) return false
          if(this.login.password.length<8) return false
          return true
      },
      resetFormValid(){
          if(!this.resetemail) return false
          return true
      }

  },
  data(){
      return{
          isbusy:false,
          login:{
              email:'',
              password:'',
          },
          loginerror: false,
          resetemail:'',
          reseterror:'',
          showlogin:false,
      }
  },
  methods:{
      btnLoginClick(){
          this.isbusy = true
          fb.auth.signInWithEmailAndPassword(this.login.email, this.login.password).then(user=>{
              let userdata = user.user
              this.$store.commit('setCurrentUser', userdata)
              fb.storesCollection.where("user", "==", userdata.uid).get().then(doc=>{
                  let storeid = doc.docs[0].id
                    if(typeof this.$route.query.from !== "undefined"){
                        let decoded_uri = decodeURIComponent(this.$route.query.from)
                        console.log(decoded_uri)
                        location.href = decoded_uri
                    }else{
                        location.href = `/${storeid}/dashboard`
                    }
              }).catch(()=>{
                    this.isbusy = false
                    if(typeof this.$route.query.from !== "undefined"){
                        let decoded_uri = decodeURIComponent(this.$route.query.from)
                        console.log(decoded_uri)
                        location.href = decoded_uri
                    }else{
                        location.href = `/cruzlife/feed`
                    }
              })

          }).catch(()=> {
              this.loginerror = true
              this.isbusy = false
          })
      },
      resetPasswordClick(){
          fb.auth.sendPasswordResetEmail(this.resetemail).then(()=> {
              Vue.$toast.open('Password reset email sent!')
          }).catch(function(error) {
              this.reseterror = "Error... please check email address and try again! For support: support@orderuptoday.com"
          });
      },
  },
  mounted(){
      let user = fb.auth.currentUser

      if (user) {
          fb.storesCollection.where("user", "==", user.uid).get().then(doc=>{
              let storeid = doc.docs[0].id
              location.href = `/${storeid}/dashboard`
          }).catch(()=>{

          })
      } else {
        this.showlogin = true
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
