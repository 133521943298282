<template>
    <div class="row mb-5">
        <AppHeader title="About Santa Cruz" v-if="!$store.state.isMobile"/>
        <div class="col-12 my-4" v-if="!$store.state.isMobile"> 
            &nbsp;
        </div>
        <div class="col-12 my-3">
            <nav>
                <div class="mobile nav nav-tabs" id="nav-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-info-tab" data-toggle="tab" href="#nav-info" role="tab" aria-controls="nav-info" aria-selected="true">Info</a>
                    <a class="nav-item nav-link" id="nav-contacts-tab" data-toggle="tab" href="#nav-contacts" role="tab" aria-controls="nav-contacts" aria-selected="false">Contacts</a>
                </div>
            </nav>
        </div>
        <div>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">
                    <div class="col-12 my-3"> 
                        <ul class="list-group card-alt">
                            <li class="list-group-item no-border">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <p >
                                            <small class="text-muted my-2 bold">About Santa Cruz</small>
                                        </p>
                                        <div class="card-img-container">
                                            <img class="img-fluid rounded" src="https://firebasestorage.googleapis.com/v0/b/order-up-app-tt.appspot.com/o/santacruzhub%2Fgreenmarket.jpg?alt=media" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8">
                                        
                                        <p class="my-2">
                                            Santa Cruz is a town in Trinidad and Tobago. It extends across the Santa Cruz Valley, between Maraval and San Juan, along the Saddle Road. It lies between the hills of the Northern Range, a mountain range.
                                        </p>
                                        
                                        <p v-if="readMore">
                                            Formerly an important cocoa bean producer, much of the valley consists of abandoned cocoa plantations or small-scale agriculture. The Santa Cruz Valley is considered prime residential real estate, and many upscale residential developments have sprouted in the valley. Some of the small neighbourhoods that form part of Upper Santa Cruz are Saddle Grove, Cangrejal, Jagan village, Hololo Road, Paxvale, Cantaro, Graceland Heights, Gasparillo, Sam Boucaud, La Pastora Settlement, Soconusco Settlement and Pipiol. Lower Santa Cruz consists of La Canoa and Bourg Mulatresse, commonly referred to as "Book" in local vernacular.
                                        </p>

                                        <p align="right" v-if="!readMore">
                                            <button class="btn btn-outline-danger btn-sm" @click="readMoreClick(true)">Read more ...</button>
                                        </p>
                                        
                                        <p v-if="readMore">
                                            Santa Cruz is home for a few primary and secondary schools. Some primary schools include the La Pastora Government Primary School, Santa Cruz Presbyterian School, Cantaro Roman Catholic School and Bourg Mulatresse Primary School. San Juan North Secondary School is also situated in the Lower Santa Cruz Valley and it was constructed along the mountain side in Bourg Mulatresse near the Bourg Mulatresse Primary School. 
                                        </p>
                                        <p v-if="readMore">
                                            There are two postal delivery offices and fuel stations in Bourg Mulatresse and Cantaro. Santa Cruz is also home of a police station and a fire station. The Santa Cruz Fire Station houses the Land Search and Rescue Team, which responds to all search and rescue calls within the Northern Division; automobile accidents, fire emergencies, North Coast Road mishaps and assisting EMTs in responding to medical emergencies beyond road access.
                                        </p>
                                        <p align="right" v-if="readMore">
                                            <button class="btn btn-outline-danger btn-sm" @click="readMoreClick(false)">Read less ...</button>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 my-4"> 
                        <ul class="list-group card-alt">
                            <li class="list-group-item no-border">
                                <small class="text-muted bold">Member of Parliament</small>
                            </li>
                            <li class="list-group-item no-border">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="card-img-container">
                                            <img class="img-fluid rounded" src="https://firebasestorage.googleapis.com/v0/b/order-up-app-tt.appspot.com/o/santacruzhub%2Fsantacruzmp.jpg?alt=media" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8" >
                                        <h5 class="my-3">
                                            The Honourable Dr. Nyan Gadsby-Dolly, 
                                            <small>Member for St. Ann's East</small>
                                        </h5>
                                        <p>
                                            <span class="pointer" @click="infoActionClick({action: 'phone', data: '18686749077'})"><small>Phone:</small> (868) 674-9077 <br/></span>
                                            <span class="pointer" @click="infoActionClick({action: 'whatsapp', data: '18687545819'})"><small>Mobile:</small> (868) 754-5819 <em class="text-muted">WhatsApp Only</em> <br/></span>
                                            <span class="pointer" @click="infoActionClick({action: 'whatsapp', data: '18686816926'})"><small>Mobile:</small> (868) 681-6926  <em class="text-muted">WhatsApp Only</em> <br/></span>
                                        </p>
                                        <p>
                                            <span class="pointer" @click="infoActionClick({action: 'email', data: 'stannseastconstituency2@outlook.com'})"><small>Email:</small> <br/>stannseastconstituency2@outlook.com </span>
                                        </p>
                                        <p>
                                            <small>Address:</small> <br/>
                                            48 Saddle Road San Juan
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 my-4"> 
                        <ul class="list-group card-alt">
                            <li class="list-group-item no-border">
                                <small class="text-muted bold">San Juan Laventille Regional Corporation</small>
                            </li>
                            <li class="list-group-item no-border">
                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="card-img-container">
                                            <img class="img-fluid rounded" src="https://firebasestorage.googleapis.com/v0/b/order-up-app-tt.appspot.com/o/santacruzhub%2Fslrc.png?alt=media" />
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-8" >
                                        <h5 class="my-3">
                                            <small>Chairman:<br/></small>
                                            Alderman Anthony Roberts    
                                        </h5>
                                        <h5 class="my-3">
                                            <small>C.E.O.:<br/></small>
                                            Mrs. Dianne Lakan    
                                        </h5>
                                        <p>
                                            <span class="pointer" @click="infoActionClick({action: 'phone', data: '18686750255'})"><small>Phone:</small> (868) 675-0255/0280 ext:112 <br/></span>
                                            <span class="pointer" @click="infoActionClick({action: 'phone', data: '18686381073'})"><small>Phone:</small> (868) 638-1073 <br/></span>
                                        </p>
                                        <p>
                                            <span class="pointer" @click="infoActionClick({action: 'email', data: 'sjlrc.ceo@gmail.com'})"><small>Email:</small> sjlrc.ceo@gmail.com </span>
                                        </p>
                                        <p>
                                            <small>Address:</small> <br/>
                                            MTS Plaza, Aranguez Main Road, Aranguez, San Juan
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="tab-pane fade" id="nav-contacts" role="tabpanel" aria-labelledby="nav-contacts-tab">
                    <div class="col-12 my-2"> 
                            <ul class="list-group card-alt list-striped my-4">
                                <li class="list-group-item no-border">
                                    <small class="text-muted bold">Councillors</small>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Mr. Sudhir Sagramsingh <small><br/>Maracas, Santa Cruz and La Fillete</small>
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18687219470'})">(868) 721-9470</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Mr. Corey Selvon <small><br/>Febeau/Bourg Mulatresse</small>
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18683815411'})">(868) 381-5411</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ul class="list-group card-alt list-striped">
                                <li class="list-group-item no-border">
                                    <small class="text-muted bold">Emergency Contacts</small>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Santa Cruz Police Station
                                        </div>
                                        <div class="col-auto" align="right" >
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18686768888'})">(868) 676-8888</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Santa Cruz Fire Sub-station
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18686760414'})">(868) 676-0414</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Santa Cruz Heath Center
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18686767400'})">(868) 676-7400</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Office of Disaster Preparedness and Management (ODPM)
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '18007572'})">800-7572</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item no-border">
                                    <div class="row">
                                        <div class="col">
                                            Global Medical Response of Trinidad and Tobago
                                        </div>
                                        <div class="col-auto" align="right">
                                            <a class="pointer" @click="infoActionClick({action: 'phone', data: '811'})">811</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import AppHeader from "@/components/AppHeader.vue"
    var oWebViewInterface = window.nsWebViewInterface
    export default {
        name: 'CruzLifeInfo',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            AppHeader,
        },
        computed:{
              
        },
        data(){
            return{
                readMore:false,
            }
        },
        methods:{
            readMoreClick(val){
                this.readMore = val
                window.scrollTo(0,0)
            },
            infoActionClick(data){
                if(this.isMobile){
                    oWebViewInterface.emit('infoActionClick', data)
                }else{
                    switch(data.action){
                        case "phone":
                            location.href=`tel:+${data.data}`
                        break;
                        case "email":
                            location.href=`mailto:${data.data}`
                        break;
                        case "website":
                            location.href=`http://${data.data}`
                        break;
                        case "whatsapp":
                            location.href=`https://wa.me/${data.data}`
                        break;
                    }
                }
            }
        },
        mounted(){
            window.scrollTo(0,0)
        }
    }
</script>

