<template>
    <div class="row">
        
        <div class="row col-lg-12">
            <div class="col-lg-8 my-1">
                <h2 class="mb-4 pointer" @click="$emit('active-item', itemref)">{{item.name}}</h2>
                
                <div class="row">
                    <div class="col-5">
                        <input class="form-control my-3" type="text" v-model="item.name" placeholder="Item name"/>
                    </div>
                    <div class="col-4" align="right">
                        <select class="form-control my-3" v-model="item.section">
                            <option v-for="(cat, index) in menuSections" :key="index" :value="cat.id">{{cat.data.name}}</option>
                        </select>
                    </div>
                    <div class="col-3" align="right">
                        <input class="form-control my-3" type="number" step="0.01"  v-model="item.price" placeholder="Item price"/>
                    </div>
                </div>
            
                <textarea rows="2" class="form-control my-3" v-model="item.desc" placeholder="Item description (optional)"></textarea>
                <small style="float:right;">/1000</small>
            </div>

            <div clas="col-lg-4">
                <div class="card-body">
                    <small v-if="item.photo"><a href="#!" @click="removeImage(item.photo, itemid)">Remove Image <i class="fas fa-times"></i></a></small>
                    <div class="pointer" style="width:200px; height:200px; background-color:#f1f1f1;" @click="$emit('upload-image', itemid)">
                        <small style="float:left; z-index:1000; margin-top:100px; margin-left:20px; padding:5px; background-color:rgba(255,255,255,0.5);">Click to add/edit image</small>
                        <img :src="item.photo" style="float:left; margin-top:-132px; height:200px; width:auto; border-radius:1rem;" v-if="item.photo"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-lg-12">

            <!-- modifiers -->
            <div class="row col-lg-12 my-5">
                <div class="col-lg-5">
                    <h4>Variations</h4>
                    <p class="small">What options are available with this item?</p>
                </div>
                <div class="col-lg-7" align="right">
                    <button type="button" class="btn btn-outline-primary btn-sm mr-2" data-toggle="modal" data-target="#addmodifier" @click="newVariationClick()">+ New Variation</button>

                    <button type="button" class="btn btn-outline-primary btn-sm" data-toggle="modal" data-target="#exitingmodifier">+ Existing Variation</button>
                </div>

                <!--- list modifiers -->
                <div class="col-lg-12">
                    <ul class="list-group">
                        <div v-for="(modifier, index) in modifiers" :key="index">
                        <li class="list-group-item my-1" v-show="item.modifiers.includes(modifier.id)">
                              <div class="row">
                                  <div class="col-11 pointer" @click="newVariationClick(modifier)" data-toggle="modal" data-target="#addmodifier">
                                      <h5>{{modifier.data.name}} <small v-if="modifier.data.required"><em>[Required]</em></small></h5>
                                      <span v-for="(option, index) in modifieritems" :key="index">
                                          <small v-if="option.data.section==modifier.id">{{option.data.name}}, </small>
                                      </span>
                                  </div>
                                  <div class="col-1" align="right">
                                      <a href="#!" class=" btn btn-link" style="float:right;"><i class="fas fa-times" @click="removeModifierClick(modifier.id, itemid)"></i></a>
                                  </div>
                              </div>
                        </li>
                        </div>
                    </ul>
                </div>
            </div>

            <!-- buttons -->
            <div class="col-lg-12 my-4" align="right">
                <button type="button" class="col-3 col-lg-3 btn btn-secondary mr-2" @click="$emit('active-item', itemref)">Close</button>
                <button type="button" class="col-3 col-lg-3 btn btn-primary" @click="updateItem(itemid)">Save</button>
            </div>
        </div>
        




        <!-- Modal new modifier -->
        <div class="modal fade" id="addmodifier" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title" v-if="!modifiereditmode"><strong>Create New Variation Group</strong></h4>
                <h4 class="modal-title" v-if="modifiereditmode"><strong>Edit Variation Group - {{variation.name}}</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      
                      <div class="col-lg-5 my-3" align="right">
                          <input class="form-control" type="text" placeholder="Variation name" v-model="variation.name"/>
                          <small>/255</small>
                      </div>
                      <div class="col-lg-3 my-3">
                          <select class="form-control" v-model="variation.required">
                              <option :value="false">Optional</option>
                              <option :value="true">Required</option>
                          </select>
                      </div>
                      <div class="col-lg-4 my-3" align="right">
                          <select class="form-control" v-model="variation.multiselect">
                              <option :value="false">Select one option</option>
                              <option :value="true">Select multiple options</option>
                          </select>
                      </div>
                      
                      
                      <div class="col-lg-12 my-3">
                          <h4>Variation options</h4>
                      </div>

                      <div class="row col-12 mb-2" v-for="(option, index) in optiongroup.length" :key="index">
                          <div class="col-lg-8 col-8" align="right">
                              <input type="hidden" v-model="optionid[index]" />
                              <input type="text" class="form-control" placeholder="Option label" v-model="optionlabel[index]"/>
                              <small>/255</small>
                          </div>
                          <div class="col-lg-3 col-3">
                              <input type="number" step="0.01" class="form-control" placeholder="Option cost" v-model="optioncost[index]"/>
                          </div>
                          <div class="col-lg-1 col-1">
                              <a class="btn btn-link" href="#!" @click="removeOption(index)"><i class="fas fa-times" style="font-size:20px;"></i></a>
                          </div>
                      </div>
                      
                      <div class="row col-12 mb-2">
                          <div class="col-12">
                            <button type="button" class="btn btn-outline-primary"  @click="optiongroup.push(1)"><i class="fas fa-plus"></i> Add another option</button>
                          </div>
                      </div>

                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Cancel</button>
                      
                      <button v-if="!modifiereditmode" type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" :disabled="!variationFormValid" @click="saveVariationTitle">Save</button>

                      <button v-if="modifiereditmode" type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" :disabled="!variationFormValid" @click="updateVariationTitle(variation.id)">Update</button>
                  </div>
              </div>
            </div>
          </div>
        </div>


        


        <!-- Modal existing modifier item-->
        <div class="modal fade" id="exitingmodifier" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Choose Variation Groups</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:30px 60px;">
                  <div class="row mb-3">
                      <div class="col-12 mb-5">
                          <input type="text" class="form-control" placeholder="Search variations..." v-model="txtsearch" />
                      </div>
                      
                      <div class="col-12 form-group form-check ml-5" v-for="(cat, index) in searchModifiers" :key="index">
                          <input type="checkbox" class="form-check-input modifier my-2" style="font-size:30px;" :id="'check'+cat.id" value="true" :checked="item.modifiers.includes(cat.id)" @change="toggleVariation(cat.id, item.modifiers.includes(cat.id))">

                          <label class="form-check-label ml-3" :for="'check'+cat.id" style="line-height:1.3rem;">
                              {{cat.data.name}}<br>
                              <span v-for="(option, index) in modifieritems" :key="index">
                                  <small v-if="option.data.section==cat.id">
                                      <em>{{option.data.name}},</em>
                                  </small>
                              </span>
                          </label>
                      </div>

                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-primary col-3 col-lg-3 mr-1" data-dismiss="modal" aria-label="Close">Close</button>
                      <!--button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" :disabled="!variationFormValid" @click="saveVariationTitle">Save</button-->
                  </div>
              </div>
            </div>
          </div>
        </div>


        
    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
//import 'cropperjs/dist/cropper.css'
//import Cropper from 'cropperjs'

Vue.use(VueToast, {
  // One of the options
  position: 'top-right',
  type: 'success'
})

const fb = require('../firebaseConfig.js')

export default {
  name: 'EditItem',
  props: ['itemid', 'itemref'],
  filters: {
      currency: function (value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  components:{
      
  },
  computed:{
      variationFormValid(){
          if(!this.variation.name) return false
          if(this.optiongroup.length<1) return false
          if(!this.optionlabel[0]) return false
          return true
      },
      searchModifiers(){
          let modifiers = this.modifiers
          if(!this.txtsearch) return this.modifiers

          const search = this.txtsearch.toLowerCase()

          let filter = function(modifier){
                          return modifier.data.name.toLowerCase().includes(search)
                      }

          return modifiers.filter(filter)
      },
      menuSections(){
          return this.$store.state.menusections
      },
  },
  data(){
      return{
          storeid:'',
          items:[],
          item:{},
          modifier:[],
          modifiers:[],
          modifieritems:[],
          optiongroup:[1],
          optionlabel:[],
          optioncost:[],
          optionid:[],
          optionstodelete:[],
          variation:{required:true, multiselect:false, type:'modifier', visible:true, public:true},
          txtsearch:'',
          modifiereditmode:true,
       }
  },
  methods:{
      updateItem(){
          if(!this.item.desc) this.item.desc = " "
          fb.itemsCollection.doc(this.itemid).update({
                  name: this.item.name,
                  section: this.item.section,
                  price: Number(this.item.price),
                  desc: this.item.desc,
              }).then(()=>{
                  Vue.$toast.open('Menu item updated!')
                  this.$emit('active-item', this.itemref)
              })
      },
      toggleVariation(cat, val){
          if(val===true){
              fb.itemsCollection.doc(this.itemid).update({
                  modifiers:fb.firebase.firestore.FieldValue.arrayRemove(cat),
              }).then(()=>{
                  Vue.$toast.open('Variation removed from item!')
              })
          }else{
              fb.itemsCollection.doc(this.itemid).update({
                  modifiers:fb.firebase.firestore.FieldValue.arrayUnion(cat),
              }).then(()=>{
                  Vue.$toast.open('Variation added to item!')
              })
          }
      },
      newVariationClick(modifier){
          if(modifier){
              this.modifiereditmode = true
              this.variation = modifier.data
              this.variation.id = modifier.id
              this.optiongroup = []
              this.optionlabel = []
              this.optioncost = []
              this.optionid = []
              this.optionstodelete = []
              for(var i=0; i < this.modifieritems.length; i++){
                  if(this.modifieritems[i].data.section == modifier.id){
                      //console.log(j, this.modifieritems[i].data.section, modifier.id)
                      this.optiongroup.push(1)
                      this.optionlabel.push(this.modifieritems[i].data.name)
                      this.optioncost.push(this.modifieritems[i].data.price)
                      this.optionid.push(this.modifieritems[i].id)
                  }
              }

          }else{
              this.modifiereditmode = false
              this.optiongroup = [1]
              this.optionlabel = []
              this.optioncost = []
              this.variation = {}
              this.variation = {required:true, multiselect:false, type:'modifier', visible:true, public:true}
          }
      },
      removeModifierClick(modifier, item){
          fb.itemsCollection.doc(item).update({
              modifiers:fb.firebase.firestore.FieldValue.arrayRemove(modifier),
          }).then(()=>{
              Vue.$toast.open('Variation removed from item!')
          })
      },
      removeOption(index){
          if(this.modifiereditmode){
              this.optionstodelete.push(this.optionid[index])
              //console.log(this.optionstodelete)
          }
          this.optionlabel.splice(index,1)
          this.optioncost.splice(index,1)
          this.optionid.splice(index,1)
          this.optiongroup.splice(index,1)
      },
      saveVariationTitle(){
          //console.log(this.variation)
          this.variation.storeid = this.storeid
          fb.categoryCollection.add(this.variation).then((doc)=>{
              Vue.$toast.open('New variation created!')
              this.saveItemModifier(doc.id)
              this.saveVariationItems(doc.id)
          })
      },
      updateVariationTitle(modifier){
          this.variation.lastupdate = new Date()
          fb.categoryCollection.doc(modifier).update(this.variation).then(()=>{
              Vue.$toast.open('Variation saved!')
          })

          //edit variation options
          for(var i=0; i < this.optiongroup.length; i++){
              if(this.optionid[i]){
                  //let docid = this.optionid[i]
                  if(!this.optioncost[i]) this.optioncost[i] = 0
                  fb.itemsCollection.doc(this.optionid[i]).update({
                      name: this.optionlabel[i],
                      price: Number(this.optioncost[i]),
                  }).then(()=>{
                      Vue.$toast.open('Variation option saved!')
                  })
              }else{
                  fb.itemsCollection.add({
                      section: modifier,
                      type: 'modifier',
                      price: Number(this.optioncost[i]),
                      public: true,
                      name: this.optionlabel[i],
                      storeid: this.storeid,
                  }).then(()=>{
                      //console.log("modifier item", doc.id)
                      Vue.$toast.open('Variation option created!')
                  })
              }
          }

          //delete options
          for(var j=0; j < this.optionstodelete.length; j++){
              fb.itemsCollection.doc(this.optionstodelete[j]).delete().then(()=>{
                  Vue.$toast.open('Variation option deleted!')
              })
          }


      },
      saveItemModifier(modifier){
          fb.itemsCollection.doc(this.itemid).update({
              modifiers:fb.firebase.firestore.FieldValue.arrayUnion(modifier),
          }).then(()=>{
              Vue.$toast.open('Variation added to item!')
          })
      },
      saveVariationItems(modifier){
          for(var i=0; i<this.optiongroup.length; i++){
              if(!this.optionlabel[i]) continue
              if(!this.optioncost[i]) this.optioncost[i] = 0

              fb.itemsCollection.add({
                  section:modifier,
                  type: 'modifier',
                  price: Number(this.optioncost[i]),
                  public:true,
                  name:this.optionlabel[i],
                  storeid: this.storeid,
              }).then(()=>{
                  //console.log("modifier item", doc.id)
                  Vue.$toast.open('Variation item created!')
              })
          }
      },
      removeImage(itemimg, itemid){
          let r = confirm(`Remove photo from item - ${itemid}?`)
          if(r==true){
              fb.storageRef.child(`${this.storeid}/menu/${itemid}.jpg`).delete().then(()=> {
                  fb.itemsCollection.doc(itemid).update({
                      photo:''
                  }).then(()=>{
                      Vue.$toast.open('Image removed from item!')
                  })

              }).catch((error)=> {
                  console.log(error)
              })
          }
      },
  },
  mounted(){
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })

      fb.itemsCollection.doc(this.itemid).onSnapshot((doc)=>{
          if (doc.exists) {
              this.item = doc.data()
          } else {
              console.log("No such document!")
          }
      })


      fb.categoryCollection.where("storeid", "==", storeid).where("type", "==", "modifier").orderBy("name", "asc").onSnapshot((querySnapshot)=>{
          let j=0
          this.modifiers.length=0
          querySnapshot.forEach((doc)=>{
              this.modifiers.push({index:j, id:doc.id, data:doc.data()})
              j++
          })

          //console.log(this.modifiers)
      })

      fb.itemsCollection.where("storeid", "==", storeid).where("type", "==", "modifier").orderBy("name", "asc").onSnapshot((querySnapshot)=>{
          let j=0
          this.modifieritems.length=0
          querySnapshot.forEach((doc)=>{
              this.modifieritems.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
      })

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

    .vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .fixed-header{
        padding:20px 20px;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    .clip-text{
        white-space: nowrap; 
        width: 100%; 
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a:hover, .btn-link{
        text-decoration:none !important;
    }

    body{
        font-size:18px;
    }

    input[type="checkbox"].modifier{
        transform: scale(1.5);
    }

</style>
