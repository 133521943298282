<template>
    <div>
        <div class="row">
            <div class="btn-group card-body col-lg-6" role="group" >
                <button type="button" class="btn" :class="(activetab=='menuitems') ? 'btn-primary' : 'btn-outline-secondary' " @click="activeTab('menuitems')">Menu Items</button>

                <button type="button" class="btn" :class="(activetab=='modifiers') ? 'btn-primary' : 'btn-outline-secondary' " @click="activeTab('modifiers')">Variations</button>

                <button type="button" class="btn" :class="(activetab=='unavailable') ? 'btn-primary' : 'btn-outline-secondary' " @click="activeTab('unavailable')">Unavailable Items</button>
            </div>
            <div class="card-body col-12">
                <input type="text" class="form-control"  @input="txtsearch=$event.target.value" placeholder="Search items..."  />
            </div>

            <div class="card-body" v-show="activetab=='menuitems'">
                <h4 class="mb-2">Menu Items</h4>

                <p class="my-5" v-if="menusections.length<1"><em>Unavailable categories will appear here...</em></p>


                <ul class="list-group my-4" v-for="(section, index) in menusections" :key="index">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8">
                                <h5><strong>{{section.data.name}}</strong></h5>
                            </div>
                            <div class="col-4" align="right">
                                <div class="custom-control custom-switch my-1">
                                    <input type="checkbox" class="custom-control-input" :id="'section'+section.id" value="true" :checked="!unavailablelist.includes(section.id)" @change="toggleItem(section, unavailablelist.includes(section.id))">
                                    <label class="custom-control-label" :for="'section'+section.id">
                                        <span v-show="!unavailablelist.includes(section.id)">Hide</span>
                                        <span v-show="unavailablelist.includes(section.id)">Show</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item" v-for="(item, index) in catitems(section.id)" :key="index">
                        <div class="row">
                            <div class="col-8">
                                <p >{{item.data.name}}</p>
                            </div>
                            <div class="col-4" align="right">
                                <div class="custom-control custom-switch my-1">
                                    <input type="checkbox" class="custom-control-input" :id="'item'+item.id" value="true" :checked="!unavailablelist.includes(item.id)" @change="toggleItem(item, unavailablelist.includes(item.id))">
                                    <label class="custom-control-label" :for="'item'+item.id">
                                        <span v-show="!unavailablelist.includes(item.id)">Hide</span>
                                        <span v-show="unavailablelist.includes(item.id)">Show</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="card-body" v-show="activetab=='modifiers'">
                <h4 class="mb-2">Variation Items</h4>

                <p class="my-5" v-if="modifiers.length<1"><em>Variation items will appear here...</em></p>


                <ul class="list-group my-4" v-for="(section, index) in modifiers" :key="index">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8">
                                <h5><strong>{{section.data.name}}</strong></h5>
                            </div>
                            <div class="col-4" align="right">
                                <div class="custom-control custom-switch my-1">
                                    <input type="checkbox" class="custom-control-input" :id="'section'+section.id" value="true" :checked="!unavailablelist.includes(section.id)" @change="toggleItem(section, unavailablelist.includes(section.id))">
                                    <label class="custom-control-label" :for="'section'+section.id">
                                        <span v-show="!unavailablelist.includes(section.id)">Hide</span>
                                        <span v-show="unavailablelist.includes(section.id)">Show</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item" v-for="(item, index) in moditems(section.id)" :key="index">
                        <div class="row">
                            <div class="col-8">
                                <p >{{item.data.name}}</p>
                            </div>
                            <div class="col-4" align="right">
                                <div class="custom-control custom-switch my-1">
                                    <input type="checkbox" class="custom-control-input" :id="'item'+item.id" value="true" :checked="!unavailablelist.includes(item.id)" @change="toggleItem(item, unavailablelist.includes(item.id))">
                                    <label class="custom-control-label" :for="'item'+item.id">
                                        <span v-show="!unavailablelist.includes(item.id)">Hide</span>
                                        <span v-show="unavailablelist.includes(item.id)">Show</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="card-body" v-show="activetab=='unavailable'">
                <h4 class="mb-2">Unavailable Items</h4>

                <p class="my-5" v-if="unavailableitems.length<1"><em>Unavailable items will appear here...</em></p>

                <ul class="list-group my-5">
                    <li class="list-group-item" v-for="(item, index) in unavailableitems" :key="index">
                        <div class="row">
                            <div class="col-8">
                                <p >{{item.data.name}}</p>
                            </div>
                            <div class="col-4" align="right">
                                <button type="button" class="btn btn-primary" @click="restoreItem(item.id)">Restore Item</button>
                            </div>
                        </div>
                    </li>
                </ul>
                
            </div>

        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import VueToast from 'vue-toast-notification'

var moment = require('moment')

const fb = require('../firebaseConfig.js')

Vue.use(VueToast, {
  // One of the options
  position: 'bottom-right',
  type:'success',
  duration:1500
})


export default {
  name: 'QuickMenu',
  props: ['location'],
  filters: {
      currency(value) {
        if (!value) return ''
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD - hh:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      
  },
  computed:{
      menusections(){
          return this.$store.state.menusections
      },
      menuitems(){
          return this.$store.state.menuitems
      },
      modifiers(){
          return this.$store.state.modifiers
      },
      modifieritems(){
          return this.$store.state.modifieritems
      },
      unavailableitems(){
          let search = ''
          if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
          const items = this.unavailable
          const filter = function(item){
                             return (item.data.name.toLowerCase().includes(search))
                          }

          return items.filter(filter)
      },
  },
  data(){
      return{
          txtsearch:'',
          activetab:'menuitems',
          unavailable:[],
          unavailablelist:[],
          storeid:'',
      }
  },
  methods:{
      catitems(catid){
          let search = ''
          if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
          const items = this.menuitems
          const filter = function(item){
                              return (item.data.section===catid && item.data.name.toLowerCase().includes(search))
                          }

          return items.filter(filter)
      },
      moditems(catid){
          let search = ''
          if(this.txtsearch.length>3) search = this.txtsearch.toLowerCase()
          const items = this.modifieritems
          const filter = function(item){
                              return (item.data.section===catid && item.data.name.toLowerCase().includes(search))
                          }

          return items.filter(filter)
      },
      activeTab(tab){
          this.activetab = tab
          this.txtsearch=''
      },
      changeItemStatus(id,status){
          var r = confirm("Change menu item online status?");
          if (r == true) {
              fb.menuitemsCollection.doc(String(id)).update({
                  public: status,
              })
              .then(()=> {
                  Vue.$toast.open('Menu item status saved!')
              })
          }
      },
      restoreItem(item){
          var r = confirm("Restore this item?");
          if (r == true) {
             fb.unavailableCollection.doc(item).delete()
             Vue.$toast.open("Item updated!")
          }
      },
      toggleItem(item, val){
          //console.log(item, val)
          if(val === false){
              fb.itemsCollection.where("storeid", "==", this.storeid).where('name','==', item.data.name).get().then((docs)=>{
                  docs.forEach((doc)=> {
                      fb.unavailableCollection.add({
                          id: doc.id,
                          location: this.location.id,
                          type: item.data.type,
                          name: item.data.name,
                          storeid: this.storeid,
                      }).then(()=>{
                         Vue.$toast.open("Item updated!")
                      })
                  })
              })
          }else if(val === true){
              fb.unavailableCollection.where("storeid", "==", this.storeid).where("name", "==", item.data.name).get().then((querySnapshot)=>{
                  querySnapshot.forEach((doc)=> {
                      doc.ref.delete()
                      Vue.$toast.open("Item updated!")
                  })
              })
          }
      },
  },
  mounted(){
      const storeid = this.$route.params.store
      this.storeid = storeid

      fb.unavailableCollection.where("storeid", "==", storeid).where("location", "==", this.location.id).onSnapshot(querySnapshot=>{
          this.unavailable.length = 0
          this.unavailablelist.length = 0
          querySnapshot.forEach(doc=>{
              this.unavailable.push({id:doc.id, data:doc.data()})
              this.unavailablelist.push(doc.data().id)
          })

          if(this.unavailable.length<1) this.unavailable = []
          if(this.unavailablelist.length<1) this.unavailablelist = []
      })

      //console.log((this.unavailablelist.includes('0qxQi1d2iFuJz9PQdDVp')))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>



</style>
