<template>
    <div>
        <div class="row app-footer" v-show="cart.length>0">
            <div class="col-12 " :class="(isIOS=='true') ? 'mb-ios' : ''">
                <button type="button" class="btn btn-dark btn-block btn-lg1" @click="btnCartClick" data-toggle="modal" data-target="#ordercart"  v-if="locationActive">VIEW ORDER:  {{cartCount}} ITEM(S) - ${{cartTotal | currency}} </button>
                <p class="text-danger" align="center" v-if="!locationActive"><em>{{inactiveLocationMsg}}</em></p>
            </div>
        </div>
        <!-- Modal view order-->
        <div class="modal fade" id="ordercart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border-bottom:0;">
                <h5 class="modal-title"><strong>{{(orderType=='pickup') ? 'Pick Up' : orderType | capitalize}} Order</strong></h5>
                <button id="footermodal" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                  <div align="center" class="my-5" v-show="cart.length<1">
                      <em>Your cart is empty...</em>
                  </div>
                  <ul class="list-group list-group-flush" v-show="cart.length>0">
                      <li class="list-group-item">
                          <div >
                              <h4 align="center"><strong>{{storeInfo.name}}</strong></h4>
                              <p align="center">
                                <span v-if="orderLoc.data">{{orderLoc.data.name}}<br>{{orderLoc.data.address.street}}, {{orderLoc.data.address.city}}  </span>
                              </p>
                          </div>
                      </li>
                      <li class="list-group-item" v-for="(item, index) in cart" :key="index">
                          <div class="row">
                              <div class="col-auto"><h1 class="bold">{{item.qty}}</h1></div>
                              <div class="col-5">
                                  <h5>
                                        {{item.item}}
                                        <span class="small" v-for="(option, i) in item.modifiers" :key="i">
                                            <em v-if="option.name!='None'"><hr size="1" class="no-margin" />  {{option.name}} <span v-if="option.price>0"> (add ${{option.price}})</span></em>
                                        </span>
                                  </h5>
                              </div>
                              <div class="col-auto" align="right"><a href="#!" class="btn-link text-secondary" @click="delCartItem(index)" ><i class="fas fa-trash-alt"></i></a></div>
                              <div class="col-3 h5" align="right">${{item.subtotal | currency}}</div>
                              <div class="col-12" v-if="item.notes">
                                    <div class="alert alert-info">
                                        {{item.notes}}
                                    </div>
                              </div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-12" align="right"><span class="badge badge-dark">{{cartCount}} item(s)</span></div>
                          </div>
                      </li>
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right">Subtotal</div>
                              <div class="col-4" align="right">${{cartTotal | currency}}</div>
                          </div>
                      </li>
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.tax">
                          <div class="row">
                              <div class="col-8" align="right">Taxes <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.tax : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderTaxes | currency}}</div>
                          </div>
                      </li>

                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.service">
                          <div class="row">
                              <div class="col-8" align="right">Service Charge <em class="small">({{(orderLoc.data) ? orderLoc.data.fees.service : '0'}}%)</em></div>
                              <div class="col-4" align="right">${{orderService | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item" v-if="orderLoc.data && orderLoc.data.fees.delivery && orderType=='delivery'">
                          <div class="row">
                              <div class="col-8" align="right">Delivery Fee <em class="small">(flat rate)</em></div>
                              <div class="col-4" align="right">${{orderDelivery | currency}}</div>
                          </div>
                      </li>
                      
                      <li class="list-group-item">
                          <div class="row">
                              <div class="col-8" align="right"><h5><strong>Total</strong></h5></div>
                              <div class="col-4" align="right"><h5><strong>${{orderTotal | currency}}</strong></h5></div>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="modal-footer">
                  <div align="center" class="col-12">
                      <button type="button" class="btn btn-dark btn-block mb-3"  data-dismiss="modal" aria-label="Close" @click="btnCheckoutClick" v-if="locationActive && cart.length>0">CHECKOUT <i class="fas fa-chevron-right ml-3"></i></button>
                      <button type="button" class="btn btn-dark btn-block" data-dismiss="modal" aria-label="Close" v-if="locationActive && cart.length<1">CONTINUE <i class="fas fa-chevron-right ml-3"></i></button>
                      <p class="text-danger" align="center" v-if="!locationActive"><em>{{inactiveLocationMsg}}</em></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {

  },
  filters: {
      currency: function (value) {
        if (!value) return 0
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
  },
  components:{

  },
  computed:{
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      pastOrders(){
          return this.$store.state.pastOrders
      },
      orderid(){
          return this.$store.state.orderid
      },
      orderType(){
          return this.$store.state.ordertype
      },
      orderLoc(){
          return this.$store.state.orderlocation
      },
      orderTotal(){
          return (this.cartTotal + this.orderTaxes + this.orderService + this.orderDelivery)
      },
      orderDelivery(){
          return (this.orderLoc.data && this.orderType==='delivery') ? Number(this.orderLoc.data.fees.delivery) : 0
      },
      orderService(){
          let fee = (this.orderLoc.data) ? Number(this.orderLoc.data.fees.service/100) : 0
          return Number(this.cartTotal) * fee 
      },
      orderTaxes(){
          let fee = (this.orderLoc.data) ? Number(this.orderLoc.data.fees.tax/100) : 0
          return Number(this.cartTotal) * fee 
      },
      lineitemtotal(){
          return (Number(this.itemqty) * (Number(this.itemprice) + Number(this.itemaddonprice)))
      },
      cart(){
          return this.$store.state.cart
      },
      cartTotal(){
          let total = 0
          for(var i=0; i<this.cart.length; i++){
              total += Number(this.cart[i].subtotal)
          }

          return total
      },
      cartCount(){
          let count = 0
          for(var i=0; i<this.cart.length; i++){
              count += Number(this.cart[i].qty)
          }

          return count
      },
      locationActive(){
          const locations = this.$store.state.locations
          const activeloc = this.$store.state.orderlocation.id

          let filter = (loc)=>{
                            return loc.id===activeloc
                        }

          locations.filter(filter)

          if(locations.filter(filter)[0] && !locations.filter(filter)[0].data.public) return false

          return true
      },
      inactiveLocationMsg(){
          return this.$store.state.inactiveLocationMsg
      },
      carNumber(){
          return this.$store.state.carnumber
      },
      isIOS(){
          if(window.localStorage.isIOS) return window.localStorage.isIOS
          return this.$store.state.isIOS
      },
  },
  data(){
      return{
          txtsearch:'',
          activeitem:{},
          itemqty: 1,
          itemoptions:[],
          itemaddons:[],
          itemprice:0,
          itemaddonprice:0,
          itemname:'',
          itemid:'',
      }
  },
  methods:{
      btnCartClick(){

      },
      delCartItem(index){
          this.$store.commit('updateCart', index)
      },
      btnCheckoutClick(){
          this.$store.commit('setSectionname', '')
          let url = `/${this.storeId}/order/checkout`
          this.$router.push(url)
      },
      cancelOrderClick(){
          let msg = "Are you sure you want to start over this order?"
          var r = confirm(msg)
          if (r == true) {
              this.$store.state.cart = []
              window.localStorage.clear()
              window.localStorage.setItem(`${this.storeId}History`, JSON.stringify(this.pastOrders))
              let url = `/${this.storeId}`
              window.location.replace(url)
          }
      },
  },
  mounted(){
      window.scrollTo(0,0)
      document.getElementById("footermodal").click()
      if(document.getElementById("btnModalClose")) document.getElementById("btnModalClose").click()
  }
}
</script>
