<template>
	<div id="app">
		<nprogress-container></nprogress-container>
		<div id="nav">
		</div>
		
		<transition name="slide">
			<router-view v-cloak />
		</transition>
	</div>
</template>

<script>
	import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
	
	export default {
		name: "App",
		components: {
			NprogressContainer,
		}
	}
</script> 

<style>

	[v-cloak] {
		display: none;
	}

	.directory-action{
		display:block;
		margin:.75rem 0;
		font-weight:400;
	}

	.excerpt{
		overflow: hidden;
		word-wrap: break-word;
		text-overflow: ellipsis;
		max-height: 4.5em;
		line-height: 1.5em;
		display: -webkit-box;
		-webkit-line-clamp: 3; /* number of lines to show */
		-webkit-box-orient: vertical;
		white-space: pre-line;
	}

	.pointer{
		cursor:pointer;
	}

	.card-img-container{
		display:block;
		min-height: 100px;
	}

	.search-bar{
		background:#ffffff; 
		box-shadow:1px 1px 7px rgba(0,0,0,0.2); 
		z-index:1000; 
		position:fixed; top:0; left:0; right:0;
	}

	.logo-img-container{
		display:block;
		min-height: 180px;
	}

	.pac-container {
        z-index: 10000 !important;
    }

    .mb-ios{
        margin-bottom: 5rem;
    }

	body{
		background-color: #fafafa;
		font-size:16px !important;
	}

	.modal-body{
		padding: 0.25rem .5rem 1rem;
	}

	.modal-header{
		padding: 1rem 1rem 0;
	}

	.no-margin{
		margin:.1rem 0;
	}

	.rounded{
		border-radius: .75rem !important;
	}

	.mb-10{
		padding-bottom: 10rem;
	}
	.card-text, .list-group-item{
		font-size:15px;
	}

	.card-title{
		font-size:20px;
		margin-bottom:0;
	}

	.card-body-alt{
		padding:.6rem 1.25rem !important;
	}

	.card-alt, .card, .card-img-only{
        border:0;
        box-shadow: 1px 1px 10px rgba(0,0,0,0.03);
		border-radius: 1rem;
    }

	.card-img-top {
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem /* calc(.25rem - 1px) */;
	}

	.cat-header{
		border-bottom:0px solid #f1f1f1; 
		padding:.5rem 1rem 0; 
		position:fixed; 
		right:0; left:0; top:0; z-index:99; 
		box-shadow:1px 1px 5px rgba(0,0,0,0.0);
		background-color:#ff0000;
		color: #fff;
	}

	.list-group-item:first-child{
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	.list-group-item:last-child {
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

    .bold{
        font-weight:800;
    }

	a,
	a:hover {
		color: #000;
	}

	.no-border{
		border:0 !important;
	}

	.list-striped li:nth-child(even){
		background:#f9f9f9 ;
	}

	.list-no-border li{
		border:0;
	}

	.app-header{
		border-bottom:0px solid #fafafa; 
		box-shadow:0px 1px 5px rgba(0,0,0,0); 
		position:fixed; z-index:5; 
		padding:.5rem 2.5rem 0.25rem 1rem; 
		background-color:#ff0000;
		left:0;
		color: #fff;
	}

	.busy-spinner{
		position:absolute; 
		top:0; left:0; right:0; bottom:0; 
		z-index:1000; 
		padding:50% 50%;
	}

	.app-header-alt{
		margin-bottom:1rem; 
		background:#fafafa; 
		top:0; left:0; right:0; 
		position:fixed; z-index:100; 
		padding-right:1rem;
	}

	.app-footer{
		border-top:1px solid #f1f1f1; 
		padding:1rem 0.7rem; 
		position:fixed; 
		bottom:0; right:0; left:0; 
		background:#fafafa;
	}

	.profile-img{
		border-radius:100%;
		width:60%;
		height: auto;
		text-align: center;
	}

	.mt-5{
		padding-top:2rem;
	}

	.mobile.nav-tabs .nav-link.active {
		color: #f00;
		background-color: #fafafa;
		border-color: #fafafa #f9f9f9 #f00;

	}

	.nav-tabs .nav-link.active {
		background-color: #f9f9f9;
	}

	.vnis__button {
        background:#343a40 !important;
    }

    .vnis__input, .vnis__button{
        height:37px  !important;
    }

    .border-bottom{
        border-bottom:1px solid #f1f1f1;
    }

    .txt-center{
        text-align:center;
    }

    .hide-menu{
        display:none;
    }

    .fixed-header{
        padding:10px 20px !important;
        display:block;
        position:fixed;
        background:#fff;
        border-bottom:1px solid #f1f1f1;
        top:0;
        left:0;
        right:0;
        z-index:100;
    }

    .fixed-header a{
        font-weight:600;
    }

    .fixed-footer{
        position:fixed;
        bottom:0;
        left:0;
        right:0;
        display:block;
        background:#fff;
        margin:0;
        z-index:100;
    }

    .pb-100{
        padding-bottom:150px;
    }

    .py-100{
        padding-top:100px;
    }

    .bold{
        font-weight:600;
    }

    .cover-img{
        background-size: cover;
        background-position: center;
    }

    .category-tile{
        background-size: cover;
        background-position: center;
        margin:auto;
        border: 2px solid #ffffff; 
        background:#000000  cover center; 
        height:150px;
        cursor:pointer;
        z-index: 0 !important;
    }


    div.transbox {
        background-color: #000000;
        opacity: 0.4 !important;
        margin:0;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        z-index:-10 !important;
    }

    .logo-img{
        position:absolute;
        bottom:25px;
    }

    .category-title {
      margin:3.5rem auto !important;
      color:#fff !important;
      text-align: center;
      font-weight:500 !important;
      z-index:10 !important;
      font-size:16px !important;
    }

    a:hover{
        text-decoration:none;
    }

	.radio-label{
		font-size:15px;
	}

	.list-group-flush li:last-child{
		border-bottom:0 ;
	}

	.list-group-flush li:first-child{
		border-top:0 ;
	}

	.tab-pane1{
		height:550px;
		overflow-y:scroll;
		width:100%;
	}

	@media (max-width: 768px) {

		.card-title-white{
			color:#fff;
			margin-top:-4.0rem;
			background-color: rgba(0,0,0,0.05);
			text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
		}

		.shop-vendor > .col-4:nth-child(2){
			padding-right:3px;
		}

		.shop-vendor > .col-4:nth-child(3){
			padding-left:9px;
			padding-right:9px;
		}

		.shop-vendor > .col-4:nth-child(4){
			padding-left:3px;
		}

		.shop-category.col-6:nth-child(even){
			padding-right:6px;
		}

		.shop-category.col-6:nth-child(odd){
			padding-left:6px;
		}
	}

	@media (max-width: 480px) {
		.col-4.nogutter {
			padding-left: 6px;
			padding-right: 6px;
		}

		.row.gutter-row {
			padding-left: 9px;
			padding-right: 9px;
		}

		.card-img-container{
			min-height: 180px;
		}

		.logo-img-container{
			min-height: 120px;
		}

		
	}

	
</style>
