<template>
    <div class="row">
        
            <div class="col-lg-7 my-3">
                <input type="text" class="form-control" @input="txtsearch=$event.target.value" placeholder="Search past orders..." />
            </div>
            <div class="col-lg-2 my-3" align="right">
                <div class="btn-group btn-block">
                    <button type="button" class="btn btn-primary"  data-toggle="modal" data-target="#pastOrdersFilterForm">Apply Filter</button>
                    <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#"><i class="fas fa-file-csv"></i> Export .csv</a>
                    </div>
                </div>
            </div>
      

        <div class="col-12" align="center" v-if="orders.length<1">
            <em>Past orders will appear here ...</em>
        </div>
        <div class="col-12  my-3" align="left" v-if="orders.length>0">
            <h4>Total Sales: ${{totalsales | currency}} <span class="ml-4">Order Count: {{orders.length}}</span></h4>
        </div>
        <div class="col-sm-6 col-lg-4 my-2"  v-for="(order, index) in ordersList" :key="index" >
            <div class="card pointer" data-toggle="modal" data-target="#pastorderscart" @click="viewOrder(order)" :style="(order.data.status==='processing') ? 'border:1px #28a745 solid !important;' : ''">
                <div class="card-body" align="center">
                    
                    <span v-if="order.data.ordertype==='pointofsale'" class="badge badge-dark mb-4">POINT OF SALE</span>

                    <span v-if="order.data.ordertype==='pickup'" class="badge badge-warning mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <span v-if="order.data.ordertype==='curbside'" class="badge badge-info mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <span v-if="order.data.ordertype==='delivery'" class="badge badge-primary mb-4">{{String(order.data.ordertype).toUpperCase()}}</span>

                    <h5 class="clip-text"><strong>{{order.data.customer.fullname}}</strong></h5>
                    <p class="small"><i class="fas fa-phone"></i> {{order.data.customer.mobile}}</p>
                    
                    <strong>Order #{{order.data.id}}</strong>
                    <p class="small">{{order.data.id | convertdate }}</p>
                  

                </div>
            </div>
        </div>


         <!-- Modal -->
        <div class="modal fade" id="pastOrdersFilterForm" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title"><strong>Filter Sales</strong></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 40px;">
                  <div class="row mb-3">
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.date">
                              <option value="0">Today</option>
                              <option value="7">Last 7 days</option>
                              <option value="30">Last 30 days</option>
                              <option value="365">Last 365 days</option>
                              <option value="-1">Custom</option>
                          </select>
                      </div>
                      <div class="row col-12 my-3" v-show="filter.date=='-1'">
                          <date-range-picker ref="picker" v-model="daterange" :singleDatePicker="false" :autoApply="true" :ranges="false" :maxDate="maxDate" @update="datePickerUpdate" class="col-12">
                          </date-range-picker>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.ordertype">
                              <option value="0">ALL Order Types</option>
                              <option value="curbside">Curbside</option>
                              <option value="delivery">Delivery</option>
                              <option value="pickup">Pickup</option>
                              <option value="pointofsale">Point of Sale</option>
                          </select>
                      </div>
                      <div class="col-12 my-3">
                          <select class="form-control" v-model="filter.paymenttype">
                              <option value="0">ALL Payment Types</option>
                              <option value="creditcard">Credit Card</option>
                              <option value="cashondelivery">Pay on Delivery</option>
                              <option value="cashonpickup">Pay on Pickup</option>                   
                          </select>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                  <div align="right" class="col-12">
                      <button type="button" class="btn btn-secondary col-3 col-lg-3 mr-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                      <button type="button" class="btn btn-primary col-3 col-lg-3" data-dismiss="modal" aria-label="Close" @click="applyFilters">Apply</button>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal view order-->
        <div class="modal fade" id="pastorderscart" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered1" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button id="btnPastOrdersModalClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="padding:20px 25px;">
                  <ViewCart :singleorder="singleorder" :orderLoc="orderLoc" :orderid="orderid" :cart="cart"/>
              </div>
              <div class="modal-footer">
                  <div class="col-12" align="center"><strong>Order delivered: {{singleorder.lastupdate | convertdate}}</strong></div>
              </div>
            </div>
          </div>
        </div>




    </div>
</template>

<script>
import ViewCart from '@/components/ViewCart.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const fb = require('../firebaseConfig.js')
var moment = require('moment')

export default {
  name: 'PastOrders',
  props: ['location'],
  filters: {
      currency(value) {
        if (!value) return '0.00'
        return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      },
      convertdate(value){
          if(!value) return ''
          return moment.unix(value).format("MMM DD YYYY - h:mm a")
      },
      capitalize: function (string) {
        if (!string) return ''
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
  },
  components:{
      ViewCart, DateRangePicker,
  },
  computed:{
      ordersList(){
          let orders = this.orders
          if(!this.txtsearch) return this.orders
          const search = this.txtsearch.toLowerCase()
          let filter = function(order){
                          return(order.data.customer.fullname.toLowerCase().includes(search) || order.data.customer.emailaddress.toLowerCase().includes(search) || order.data.customer.mobile.toLowerCase().includes(search) || order.id.includes(search))
                      }
          return orders.filter(filter)
      },
      storeInfo(){
          return this.$store.state.storeinfo
      },
      storeId(){
          return this.$store.state.storeid
      },
      totalsales(){
          let sales = 0
          let cleansales = 0
          const orders = this.orders
          const filter = (order)=>{
                      if(order.data.ordertype=='pointofsale'){
                          cleansales = Number(order.data.total - order.data.fees.delivery)
                      }else{
                          cleansales = Number(order.data.total)
                      }
                      sales += Number(cleansales)
                      return (order.id)
                  }
          orders.filter(filter)
          return sales
      },
  },
  data(){
      return{
          storeinfo:{pagesettings:{}, address:{}, deliveryoptions:{}, paymentoptions:{}, receiveorders:{},},
          storelocations:[],
          storeid:'',
          orders:[],
          orderid:'',
          singleorder:{},
          cart:[],
          txtsearch:'',
          pendingcount:0,
          orderLoc:'',
          filter:{date:'0', locations:'0', ordertype:'0', paymenttype:'0',},
          daterange:{startDate: moment(), endDate: moment()},
          maxDate: moment().add(1,'days').format('YYYY-MM-DD'),
      }
  },
  methods:{
      viewOrder(order){
          this.singleorder = order.data
          this.orderid = order.id
          this.cart = order.data.details
          this.orderLoc = order.data.pickuplocation.data
      },
      datePickerUpdate(){
      },
      applyFilters(){

          let filter = this.filter

          let ordersref = fb.ordersCollection.where("storeid", "==", this.storeid).where("pickuplocation.id", "==", this.location.id)

          if(filter.date != '-1') {
              let filterdate = moment().subtract(Number(filter.date),'d').startOf('day').unix()
              ordersref = ordersref.where("id", ">=", filterdate)
          }else{
              let startfilterdate = moment(this.daterange.startDate).startOf('day').unix()
              let endfilterdate = moment(this.daterange.endDate).endOf('day').unix()
              ordersref = ordersref.where("id", ">=", startfilterdate).where("id", "<=", endfilterdate)
          }
          if(filter.ordertype != '0') ordersref = ordersref.where("ordertype", "==", filter.ordertype)
          if(filter.paymenttype != '0') ordersref = ordersref.where("paymethod", "==", filter.paymenttype)
          ordersref.where("status", "in", ["complete", "pending", "ready"]).orderBy("id", "desc").onSnapshot((querySnapshot)=>{
              let j=0
              this.orders.length=0
              querySnapshot.forEach((doc)=>{
                  this.orders.push({index:j, id:doc.id, data:doc.data()})
                  j++
              })
              if(this.orders.length<1) this.orders = []
          })


      },
      btnCancelClick(id){
          var r = confirm("Are you sure you want to cancel order #"+id);
          if (r == true) {
              fb.customerordersCollection.doc(String(id)).update({
                  status: "cancel"
              })
              .then(()=> {
                  this.$toast.success("Order status successfully updated!")
                  document.getElementById('btnPastOrdersModalClose').click()
              })
              .catch((error)=> {
                  console.error("Error updating document: ", error)
              })
          } else {
              return false
          }
      },
      btnDeliverClick(id){
          var r = confirm("Change order status to 'Complete' for order #"+id);
          if (r == true) {
              fb.customerordersCollection.doc(String(id)).update({
                  status: "complete",
                  lastupdate:Math.floor(Date.now()/1000),
              })
              .then(()=> {
                  this.$toast.success("Order status successfully updated!")
                  document.getElementById('btnPastOrdersModalClose').click()
              })
              .catch((error)=> {
                  console.error("Error updating document: ", error)
              })
          } else {
              return false
          }
      },
  },
  mounted(){
      const storeid = this.$route.params.store
      this.storeid = storeid
      fb.storesCollection.doc(storeid).onSnapshot((doc) =>{
          if (doc.exists) {
              this.storeinfo = doc.data()
          } else {
              console.log("No such document!")
          }
      })
      let filterdate = moment().subtract(0,'d').startOf('day').unix()
      fb.ordersCollection.where("storeid", "==", storeid).where("pickuplocation.id", "==", this.location.id).where("id", ">=", filterdate).where("status", "==", "complete").orderBy("id", "desc").onSnapshot((querySnapshot)=>{
          let j=0
          this.orders.length=0
          querySnapshot.forEach((doc)=>{
              this.orders.push({index:j, id:doc.id, data:doc.data()})
              j++
          })
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
