<template>
    <div class="row mb-10">
        <div class="col-12 my-4" >
            <div class="card card-alt" style="border:0; padding:0;" >
                <div class="card-body">
                    <small>Post type:</small>
                    <select class="form-control my-3" v-model="feed.type">
                        <option value="post">Post</option>
                        <option value="news">News</option>
                        <option value="event">Event</option>
                    </select>
                    <input v-model="feed.title" v-show="feed.type!= 'post'" class="form-control my-3" placeholder="Title" />
                   <textarea v-model="feed.body" wrap="hard" rows="6" class="form-control my-3" placeholder="What's on your mind?"/>
                   <ImageUploader type="feed" docId="null" currentImg="" :folder="`cruzlifefeed`" @downloadURL="handleImageUpload"/>
                   <hr/>
                   <button class="my-3 btn btn-block btn-dark btn-lg" :disabled="isBusy" @click="submitPost">
                       <span v-if="!isBusy">Submit Post</span>
                        <div v-if="isBusy" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                   </button>
                   <button class="my-1 btn btn-block btn-outline-dark" @click="$emit('btnCancelPost')">
                       Cancel
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import ImageUploader from '@/components/ImageUploader'
    import Vue from 'vue'
    Vue.use(require('vue-moment'))
    const fb = require('../firebaseConfig.js')
    var oWebViewInterface = window.nsWebViewInterface

    export default {
        name: 'CruzlifeFeedAdd',
        props: {

        },
        filters: {
          currency: function (value) {
            if (!value) return ''
            return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
          }
        },
        components:{
            ImageUploader,
        },
        computed:{
              
        },
        data(){
            return{
                isBusy:false,
                imgData: {imgFile:'', imgFileName:''},
                feed:{
                    title:'',
                    type:'post',
                    body:'',
                    img:'',
                    postDate:'',
                    status: true,
                    excerpt:'',
                    author:'system',
                    location:'cruzlife',
                },
                feedError: true,
            }
        },
        methods:{
            submitPost(){
                this.isBusy = true
                this.feedError = false
                if(!this.feed.title && this.feed.type!='post') this.feedError = true
                if(!this.feed.body) this.feedError = true
                if(this.feedError===false){
                    this.feed.postDate = Math.floor(Date.now() / 1000)
                    this.feed.img = this.imgData.imgFile
                    this.feed.imgFileName = this.imgData.imgFileName
                    fb.feedCollection.add(this.feed).then((doc)=>{
                        console.log(doc.id)
                        this.feed = {
                            title:'',
                            type:'post',
                            body:'',
                            img:'',
                            postDate:'',
                            status:false,
                            excerpt:'',
                            author:'system',
                            location:'cruzlife',
                        }
                        oWebViewInterface.emit('feedActionClick', {action: "savePost", data:false})
                        this.$emit("btnCloseModalClick")
                        this.imgData = {imgFile:'', imgFileName:''}
                        this.isBusy = false
                        window.scrollTo(0,0)
                        this.$emit("btnCancelPost")
                    })
                }else{
                    Vue.$toast.error('Please fill in all the required text fields.', {position:'top'})
                    this.isBusy = false
                    window.scrollTo(0,0)
                }
            },
            handleImageUpload(data){
				this.imgData = data
			},
        },
        mounted(){
            window.scrollTo(0,0)
        }
    }
</script>
<style >

</style>

